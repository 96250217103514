import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OrganisationDTO, OrganisationInformationDTO, OrganisationDocumentsAndNotesDTO, OrganisationListItemDTO, OrganisationFilters, OrganisationStatusDTO, PersonRoleDTO, ApiListResponse, ThingDTO, OrganisationMembersDTO, OrganisationFinancialDTO, OrganisationWithRelationsDTO, OrganisationVenueInformationDTO, OrganisationCompetitionListItemDTO, OrganisationSanctionsDTO, OrganisationCategories, OrganisationTempDTO, PersonFilters } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { OrganisationLeagueListItemDTO } from '@fiba/models/dtos/generated/organisation-league-list-item-dto';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrganisationDataService {
    protected organisationApiEndpoint: string;
    protected organisationAgenciesApiEndpoint: string;
    protected organisationAutocompleteApiEndpoint: string;
    protected organisationStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataOrganisationStatusService: DataCachedService<OrganisationStatusDTO>) {
        this.organisationApiEndpoint = config.organisationApiEndpoint;
        this.organisationAgenciesApiEndpoint = config.organisationAgenciesApiEndpoint;
        this.organisationAutocompleteApiEndpoint = config.organisationAutocompleteApiEndpoint;
        this.organisationStatusApiEndpoint = config.organisationStatusApiEndpoint;
    }

    fetchList(filters: OrganisationFilters): Observable<ApiListResponse<OrganisationListItemDTO>> {
        return this.http.get(this.organisationApiEndpoint, filters.getSearchParams());
    }

    fetchOrganisationsAgencies(filters: OrganisationFilters): Observable<OrganisationListItemDTO[]> {
        return this.http.get(this.organisationAgenciesApiEndpoint, filters.getSearchParams()).pipe(
            map((result: ApiListResponse<OrganisationListItemDTO>) => { return result.data})
        );
    }

    fetchOrganisation(organisationId: number): Observable<OrganisationDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}`);
    }

    fetchOrganisationForPicker(organisationId: number, displayNFWithCountryName: boolean = false, displayLeagueWithOrganisationName: boolean = false): Observable<OrganisationDTO> {
        const params = {
             displayNFWithCountryName: String(displayNFWithCountryName)
            , displayLeagueWithOrganisationName: String(displayLeagueWithOrganisationName)
        };
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-picker`, params);
    }
    fetchOrganisationInformation(organisationId: number, context?: string): Observable<OrganisationInformationDTO> {
        let params = {};
        if (context) {
            params = { context };
        }
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-information`, params);
    }

    fetchOrganisationSanctions(organisationId: number): Observable<OrganisationSanctionsDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-sanctions`);
    }

    fetchOrganisationFinancial(organisationId: number): Observable<OrganisationFinancialDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/financial`);
    }

    fetchOrganisationVenues(organisationId: number): Observable<OrganisationVenueInformationDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/venues`);
    }

    fetchOrganisationMembers(organisationId: number): Observable<OrganisationMembersDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/unfiltered-organisation-members`);
    }
    fetchFilteredOrganisationMembers(organisationId: number, filters: PersonFilters): Observable<OrganisationMembersDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-members`, filters.getSearchParams());
    }
    fetchOrganisationWithRelations(organisationId: number): Observable<OrganisationWithRelationsDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-with-relations`);
    }

    fetchStatuses(): Observable<OrganisationStatusDTO[]> {
        return this.dataOrganisationStatusService.fetchEntities(this.organisationStatusApiEndpoint);
    }

    fetchAutoCompleteList(name: string = '', organisationCategories: Array<string>, displayNFWithCountryName: boolean = false, displayLeagueWithOrganisationName: boolean = false, excludedOrganisationId: number = undefined, isZoneOrganisationCommittee: boolean = undefined, approvalCategoryId: number = undefined): Observable<ThingDTO[]> {
        const params = {
            name
            , displayNFWithCountryName: String(displayNFWithCountryName)
            , displayLeagueWithOrganisationName: String(displayLeagueWithOrganisationName)
        };
        if (organisationCategories && organisationCategories.length > 0) {
            params['organisationCategoryCodes'] = organisationCategories;
        }
        if (excludedOrganisationId) {
            params['excludedOrganisationId'] = String(excludedOrganisationId);
        }
        if (isZoneOrganisationCommittee) {
            params['isZoneOrganisationCommittee'] = isZoneOrganisationCommittee;
        }
        if (approvalCategoryId) {
            params['approvalCategoryId'] = approvalCategoryId;
        }
        return this.http.get(this.organisationAutocompleteApiEndpoint, params);
    }

    fetchOrganisationChart(organisationId: number): Observable<PersonRoleDTO[]> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/chart`);
    }

    downloadExport(filters: OrganisationFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.organisationApiEndpoint}/export-csv`, filters.getSearchParams());
    }

    exportOrganisationMembers(organisationId: number, filters: PersonFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.organisationApiEndpoint}/${organisationId}/organisation-members/export-xls`, filters.getSearchParams());
    }

    exportOrganisationRelations(organisationId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.organisationApiEndpoint}/${organisationId}/organisation-relations/export-xls`);
    }

    createOrganisationInformation(model: OrganisationInformationDTO): Observable<OrganisationInformationDTO> {
        return this.http.post(`${this.organisationApiEndpoint}/organisation-information`, model);
    }

    createTemporaryOrganisation(model: OrganisationTempDTO): any {
        return this.http.post(this.organisationApiEndpoint, model);
    }

    updateOrganisationInformation(model: OrganisationInformationDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/organisation-information`, model);
    }

    updateOrganisationSanctions(model: OrganisationSanctionsDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/organisation-sanctions`, model);
    }

    updateOrganisationFinancial(model: OrganisationFinancialDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/financial`, model);
    }

    updateOrganisationVenues(model: OrganisationVenueInformationDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/venues`, model);
    }

    updateOrganisationMembers(model: OrganisationMembersDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/organisation-members`, model);
    }
    updateOrganisationWithRelations(model: OrganisationWithRelationsDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/organisation-with-relations`,model);
    }

    fetchOrganisationDocuments(organisationId: number): Observable<OrganisationDocumentsAndNotesDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-documents`);
    }

    updateOrganisationDocuments(model: OrganisationDocumentsAndNotesDTO): Observable<any> {
        return this.http.put(`${this.organisationApiEndpoint}/${model.organisationId}/organisation-documents`, model);
    }

    fetchOrganisationCompetitions(organisationId: number): Observable<OrganisationCompetitionListItemDTO[]> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/competitions`);
    }

    fetchLeagueList(): Observable<OrganisationLeagueListItemDTO[]> {
        return this.http.get(`${this.organisationApiEndpoint}/leagues`);
    }
}
