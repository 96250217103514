import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { EventDataService, ItemDataService } from '@fiba/data-services';
import { FibaLoadingService } from '@fiba/loading';
import { City, EventFilters, EventListItemDTO, EventTypes, ItemCategoryCodes, MapPolicy } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';

const EVENT_PAGE_SIZE = 30;
const EVENT_PICKER_PAGE_SIZE = 5;
const STORAGE_KEY = 'EventSearchComponent';
const PICKER_STORAGE_KEY = 'EventSearchPicker';

@Component({
    selector: 'event-search',
    templateUrl: './event-search.component.html',
})

export class EventSearchComponent implements OnInit {
    public readonly ItemCategoryCodes_EventType: string = ItemCategoryCodes.EventType;
    public readonly EventTypes_Test: string = EventTypes.Test;
    public readonly MapPolicy_EventsEdit = MapPolicy.EventsEdit;
    public isLoading: boolean;
    public filters: EventFilters;
    public cityName: City;

    protected filterStorageKey: string;
    public sort: SortDescriptor[] = [];
    public exportInProgress = false;
    events: GridDataResult;
    protected fetchSub: Subscription;

    @Input() public fromPicker = false;
    @Input() public filterTestEvents = false;

    @Input() public value: EventListItemDTO;
    @Output() public valueChange: EventEmitter<EventListItemDTO> = new EventEmitter<EventListItemDTO>();

    constructor(
        protected dataService: EventDataService,
        protected itemDataService: ItemDataService,
        protected router: Router,
        protected notificationService: NotificationService,
        protected simpleStorageService: SimpleStorageService,
        protected fibaLoadingService: FibaLoadingService) {
    }

    public ngOnInit(): void {

        this.filterStorageKey = this.fromPicker ? PICKER_STORAGE_KEY : STORAGE_KEY;

        const savedFilters = this.simpleStorageService.get(this.filterStorageKey);
        if (savedFilters) {
            this.filters = savedFilters.clone();

        } else {
            this.filters = new EventFilters(this.fromPicker ? EVENT_PICKER_PAGE_SIZE : EVENT_PAGE_SIZE);
        }

        // Force test events in filters
        if (this.filterTestEvents) {
            this.filters.testEventsOnly = true;
        }
        this.fetchList();
    }

    protected fetchList(displayLoading: boolean = true): void {
        this.isLoading = displayLoading;
        this.fibaLoadingService.show(800, 'Loading');
        if (this.fetchSub) {
            this.fetchSub.unsubscribe();
        }
        this.fetchSub = this.dataService.fetchList(this.filters).subscribe(
            (val) => {
                this.events = val;
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
                Logger.error(error);
            },
            () => {
                this.isLoading = false;
                this.fibaLoadingService.hide();
            },
        );
    }

    public filter(): void {
        this.filters.skip = 0;
        this.fetchList(true);
        if (this.filterStorageKey) {
            this.simpleStorageService.set(this.filterStorageKey, this.filters.clone());
        }
    }

    /**
     * Resets the filters
     */
    public reset(): void {
        this.filters.reset();
        this.cityName = undefined;

        if (this.filterStorageKey) {
            this.simpleStorageService.delete(this.filterStorageKey);
        }
    }

    // Grid events
    public handlePageChange(event: PageChangeEvent): void {
        this.filters.skip = event.skip;
        this.fetchList(false);
    }

    public handleSortChange(sort: SortDescriptor[]): void {
        Logger.debug(sort);
        this.sort = sort;
        this.filters.skip = 0;
        this.filters.sortBy = sort[0].field;
        this.filters.sortOrder = sort[0].dir;
        this.fetchList();
    }

    public handleSelChange(event: SelectionEvent): void {
        const firstSelectedRowDataItem = event?.selectedRows?.[0]?.dataItem;

        if (this.fromPicker) {
            const currentEventListItem = firstSelectedRowDataItem;
            this.valueChange.emit(currentEventListItem);
        } else {
            let currentEventId: number = firstSelectedRowDataItem.eventId;
            // Event -> (Test/Standard)
            if (!!currentEventId) {
                this.router.navigate(['events', currentEventId]);
            } else {
                currentEventId = firstSelectedRowDataItem.competitionId;
                this.router.navigate(['events', currentEventId, 'competition-info']);
            }
        }
    }

    public goToNew(): void {
        this.router.navigate(['events/create']);
    }

    public downloadHandler(): void {
        this.exportInProgress = true;
        this.dataService.downloadExport(this.filters).subscribe(
            (res: any) => {
                if (!(window.navigator as any).msSaveOrOpenBlob) {
                    const url = window.URL.createObjectURL(res.blob);
                    this.notificationService.emitFileNotification(res.name, url);
                } else {
                    (window.navigator as any).msSaveBlob(res.blob, res.name);
                }
                this.exportInProgress = false;
            },
            (error: any) => {
                Logger.error(error);
                this.notificationService.emitNotification(NotificationType.Error, error);
            },
            () => Logger.info('Completed file download.'),
        );
    }
}
