import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { FibaInputBase } from '@fiba/forms';

/**
 * This class gives the base setup for a popup-based picker
 */
@Directive()
export abstract class FibaPickerBase<T> extends FibaInputBase<T> implements OnInit {
    public isNew: boolean;
    public tmpValue: T; // Bound to the popup (and lives only while the popup is open), whereas this.value is the value managed by the picker component
    protected idField: string;
    @Input() public openAtLoad = false;

    // TODO CODE REVIEW : May have to remove ngModel in picker
    @Input() protected byPassBugNgModel = false;

    @Input() protected modelId: number;
    @Output() public modelIdChange: EventEmitter<number> = new EventEmitter();
    @Output() public modelChange: EventEmitter<T> = new EventEmitter();

    public ngOnInit() {
        if (!this.byPassBugNgModel) {
            super.ngOnInit();
        }
        if (this.openAtLoad) {
            this.editHandler();
        }
    }

    public editHandler() {
        if (this.value) {
            this.tmpValue = JSON.parse(JSON.stringify(this.value)); // Duplicate object. TODO : Find cleaner way? EDIT: 26/04 do lodash CLONE
            this.isNew = false;
        } else {
            this.tmpValue = this.newModel();
            this.isNew = true;
        }
    }

    public cancelHandler() {
        this.tmpValue = undefined;
    }

    public saveHandler(item: T) {
        this.value = item;

        this.modelId = item ? item[this.idField] : null;
        this.modelIdChange.emit(this.modelId);
        this.modelChange.emit(this.value);
        this.tmpValue = undefined;
        this.manualChange(); // mark as touched
    }

    protected newModel(): T { return {} as T; }

}
