<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox #pickerCombobox
                    [data]="listItems"
                    [textField]="'code'"
                    [valueField]="'sanctionId'"
                    [placeholder]="'Search sanctions...'"
                    [filterable]="true"
                    [(ngModel)]="value"
                    (valueChange)="valueChanged($event)"
                    (cancel)="cancelHandler()"
                    [disabled]="disabled"
                    class="no-arrow fiba-picker"></kendo-combobox>
    <button kendoButton type="button"
            (click)="editHandler()"
            class="icon-only"
            [disabled]="disabled">
        <i class="fa fa-lg fa-search"></i>
    </button>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>

<fibaPopupSanctionSearch [contextCode]="contextCode"
                         [model]="tmpValue"
                         (save)="saveHandler($event)"
                         (cancel)="cancelHandler()">
</fibaPopupSanctionSearch>
