<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{ isNew ? 'Add new document' : 'Edit document' }}
    </kendo-dialog-titlebar>
    <form #form="ngForm" novalidate [ngClass]="{'submitted': submitted}" autocomplete="off">
        <div class="form vertical labels-left">
            <fibaSelectItem *ngIf="!hideTypeDropdown"
                            [disabled]="!isNew"
                            label="Type"
                            name="type"
                            [(ngModel)]="_model.typeItem"
                            [categoryCode]="categoryCode"
                            [parentCode]="parentCode"
                            [required]="true">
            </fibaSelectItem>

            <fibaInputText name="title" label="Title" [required]="true" [(ngModel)]="_model.title"></fibaInputText>

            <ng-template [ngIf]="documentType == DocumentGenericTypes_NATIONALITY">
                <fibaInputText name="identificationDocumentNumber"
                               label="ID number"
                               [(ngModel)]="_model.identificationDocumentNumber"
                               [required]="true">
                </fibaInputText>
                <fibaInputDate [formSaveSuccessEvent]="formSaveSuccessEvent"
                               name="issueDate"
                               label="Issue date"
                               [(ngModel)]="_model.identificationDocumentIssueDate"
                               fibaGreaterValidator="expirationDate" lowestDate="true"
                               [required]="roleFamily == RoleFamilies_Player">
                </fibaInputDate>
                <fibaInputDate [formSaveSuccessEvent]="formSaveSuccessEvent"
                               name="expirationDate"
                               label="Expiration date"
                               [(ngModel)]="_model.identificationDocumentExpirationDate"
                               fibaGreaterValidator="issueDate"
                               [required]="!_model.hasNoExpirationIdentificationDocument && roleFamily == RoleFamilies_Player"
                               [disabled]="_model.hasNoExpirationIdentificationDocument">
                </fibaInputDate>
                <fibaCheckbox name="hasNoExpirationIdentificationDocument" label="No expiration date"
                              [(ngModel)]="_model.hasNoExpirationIdentificationDocument"
                              (change)="onHasNoExpirationChanges()">
                </fibaCheckbox>
            </ng-template>
            <ng-template [ngIf]="showComment">
                <fibaInputText name="comment" label="Comment" [(ngModel)]="_model.comment"></fibaInputText>
            </ng-template>

            <fibaInputUpload [disabled]="!_model.typeItem"
                             [title]="_model.title"
                             [item]="_model.typeItem"
                             [documentId]="_model.documentId"
                             [entityTypeBase]="entityTypeBase"
                             [entityIdBase]="entityIdBase"
                             [entityType]="entityType"
                             [entityId]="entityId"
                             (getTitle)="getTitle($event)"
                             (getDocumentInfo)="getDocumentInfo($event)"
                             (fileUploaded)="fileUploadedHandler($event)"
                             [saveMethod]="saveMethod"
                             [documentName]="documentName"
                             [publicApi]="publicApi"
                             [isNew]="isNew"
                             [restrictionPicture]="restrictionPicture">
            </fibaInputUpload>
        </div>
    </form>
    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button class="k-button bg-gold" [disabled]="!fileUploaded" (click)="onSave($event)">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>