import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { CompetitionTeamDelegationDTO, PreliminaryRosterDTO, CompetitionTeamDelegationSummaryDTO, ThingDTO, PreliminaryRosterConfigurationListItemDTO, FinalRosterConfigurationListItemDTO, PreliminaryRosterConfigurationCarouselItemDTO, CompetitionTeamDelegationFilters, FinalRosterDTO, FinalRosterMemberDTO, UniformSetStatusDTO, RosterConfigurationItemDTO, CompetitionTeamDelegationFiltersV2, TeamDelegationConfigurationDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionTeamsDelegationDataService {

    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionTeamDelegation(filters: CompetitionTeamDelegationFilters): Observable<CompetitionTeamDelegationDTO> {
        const params = filters.getSearchParams();

        if (filters.preliminaryRosterConfiguration) {
            params['preliminaryRosterId'] = String(filters.preliminaryRosterConfiguration.preliminaryRosterId);
            params['preliminaryRosterConfigurationId'] = String(filters.preliminaryRosterConfiguration.preliminaryRosterConfigurationId);
        }
        if (filters.finalRosterConfiguration) {
            params['finalRosterId'] = String(filters.finalRosterConfiguration.finalRosterId);
            params['finalRosterConfigurationId'] = String(filters.finalRosterConfiguration.finalRosterConfigurationId);
        }

        const competitionTeamId = (!!filters.competitionTeam ? filters.competitionTeam.competitionTeamId : null);
        return this.http.get(`${this.competitionApiEndpoint}/${filters.competitionId}/team-delegation/${competitionTeamId}/members`, params);
    }

    fetchCompetitionTeamDelegationV2(filters: CompetitionTeamDelegationFiltersV2): Observable<CompetitionTeamDelegationDTO> {
        const params = filters.getSearchParams();

        if (filters.rosterConfiguration) {
            params['preliminaryRosterId'] = String(filters.rosterConfiguration.preliminaryRosterConfiguration.preliminaryRosterId);
            params['preliminaryRosterConfigurationId'] = String(filters.rosterConfiguration.preliminaryRosterConfiguration.preliminaryRosterConfigurationId);
            params['finalRosterId'] = String(filters.rosterConfiguration.finalRosterConfiguration.finalRosterId);
            params['finalRosterConfigurationId'] = String(filters.rosterConfiguration.finalRosterConfiguration.finalRosterConfigurationId);
        }

        const competitionTeamId = (!!filters.competitionTeam ? filters.competitionTeam.competitionTeamId : null);
        return this.http.get(`${this.competitionApiEndpoint}/${filters.competitionId}/team-delegation/${competitionTeamId}/members`, params);
    }

    createCompetitionTeamDelegation(model: CompetitionTeamDelegationDTO): Observable<CompetitionTeamDelegationDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/team-delegation`, model);
    }

    updateCompetitionTeamDelegation(model: CompetitionTeamDelegationDTO, isCommunication: boolean = false): Observable<any> {
        if (!isCommunication) {
            return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/team-delegation/${model.competitionTeamId}/members`, model);
        }
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/team-delegation/${model.competitionTeamId}/members-communication`, model);
    }

    updateCompetitionTeamDelegationPlayerInsurance(model: CompetitionTeamDelegationDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/team-delegation/${model.competitionTeamId}/members-player-insurance`, model);
    }

    fetchPreliminaryRosters(competitionId: number, competitionTeamId: number, showVersions: boolean): Observable<PreliminaryRosterConfigurationListItemDTO[]> {
        const params = {};
        if (showVersions) {
            params['showVersions'] = 'true';
        }

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/team-delegation/${competitionTeamId}/preliminary-roster`, params);
    }

    fetchRostersV2(competitionId: number, competitionTeamId: number, showVersions: boolean): Observable<RosterConfigurationItemDTO[]> {
        const params = {};
        if (showVersions) {
            params['showVersions'] = 'true';
        }

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/team-delegation/${competitionTeamId}/rosters`, params);
    }

    fetchFinalRoster(competitionId: number, competitionTeamId: number, preliminaryRosterConfigurationId: number, preliminaryRosterId: number, showVersions: boolean): Observable<FinalRosterConfigurationListItemDTO[]> {
        const params = {
            preliminaryRosterId: String(preliminaryRosterId),
            preliminaryRosterConfigurationId: String(preliminaryRosterConfigurationId)
        };
        if (showVersions) {
            params['showVersions'] = 'true';
        }

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/team-delegation/${competitionTeamId}/final-roster`, params);
    }

    fetchNextNFTeamDelegationSummary(competitionId: number): Observable<CompetitionTeamDelegationSummaryDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/team-delegation/next-summary`);
    }

    fetchFinalRosterForGame(competitionId: number, competitionTeamId: number, gameId: number, frmId: boolean = false): Observable<ThingDTO[]> {
        const params = {
            frmId: String(frmId)
        };

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/game/${gameId}/team/${competitionTeamId}/final-roster`, params);
    }
    fetchConfigurationPlayerInsuranceMaxAnnualGrossSalaryCovered(): Observable<number> {
        return this.http.get(`${this.competitionApiEndpoint}/max-annual-gross-salary-covered`);
    }

    fetchUniformSetStatuses(): Observable<UniformSetStatusDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/uniform-set-statuses`);
    }

    fetchTeamDelegationConfigurationByRole(competitionId: number, roleCode: string): Observable<TeamDelegationConfigurationDTO> {
        const params = {};
        if (roleCode) {
            params['roleCode'] = roleCode;
        }
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/team-delegation-configuration-by-role`,params);
    }

    confirmPreliminaryRosterInsurance(preliminaryRosterId: number, competitionId: number, competitionTeamId: number, preliminaryRosterConfigurationId: number,): Observable<any> {
        const params = {
            competitionId: String(competitionId),
            competitionTeamId: String(competitionTeamId),
            preliminaryRosterConfigurationId: String(preliminaryRosterConfigurationId)
        };

        return this.http.put(`${this.competitionApiEndpoint}/team-delegation/${preliminaryRosterId}/confirm-insurance`, null, params);
    }

    public downloadInsurancePolicyDocuments(preliminaryRosterConfigurationId: number) {
        return this.http.getFileData(`${this.competitionApiEndpoint}/preliminary-roster-configuration/${preliminaryRosterConfigurationId}/download-insurance-documents`, null, 'insurancePolicyDocuments.zip');
    }

    public getTeamInformationForm(competitionId: number, competitionTeamId: number, preliminaryRosterId: number) {
        return this.http.getFileData(`${this.competitionApiEndpoint}/${competitionId}/team-delegation/${competitionTeamId}/preliminary-roster/${preliminaryRosterId}/team-information-form`, null, 'TeamRegistrationForm.zip');
    }

}
