<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox id="{{name}}"
                    [placeholder]="placeholder"
                    [data]="_filteredItems"
                    [valuePrimitive]="valuePrimitive"
                    [(ngModel)]="value"
                    [suggest]="true"
                    [filterable]="true"
                    [disabled]="disabled"
                    (filterChange)="handleFilter($event)"
                    (valueChange)="handleValue($event)"
                    [allowCustom]="false">
    </kendo-combobox>   
    <div *ngIf="ngControl && ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                'Season from' must be lower than 'Season to'
            </li>
        </ul>
    </div>
</div>
