import { Injectable } from '@angular/core';

import { HttpService } from '../utils/http.service';
import { Observable } from 'rxjs';
import { ApiListResponse, FormRegistrationCompetitionTeamDetailDTO } from '@fiba/models';
import { FormRegistrationCompetitionTeamFilters } from '@fiba/models/form-registration-competition-team/form-registration-competition-team';
import { FormRegistrationCompetitionTeamListItemDTO } from '@fiba/models/dtos/generated/form-registration-competition-team-list-item-dto';
import { FormRegistrationAction } from '@fiba/models/constants/generated/form-registration-action';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class CompetitionTeamFormRegistrationDataService {
    protected competitionTeamFormRegistrationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionTeamFormRegistrationApiEndpoint = config.competitionTeamFormRegistrationApiEndpoint;
    }

    fetchList(filters: FormRegistrationCompetitionTeamFilters): Observable<ApiListResponse<FormRegistrationCompetitionTeamListItemDTO>> {
        return this.http.get(this.competitionTeamFormRegistrationApiEndpoint, filters.getSearchParams()).pipe(map(
            response => response as ApiListResponse<FormRegistrationCompetitionTeamListItemDTO>));
    }

    fetchCompetitionTeamFormRegistration(competitionTeamFormRegistrationId: number): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.get(this.competitionTeamFormRegistrationApiEndpoint + '/' + competitionTeamFormRegistrationId);
    }

    fetchInitialVersion(entityItemId?: number, competitionTeamId?: number): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        const params = {};
        if (entityItemId) {
            params['entityItemId'] = entityItemId;
        }
        if (competitionTeamId) {
            params['competitionTeamId'] = competitionTeamId;
        }
        return this.http.get(this.competitionTeamFormRegistrationApiEndpoint + '/initial-version', params);
    }

    createCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.post(this.competitionTeamFormRegistrationApiEndpoint, model);
    }

    createSubmitCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.post(this.competitionTeamFormRegistrationApiEndpoint + '?action=' + FormRegistrationAction.Submit, model);
    }

    updateCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId, model);
    }

    submitCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Submit, model);
    }

    reopenCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Reopen, model);
    }

    approveCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Approve, model);
    }

    rejectCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Rejected, model);
    }

    requestInformationCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.PendingUpdates, model);
    }

    cancelCompetitionTeamFormRegistration(model: FormRegistrationCompetitionTeamDetailDTO): Observable<FormRegistrationCompetitionTeamDetailDTO> {
        return this.http.put(this.competitionTeamFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Cancel, model);
    }

}
