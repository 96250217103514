import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import { SelectionEvent } from '@progress/kendo-angular-grid';

import { FilterableArray, NationalFederationListItemDTO, ZoneDTO } from '@fiba/models';

import { NationalFederationSearchBaseComponent } from '../../../base/national-federation-search-base.component';

import { NationalFederationDataService } from '@fiba/data-services';
import { NotificationService } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';

const NF_MINI_SEARCH_PAGE_SIZE = 5;

@Component({
    selector: 'national-federation-search-mini',
    templateUrl: './national-federation-search-mini.component.html',
})
export class NationalFederationSearchMiniComponent extends NationalFederationSearchBaseComponent implements OnInit {
    @Input() public contextCode: string;

    @Input() public value: NationalFederationListItemDTO;
    @Output() public valueChange: EventEmitter<NationalFederationListItemDTO> = new EventEmitter<NationalFederationListItemDTO>();

    @Input()
    public set zone(zoneFilter: FilterableArray) {
        if (zoneFilter) {
            this.filters.zone.setFilterValue(zoneFilter.value);
        } else {
            this.filters.zone.reset();
        }
    }

    constructor(
        protected dataService: NationalFederationDataService,
        protected notificationService: NotificationService,
        protected simpleStorageService: SimpleStorageService,
        protected injector: Injector) {
        super(injector, dataService, notificationService, simpleStorageService, NF_MINI_SEARCH_PAGE_SIZE);
    }

    public ngOnInit(): void {
        this.filters.context = this.contextCode;
        super.ngOnInit();
    }

    public handleSelChange(event: SelectionEvent): void {
        super.handleSelChange(event);
        this.valueChange.emit(this.currentNationalFederationListItem);
    }
}
