<button type="button" aria-label="back to top" class="back-to-top-button" 
        kendoButton
        [look]="'bare'"
        [primary]="true"
        [@appearInOut]="animationState"
        [ngStyle]="defaultStylesTop"
        (click)="scrollTop($event)">
    <i class="fa fa-chevron-circle-up fa-4x" aria-hidden="true"></i>
</button>

<button type="button" aria-label="go to bottom" class="go-to-bottom-button"
        kendoButton
        [look]="'bare'"
        [primary]="true"
        [@appearInOut]="animationStateBottom"
        [ngStyle]="defaultStylesBottom"
        (click)="scrollBottom($event)">
    <i class="fa fa-chevron-circle-down fa-4x" aria-hidden="true"></i>
</button>