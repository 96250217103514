import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EventDataService } from '@fiba/data-services';
import { FibaPickerBase } from '@fiba/forms/base/fiba-picker-base';
import { EventDTO, EventListItemDTO, ThingDTO } from '@fiba/models';

import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import {ComboBoxComponent} from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';


@Component({
    selector: 'fibaPickerEvent',
    templateUrl: './fiba-picker-event.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaPickerEventComponent),
            multi: true,
        },
        EventDataService,
    ],
    host: { class: 'fiba-input' },
})
export class FibaPickerEventComponent extends FibaPickerBase<EventDTO> implements OnInit {
    @ViewChild('pickerCombobox', {static: true}) public comboBox: ComboBoxComponent;
    public listItems: ThingDTO[] = [];
    @Input() protected delay = 300;
    public simpleValue: ThingDTO;

    public _eventCategories: string[];
    @Input()
    protected set eventCategories(value: string[]) {
        this._eventCategories = value;
    }

    @Input() public fromPicker = false;
    @Input() public filterTestEvents = false;

    constructor(
        protected injector: Injector,
        protected eventDataService: EventDataService,
        protected notificationService: NotificationService) {
        super(injector);
        this.idField = 'eventId';
    }

    public ngOnInit() {
        super.ngOnInit();

        this.comboBox.filterChange.pipe(
            debounceTime(this.delay),
            switchMap((searchValue) => this.fetchAutocompleteObservable(searchValue))
        )
            .subscribe(
                (result) => {
                    this.listItems = result;
                },
            );
    }

    public filterHandler(value) {
        this.filterList(value);
    }

    protected filterList(value: string = undefined) {
        this.fetchAutocompleteObservable(value).subscribe(
            (data) => {
                this.listItems = data;
            },
        );
    }

    private fetchAutocompleteObservable(value: string = null): Observable<ThingDTO[]> {
        return this.eventDataService.fetchAutoCompleteList(value, this._eventCategories)
    }

    protected updatePickerEvent(event: ThingDTO = undefined) {
        if (event) {
            this.eventDataService.fetchEventInformation(event.id).subscribe(
                (response) => {
                    this.value = response;
                    super.saveHandler(response);
                },
                (error) => {
                    this.notificationService.emitNotification(NotificationType.Error, `Fetch event ${event.id} failed`);
                },
                () => { },
            );
        } else {
            super.saveHandler(undefined);
        }
    }

    /**
     * Callback after value is changed. Needed when picker is initialized with existing value
     *
     * @param val
     */
    public valueChanged(val: EventDTO) {
        if (val) {
            this.simpleValue = { id: val.eventId, name: val.title } as ThingDTO;

            // the list of Items must contain the new value, otherwise the
            // value change will be ignored by the combobox
            this.listItems = [this.simpleValue];
        } else {
            this.simpleValue = undefined;
        }
    }

    /**
     * Called when user clicks on autocomplete list item, or reset
     *
     * @param newValue ThingDTO that contains the item clicked on, or null if reset
     */
    public valueChangeHandler(newValue: ThingDTO): void {
        this.updatePickerEvent(newValue);
    }

    /**
     * Called when the user clicks on the OK button of the popup
     *
     * @param item
     */
    public popupSaveHandler(item: EventListItemDTO) {
        this.simpleValue = { id: item.eventId, name: item.title } as ThingDTO;
        this.updatePickerEvent(this.simpleValue);
    }
}
