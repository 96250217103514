import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FinancialDataPackageDataService {
    protected financialDataPackageApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.financialDataPackageApiEndpoint = config.financialDataPackageApiEndpoint;
    }
    
    public SetFinancialDataPackageAsPaid(financialDataPackageId: number): Observable<void> {

        return this.http.put(`${this.financialDataPackageApiEndpoint}/${financialDataPackageId}/set-internal-payment-status/paid`);
    }
}
