import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, EquipmentFeeDTO, FeeFilters, EquipmentFeeListItemDTO } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FeeDataService {
    protected feeApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.feeApiEndpoint = config.feeApiEndpoint;
    }

    public fetchList(filters: FeeFilters): Observable<ApiListResponse<EquipmentFeeListItemDTO>> {
        return this.http.get(`${this.feeApiEndpoint}/list-fee`, filters.getSearchParams());
    }

    public fetchFee(feeId: number): Observable<EquipmentFeeDTO> {
        return this.http.get(`${this.feeApiEndpoint}/${feeId}`);
    }

    public createFeeInformation(model: EquipmentFeeDTO): Observable<EquipmentFeeDTO> {
        return this.http.post(`${this.feeApiEndpoint}/information`, model);
    }

    public updateFeeInformation(model: EquipmentFeeDTO): Observable<any> {
        return this.http.put(`${this.feeApiEndpoint}/${model.feeId}/information`, model);
    }

    public deleteFee(equipmentFeeId: number): Observable<any> {
        return this.http.delete(`${this.feeApiEndpoint}/${equipmentFeeId}`);
    }
}
