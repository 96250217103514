import { Injectable } from '@angular/core';
import { TreeviewItem, TreeviewSelection } from './treeview-item';

@Injectable()
export abstract class TreeviewI18n {
    public abstract getText(selection: TreeviewSelection): string;
    public abstract getAllCheckboxText(): string;
    public abstract getFilterPlaceholder(): string;
    public abstract getFilterNoItemsFoundText(): string;
    public abstract getTooltipCollapseExpandText(isCollapse: boolean): string;
}

@Injectable()
export class TreeviewI18nDefault extends TreeviewI18n {
    public getText(selection: TreeviewSelection): string {

        if (!selection){
            return this.getAllCheckboxText();
        }

        if (selection.uncheckedItems.length === 0) {
            return this.getAllCheckboxText();
        }

        switch (selection.checkedItems.length) {
            case 0:
                return this.getAllCheckboxText();
            case 1:
                return selection.checkedItems[0].text;
            default:
                return `${selection.checkedItems.length} options selected`;
        }
    }

    public getAllCheckboxText(): string {
        return 'All';
    }

    public getFilterPlaceholder(): string {
        return 'Filter';
    }

    public getFilterNoItemsFoundText(): string {
        return 'No items found';
    }

    public getTooltipCollapseExpandText(isCollapse: boolean): string {
        return isCollapse ? 'Expand' : 'Collapse';
    }
}
