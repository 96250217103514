import { ThinModel } from '@fiba/models/common/thin.model';

export enum PersonTypes {
    OfficialLicense = 'official license',
    Person = 'person',
    Agent = 'agent',
    //Not used yet
    Player = 'player',
    Coach = 'coach',
}

export class PersonTypeView {
    private _name: PersonTypes;
    private _role: ThinModel;

    constructor(name: PersonTypes = null) {
        this._name = name;
    }
    public get name(): PersonTypes {
        return this._name;
    }
    public set role(value: ThinModel) {
        this._role = value;
    }
    public get role(): ThinModel {
        return this._role;
    }
}
