import { Component, Injector, Input } from '@angular/core';

import { DocumentDataService } from '@fiba/data-services';
import { FibaPopupBase } from '@fiba/forms';
import { DocumentDTO, DocumentGenericDTO, DocumentGenericTypes } from '@fiba/models';

@Component({
    selector: 'fibaPopupDocument',
    templateUrl: './fiba-popup-document.component.html',
})
export class FibaPopupDocumentComponent extends FibaPopupBase<DocumentGenericDTO> {
    readonly DocumentGenericTypes_NATIONALITY = DocumentGenericTypes.NATIONALITY;

    @Input() public parentCode: string;
    @Input() public categoryCode: string;
    @Input() public showComment = false;
    @Input() public documentName: string = null;
    @Input() public documentType: string;
    @Input() public entityType: string;
    @Input() public entityId: number;
    @Input() public entityTypeBase: string = null;
    @Input() public entityIdBase: number = null;
    @Input() public saveMethod: string;
    @Input() protected titleRequired = true;
    @Input() protected originalDocumentId: number;
    @Input() publicApi = false;
    @Input() public roleFamily: string;
    @Input() public restrictionPicture = false;
    @Input() public hideTypeDropdown = false;
    fileUploaded = false;

    constructor(
        protected dataService: DocumentDataService,
        protected injector: Injector) {
        super(injector);
        this._model = {} as DocumentGenericDTO;
    }

    public getDocumentInfo(document: DocumentDTO) {
        this._model.createdBy = document.createdBy;
        this._model.creationDate = document.creationDate;
        this._model.documentId = document.documentId;
        this._model.mimeType = document.mimeType;
        if (!this._model.title) {
            this._model.title = document.title;
        }
        this._model.typeItem = document.typeItem;
        this._model.typeItemId = document.typeItemId;
        this._model.documentDmsid = document.documentDmsid;
        this._model.fileName = document.fileName;
        this._model.storageContainer = document.storageContainer;
        this._model.blobFullPath = document.blobFullPath;
        this._model.comment = document.comment;
        this._model.lastModificationDateTime = document.lastModificationDateTime;
        this._model.lastModificationUserName = document.lastModificationUserName;
        this._model.versionLabel = document.versionLabel;

        if (this.originalDocumentId === 0) {
            this._model.documentId = document.documentId;
        }
        this._model.documentType = this.documentType;
    }

    public getTitle(title: string) {
        if (!this._model.title) {
            this._model.title = title;
        }
    }

    public onCancel(e): void {
        //if (!!this._model.documentId) {
        //    if (this.originalDocumentId === 0 || (this.originalDocumentId != this._model.documentId)) {
        //        this.dataService.cancelCheckOut(this._model.documentId).subscribe();
        //    }
        //}
        super.onCancel(e);
    }

    public onHasNoExpirationChanges(): void {
        this._model.identificationDocumentExpirationDate = null;
    }

    fileUploadedHandler(value: boolean) {
        this.fileUploaded = value;
    }
}
