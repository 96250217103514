import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Observable } from 'rxjs';

import { LicenseListItemDTO, PersonStatusDTO, ThingDTO} from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService, IFileData } from '../utils/http.service';
import { map, timeout } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';
import { GameOfficiaInformationDTO } from '../../models/dtos/generated/game-officialnformation-dto';
import { ContactValidationFormDTO } from '../../models/dtos/generated/contact-validation-form-dto';
import { GameOfficiaELicenselnformationDTO } from '../../models/dtos/generated/game-officia-e-licenselnformation-dto';

const PERSON_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class GameOfficialDataService {

    protected baseUrlEndpoint: string;
    protected gameOfficialApiEndpoint: string;
   

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataPersonStatusService: DataCachedService<PersonStatusDTO>) {
        this.baseUrlEndpoint = config.baseUrl;
        this.gameOfficialApiEndpoint = config.gameOfficialApiEndpoint;
    }

    public fetchGameOfficialPerson(): Observable<GameOfficiaInformationDTO> {

        return this.http.get(`${this.gameOfficialApiEndpoint}/person-informations`);
    }
    public fetchGameOfficialELicensePerson(): Observable<GameOfficiaELicenselnformationDTO> {

        return this.http.get(`${this.gameOfficialApiEndpoint}/game-official-e-license-informations`);
    }
    
    public updateGameOfficialPerson(model: GameOfficiaInformationDTO): Observable<GameOfficiaInformationDTO> {
        model.mapUrl = this.baseUrlEndpoint;
        return this.http.put(`${this.gameOfficialApiEndpoint}/person-informations`, model);
    }

    public validGameOfficialContact(model: ContactValidationFormDTO): Observable<ContactValidationFormDTO> {
        return this.http.post(`${this.gameOfficialApiEndpoint}/send-email-validation`, model);
    }

    public downloadELicense(model: LicenseListItemDTO): Observable<ThingDTO> {
        return this.http.post(`${this.gameOfficialApiEndpoint}/download-e-license-card`, model);
    }
}
