import { HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { FibaLoadingService } from '@fiba/loading';
import { DialogActionService, DialogCloseResult } from '@fiba/utils/dialog-action.service';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { PromptBeforeLeaving } from '@fiba/utils/prompt-before-leaving.service';
import { Observable ,  Subject } from 'rxjs';
import { MergeValueOnlyOneIntDTO } from '@fiba/models/dtos/generated/merge-value-only-one-int-dto';
import { MergeSelection } from '@fiba/models/dtos/generated/merge-selection';

const loadingThreshold = 800;
const loadingText = 'Loading';
const savingThreshold = 0;
const savingText = 'Merging';

@Directive()
export abstract class FibaMergeFormBase<T> implements PromptBeforeLeaving, OnInit, OnDestroy {
    public isNew: boolean;
    public isLoading: boolean;
    public model: T;
    protected mergedEntityId: number;

    public entityId: MergeValueOnlyOneIntDTO = <MergeValueOnlyOneIntDTO>{ selection: MergeSelection.First };

    protected description: string;

    protected formSaveSuccessEvent: Subject<boolean> = new Subject<boolean>();

    @ViewChild('form') protected ngForm: NgForm;

    constructor(protected route: ActivatedRoute,
                protected dialogActionService: DialogActionService,
                protected notificationService: NotificationService,
                protected fibaLoadingService: FibaLoadingService) {
    }

    @HostListener('window:beforeunload')
    public canLeave(): boolean | Promise<boolean> {
        // TODO - 02/05/2018 - Is fn Usued ?
        const fn = (result: any) => {
            if (!(result instanceof DialogCloseResult)) {
                if (result.text === DialogActionService.ANSWER_OK) {
                    return true;

                }
            }
            return false;
        };

        if (!this.ngForm || this.ngForm.pristine) {
            return true;
        } else {
            return this.dialogActionService.askCanLeave();
        }
    }

    protected getDescription(): string {
        return this.description ? this.description : this.model.constructor.name;
    }

    // FETCH
    protected abstract getFetchObservable(): Observable<T>;

    /**
     * Called when fetch succeeds. Triggers filling the model from the API data.
     *
     * @param response
     */
    protected fetchSuccess(response): void {
        this.model = response;
    }

    /**
     * Called when fetch API returns an error.
     *
     * @param error
     */
    protected fetchError(error): void {
        this.fibaLoadingService.hide();
        this.notificationService.emitNotification(NotificationType.Error, error);
    }

    /**
     * Called when fetch is complete (after fetchSuccess).
     */
    protected fetchComplete(): void {
        this.isLoading = false;
        this.fibaLoadingService.hide();
        if (!this.model || (typeof (this.model['hasWriteAccess']) === 'boolean' && !this.model['hasWriteAccess'])) {
            // TODO: Ugly hack to be reworked (maybe with Guards? https://angular.io/docs/ts/latest/guide/router.html#!#guards)
            setTimeout(() => {
                if (this.ngForm) {
                    for (const key in this.ngForm.form.controls) {
                        this.ngForm.form.controls[key].disable();
                    }
                }
            }, 10);
        }
    }

    // MERGE
    protected abstract getMergeObservable(): Observable<any>;

    /**
     * Called when merge succeeds.
     *
     * @param response the response of the update API. Should contain the DTO of the created entity.
     */
    protected mergeSuccess(response): void {
        this.mergedEntityId = response;
    }

    /**
     * Called when update API returns an error.
     *
     * @param error
     */
    protected mergeError(error) {
        if (Array.isArray(error)) { // If array, it's a list of validation errors. Maybe TODO refactor properly with an error type instead
            this.notificationService.emitNotification(NotificationType.Warning,
                `Validation failed on ${this.getDescription()} update : ${error}`);
        } else {
            this.notificationService.emitNotification(NotificationType.Error,
                `Update ${this.getDescription()} failed: ${error}`);
        }
        this.fibaLoadingService.hide();
        this.isLoading = false;
    }

    /**
     * Called when update is complete.
     */
    protected mergeComplete() {
        this.notificationService.emitNotification(NotificationType.Success, `Merged ${this.getDescription()} ${this.entityId.firstValue} and ${this.entityId.secondValue} successfully`);
        this.isLoading = false;
        this.fibaLoadingService.hide();
        // Mark all inputs pristine & untouched
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsPristine();
            this.ngForm.form.controls[key].markAsUntouched();
        }
        this.redirectAfterMerge();
    }

    protected redirectAfterMerge(): void { }

    public ngOnInit(): void {
        //this.isLoading = true;
        //this.loadItem();
    }

    public loadItem(): void {
        this.fibaLoadingService.show(loadingThreshold, loadingText);

        if (this.canFetchItem()) { // Update
            this.isNew = false;
            this.getFetchObservable().subscribe(
                (response) => {
                    this.fetchSuccess(response);
                },
                (error) => {
                    this.fetchError(error);
                },
                () => {
                    this.fetchComplete();
                    this.formSaveSuccessEvent.next(true);
                },
            );
        } else {
            this.model = undefined;
            this.fibaLoadingService.hide();
        }
    }

    public canFetchItem(): boolean {
        let test = typeof this.entityId.firstValue === 'number';
        test = test && typeof this.entityId.secondValue === 'number';
        return Number.isInteger(this.entityId.firstValue) && Number.isInteger(this.entityId.secondValue);
    }

    public canMergeEntities(): boolean {
        return this.entityId.firstValue !== undefined && this.entityId.secondValue !== undefined;
    }

    public ngOnDestroy(): void { }

    protected initNewModel(): T {
        return null;
    }

    protected save(): boolean {
        // Mark all inputs touched
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsTouched();
        }
        if (!this.ngForm.valid) {
            this.notificationService.emitNotification(NotificationType.Warning, 'There are validation errors');
            return false;
        } else {
            this.fibaLoadingService.show(savingThreshold, savingText);
            this.isLoading = true;
            if (this.canMergeEntities()) { // merge
                this.getMergeObservable().subscribe(
                    (response) => {
                        this.mergeSuccess(response);
                    },
                    (error) => {
                        this.mergeError(error);
                    },
                    () => {
                        this.mergeComplete();
                    },
                );
            }else {
                this.fibaLoadingService.hide();
            }
            return true;
        }
    }

    /**
     * Called when user clicks on the close button
     */
    abstract clear(): void;
}
