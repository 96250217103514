import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompetitionOperationFinalRosterDTO, RosterMemberDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionFinalRosterDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchFinalRosterTechnicalMeeting(finalRosterId: number, preliminaryRosterId: number = null, technicalMeetingId: number = null): Observable<CompetitionOperationFinalRosterDTO> {
        const params = {};
        if (preliminaryRosterId) {
            params['preliminaryRosterId'] = String(preliminaryRosterId);
        }
        if (technicalMeetingId) {
            params['technicalMeetingId'] = String(technicalMeetingId);
        }
        return this.http.get(`${this.competitionApiEndpoint}/final-rosters/${finalRosterId}`, params);
    }

    fetchFinalRosterMember(finalRosterId: number, preliminaryRosterMemberId: number, preliminaryRosterId: number, technicalMeetingId: number): Observable<RosterMemberDTO> {
        const params = {};
        if (preliminaryRosterId) {
            params['preliminaryRosterId'] = String(preliminaryRosterId);
        }
        if (technicalMeetingId) {
            params['technicalMeetingId'] = String(technicalMeetingId);
        }
        return this.http.get(`${this.competitionApiEndpoint}/final-rosters/${finalRosterId}/members/${preliminaryRosterMemberId}`, params);
    }

    updateCompetitionFinalRoster(technicalMeetingId: number, model: CompetitionOperationFinalRosterDTO, statusCode: string): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/final-rosters/${model.finalRosterId}?&technicalMeetingId=${technicalMeetingId}&statusCode=${statusCode}`, model);
    }

    updateCompetitionFinalRosterMember(finalRosterId: number, model: RosterMemberDTO, statusCode, technicalMeetingId: number): Observable<any> {
        let params = `statusCode=${statusCode}`;
        if (technicalMeetingId) {
            params = params + `&technicalMeetingId=${technicalMeetingId}`;
        }
        return this.http.put(`${this.competitionApiEndpoint}/final-rosters/${finalRosterId}/members?` + params, model);
    }
    updateCompetitionFinalRosterMembersStatus(finalRosterId: number, statusCode, technicalMeetingId: number = null): Observable<any> {
        let params = `statusCode=${statusCode}`;
        if (technicalMeetingId) {
            params = params + `&technicalMeetingId=${technicalMeetingId}`;
        }
        return this.http.put(`${this.competitionApiEndpoint}/final-rosters/${finalRosterId}/validate-technical-meeting?` + params);
    }
}
