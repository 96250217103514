<div class="vertical space10 margin-below-10">
    <div class="horizontal space10 fill-all">
        <breadcrumb class="grow5"></breadcrumb>
        <div class="block-top filled horizontal notif-actions">
            <span class="margin-right-10">Notifications:</span>
            <a (click)="notifs.clear()" class="clickable">Hide all</a>
            Show last&nbsp;&nbsp;
            <a (click)="notifs.showLast(1)" class="clickable">1</a>
            <a (click)="notifs.showLast(3)" class="clickable">3</a>
            <a (click)="notifs.showLast(5)" class="clickable">5</a>
            <a (click)="notifs.showLast(10)" class="clickable">10</a>
        </div>
    </div>
    <fibaNotificationDisplay #notifs></fibaNotificationDisplay>
</div>
