import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WindowsDTO, ActionMessageDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class WindowsConfigurationService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchWindows(competitionId: number): Observable<WindowsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/windows-configuration`);
    }

    updateWindows(model: WindowsDTO): Observable<ActionMessageDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/windows-configuration`, model);
    }
}
