import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { SelectionEvent } from '@progress/kendo-angular-grid';

import { CompetitionListItemDTO, MapContexts } from '@fiba/models';

import { CompetitionSearchBaseComponent } from './base/competition-search-base.component';

const COMPETITION_MINI_SEARCH_PAGE_SIZE = 5;

@Component({
    selector: 'competition-search-mini',
    templateUrl: './competition-search-mini.component.html',
})
export class CompetitionSearchMiniComponent extends CompetitionSearchBaseComponent {

    @Input() public value: CompetitionListItemDTO;
    @Output() public valueChange: EventEmitter<CompetitionListItemDTO> = new EventEmitter<CompetitionListItemDTO>();

    constructor(protected injector: Injector) {
        super(injector, COMPETITION_MINI_SEARCH_PAGE_SIZE, CompetitionSearchMiniComponent.name);
        this.filters.context = MapContexts.CompetitionsSearch;
    }

    public handleSelChange(event: SelectionEvent): void {
        super.handleSelChange(event);
        this.valueChange.emit(this.currentCompetitionListItem);
    }

    public filter(): void {
        super.filter();
        if (this.filterStorageKey) {
            this.simpleStorageService.set(this.filterStorageKey, this.filters.clone());
        }
    }
}
