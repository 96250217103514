<input #input="ngModel"
       type="checkbox"
       id="{{name}}"
       class="k-checkbox"
       [(ngModel)]="value"
       [disabled]="disabled"
       (change)="manualChange()" />
<label attr.for="{{name}}" class="k-checkbox-label">{{label | nullReplace : ''}}</label><span *ngIf="description" class="label-description">{{description}}</span>
<div *ngIf="ngControl.errors" class="validation-errors">
    <ul>
        <li [hidden]="!ngControl.errors.required">
            {{label}} is required
        </li>
    </ul>
</div>
