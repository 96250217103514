<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <div class="horizontal space10">
        <kendo-datepicker class="date"
                          [required]="required"
                          [(ngModel)]="date"
                          id="{{name}}"
                          [disabled]="disabledDate || disabled"
                          [format]="'dd-MMM-yyyy'"
                          (valueChange)="handleDateChange($event)"></kendo-datepicker>
        <span class="add-time-button" *ngIf="!hasTime && canSetHasTime && !disabled" (click)="addTime()" style="display: inline-block; cursor: pointer;">
            <i class="fa fa-clock-o"></i>
        </span>
        <span>
            <kendo-dateinput *ngIf="hasTime"
                             class="time"
                             [required]="required"
                             [format]="'HH:mm'"
                             [disabled]="disabledTime || disabled"
                             [(ngModel)]="time"
                             (valueChange)="handleTimeChange($event)"
                             (blur)="handleBlur($event)"></kendo-dateinput>
            <span class="remove-time-button" *ngIf="hasTime && canSetHasTime && !disabled" (click)="removeTime()" style="display: inline-block; cursor: pointer;">
                <i class="fa fa-times"></i>
            </span>
        </span>
    </div>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>

