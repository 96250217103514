import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ThingDTO, WindowDTO, GroupPairingDTO, GameDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class GameDetailsDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchSimpleGameList(competitionId: number): Observable<ThingDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/games/simplelist`);
    }

    fetchGameGroupPairing(gameId: number): Observable<GroupPairingDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/grouppairing`);
    }

    fetchWindows(competitionId: number): Observable<WindowDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/windows`);
    }

    fetchGames(competitionId: number): Observable<GameDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/games`);
    }
}
