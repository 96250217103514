import { Injectable } from '@angular/core';

import { HttpService } from '../utils/http.service';
import { Observable } from 'rxjs';
import { ApiListResponse, FormRegistrationTechnicalDelegateReportingFormDetailDTO, FormRegistrationTechnicalDelegateReportingFormListItemDTO } from '@fiba/models';

import { FormRegistrationAction } from '@fiba/models/constants/generated/form-registration-action';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';
import { FormRegistrationTechnicalDelegateReportingFormFilters } from '../../models/form-registration-technical-delegate-reporting-form/form-registration-technical-delegate-reporting-form';


@Injectable({
    providedIn: 'root',
})
export class TechnicalDelegateReportingFormRegistrationDataService {
    protected technicalDelegateReportingFormRegistrationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.technicalDelegateReportingFormRegistrationApiEndpoint = config.technicalDelegateReportingFormRegistrationApiEndpoint;
    }

    fetchList(filters: FormRegistrationTechnicalDelegateReportingFormFilters): Observable<ApiListResponse<FormRegistrationTechnicalDelegateReportingFormListItemDTO>> {
        return this.http.get(this.technicalDelegateReportingFormRegistrationApiEndpoint, filters.getSearchParams()).pipe(map(
            response => response as ApiListResponse<FormRegistrationTechnicalDelegateReportingFormListItemDTO>));
    }

    fetchTechnicalDelegateReportingFormRegistration(gameInformationFormRegistrationId: number): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.get(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/' + gameInformationFormRegistrationId);
    }

    fetchInitialVersion(entityItemId?: number, gameId?: number, bubbleId?: number, windowId?: number, hostCountryId?: number, hostCityName?: string): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        const params = {};
        if (entityItemId) {
            params['entityItemId'] = entityItemId;
        }
        if (gameId) {
            params['gameId'] = gameId;
        }
        if (bubbleId) {
            params['bubbleId'] = bubbleId;
        }
        if (windowId) {
            params['windowId'] = windowId;
        }
        if (hostCountryId) {
            params['hostCountryId'] = hostCountryId;
        }
        if (hostCityName) {
            params['hostCityName'] = hostCityName;
        }
        return this.http.get(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/initial-version', params);
    }

    createTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.post(this.technicalDelegateReportingFormRegistrationApiEndpoint, model);
    }

    createSubmitTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.post(this.technicalDelegateReportingFormRegistrationApiEndpoint + '?action=' + FormRegistrationAction.Submit, model);
    }

    updateTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.put(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/' + model.formRegistrationId, model);
    }

    submitTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.put(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Submit, model);
    }

    reopenTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.put(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Reopen, model);
    }


    requestInformationTechnicalDelegateReportingFormRegistration(model: FormRegistrationTechnicalDelegateReportingFormDetailDTO): Observable<FormRegistrationTechnicalDelegateReportingFormDetailDTO> {
        return this.http.put(this.technicalDelegateReportingFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.PendingUpdates, model);
    }

}
