const MILLISEC_PER_DAY: number = 1000 * 60 * 60 * 24;
// ISO DateTime strings regex. To refine if necessary. Not the best one (among others, doesn't handle Z), but for now it fits our needs.
const REGEX_ISO_8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+-](\d{2}):(\d{2}))?/;
// Date fields whitelist. TODO: refactor and automatically generate this ?
const KNOWN_DATE_FIELD_NAMES: string[] = [
    'validFrom',
    'validTo',
    'finalRosterDeadline',
    'preliminaryRosterDeadline',
    'registrationStartDeadline',
    'registrationEndDeadline',
    'regionalOfficeValidationDeadline',
    'headquarterValidationDeadline',
    'nationalFederationUpdateDeadline',
];

export class DateUtils {

    /**
     * Compute the number of days between two dates given as Date objects or milliseconds
     */
    public static dayDiff(first: number | Date, second: number | Date): number {
        if (first instanceof Date) {
            first = first.valueOf();
        }
        if (second instanceof Date) {
            second = second.valueOf();
        }
        return Math.ceil((second - first) / MILLISEC_PER_DAY); // TODO :  DANGER : this is wrong (not valid for all days)
    }

    /**
     * Recursively convert all fields in an object that are strings like ISO DateTime into Date object
     *
     * For now, only fields containing "Date" or present in in their name or in the KNOWN_DATE_FIELD_NAMES whitelist will be converted
     * TODO : care for timezone
     *
     * @param input : any object. Other types are just sent back unmodified.
     */
    public static convertDateStringsToDates(input: any): any {
        // Ignore things that aren't objects.
        if (typeof input !== 'object') {return input;}

        for (const key in input) {
            if (!input.hasOwnProperty(key)) {continue;}

            const value: any = input[key];
            let match: RegExpMatchArray;
            // Check for string properties which look like dates and are named with "date" somewhere.
            if (typeof value === 'string' && DateUtils.isDateField(key) && (match = value.match(REGEX_ISO_8601))) {
                const milliseconds: number = Date.parse(match[0]);
                if (!isNaN(milliseconds)) {
                    input[key] = new Date(milliseconds);
                }
            } else if (typeof value === 'object') {
                // Recurse into object
                DateUtils.convertDateStringsToDates(value);
            }
        }

        return input;
    }

    /**
     * Tells whether a field name is supposed to be a date field or not.
     * i.e. contains "Date" or belongs to the whitelist.
     */
    private static isDateField(key: string): boolean {

        return KNOWN_DATE_FIELD_NAMES.indexOf(key) > -1 || (key.match(/Date/) !== null);
    }
}
