import { Injector, Component, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import { ItemDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaSelectItem',
    templateUrl: '../../forms/base/fiba-select-base.component.html', providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectItemComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectItemComponent extends FibaAjaxSelectBaseComponent<ItemDTO> implements OnInit {
    @Input() protected categoryCode;
    @Input() protected set parentCode(value: string) {
        this._parentCode = value;
        this.getSubscription();
    };
    @Input() protected set parentId(value: number) {
        this._parentId = value;
        this.getSubscription();
    };
    @Input() protected displayParent: boolean;
    @Input() protected showOnlyRoots = false;
    @Input() protected showOnlyChildren = false;

    @Input() protected excludeItemCode: string = null;

    @Input() protected set valueField(value: string) {
        this._valueField = value || 'itemId';
    }

    @Input() protected set textField(value: string) {
        this._textField = value;
    }

    @Input() protected setDefaultValue = false;
    @Input() public clearButtonVisible = true;

    protected _parentCode: string;
    protected _parentId: number;

    constructor(protected injector: Injector, protected dataService: ItemDataService) {
        super(injector);
        this._valueField = 'itemId';
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.clearButton = this.clearButtonVisible;
        this._textField = this._textField || (this.displayParent ? 'cli_displayNameWithParent' : 'name');
    }

    getBindData(): (data: ItemDTO[]) => void {
        return (data: ItemDTO[]) => {
            this.getBindDataCallbackBase(data);
        };
    }

    protected getBindDataCallbackBase(data: ItemDTO[]): void {
        if (this._parentCode) {
            const parentId = data.find(i => i.code === this._parentCode).itemId;
            data = data.filter(i => i.parentItemId === parentId);
        }
        if (this._parentId) {
            data = data.filter(i => i.parentItemId === this._parentId);
        }
        if (this.showOnlyRoots) {
            data = data.filter(i => !i.parentItemId);
        }
        if (this.showOnlyChildren) {
            data = data.filter(i => !!i.parentItemId);
        }
        if (this.excludeItemCode) {
            data = data.filter(i => i.code !== this.excludeItemCode);
        }

        data = this.sortItemsList(data);
        this._originalItems = data.map(a => { a.cli_displayNameWithParent = (a.parentItem ? `${a.parentItem.name} / ` : '') + `${a.name}`; return a; });
        this._filteredItems = data.slice(0);

        if (!this._value && this.setDefaultValue) {
            this._value = this._filteredItems.find(item => item.isDefaultValueCreation);
            if (this._value) {
                this.modelId = this._value.itemId;
            } else {
                this.modelId = null;
            }
            this.change.emit(this._value);
            this.modelIdChange.emit(this.modelId);
        }
    }

    protected sortItemsList(data: ItemDTO[]): ItemDTO[] {
        if (data) {
            data = data.sort((x, y) => {
                if (x.displayOrder === y.displayOrder) {
                    return !x.parentItemId ? -1 : 1;
                }
                return x.displayOrder < y.displayOrder ? -1 : 1;
            });
        }
        return data;
    }

    getObservable(): Observable<ItemDTO[]> {
        return this.dataService.fetchItems(this.categoryCode);
    }
}
