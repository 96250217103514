import { Logger } from './logger';

export class DateNullableTime extends Date {
    private _hasTime: boolean;

    public setHasTime(value) {
        this._hasTime = value;
    };

    public getHasTime() {
        return this._hasTime;
    }

    constructor(date: Date, hasTime: boolean = true) {
        super(date);
        if (!date) {
            Logger.warn('DateNullableTime constructor called on null or undefined value.');
        } else if (typeof date === 'string') {
            Logger.warn(`DateNullableTime constructor called on a string, not a date. (${date})`);
        } else {
            this.setHasTime(hasTime);
        }
    }
}
