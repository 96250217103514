import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { KendoModule } from './kendo.module';

import { TreeModule } from 'angular-tree-component';

import { FibaLoadingService, LoadingModule } from '@fiba/loading';
import { DialogService } from '@progress/kendo-angular-dialog';
import { EditorModule } from '@progress/kendo-angular-editor';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        KendoModule.forRoot(),
        TreeModule.forRoot(),
        EditorModule,
        LoadingModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        KendoModule,
        TreeModule,
        LoadingModule,
        EditorModule
    ],
})

export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                DialogService, // TODO: Check if necessary
                FibaLoadingService,
            ],
        };
    }
}
