import { Pipe, PipeTransform } from '@angular/core';
import { HostDTO } from '@fiba/models';

const formatHost = (host: HostDTO): string => {
    if (host) {
        if (host.cityName) {
            return `${host.cityName} (${host.country.fibacode})`;
        } else {
            return host.country.shortName;
        }
    }
    return '';
};

@Pipe({ name: 'hostFormat' })
export class HostFormatPipe implements PipeTransform {
    transform(host: HostDTO): string {
        return formatHost(host);
    }
}

@Pipe({ name: 'hostFormatArray' })
export class HostFormatArrayPipe implements PipeTransform {
    transform(hosts: Array<HostDTO>): Array<string> {
        if (hosts) {
            return hosts
                .filter(host => !!host)
                .map(host => formatHost(host));
        }
        return [];
    }
}
