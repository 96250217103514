import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    private breadcrumbSource = new Subject<{ level: number; name: string }>();

    public breadcrumbSet$ = this.breadcrumbSource.asObservable();

    public updateLevel(level: number, name: string) {
        this.breadcrumbSource.next({ level, name });
    }
}
