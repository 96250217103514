import { Injectable } from '@angular/core';
import { IAuditable, AuditInfos } from '@fiba/models';

// TODO review 2018-03-20 : should this be this static instead ?
@Injectable({
    providedIn: 'root',
})
export class AuditUtilsService {
    getMostRecentAuditInfos(auditableList: IAuditable[]): IAuditable {
        const result = new AuditInfos();
        if (auditableList) {
            const mostRecentAuditable =
                (auditableList
                .filter(x => !!x.lastModificationDateTime) // trim null/undefined dates
                    .sort((a, b) => {
                        const dateA = new Date(a.lastModificationDateTime);
                        const dateB = new Date(b.lastModificationDateTime);
                        return dateB.getTime() - dateA.getTime();
                    })) // sort by descending order
                [0]; // get first (or undefined if none)

            if (mostRecentAuditable) {
                result.lastModificationDateTime = mostRecentAuditable.lastModificationDateTime;
                result.lastModificationUserName = mostRecentAuditable.lastModificationUserName;
            }
        }

        return auditableList && result; // if auditableList is null/undefined give back same, else return result
    }
}
