import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {CompetitionOperationTechnicalMeetingDTO, CompetitionOperationFinalRosterDTO, TechnicalMeetingConfigurationListItemDTO } from '@fiba/models';

import { HttpService, IFileData } from '../../utils/http.service';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionTechnicalMeetingDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchTechnicalMeeting(technicalMeetingId: number, loadFinalRosters: boolean = false): Observable<CompetitionOperationTechnicalMeetingDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/technical-meetings/${technicalMeetingId}/?loadFinalRosters=${loadFinalRosters}`);
    }

    fetchCompetitionTechnicalMeeting(competitionId: number): Observable<CompetitionOperationTechnicalMeetingDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/technical-meetings`);
    }

    fetchFinalRostersTechnicalMeeting(technicalMeetingId: number): Observable<CompetitionOperationFinalRosterDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/technical-meetings/${technicalMeetingId}/final-rosters`);
    }

    updateCompetitionTechnicalMeeting(model: CompetitionOperationTechnicalMeetingDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/technical-meetings/${model.technicalMeetingId}`, model);
    }

    updateCompetitionTechnicalMeetingLock(technicalMeetingId: number, locked: boolean): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/technical-meetings/${technicalMeetingId}/status/?locked=${locked}`, '');
    }

    exportDocumentZip(technicalMeetingId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.competitionApiEndpoint}/technical-meetings/${technicalMeetingId}/export`).pipe(timeout(300000));
    }

    downloadUniformsZip(technicalMeetingId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.competitionApiEndpoint}/technical-meetings/${technicalMeetingId}/uniforms/download`).pipe(timeout(300000));
    }

    fetchCurrentUserTechnicalMeetingConfigurations(): Observable<TechnicalMeetingConfigurationListItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/current-user/technical-meeting-configuration`);
    }
}
