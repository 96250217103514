import { BaseFilters } from '../common/base.filters';

export class EquipmentProductFilters extends BaseFilters {
    modelName: string;
    approvalCategoryId: number;
    partnerOrganisationId: number;
    testInstituteOrganisationId: number;
    year: number;
    reset(): void {
        delete this.modelName;
        delete this.approvalCategoryId;
        delete this.partnerOrganisationId;
        delete this.testInstituteOrganisationId;
        delete this.year;
    }
}
