<kendo-dialog *ngIf="active" novalidate (close)="closeForm()">
    <kendo-dialog-titlebar>
        Select a sanction
    </kendo-dialog-titlebar>

    <sanction-search [(value)]="_value"></sanction-search>

    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event)" [disabled]="!_value">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>