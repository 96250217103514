<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        Select an item
    </kendo-dialog-titlebar>
    <form #form="ngForm">
        <fibaSelectItem name="item"
                        [(ngModel)]="_model"
                        [categoryCode]="categoryCode">
        </fibaSelectItem>
    </form>
    <kendo-dialog-actions>
        <button type="button"
                primary
                class="k-button"
                (click)="onSave($event)">
            Ok
        </button>
        <button type="button"
                class="k-button"
                (click)="onCancel($event)">
            Close
        </button>
    </kendo-dialog-actions>
</kendo-dialog>