export * from './generated/action-message-dto';
export * from './generated/address-dto';
export * from './generated/addressee-category-dto';
export * from './generated/agent-club-relation-dto';
export * from './generated/agent-contract-historical-data-line-dto';
export * from './generated/agent-contract-new-sharing-dto';
export * from './generated/agent-contract-share-detail-line-dto';
export * from './generated/agent-contract-share-detail-list-dto';
export * from './generated/agent-contract-share-details-dto';
export * from './generated/agent-list-item-dto';
export * from './generated/agent-person-information-dto';
export * from './generated/agent-person-relation-dto';
export * from './generated/agent-person-relation-view-dto';
export * from './generated/agent-pre-registration-form-dto';
export * from './generated/agent-pre-registration-form-list-item-dto';
export * from './generated/agent-pre-registration-validate-dto';
export * from './generated/agent-registration-document-status-dto';
export * from './generated/agent-registration-form-document-status-dto';
export * from './generated/agent-registration-form-dto';
export * from './generated/agent-registration-form-list-item-dto';
export * from './generated/agent-registration-form-public-dto';
export * from './generated/agent-registration-form-status-dto';
export * from './generated/agent-registration-list-item-dto';
export * from './generated/agent-registration-status-dto';
export * from './generated/agent-relation-share-dto';
export * from './generated/agent-relation-share-status-dto';
export * from './generated/agent-role-license-request-dto';
export * from './generated/agent-test-counters-dto';
export * from './generated/agent-test-list-item-dto';
export * from './generated/agent-test-season-dto';
export * from './generated/award-competition-dto';
export * from './generated/award-configuration-dto';
export * from './generated/award-dto';
export * from './generated/azure-file-dto';
export * from './generated/bank-account-dto';
export * from './generated/base-roster-overview-line-dto';
export * from './generated/basketball-background-dto';
export * from './generated/basketball-data-dto';
export * from './generated/basketball-equipment-approval-dto';
export * from './generated/basketball-equipment-dto';
export * from './generated/basketball-game-assignment-dto';
export * from './generated/basketball-license-view-dto';
export * from './generated/basketball-nomination-dto';
export * from './generated/basketball-nominations-dto';
export * from './generated/basketball-test-dto';
export * from './generated/box-score-dto';
export * from './generated/bubble-dto';
export * from './generated/cart-dto';
export * from './generated/cart-edit-dto';
export * from './generated/cart-list-item-dto';
export * from './generated/cart-organisation-to-export-dto';
export * from './generated/cart-person-to-export-dto';
export * from './generated/club-details-dto';
export * from './generated/club-dto';
export * from './generated/club-list-item-dto';
export * from './generated/club-sanction-ban-details-dto';
export * from './generated/club-status-dto';
export * from './generated/club-temp-dto';
export * from './generated/club-with-sanction-ban-details-dto';
export * from './generated/coach-license-dto';
export * from './generated/coach-license-edit-request-dto';
export * from './generated/coach-license-request-dto';
export * from './generated/coach-license-request-list-item-dto';
export * from './generated/coach-license-request-summary-dto';
export * from './generated/coach-list-item-dto';
export * from './generated/coach-role-license-request-status-dto';
export * from './generated/competition-award-person-dto';
export * from './generated/competition-configuration-dto';
export * from './generated/competition-configuration-setup-dto';
export * from './generated/competition-document-guideline-dto';
export * from './generated/competition-dto';
export * from './generated/competition-game-assignment-dto';
export * from './generated/competition-game-information-form-dto';
export * from './generated/competition-game-information-form-list-item-dto';
export * from './generated/competition-game-management-dto';
export * from './generated/competition-game-management-list-item-dto';
export * from './generated/competition-game-nomination-summary-dto';
export * from './generated/competition-host-dto';
export * from './generated/competition-information-dto';
export * from './generated/competition-information-summary-dto';
export * from './generated/competition-list-item-dto';
export * from './generated/competition-list-item-front-dto';
export * from './generated/competition-nomination-dto';
export * from './generated/competition-nomination-for-nfdto';
export * from './generated/competition-nomination-information-for-nfdto';
export * from './generated/competition-operation-classification-competition-dto';
export * from './generated/competition-operation-classification-competition-line-dto';
export * from './generated/competition-operation-classification-dto';
export * from './generated/competition-operation-classification-game-dto';
export * from './generated/competition-operation-classification-group-dto';
export * from './generated/competition-operation-classification-group-line-dto';
export * from './generated/competition-operation-classification-group-line-update-dto';
export * from './generated/competition-operation-classification-group-update-dto';
export * from './generated/competition-operation-classification-round-dto';
export * from './generated/competition-operation-classification-round-line-dto';
export * from './generated/competition-operation-classification-tie-popup-info-dto';
export * from './generated/competition-operation-competition-team-dto';
export * from './generated/competition-operation-final-roster-dto';
export * from './generated/competition-operation-game-results-dto';
export * from './generated/competition-operation-technical-meeting-dto';
export * from './generated/competition-ranking-information-dto';
export * from './generated/competition-reports-info-dto';
export * from './generated/competition-standing-header-configuration-dto';
export * from './generated/competition-standing-header-configuration-search-result-dto';
export * from './generated/competition-standing-header-dto';
export * from './generated/competition-standing-header-round-configuration-dto';
export * from './generated/competition-standing-header-search-result-line-dto';
export * from './generated/competition-status-dto';
export * from './generated/competition-structure-dto';
export * from './generated/competition-team-delegation-dto';
export * from './generated/competition-team-delegation-summary-dto';
export * from './generated/competition-team-delegation-update-result-dto';
export * from './generated/competition-team-document-dto';
export * from './generated/competition-team-document-status-dto';
export * from './generated/competition-team-dto';
export * from './generated/competition-team-form-registration-detail-dto';
export * from './generated/competition-team-form-registration-dto';
export * from './generated/competition-team-form-registration-edit-dto';
export * from './generated/competition-team-list-item-dto';
export * from './generated/competition-team-notification-dto';
export * from './generated/competition-team-rank-dto';
export * from './generated/competition-team-registration-dto';
export * from './generated/competition-team-roster-item-dto';
export * from './generated/competition-team-status-dto';
export * from './generated/competition-teams-registration-dto';
export * from './generated/competition-technical-delegate-reporting-form-dto';
export * from './generated/competition-technical-delegate-reporting-form-list-item-dto';
export * from './generated/competition-time-management-dto';
export * from './generated/competition-time-management-list-item-dto';
export * from './generated/competition-timeline-dto';
export * from './generated/competition-timelines-dto';
export * from './generated/competition-umbrella-dto';
export * from './generated/competition-with-windows-dto';
export * from './generated/configuration-competition-age-category-dto';
export * from './generated/configuration-competition-milestone-configuration-dto';
export * from './generated/configuration-competition-milestone-dto';
export * from './generated/configuration-discipline-game-period-dto';
export * from './generated/configuration-document-dto';
export * from './generated/configuration-financial-data-package-content-dto';
export * from './generated/configuration-financial-data-package-dto';
export * from './generated/configuration-form-registration-template-field-answer-dto';
export * from './generated/configuration-game-action-type-qualifier-dto';
export * from './generated/configuration-game-plan-dto';
export * from './generated/configuration-milestone-for-popup-dto';
export * from './generated/configuration-recipient-notification-type-dto';
export * from './generated/configuration-role-compatibility-dto';
export * from './generated/configuration-role-competition-role-dto';
export * from './generated/configuration-role-game-assignment-role-dto';
export * from './generated/configuration-role-test-result-detail-dto';
export * from './generated/configuration-role-test-type-dto';
export * from './generated/configuration-round-preset-dto';
export * from './generated/configuration-sanction-application-type-dto';
export * from './generated/configuration-transfer-request-business-rule-selector-dto';
export * from './generated/configuration-transfer-request-document-dto';
export * from './generated/contact-dto';
export * from './generated/contact-validation-form-dto';
export * from './generated/country-dto';
export * from './generated/country-relation-dto';
export * from './generated/country-zone-dto';
export * from './generated/create-roster-from';
export * from './generated/debtor-dto';
export * from './generated/document-dto';
export * from './generated/document-generic-dto';
export * from './generated/document-status-dto';
export * from './generated/doping-control-dto';
export * from './generated/equipmen-partner-questionnaire-detail-dto';
export * from './generated/equipment-agreement-document-dto';
export * from './generated/equipment-agreement-dto';
export * from './generated/equipment-agreement-status-dto';
export * from './generated/equipment-approval-category-accreditation-list-item-dto';
export * from './generated/equipment-approval-category-document-dto';
export * from './generated/equipment-approval-category-dto';
export * from './generated/equipment-approval-category-form-registration-template-configuration-dto';
export * from './generated/equipment-approval-category-item-dto';
export * from './generated/equipment-approval-category-list-item-dto';
export * from './generated/equipment-approval-category-status-dto';
export * from './generated/equipment-approval-category-tree-view-dto';
export * from './generated/equipment-dashboard-categories-dto';
export * from './generated/equipment-dashboard-partner-by-categories-dto';
export * from './generated/equipment-dashboard-partner-by-country-dto';
export * from './generated/equipment-dashboard-partner-by-region-dto';
export * from './generated/equipment-fee-dto';
export * from './generated/equipment-fee-list-item-dto';
export * from './generated/equipment-fiba-partner-information-dto';
export * from './generated/equipment-fiba-partner-list-item-dto';
export * from './generated/equipment-form-registration-detail-dto';
export * from './generated/equipment-form-registration-edit-dto';
export * from './generated/equipment-form-registration-list-item-dto';
export * from './generated/equipment-partner-application-list-item-dto';
export * from './generated/equipment-partner-company-dto';
export * from './generated/equipment-partner-company-person-dto';
export * from './generated/equipment-partner-company-registration-status-dto';
export * from './generated/equipment-payment-list-item-dto';
export * from './generated/equipment-product-application-list-item-dto';
export * from './generated/equipment-product-dto';
export * from './generated/equipment-product-license-dto';
export * from './generated/equipment-product-list-item-dto';
export * from './generated/equipment-product-status-dto';
export * from './generated/equipment-test-institute-dto';
export * from './generated/equipment-test-institute-list-item-dto';
export * from './generated/equipment-test-result-application-list-item-dto';
export * from './generated/equipment-test-result-detail-dto';
export * from './generated/equipment-test-result-dto';
export * from './generated/equipment-test-result-edit-dto';
export * from './generated/equipment-test-result-form-registration-detail-dto';
export * from './generated/equipment-test-result-list-item-dto';
export * from './generated/event-candidate-to-export-dto';
export * from './generated/event-competition-dto';
export * from './generated/event-competition-information-dto';
export * from './generated/event-dto';
export * from './generated/event-licensable-participant-dto';
export * from './generated/event-list-item-dto';
export * from './generated/event-list-item-export-dto';
export * from './generated/event-list-item-participant-export-dto';
export * from './generated/event-participant-dto';
export * from './generated/event-person-dto';
export * from './generated/event-status-dto';
export * from './generated/event-technical-delegate-participant-dto';
export * from './generated/event-test-result-detail-configuration-dto';
export * from './generated/event-type-dto';
export * from './generated/event-with-licensable-participants-dto';
export * from './generated/event-with-participants-dto';
export * from './generated/event-with-technical-delegate-participants-dto';
export * from './generated/final-roster-configuration-carousel-item-dto';
export * from './generated/final-roster-configuration-dto';
export * from './generated/final-roster-configuration-item-dto';
export * from './generated/final-roster-configuration-list-item-dto';
export * from './generated/final-roster-dto';
export * from './generated/final-roster-member-dto';
export * from './generated/final-roster-member-status-dto';
export * from './generated/final-roster-notification-dto';
export * from './generated/final-roster-status-dto';
export * from './generated/financial-data-package-content-dto';
export * from './generated/financial-data-package-content-list-item-dto';
export * from './generated/financial-data-package-dto';
export * from './generated/financial-data-package-list-item-dto';
export * from './generated/financial-data-package-list-item-sanction-dto';
export * from './generated/financial-data-package-status-dto';
export * from './generated/financial-data-package-status-history-dto';
export * from './generated/flat-structure-dto';
export * from './generated/form-document-dto';
export * from './generated/form-notification-dto';
export * from './generated/form-question-answer-dto';
export * from './generated/form-registration-competition-team-detail-dto';
export * from './generated/form-registration-competition-team-edit-dto';
export * from './generated/form-registration-competition-team-list-item-dto';
export * from './generated/form-registration-criteria-list-item-dto';
export * from './generated/form-registration-data-to-push-dto';
export * from './generated/form-registration-dto';
export * from './generated/form-registration-edit-dto';
export * from './generated/form-registration-evaluation-dto';
export * from './generated/form-registration-field-answer-dto';
export * from './generated/form-registration-game-information-form-detail-dto';
export * from './generated/form-registration-game-information-form-edit-dto';
export * from './generated/form-registration-game-information-form-list-item-dto';
export * from './generated/form-registration-requirement-dto';
export * from './generated/form-registration-requirement-level-dto';
export * from './generated/form-registration-site-visit-detail-dto';
export * from './generated/form-registration-site-visit-edit-dto';
export * from './generated/form-registration-site-visit-list-item-dto';
export * from './generated/form-registration-status-dto';
export * from './generated/form-registration-technical-delegate-reporting-form-detail-dto';
export * from './generated/form-registration-technical-delegate-reporting-form-edit-dto';
export * from './generated/form-registration-technical-delegate-reporting-form-list-item-dto';
export * from './generated/form-registration-template-dto';
export * from './generated/form-registration-template-field-answer-item-dto';
export * from './generated/form-registration-template-field-answer-item-trigger-dto';
export * from './generated/form-registration-template-field-dto';
export * from './generated/form-registration-template-field-render-dto';
export * from './generated/form-registration-template-field-tree-node-dto';
export * from './generated/form-registration-template-field-trigger-dto';
export * from './generated/form-registration-template-group-tree-node-dto';
export * from './generated/form-registration-template-linked-entity-list-item-dto';
export * from './generated/form-registration-template-list-item-dto';
export * from './generated/form-registration-template-section-tree-node-dto';
export * from './generated/form-registration-template-select-item-dto';
export * from './generated/form-registration-template-status-dto';
export * from './generated/form-registration-template-tree-node-dto';
export * from './generated/form-registration-venue-detail-dto';
export * from './generated/form-registration-venue-edit-dto';
export * from './generated/form-registration-venue-list-item-dto';
export * from './generated/form-registration-with-data-to-push-dto';
export * from './generated/from-group-pairing-competition-team-rank-dto';
export * from './generated/from-group-pairing-placeholder-dto';
export * from './generated/from-group-pairing-round-next-qualified-team-dto';
export * from './generated/game-action-dto';
export * from './generated/game-action-qualifier-dto';
export * from './generated/game-action-statistic-dto';
export * from './generated/game-actor-dto';
export * from './generated/game-actor-on-court-dto';
export * from './generated/game-assignment-configuration-dto';
export * from './generated/game-assignment-dto';
export * from './generated/game-assignments-dto';
export * from './generated/game-dto';
export * from './generated/game-hosted-carousel-item-dto';
export * from './generated/game-information-form-registration-detail-dto';
export * from './generated/game-information-form-registration-dto';
export * from './generated/game-information-form-registration-edit-dto';
export * from './generated/game-list-item-dto';
export * from './generated/game-list-item-export-dto';
export * from './generated/game-officia-e-licenselnformation-dto';
export * from './generated/game-official-list-item-dto';
export * from './generated/game-officialnformation-dto';
export * from './generated/game-period-dto';
export * from './generated/game-play-by-play-action-dto';
export * from './generated/game-play-by-play-dto';
export * from './generated/game-play-by-play-period-dto';
export * from './generated/game-reports-info-dto';
export * from './generated/game-result-dto';
export * from './generated/game-result-status-dto';
export * from './generated/game-result-streak-dto';
export * from './generated/game-sanctions-dto';
export * from './generated/game-search-list-item-dto';
export * from './generated/game-statistic-dto';
export * from './generated/game-statistic-status-dto';
export * from './generated/game-status-dto';
export * from './generated/game-structure-dto';
export * from './generated/game-team-statistics-dto';
export * from './generated/game-upload-log-dto';
export * from './generated/game-upload-message-dto';
export * from './generated/game-with-game-upload-message-dto';
export * from './generated/generate-financial-data-packages-dto';
export * from './generated/group-pairing-dto';
export * from './generated/group-pairing-host-dto';
export * from './generated/group-pairing-nomination-dto';
export * from './generated/group-pairing-placeholder-dto';
export * from './generated/group-pairing-standing-dto';
export * from './generated/group-pairing-standing-placeholder-dto';
export * from './generated/group-pairing-status-dto';
export * from './generated/group-pairing-structure-dto';
export * from './generated/host-dto';
export * from './generated/host-structure-dto';
export * from './generated/incorporated-games-dto';
export * from './generated/insurance-policy-competition-age-category-information-dto';
export * from './generated/insurance-policy-competition-gender-information-dto';
export * from './generated/insurance-policy-coverage-assignment';
export * from './generated/insurance-policy-coverage';
export * from './generated/insurance-policy-document-dto';
export * from './generated/insurance-policy-edit-dto';
export * from './generated/insurance-policy-information-dto';
export * from './generated/insurance-policy-list-item-dto';
export * from './generated/insurance-policy-status-dto';
export * from './generated/invoice-list-item-dto';
export * from './generated/item-category-dto';
export * from './generated/item-category-search-result-line-dto';
export * from './generated/item-dto';
export * from './generated/item-search-result-line-dto';
export * from './generated/item-tree-node-dto';
export * from './generated/license-create-dto';
export * from './generated/license-edit-dto';
export * from './generated/license-list-item-dto';
export * from './generated/license-request-document-dto';
export * from './generated/license-request-dto';
export * from './generated/license-request-list-item-dto';
export * from './generated/license-request-status-dto';
export * from './generated/license-request-update-reason-dto';
export * from './generated/map-claim-dto';
export * from './generated/map-policy-dto';
export * from './generated/map-role-dto';
export * from './generated/merge-clubs-dto';
export * from './generated/merge-entity-dto';
export * from './generated/merge-persons-dto';
export * from './generated/merge-selection-option';
export * from './generated/merge-selection';
export * from './generated/merge-value-both-list-player-competition-dto';
export * from './generated/merge-value-one-or-both-list-agent-person-relation-dto';
export * from './generated/merge-value-one-or-both-list-agent-registration-list-item-dto';
export * from './generated/merge-value-one-or-both-list-agent-relation-share-dto';
export * from './generated/merge-value-one-or-both-list-bank-account-dto';
export * from './generated/merge-value-one-or-both-list-basketball-background-dto';
export * from './generated/merge-value-one-or-both-list-basketball-equipment-approval-dto';
export * from './generated/merge-value-one-or-both-list-basketball-equipment-dto';
export * from './generated/merge-value-one-or-both-list-basketball-license-request-with-out-licenses-dto';
export * from './generated/merge-value-one-or-both-list-basketball-nomination-dto';
export * from './generated/merge-value-one-or-both-list-basketball-nomination-restriction-dto';
export * from './generated/merge-value-one-or-both-list-basketball-test-result-dto';
export * from './generated/merge-value-one-or-both-list-basketball-unavailability-dto';
export * from './generated/merge-value-one-or-both-list-club-name-history-dto';
export * from './generated/merge-value-one-or-both-list-debtor-dto';
export * from './generated/merge-value-one-or-both-list-document-generic-dto';
export * from './generated/merge-value-one-or-both-list-doping-control-dto';
export * from './generated/merge-value-one-or-both-list-dto';
export * from './generated/merge-value-one-or-both-list-event-person-dto';
export * from './generated/merge-value-one-or-both-list-license-list-item-dto';
export * from './generated/merge-value-one-or-both-list-official-license-registration-dto';
export * from './generated/merge-value-one-or-both-list-official-registration-dto';
export * from './generated/merge-value-one-or-both-list-organisation-contact-dto';
export * from './generated/merge-value-one-or-both-list-organisation-note-dto';
export * from './generated/merge-value-one-or-both-list-organisation-person-contact-dto';
export * from './generated/merge-value-one-or-both-list-organisation-venues-dto';
export * from './generated/merge-value-one-or-both-list-person-contact-dto';
export * from './generated/merge-value-one-or-both-list-person-language-dto';
export * from './generated/merge-value-one-or-both-list-person-nationality-dto';
export * from './generated/merge-value-one-or-both-list-person-note-dto';
export * from './generated/merge-value-one-or-both-list-person-role-dto';
export * from './generated/merge-value-one-or-both-list-person-roles-dto';
export * from './generated/merge-value-one-or-both-list-player-award-dto';
export * from './generated/merge-value-one-or-both-list-player-former-name-dto';
export * from './generated/merge-value-one-or-both-list-player-license-dto';
export * from './generated/merge-value-one-or-both-list-sanction-application-dto';
export * from './generated/merge-value-one-or-both-list-technical-meeting-operator-dto';
export * from './generated/merge-value-one-or-both-list-transfer-request-list-item-dto';
export * from './generated/merge-value-one-or-both-list-user-dto';
export * from './generated/merge-value-one-or-both-list-venue-note-dto';
export * from './generated/merge-value-one-or-both-list-venue-organisation-dto';
export * from './generated/merge-value-one-or-both-list-venue-venue-registrations-list-item-dto';
export * from './generated/merge-value-only-both-list-club-competition-dto';
export * from './generated/merge-value-only-both-list-venue-competition-list-item-dto';
export * from './generated/merge-value-only-one-address-dto';
export * from './generated/merge-value-only-one-bool-dto';
export * from './generated/merge-value-only-one-club-documents-and-notes-dto';
export * from './generated/merge-value-only-one-contact-dto';
export * from './generated/merge-value-only-one-date-dto';
export * from './generated/merge-value-only-one-float-dto';
export * from './generated/merge-value-only-one-int-dto';
export * from './generated/merge-value-only-one-item-dto';
export * from './generated/merge-value-only-one-organisation-contact-details-dto';
export * from './generated/merge-value-only-one-person-basketball-data-dto';
export * from './generated/merge-value-only-one-person-contact-details-dto';
export * from './generated/merge-value-only-one-person-details-dto';
export * from './generated/merge-value-only-one-person-documents-and-notes-dto';
export * from './generated/merge-value-only-one-person-financial-data-dto';
export * from './generated/merge-value-only-one-person-informations-dto';
export * from './generated/merge-value-only-one-person-player-data-dto';
export * from './generated/merge-value-only-one-photo-azure-file-dto';
export * from './generated/merge-value-only-one-string-dto';
export * from './generated/merge-value-only-one-venue-documents-and-notes-dto';
export * from './generated/merge-venues-dto';
export * from './generated/min-max-season-dto';
export * from './generated/national-federation-details-dto';
export * from './generated/national-federation-dto';
export * from './generated/national-federation-grouped-by-zone';
export * from './generated/national-federation-list-item-dto';
export * from './generated/national-federation-membership-status-dto';
export * from './generated/national-federation-membership-status-history-dto';
export * from './generated/national-federation-ranking-dto';
export * from './generated/national-federation-to-invoice-dto';
export * from './generated/navigation-property-criteria-dto';
export * from './generated/navigation-property-dto';
export * from './generated/nomination-dto';
export * from './generated/nomination-exclusion-rule-dto';
export * from './generated/nomination-restriction-dto';
export * from './generated/note-dto';
export * from './generated/notification-dto';
export * from './generated/official-candidate-list-dto';
export * from './generated/official-candidate-list-status-dto';
export * from './generated/official-candidate-referee-commissioner-list-dto';
export * from './generated/official-game-official-candidates-dto';
export * from './generated/official-license-period-document-configuration-dto';
export * from './generated/official-license-period-document-dto';
export * from './generated/official-license-period-dto';
export * from './generated/official-license-period-game-official-candidate-dto';
export * from './generated/official-license-period-list-item-dto';
export * from './generated/official-license-period-notification-dto';
export * from './generated/official-license-period-role-dto';
export * from './generated/official-license-period-role-registration-summary-dto';
export * from './generated/official-license-period-selector-list-item-dto';
export * from './generated/official-license-period-spot-candidate-dto';
export * from './generated/official-license-period-spot-candidate-role-dto';
export * from './generated/official-license-period-spot-candidates-dto';
export * from './generated/official-license-period-spot-config-dto';
export * from './generated/official-license-period-spot-configuration-dto';
export * from './generated/official-license-period-spot-dto';
export * from './generated/official-license-period-spot-grid-row-dto';
export * from './generated/official-license-period-spot-grid-spot-dto';
export * from './generated/official-license-period-spot-role-dto';
export * from './generated/official-license-period-spot-summary-list-item-dto';
export * from './generated/official-license-period-spot-zone-configuration-dto';
export * from './generated/official-license-period-status-dto';
export * from './generated/official-license-period-with-spots-grid-dto';
export * from './generated/official-license-person-information-dto';
export * from './generated/official-license-registration-by-nf-list-item-dto';
export * from './generated/official-license-registration-grid-item-dto';
export * from './generated/official-license-registration-merge-list-item-dto';
export * from './generated/official-license-registration-zone-list-item-dto';
export * from './generated/official-license-request-dto';
export * from './generated/official-nomination-competition-dto';
export * from './generated/official-nomination-game-dto';
export * from './generated/official-nomination-group-pairing-dto';
export * from './generated/official-nomination-round-dto';
export * from './generated/official-nominations-dto';
export * from './generated/official-registration-edit-dto';
export * from './generated/official-registration-list-item-dto';
export * from './generated/official-registration-merge-list-item-dto';
export * from './generated/official-registration-status-dto';
export * from './generated/official-registrations-edit-dto';
export * from './generated/organisation-bank-account-dto';
export * from './generated/organisation-competition-list-item-dto';
export * from './generated/organisation-contact-dto';
export * from './generated/organisation-debtor-dto';
export * from './generated/organisation-document-dto';
export * from './generated/organisation-documents-and-notes-dto';
export * from './generated/organisation-dto';
export * from './generated/organisation-equipment-approval-category-accreditation-dto';
export * from './generated/organisation-equipment-approval-category-list-item-dto';
export * from './generated/organisation-financial-dto';
export * from './generated/organisation-information-dto';
export * from './generated/organisation-league-list-item-dto';
export * from './generated/organisation-list-item-dto';
export * from './generated/organisation-member-to-export-dto';
export * from './generated/organisation-members-dto';
export * from './generated/organisation-name-history-dto';
export * from './generated/organisation-note-dto';
export * from './generated/organisation-person-role-dto';
export * from './generated/organisation-public-dto';
export * from './generated/organisation-relation-dto';
export * from './generated/organisation-sanctions-dto';
export * from './generated/organisation-status-dto';
export * from './generated/organisation-temp-dto';
export * from './generated/organisation-venue-information-dto';
export * from './generated/organisation-with-relations-dto';
export * from './generated/payment-abort-dto';
export * from './generated/payment-assert-dto';
export * from './generated/payment-dto';
export * from './generated/payment-forward-dto';
export * from './generated/payment-initialize-dto';
export * from './generated/payment-list-item-dto';
export * from './generated/payment-manual-dto';
export * from './generated/payment-receipt-dto';
export * from './generated/payment-status-dto';
export * from './generated/payment-terms-and-conditions-dto';
export * from './generated/payment-transaction-dto';
export * from './generated/person-bank-account-dto';
export * from './generated/person-basketball-restrictions-dto';
export * from './generated/person-basketball-tests-dto';
export * from './generated/person-check-duplicate-list-item-dto';
export * from './generated/person-coach-license-dto';
export * from './generated/person-coach-license-linked-request-dto';
export * from './generated/person-communication-dto';
export * from './generated/person-competition-dto';
export * from './generated/person-contact-details-dto';
export * from './generated/person-contact-dto';
export * from './generated/person-contact-summary-dto';
export * from './generated/person-debtor-dto';
export * from './generated/person-details-dto';
export * from './generated/person-document-dto';
export * from './generated/person-documents-and-notes-dto';
export * from './generated/person-dto';
export * from './generated/person-financial-data-dto';
export * from './generated/person-game-dto';
export * from './generated/person-game-official-details-dto';
export * from './generated/person-information-dto';
export * from './generated/person-language-dto';
export * from './generated/person-list-item-dto';
export * from './generated/person-list-item-export-dto';
export * from './generated/person-merge-history-dto';
export * from './generated/person-merge-history-list-dto';
export * from './generated/person-nationality-document-dto';
export * from './generated/person-nationality-dto';
export * from './generated/person-nomination-calendar-item-dto';
export * from './generated/person-nomination-dto';
export * from './generated/person-note-dto';
export * from './generated/person-official-license-dto';
export * from './generated/person-official-license-request-dto';
export * from './generated/person-role-dto';
export * from './generated/person-role-list-item-dto';
export * from './generated/person-roles-dto';
export * from './generated/person-roles-with-user-dto';
export * from './generated/person-sanctions-dto';
export * from './generated/person-statistician-license-dto';
export * from './generated/person-status-dto';
export * from './generated/person-users-dto';
export * from './generated/person-with-nominations-dto';
export * from './generated/player-award-dto';
export * from './generated/player-competition-dto';
export * from './generated/player-competition-injury-dto';
export * from './generated/player-data-dto';
export * from './generated/player-former-name-dto';
export * from './generated/player-game-played-dto';
export * from './generated/player-in-team-dto';
export * from './generated/player-last-known-info-dto';
export * from './generated/player-license-document-dto';
export * from './generated/player-license-dto';
export * from './generated/player-license-list-item-dto';
export * from './generated/player-license-list-item-export-dto';
export * from './generated/player-license-notification-dto';
export * from './generated/player-license-request-dto';
export * from './generated/player-license-sanction-application-list-item-dto';
export * from './generated/player-license-status-dto';
export * from './generated/player-license-status-update-dto';
export * from './generated/player-license-summary-dto';
export * from './generated/player-nationality-dto';
export * from './generated/player-release-dto';
export * from './generated/player-release-list-item-dto';
export * from './generated/player-release-status-dto';
export * from './generated/player-release-status-history-dto';
export * from './generated/player-release-status-history-item-dto';
export * from './generated/player-stats-in-competition-dto';
export * from './generated/player-with-conditional-transfers-informations-dto';
export * from './generated/player-with-doping-controls-dto';
export * from './generated/player-with-sanction-ban-details-dto';
export * from './generated/pre-roster-member-communication-dto';
export * from './generated/preliminary-roster-configuration-carousel-item-dto';
export * from './generated/preliminary-roster-configuration-dto';
export * from './generated/preliminary-roster-configuration-insurance-view-dto';
export * from './generated/preliminary-roster-configuration-item-dto';
export * from './generated/preliminary-roster-configuration-list-item-dto';
export * from './generated/preliminary-roster-dto';
export * from './generated/preliminary-roster-for-person-dto';
export * from './generated/preliminary-roster-insurance-summary-dto';
export * from './generated/preliminary-roster-list-item-dto';
export * from './generated/preliminary-roster-member-document-dto';
export * from './generated/preliminary-roster-member-dto';
export * from './generated/preliminary-roster-member-injury-dto';
export * from './generated/preliminary-roster-member-insurance-dto';
export * from './generated/preliminary-roster-notification-dto';
export * from './generated/published-event-address-dto';
export * from './generated/published-event-competition-host-dto';
export * from './generated/published-event-details-dto';
export * from './generated/published-event-dto';
export * from './generated/ranking-detail-dto';
export * from './generated/ranking-list-item-dto';
export * from './generated/ranking-status-dto';
export * from './generated/re-captchable-dto';
export * from './generated/reference-item-dto';
export * from './generated/related-organisation-to-export-dto';
export * from './generated/report-dto';
export * from './generated/role-dto';
export * from './generated/role-family-dto';
export * from './generated/role-list-item-dto';
export * from './generated/role-list-item-export-dto';
export * from './generated/role-type-dto';
export * from './generated/roster-configuration-item-dto';
export * from './generated/roster-member-dto';
export * from './generated/roster-overview-competition-competition-dto';
export * from './generated/roster-overview-competition-competition-line-dto';
export * from './generated/roster-overview-game-game-dto';
export * from './generated/roster-overview-game-game-line-dto';
export * from './generated/roster-overview-roster-limits-dto';
export * from './generated/roster-overview-round-round-dto';
export * from './generated/roster-overview-round-round-line-dto';
export * from './generated/roster-overview-structure-dto';
export * from './generated/roster-overview-structure-game-day-dto';
export * from './generated/roster-overview-structure-round-dto';
export * from './generated/roster-overview-structure-window-dto';
export * from './generated/roster-overview-window-game-dto';
export * from './generated/roster-overview-window-game-line-dto';
export * from './generated/roster-overview-window-window-dto';
export * from './generated/roster-overview-window-window-line-dto';
export * from './generated/round-draw-placeholder-dto';
export * from './generated/round-dto';
export * from './generated/round-host-dto';
export * from './generated/round-information-summary-dto';
export * from './generated/round-next-qualified-team-dto';
export * from './generated/round-nomination-dto';
export * from './generated/round-status-dto';
export * from './generated/round-structure-dto';
export * from './generated/sample-dto';
export * from './generated/sanction-application-document-dto';
export * from './generated/sanction-application-dto';
export * from './generated/sanction-application-list-item-dto';
export * from './generated/sanction-application-list-item-export-dto';
export * from './generated/sanction-ban-application-dto';
export * from './generated/sanction-dto';
export * from './generated/sanction-organisation-person-dto';
export * from './generated/site-visit-dto';
export * from './generated/site-visit-edit-dto';
export * from './generated/site-visit-form-registration-detail-dto';
export * from './generated/site-visit-form-registration-edit-dto';
export * from './generated/site-visit-list-item-dto';
export * from './generated/statistician-license-dto';
export * from './generated/status-dto';
export * from './generated/structure-classification-competition-dto';
export * from './generated/structure-classification-game-dto';
export * from './generated/structure-classification-group-pairing-dto';
export * from './generated/structure-classification-round-dto';
export * from './generated/team-box-score-dto';
export * from './generated/team-communication-dto';
export * from './generated/team-delegation-configuration-dto';
export * from './generated/team-delegation-detailed-configuration-dto';
export * from './generated/team-participation-dto';
export * from './generated/team-registration-summary-dto';
export * from './generated/team-statistics-line-dto';
export * from './generated/tech-mapexternal-application-id-mapping-dto';
export * from './generated/tech-reporting-dto';
export * from './generated/technical-delegate-public-confirmation-dto';
export * from './generated/technical-delegate-public-test-dto';
export * from './generated/technical-delegate-reporting-form-registration-detail-dto';
export * from './generated/technical-delegate-reporting-form-registration-dto';
export * from './generated/technical-delegate-reporting-form-registration-edit-dto';
export * from './generated/technical-meeting-competition-team-dto';
export * from './generated/technical-meeting-configuration-details-dto';
export * from './generated/technical-meeting-configuration-list-item-dto';
export * from './generated/technical-meeting-configurations-dto';
export * from './generated/technical-meeting-dto';
export * from './generated/technical-meeting-operator-dto';
export * from './generated/technical-meeting-operator-user-details-dto';
export * from './generated/technical-meeting-status-dto';
export * from './generated/template-administration-dto';
export * from './generated/template-dto';
export * from './generated/template-recipient-dto';
export * from './generated/template-search-result-line-dto';
export * from './generated/terms-and-conditions-dto';
export * from './generated/test-result-detail-dto';
export * from './generated/test-result-dto';
export * from './generated/thing-dto';
export * from './generated/thing-sortable-dto';
export * from './generated/transfer-agent-investigation-dashboard-dto';
export * from './generated/transfer-investigation-dto';
export * from './generated/transfer-loc-request-dto';
export * from './generated/transfer-nba-leagues-counters-dto';
export * from './generated/transfer-request-conditional-informations-dto';
export * from './generated/transfer-request-document-dto';
export * from './generated/transfer-request-document-status-dto';
export * from './generated/transfer-request-dto';
export * from './generated/transfer-request-fo-dashboard-dto';
export * from './generated/transfer-request-investigation-dto';
export * from './generated/transfer-request-investigation-status-dto';
export * from './generated/transfer-request-list-item-dto';
export * from './generated/transfer-request-list-item-export-dto';
export * from './generated/transfer-request-sanction-application-list-item-dto';
export * from './generated/transfer-request-status-dto';
export * from './generated/transfer-request-status-history-dto';
export * from './generated/umbrella-dto';
export * from './generated/umbrella-status-dto';
export * from './generated/unavailability-dto';
export * from './generated/uniform-set-document-dto';
export * from './generated/uniform-set-dto';
export * from './generated/uniform-set-status-dto';
export * from './generated/user-access-dto';
export * from './generated/user-agent-information-dto';
export * from './generated/user-authentication-dto';
export * from './generated/user-authorisation-context';
export * from './generated/user-authorisation-dto';
export * from './generated/user-authorisations-dto';
export * from './generated/user-device-dto';
export * from './generated/user-dto';
export * from './generated/user-information-dto';
export * from './generated/user-list-item-dto';
export * from './generated/user-map-notification-config-list-item-dto';
export * from './generated/user-merge-information-dto';
export * from './generated/user-national-federation-dto';
export * from './generated/user-person-information-dto';
export * from './generated/user-profile-dto';
export * from './generated/user-status-dto';
export * from './generated/user-top-menu-info-dto';
export * from './generated/user-trace-dto';
export * from './generated/user-with-map-notification-config-dto';
export * from './generated/venue-competition-list-item-dto';
export * from './generated/venue-contact-dto';
export * from './generated/venue-document-dto';
export * from './generated/venue-documents-and-notes-dto';
export * from './generated/venue-dto';
export * from './generated/venue-form-registration-detail-dto';
export * from './generated/venue-form-registration-edit-dto';
export * from './generated/venue-form-registration-list-item-dto';
export * from './generated/venue-list-item-dto';
export * from './generated/venue-note-dto';
export * from './generated/venue-organisation-dto';
export * from './generated/venue-playing-clubs-dto';
export * from './generated/venue-status-dto';
export * from './generated/venue-venue-registrations-list-item-dto';
export * from './generated/venue-with-basketball-equipments-dto';
export * from './generated/venue-with-competitions-dto';
export * from './generated/venue-with-venue-registrations-dto';
export * from './generated/versus-result-dto';
export * from './generated/window-dto';
export * from './generated/window-form-registration-template-configuration-dto';
export * from './generated/windows-dto';
export * from './generated/zone-dto';
export * from './generated/zone-main-zone-association-dto';
export * from './generated/zone-relation-dto';
export * from './generated/zones-and-zone-relations-dto';
