import { Injectable } from '@angular/core';
import { LicenseCreateDTO } from '@fiba/models/dtos/generated/license-create-dto';
import { Observable } from 'rxjs';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class PersonAgentLicenseDataService {
    private readonly personAgentApiEndpoint: string;

    constructor(private http: HttpService, private config: AppConfig) {
        this.personAgentApiEndpoint = config.personApiEndpoint;
    }
    /**
     * Create Agent License
     *
     * @param model
     */
    public createAgentLicense(model: LicenseCreateDTO): Observable<LicenseCreateDTO> {
        return this.http.post(`${this.personAgentApiEndpoint}/${model.personId}/agent-license`, model);
    }
}
