import { Component, Injector, Input } from '@angular/core';

import { FibaPopupBase } from '@fiba/forms';
import { ThingDTO, ZoneDTO } from '@fiba/models';

@Component({
    selector: 'fibaPopupNationalFederation',
    templateUrl: './fiba-popup-national-federation.component.html',
})
export class FibaPopupNationalFederationComponent extends FibaPopupBase<ThingDTO> {
    _value: ThingDTO = null;

    public _zone: ZoneDTO;
    @Input() public set zone(value: ZoneDTO) {
        this._zone = value;
    }

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected onSave(e): void {
        this._model = this._value;
        this.emptyInternalPopupValue();
        super.onSave(e);
    }

    closeForm(): void {
        this.emptyInternalPopupValue();
        super.closeForm();
    }

    onCancel(e): void {
        this.emptyInternalPopupValue();
        super.onCancel(e);
    }

    /**
     *  Empty the popup value, otherwise one can open the popup again and just click ok to select the last value
     */
    protected emptyInternalPopupValue(): void {
        this._value = undefined;
    }
}
