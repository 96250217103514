import { BaseFilters } from '@fiba/models/common/base.filters';

export class PersonLicenseFilters  extends BaseFilters {
    public endDate: Date;
    public nationalFederation: any;
    public startDate: Date;

    reset(): void {
        this.endDate = null;
        this.nationalFederation = null;
        this.startDate = null;
    }
}
