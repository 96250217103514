import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationContactCategoryDataService } from '@fiba/data-services';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import { Observable } from 'rxjs';


@Component({
    selector: 'fibaSelectContactTypeItem',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectContactTypeItemComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectContactTypeItemComponent extends FibaAjaxSelectBaseComponent<ItemDTO> {
    public _selectedProcessDrivenIds: number[];
    @Input() protected set selectedProcessDrivenIds(value: number[]) {
        this._selectedProcessDrivenIds = value;
        this._filteredItems = this._originalItems ? this._originalItems.filter((item: ItemDTO) => !this.arrayContains(this._selectedProcessDrivenIds, item.itemId) || this.modelId === item.itemId) : [];
    }
    @Input() contactEntityItemCode: string;
    @Input() contactTypeItemCode: string;

    constructor(protected injector: Injector, protected dataService: ConfigurationContactCategoryDataService) {
        super(injector);
        this._valueField = 'itemId';
        this._textField = 'name';

    }

    public getObservable(): Observable<ItemDTO[]> {
        return this.dataService.fetchCategoryItems(this.contactEntityItemCode, this.contactTypeItemCode);
    }

    public getDefaultValueCreation(): ItemDTO {
        if (this._filteredItems && this._filteredItems.length === 1) {
            return this._filteredItems[0];
        }
    }
    private arrayContains(array: number[], element: number): boolean {
        return array?.indexOf(element) > -1;
    }
    protected handleRestrictedItems(): void {
        if (this._originalItems) {
            this._filteredItems = this._originalItems.filter((item: ItemDTO) => !this.arrayContains(this._selectedProcessDrivenIds, item.itemId) || this.modelId === item.itemId);
        }
    }
}
