import { Injector, Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { CountryDTO, ZoneDTO } from '@fiba/models';
import { CountryDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaSelectCountry',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectCountryComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectCountryComponent extends FibaAjaxSelectBaseComponent<CountryDTO> {

    public _zone: ZoneDTO;
    @Input() public set zone(value: ZoneDTO) {
        this._zone = value;
        this.handleRestrictedItems();
        this.handlePreselectedItem();
    }

    constructor(protected injector: Injector, protected dataService: CountryDataService) {
        super(injector);
        this._valueField = 'countryId';
        this._textField = 'displayName';
    }

    getBindData(): (data: CountryDTO[]) => void {
        return (data: CountryDTO[]) => {
            this._originalItems = data.map(item => { item['displayName'] = `${item.shortName} (${item.fibacode})`; return item; });
            this.handleRestrictedItems();
        };
    }

    getObservable(): Observable<CountryDTO[]> {
        return this.dataService.fetchCountries(false);
    }

    protected handleRestrictedItems(): void {
        if (this._zone && this._zone.zoneId && this._originalItems) {
            this._filteredItems = this._originalItems.filter(item => !item.countryZone || item.countryZone.find(cz => cz.zoneId === this._zone.zoneId));
        } else {
            this._filteredItems = this._originalItems;
        }
    }

    protected handlePreselectedItem(): void {
        if (this._value && this._valueField) {
            const foundCountry = this._originalItems.filter(item => item[this._valueField] === this._value[this._valueField]);
            if (foundCountry.length <= 0) {
                this._value = null;
            }
        }
    }
}
