import { Component, Input } from '@angular/core';
import { ItemDataService } from '@fiba/data-services';
import { ItemCategoryCodes, CoreDisplayTexts, NotificationTypes } from '@fiba/models';
import { NotificationService, NotificationType } from '@fiba/utils/utils.module';

@Component({
    selector: 'fibaDisplayText',
    templateUrl: './fiba-display-text.component.html',
})
export class FibaDisplayTextComponent {

    protected notificationService: NotificationService;
    protected itemDataService: ItemDataService;

    public displayText: string;

    @Input()
    set itemCode(value: string) {
        this.itemDataService.fetchItemByCategoryAndCode(ItemCategoryCodes.CoreDisplayText, value).subscribe(
            response => this.displayText = response.description,
            error => this.notificationService.emitNotification(NotificationType.Error, error),
            () => { }
        );
    }

    constructor(itemDataService: ItemDataService, notificationService: NotificationService) {
        this.notificationService = notificationService;
        this.itemDataService = itemDataService;
    }

}
