import { Injector, Component, forwardRef, Input, OnInit, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { FibaSelectBaseComponent } from '@fiba/forms';
import { Season } from '@fiba/models/common/season';


@Component({
    selector: 'fibaSelectUniformNumber',
    templateUrl: './fiba-select-uniform-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectUniformNumberComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectUniformNumberComponent extends FibaSelectBaseComponent<number> implements OnChanges {

    private readonly UNIFORM_NUMBER_MIN: number = 0;
    private readonly UNIFORM_NUMBER_MAX: number = 100;

    @Input() uniformNumbersAlreadySelected: string[] = Array<string>();

    constructor(protected injector: Injector) {
        super(injector);
        this.valuePrimitive = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this._originalItems = this.processListOfUniformNumbers();
        this.handleRestrictedItems();
    }

    ngOnChanges(changes) {
        this._originalItems = this.processListOfUniformNumbers();
        this.handleRestrictedItems();
    }

    handleValue($event) {
        super.handleValue($event);
        this._originalItems = this.processListOfUniformNumbers();
        this.handleRestrictedItems();
    }

    protected _minUniformNumber: number;
    protected get minUniformNumber(): number {
        return this.UNIFORM_NUMBER_MIN;
    }

    protected _maxUniformNumber: number;
    protected get maxUniformNumber(): number {
        return this.UNIFORM_NUMBER_MAX;
    }

    getObservable(): Observable<number[]> {
        return of(this.processListOfUniformNumbers());
    }

    private processListOfUniformNumbers(): any[] {
        const listOfUniformNumbers = Array<string>();
        if (!this.uniformNumbersAlreadySelected.some(x => x === '00')) {
            listOfUniformNumbers.push('00');
        }
        for (let uniformNumber = this.minUniformNumber; uniformNumber < this.maxUniformNumber; uniformNumber++) {
            if (!this.uniformNumbersAlreadySelected.some(x => x === String(uniformNumber))) {
                listOfUniformNumbers.push(String(uniformNumber));
            }
        }
        return listOfUniformNumbers;
    }

    private checkValueOrSetDefault(value: number, defaultValue: number) {
        if (typeof value === 'number') {
            return value;
        } else {
            return null;
        }
    }
}
