import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AuthService, CityDataService } from '@fiba/data-services';
import { FibaInputBase } from '@fiba/forms';

import * as moment from 'moment-timezone';

import { Airport } from '@fiba/models';
import { Observable, of, Subject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AirportDataService } from '../../data-services/common/airport-data.service';

@Component({
    selector: 'fibaAutocompleteAirport',
    templateUrl: './fiba-autocomplete-airport.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaAutocompleteAirportComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
    styleUrls: ['./fiba-autocomplete-airport.component.css'],
})
/**
 Component's ngModel must be bound to a string
 City value is managed as internal value
 (This is why it doesn't extend FibaInputAutocompleteBase)
 */
export class FibaAutocompleteAirportComponent extends FibaInputBase<Airport> implements OnInit {
    public textField: string;
    public valueField: string;
    public data: Observable<Airport[]>;
    public valueAirport: Airport;
    public searchTerms: Subject<string> = new Subject<string>();
    public currentDate = Date.now();
    @Input() public placeholder: string;
    @Input() public type = 'filter';
    @Input() public delay = 300;
    @Input() public disabled = false;

    @Output() public airportChange: EventEmitter<string> = new EventEmitter();

    constructor(
        protected injector: Injector,
        private service: AirportDataService,
        protected authService: AuthService) {
        super(injector);

        this.data = this.searchTerms.asObservable().pipe(
            debounceTime<string>(this.delay),
            distinctUntilChanged<string>(),
            switchMap((term: string) => this.getObservable(term)
            ),
        );

        this.data.subscribe((item) => {
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.valueField = 'geonameId';
        this.textField = 'toponymName';
    }

    public getObservable(airport: string): Observable<Airport[]> {
        if (airport) {
            return this.service.fetchAirports(airport);
        } else {
            return of<Airport[]>([]);
        }
    }

    public writeValue(value: Airport): void {
        this.value = value;
        if (value) {
            this.valueAirport = {
                toponymName: value.toponymName,
                displayName: value.displayName,
                geonameId: value.geonameId,
            } as Airport;
        } else {
            this.valueAirport = null;
        }
    }

    public handleFilter(value: string): void {
        if (value) {
            this.searchTerms.next(value);
        } else {
            this.handleValue(null);
        }
        this.onTouched();
    }

    public handleValue(value: Airport): void {
        if (value) {
            this.airportChange.emit(value.toponymName);
            this.writeValue(value);
        } else {
            this.airportChange.emit(null);
            this.writeValue(null);
        }
        this.manualChange();
        this.onTouched();
    }

    public valueNormalizer = (text: Observable<string>) => text.pipe(map((toponymName: string) => ({
        toponymName,
        displayName: toponymName,
    } as Airport)));

}
