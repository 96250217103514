import { Directive, Input, Output, HostBinding, HostListener, EventEmitter, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Directive({
    selector: '[ngxDropdown]',
    exportAs: 'ngxDropdown'
})
export class DropdownDirective {
    toggleElement: any;
    @Input() open = false;
    @Output() openChange = new EventEmitter<boolean>();

    @HostBinding('class.show') get isOpen(): boolean {
        return this.open;
    }

    @HostListener('keyup.esc')
    onKeyupEsc() {
        this.onClose();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (event.button !== 2 && !this.isEventFromToggle(event)) {
            this.onClose();
        }
    }

    onOpen() {
        if (!this.open) {
            this.open = true;
            this.openChange.emit(true);
        }
    }

    onClose() {
        if (this.open) {
            this.open = false;
            this.openChange.emit(false);
        }
    }

    toggle() {
        if (this.isOpen) {
            this.onClose();
        } else {
            this.onOpen();
        }
    }

    private isEventFromToggle(event: MouseEvent) {
        return !_.isNil(this.toggleElement) && this.toggleElement.contains(event.target);
    }
}
