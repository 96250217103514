import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment-timezone';

@Pipe({ name: 'fibaUtcOffset' })
export class FibaUtcOffsetPipe implements PipeTransform {
    transform(value, format: string): string {
        if (format) {
            const momentDate = Moment.tz(Moment.utc(value).format('YYYY-MM-DD HH:mm'), format);
            return 'UTC' + momentDate.format('Z');
        }
        return '';
    }
}
