<div kendoTooltip showOn="none">

    <img *ngIf="pictureUrl" [src]="pictureUrl" [ngStyle]="{'max-width':'250px', 'max-height':'100px'}" />
    <img *ngIf="!pictureUrl" [src]="getDefaultPicture()" [ngStyle]="{'max-width':'250px', 'max-height':'100px'}" />

    <ng-container *ngIf="isEditable">
        <button *fibaHideByPolicy="MapPolicy_AzureFilesEdit"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-pencil'"
                [look]="'bare'"
                (click)="editClick()"
                id="buttonEdit"></button>
        <button *ngIf="pictureUrl"
                #anchor
                title="URL copied"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-copy'"
                [look]="'bare'"
                (click)="copyLogoToClipboard(anchor)"
                id="buttonCopy"></button>
        <button *ngIf="pictureUrl"
                title="Remove picture"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-trash'"
                [look]="'bare'"
                (click)="deletePicture()"
                id="buttonDelete"></button>
    </ng-container>
</div>

<fibaPopupCompetitionPicture (save)="saveCompetitionLogoHandler($event)"
                             (cancel)="cancelCompetitionLogoHandler()"
                             [model]="editCompetitionLogo"
                             [entityReference]="entityReference"
                             [fileType]="fileType"
                             [recommendedSize]="recommendedSize"
                             [isNew]="isNew"></fibaPopupCompetitionPicture>