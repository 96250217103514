import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared.module';
import { FibaFormsModule } from '@fiba/forms';
import { FibaCommonSelectModule } from '@fiba/common/fiba-common-select.module';

import { FibaPopupNationalFederationComponent } from './fiba-popup-national-federation.component';
import { NationalFederationSearchMiniComponent } from './national-federation-search-mini.component';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPopupNationalFederationComponent,
];

const INTERNAL_COMPONENTS = [
    NationalFederationSearchMiniComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonSelectModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPopupNationalFederationModule {
}
