import { Injector, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { FibaSelectBaseComponent } from '@fiba/forms';
import { Season } from '@fiba/models/common/season';
import { FibaSelectSeasonComponent } from './fiba-select-season.component';
import { CompetitionPickerDataService, PlayerLicenseDataService } from '@fiba/data-services';
import { MinMaxSeasonDTO } from '@fiba/models';


@Component({
    selector: 'fibaSelectPlayerLicenseSeason',
    templateUrl: './fiba-select-season.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectPlayerLicenseSeasonComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectPlayerLicenseSeasonComponent extends FibaSelectSeasonComponent {

    protected dataService: PlayerLicenseDataService;
    constructor(protected injector: Injector) {
        super(injector);
        this.dataService = injector.get(PlayerLicenseDataService);
    }

    ngOnInit() {
        this.dataService.fetchPlayerLicenseMinMaxSeason().subscribe(
            (result: MinMaxSeasonDTO) => {
                this.minSeason = result.minSeason;
                this.maxSeason = result.maxSeason;
                super.ngOnInit();
                this._originalItems = this.processListOfSeasons();
                this.handleRestrictedItems();
            }
        );
    }
}
