<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <input #input="ngModel"
           type="password"
           id="{{name}}"
           [required]="required"
           [disabled]="disabled"
           [ngModel]="value"                     
           (ngModelChange)="onPasswordChanged($event)"
           (blur)="onTouched($event)"           
           />
     <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.minlength">
                {{label}} must be at least TODO characters long.
            </li>
            <li [hidden]="!ngControl.errors.maxlength">
                {{label}} cannot be more than TODO characters long.
            </li>
            <li [hidden]="!ngControl.errors.fibaEqualValidator">
                Password fields do not match.
            </li>
        </ul>
    </div>
</div>
