import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../utils/http.service';
import { AgentRelationFilters, ApiListResponse, AgentPersonRelationViewDTO, PersonDTO, ThinModel, PersonListItemDTO } from '@fiba/models';
import { map } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

@Injectable()
export class AgentRelationDataService {
    private readonly agentRelationsApiEndpoint: string;
    private readonly agentRelationsAutocompleteApiEndpoint: string;
    private readonly personApiEndpoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig) {
        this.agentRelationsApiEndpoint = config.agentRelationsApiEndpoint;
        this.personApiEndpoint = config.personApiEndpoint;
        this.agentRelationsAutocompleteApiEndpoint = `${config.agentRelationsApiEndpoint}/autocomplete`;
    }

    // Get player, coach or club
    // <params> represented:ThingDTO</params>
    public fetchRepresentedPersonOrClub(represented: ThinModel): Observable<ThinModel> {
        if (represented.name === 'club') {
            const c = {} as ThinModel;
            c.id = represented.id;
            c.name = represented.id + ':<name>';
            c.code = 'club';
            return Observable.create((observer) => {
                observer.next(c);
                //call complete if you want to close this stream (like a promise)
                observer.complete();
            });
        }
        return this.http.get(`${this.personApiEndpoint}/${represented.id}`)
            .pipe(map((res) => {
                const p = res as PersonDTO;
                return {
                    id: p.personId,
                    name: p.displayFirstName + ' ' + p.displayLastName,
                } as ThinModel;
            }));
    }

    public fetchList(filters: AgentRelationFilters): Observable<ApiListResponse<PersonListItemDTO>> {
        return this.http.get(this.agentRelationsApiEndpoint + '/persons', filters.getSearchParams()).pipe(map(
            (response) => response as ApiListResponse<PersonListItemDTO>));
    }

    public fetchAutoCompleteRelatedPersonList(name: string = ''): Observable<ThinModel[]> {
        const params = { name };
        return this.http.get(this.agentRelationsAutocompleteApiEndpoint, params);
    }
}
