import { SortDescriptor } from '@progress/kendo-data-query';

function getValue(obj: any, field: string) {
    const atts = field.split('.');
    let value: any = obj;
    for (const att of atts) {
        value = value[att];
    }
    return value;
}

export function getGridSortFn(sortDescriptor: SortDescriptor): (a: any, b: any) => number {
    let sortFn: (a: any, b: any) => number;
    if (sortDescriptor.dir === 'asc') {
        sortFn = (a, b) => {
            const aValue = getValue(a, sortDescriptor.field);
            const bValue = getValue(b, sortDescriptor.field);
            return ((aValue === bValue) ? 0 : ((aValue > bValue) ? 1 : -1));
        };
    } else {
        sortFn = (a, b) => {
            const aValue = getValue(a, sortDescriptor.field);
            const bValue = getValue(b, sortDescriptor.field);
            return ((aValue === bValue) ? 0 : ((bValue > aValue) ? 1 : -1));
        };
    }
    return sortFn;
}
