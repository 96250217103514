import { Observable } from 'rxjs';

import { InjectionToken, Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import {timeout} from 'rxjs/operators';


@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(timeout(1800000));
    }
}
