import { Pipe, PipeTransform } from '@angular/core';
import { CountryDTO, ItemCategoryCodes, ItemDTO } from '@fiba/models';
import { ItemDataService } from '../../data-services';

@Pipe({ name: 'itemToText', pure: false })
export class ItemToTextPipe implements PipeTransform {

    protected displayItems: ItemDTO[] = [];

    constructor(protected itemDataService: ItemDataService) {
        this.itemDataService.fetchItems(ItemCategoryCodes.CoreDisplayText)
            .subscribe(
                (items) => { this.displayItems = items;}
        );
    }

    transform(itemCode: string, alternativeText: string): string {
        if (this.displayItems && itemCode) {

            let item = this.displayItems.find(i => i.code == itemCode);

            if (item) {
                return item.description;
            }
        }
        return alternativeText || '';
    }
}
