import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

import { AuthService } from '@fiba/data-services';
import { MapPolicy } from '@fiba/models';

@Directive({
    selector: '[fibaHideByPolicy]'
})
export class FibaHideByPolicyDirective {

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, protected authService: AuthService) { }

    @Input()
    set fibaHideByPolicy(policy: MapPolicy) {
        this.authService.isUserAuthorized(policy).then(isAuthorized => {
            if (isAuthorized) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }
}
