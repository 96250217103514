import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

import { AuthService } from '@fiba/data-services';
import { MapPolicy } from '@fiba/models';

@Directive({
    selector: '[fibaHideIfBack]'
})
export class FibaHideIfBackDirective {

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, protected authService: AuthService) {
        this.authService.getIsFrontOfficeUserPromise().then(isFrontOffice => {
            if (!isFrontOffice) {
                this.viewContainer.clear();
            } else {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }
}
