import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CancelEvent, ClearEvent, FileInfo, FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';

import { DocumentCreationRequest, DocumentCreationRequestWithBaseEntity, DocumentDTO, ItemDTO } from '@fiba/models';
import { AuthService } from '@fiba/data-services';
import { IHttpClientRequestOptions } from '@fiba/data-services/utils/http.service';
import { process } from '@progress/kendo-data-query';
import {AppConfig} from '@app/app.config';

@Component({
    selector: 'fibaInputUploadAzureDocument',
    templateUrl: './fiba-input-upload-azure-document.component.html',
    styles: [':host ::ng-deep .k-upload .k-file-size { display: none; }'],
})
export class FibaInputUploadAzureDocumentComponent implements OnInit {
    public uploadSaveUrl: string = this.config.azureDocumentApiEndpoint;
    public uploadRemoveUrl = 'removeUrl';
    public _restrictions: FileRestrictions = {
        maxFileSize: 10000000,
        allowedExtensions: ['.pdf', '.jpg', '.jpeg', '.png'],
    };
    public myFiles: FileInfo[] = [];

    // Logic to always keep 1 document at a time
    @ViewChild('kendoUploadCmp', {static: true}) public kendoUploadCmp;
    private lastDocumentUid: string = undefined;

    @Output() public getDocumentInfo: EventEmitter<Document> = new EventEmitter();
    @Output() public getTitle: EventEmitter<string> = new EventEmitter();
    @Output() public fileUploaded: EventEmitter<boolean> = new EventEmitter();

    @Input() protected entityType: string;
    @Input() protected item: ItemDTO;
    @Input() protected title: string;
    @Input() protected entityId: number;
    @Input() protected documentId = -1;
    @Input() public disabled = false;
    @Input() protected isNew = true;
    @Input() public saveMethod = 'POST';
    @Input() protected documentName: string;
    @Input() protected entityTypeBase: string = undefined;
    @Input() protected entityIdBase: number = undefined;
    @Input() protected set publicApi(value: boolean) {
        if (value) {
            this.uploadSaveUrl = this.config.publicDocumentApiEndpoint;
        } else {
            this.uploadSaveUrl = this.config.azureDocumentApiEndpoint;
        }
    }
    @Input()
    protected set restrictionPicture(value: boolean) {
        if (value) {
            this._restrictions = {
                maxFileSize: 10000000, allowedExtensions: ['.jpg', '.jpeg', '.png'],
            };
        }
    }

    private doc: DocumentDTO;

    constructor(protected authService: AuthService, protected config: AppConfig) {
    }

    public ngOnInit() {
        if (this.documentName) {
            this.myFiles = [{ name: this.documentName, size: -1 }];
            this.fileUploaded.emit(true);
        } else {
            this.fileUploaded.emit(false);
        }
        // LOL
    }

    // noinspection JSUnusedGlobalSymbols
    public uploadEventHandler(e: UploadEvent): void {
        this.fileUploaded.emit(false);

        if (!this.title) {
            this.title = e.files[0].name.split(e.files[0].extension)[0];
        }
        this.getTitle.emit(this.title);

        e.headers = this.createRequestOptions().headers;
        e.data = !this.entityTypeBase ?
            this.buildDocCreateRequest() :
            this.buildDocRequestWithBaseEntity();
    }

    // noinspection JSUnusedGlobalSymbols
    public clearEventHandler(e: ClearEvent): void {
        e.preventDefault();
        this.fileUploaded.emit(false);
        this.disabled = false;
    }

    // noinspection JSUnusedGlobalSymbols
    public cancelEventHandler(e: CancelEvent): void {
        e.files = undefined;
        this.fileUploaded.emit(false);
    }

    // noinspection JSUnusedGlobalSymbols
    public successEventHandler(e: SuccessEvent): void {
        if (e.operation === 'upload') {
            //if (this.lastDocumentUid !== undefined) {
            //    this.kendoUploadCmp.removeFilesByUid(this.lastDocumentUid);
            //}

            this.lastDocumentUid = e.files[0].uid;

            this.doc = e.response.body;
            this.doc.title = this.removeFileExtension(e.response.body.fileName);
            this.getDocumentInfo.emit(this.doc as Document);
            this.fileUploaded.emit(true);
            // Timeout is needed because removeFilesByUid Triggers successEventHandler
            // with operation 'remove' qnd change the fileUpload boolean
            setTimeout(() => {
                this.fileUploaded.emit(true);
            }, 1500);

        } else if (e.operation === 'remove') {
            this.fileUploaded.emit(false);
        }
    }
    private removeFileExtension(fileName: string): any {
        // remove file extension
        if (fileName) {
            return fileName.substring(0, fileName.lastIndexOf('.'));
        }
        return fileName;
    }

    // noinspection JSUnusedGlobalSymbols
    public selectHandler(): void {
        this.disabled = true;
    }

    private createRequestOptions(): IHttpClientRequestOptions {
        const req = this.authService.getAuthHeaders();

        req.headers.append('Access-Control-Allow-Origin', '*');

        return req;
    }

    private buildDocCreateRequest(): DocumentCreationRequest {
        const params: DocumentCreationRequest = new DocumentCreationRequest();

        params.documentId = this.documentId;
        params.entityType = this.entityType;
        params.entityId = this.entityId;
        params.title = this.title;
        params.typeItemId = this.item.itemId;

        return params;
    }

    private buildDocRequestWithBaseEntity(): DocumentCreationRequestWithBaseEntity {
        const params: DocumentCreationRequestWithBaseEntity = new DocumentCreationRequestWithBaseEntity();

        params.documentId = this.documentId;
        params.entityType = this.entityType;
        params.entityId = this.entityId;
        params.title = this.title;
        params.typeItemId = this.item.itemId;
        params.entityTypeBase = this.entityTypeBase;
        params.entityIdBase = this.entityIdBase;

        return params;
    }

}
