/**
 * Input status class
 */
export class InputState {
    /**
     * Input editable
     */
    protected _editable = false;
    /**
     * Input visible
     */
    protected _visible = true;
    /**
     * Input required
     */
    protected _required = false;

    protected _errors: any = null;

    constructor(visible: boolean = false, editable: boolean = false, required: boolean = false) {
        this.visible = visible;
        this.editable = editable;
        this.required = required;
    }

    public set editable(value: boolean) {
        this._editable = value;
        if (!this._editable) {
            this._required = false;
        }
    }
    public get editable(): boolean {
        return this._editable;
    }

    public set visible(value: boolean) {
        this._visible = value;
        if (!this._visible) {
            this._editable = false;
            this._required = false;
        }
    }
    public get visible(): boolean {
        return this._visible;
    }

    public set required(value: boolean) {
        this._required = value;
    }
    public get required(): boolean {
        return this._required;
    }

    public set errors(value: any) {
        this._errors = value;
    }
    public get errors(): any {
        return this._errors;
    }
}
