import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FibaSelectBaseComponent } from '@fiba/forms';
import { CompetitionTeamDTO } from '@fiba/models';

@Component({
    selector: 'fibaSelectTeam',
    templateUrl: '../../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectTeamComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectTeamComponent extends FibaSelectBaseComponent<CompetitionTeamDTO> {

    @Input() protected set items(value: CompetitionTeamDTO[]) {
        this._originalItems = value;
    }

    constructor(protected injector: Injector) {
        super(injector);
        this._textField = 'communicationDisplayName';
        this._valueField = 'competitionTeamId';
        this.type = 'input';
    }

    /**
     * Initialise correctly the already selected item.
     */
    public valueChanged(val: CompetitionTeamDTO): void {
        super.valueChanged(val);
        this.handleValue(val);
    }
}
