import {Component, OnInit} from '@angular/core';

import {AuthService, UserDataService} from '@fiba/data-services';
import {PasswordValidationRules} from '@fiba/models';
import {NotificationService, NotificationType} from '@fiba/utils/notification.service';
import {UserPasswordService} from '../../@fiba/user/services/user-password.service';

@Component({
    selector: 'changePassword',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {

    public model: any;
    public isLoading = true;

    passwordValidationRules: PasswordValidationRules = new PasswordValidationRules();
    // protected showPasswordValidation = false;
    // protected selectedPerson: any = undefined;

    constructor(
        protected authService: AuthService,
        protected notificationService: NotificationService,
        protected userPasswordService: UserPasswordService,
        protected dataService: UserDataService,) {
    }

    public ngOnInit(): void {
        this.model = {};
        this.model.password = '';
        this.model.cli_confirmPassword = this.model.password;
        this.passwordValidationRules = this.userPasswordService.PasswordValidationRules;
        this.isLoading = false;
    }

    /**
     * Event triggered on password changed
     *
     * @param newValue
     */
    public onChangePassword(newValue: string): void {
        if (newValue && newValue.length > 0) {
            this.userPasswordService.CheckRules(newValue);
            this.passwordValidationRules = this.userPasswordService.PasswordValidationRules;
            this.model['totalRules'] = this.passwordValidationRules.TotalRules;
            this.model['totalValidRules'] = this.passwordValidationRules.TotalValidRules;
        } else {
            this.userPasswordService.PasswordValidationRules.CleanRuleRules();
            this.model['totalRules'] = undefined;
            this.model['totalValidRules'] = undefined;
        }
    }

    public logout(): void {
        this.authService.logout();
    }

    /**
     * Generate random password
     */
    generatePassword(): void {
        const password = this.userPasswordService.generatePassword();

        if (this.userPasswordService.showGeneratedPasswordPrompt(password)) {
            this.model.password = password;
            this.model.cli_confirmPassword = password;
            this.onChangePassword(password);
        }
    }

    save(): void {
        if (this.model.password && this.passwordValidationRules.TotalMustHaveRules > this.passwordValidationRules.TotalValidMustHaveRules) {
            this.notificationService.emitNotification(NotificationType.Error, 'The minimum criteria for the password strength is not met.');
        } else if (this.model.password !== this.model.cli_confirmPassword) {
            this.notificationService.emitNotification(NotificationType.Error, 'The fields \'password\' and \'confirm password\' must be equal.');
        } else {
            this.dataService.updateCurrentUserPassword(this.model.password).subscribe(
                (response) => {
                    this.model.password = '';
                    this.model.cli_confirmPassword = this.model.password;
                    this.notificationService.emitNotification(NotificationType.Success, 'Updated password successfully. Please logout and login again', 30);
                },
                (error) => {
                    this.notificationService.emitNotification(NotificationType.Error, error);
                },
            );
        }
    }
}
