import { Automapper } from '@fiba/utils/automapper';

export class DocumentCreationRequest {
    public documentId: number = null;
    public entityType: string = null;
    public entityDocumentType: string = null;
    public entityId: number = null;
    public title: string = null;
    public typeItemId: number = null;


    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
        }
    }
}

export class DocumentCreationRequestWithBaseEntity extends DocumentCreationRequest {
    public entityTypeBase: string = null;
    public entityIdBase: number = null;

    constructor(source?: any) {
        super();
        if (source) {
            Automapper.autoMap(this, source);
        }
    }
}

export abstract class DocumentStatus {
    abstract get documentStatusId(): number;
    code: string;
    name: string;
    description: string;
}
