import { CompetitionTeamDTO, PreliminaryRosterConfigurationListItemDTO, FinalRosterConfigurationListItemDTO, RosterConfigurationItemDTO } from '@fiba/models';

import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class CompetitionTeamDelegationFilters extends BaseFilters {
    competitionId: number;
    competitionTeam: CompetitionTeamDTO = null;
    preliminaryRosterConfiguration: PreliminaryRosterConfigurationListItemDTO;
    finalRosterConfiguration: FinalRosterConfigurationListItemDTO;
    showVersions: boolean;

    public constructor(source: CompetitionTeamDelegationFilters = null) {
        super();
        if (source) {
            this.competitionId = source.competitionId;
            if (source.competitionTeam) {
                this.competitionTeam = <CompetitionTeamDTO>JSON.parse(JSON.stringify(source.competitionTeam));
            }
            else {
                this.competitionTeam = null;
            }
            if (source.preliminaryRosterConfiguration) {
                this.preliminaryRosterConfiguration = <PreliminaryRosterConfigurationListItemDTO>JSON.parse(JSON.stringify(source.preliminaryRosterConfiguration));
            } else {
                this.preliminaryRosterConfiguration = null;
            }
            if (source.finalRosterConfiguration) {
                this.finalRosterConfiguration = <FinalRosterConfigurationListItemDTO>JSON.parse(JSON.stringify(source.finalRosterConfiguration));
            } else {
                this.finalRosterConfiguration = null;
            }
            this.showVersions = source.showVersions;
        }
    }

    public isFilterSet(): boolean {
        return !!this.competitionId &&
            !!this.competitionTeam &&
            !!this.competitionTeam.competitionTeamId &&
            !!this.preliminaryRosterConfiguration &&
            !!this.preliminaryRosterConfiguration.preliminaryRosterConfigurationId &&
            !!this.finalRosterConfiguration &&
            !!this.finalRosterConfiguration.finalRosterConfigurationId;
    }
}

export class CompetitionTeamDelegationFiltersV2 extends BaseFilters {
    competitionId: number;
    competitionTeam: CompetitionTeamDTO = null;
    rosterConfiguration: RosterConfigurationItemDTO;
    showVersions: boolean;
    public constructor(source: CompetitionTeamDelegationFiltersV2 = null) {
        super();
        if (source) {
            this.competitionId = source.competitionId;
            if (source.competitionTeam) {
                this.competitionTeam = <CompetitionTeamDTO>JSON.parse(JSON.stringify(source.competitionTeam));
            }
            else {
                this.competitionTeam = null;
            }
            if (source.rosterConfiguration) {
                this.rosterConfiguration = <RosterConfigurationItemDTO>JSON.parse(JSON.stringify(source.rosterConfiguration));
            } else {
                this.rosterConfiguration = null;
            }
            this.showVersions = source.showVersions;
        }
    }

    public isFilterSet(): boolean {
        return !!this.competitionId &&
            !!this.competitionTeam &&
            !!this.competitionTeam.competitionTeamId &&
            !!this.rosterConfiguration;
    }
}

export class CompetitionTeamDelegationViewFilter {
    view: CompetitionTeamDelegationViewFilterModes = CompetitionTeamDelegationViewFilterModes.standardView;
    isHeightCm = true;
}

export const enum CompetitionTeamDelegationViewFilterModes {
    standardView,
    communicationView,
    insuranceView
}

declare module '../dtos/generated/roster-member-dto' {
    export interface RosterMemberDTO {
        cli_guidId: string;
        cli_communicationHeightInch: string;
        cli_visible: boolean;
        cli_alreadyOpened: boolean;
        cli_showPlayerReleaseStatus: boolean;
    }
}

declare module '../dtos/generated/roster-configuration-item-dto' {
    export interface RosterConfigurationItemDTO {
        cli_past?: boolean;
        cli_current: boolean;
    }
}
