<form novalidate autocomplete="off">
    <div class="vertical space10">
        <div class="horizontal">
            <div class="form horizontal labels-left space10">
                <fibaInputText name="name" label="Name" [(ngModel)]="filters.name"></fibaInputText>
                <fibaInputText name="zip" label="ZIP code (addr.)" [(ngModel)]="filters.addressZipCode"></fibaInputText>
                <fibaAutocompleteCity name="city" label="City" [(ngModel)]="selectedCity" (cityChange)="filters.cityName = $event"></fibaAutocompleteCity>
                <fibaSelectCountry name="country" label="Country" [(ngModel)]="filters.country.value"></fibaSelectCountry>
                <fibaMultiSelectZone name="zone" label="Zone" [(ngModel)]="filters.zone.value"></fibaMultiSelectZone>
                <fibaSelectItem label="Gender"
                                [categoryCode]="ItemCategoryCodes_ClubGender"
                                name="clubGenderCode"
                                [disabled]="isGenderCodeReadOnly"
                                [valueField]="'code'"
                                [(ngModel)]="filters.clubGenderCode"
                                [valuePrimitive]="true"></fibaSelectItem>
                <fibaInputTextNumeric name="clubId" label="Club Id" [(ngModel)]="filters.clubId"></fibaInputTextNumeric>
            </div>
        </div>
        <div class="button-bar">
            <button type="button" kendoButton
                    (click)="filter()"
                    [primary]="true">
                <i class="fa fa-lg fa-search"></i>
                Apply filters
            </button>
            <button type="button" kendoButton *ngIf="allowCreateTemporaryClub && initialSearchDone"
                    (click)="createNew()"
                    [primary]="true">
                <i class="fa fa-lg fa-plus-circle" aria-hidden="true"></i>
                Create new
            </button>
        </div>
        <span [hidden]="!isLoading">Loading...</span>
        <kendo-grid [hidden]="isLoading"
                    #grid
                    class="no-scrollbar navigable"
                    [data]="clubs"
                    [pageSize]="filters.take"
                    [skip]="filters.skip"
                    [sortable]="{ mode: 'single' }"
                    [sort]="sort"
                    [pageable]="true"
                    [selectable]="true"
                    (selectionChange)="handleSelChange($event)"
                    (pageChange)="handlePageChange($event)"
                    (sortChange)="handleSortChange($event)">
            <kendo-grid-column *ngIf="mergeMode" title="Display name" field="displayName"></kendo-grid-column>
            <kendo-grid-column *ngIf="mergeMode" title="Code" field="code" width="80"></kendo-grid-column>
            <kendo-grid-column *ngIf="!mergeMode" title="Name" field="name">
                <ng-template kendoGridHeaderTemplate let-club *ngIf="teamDelegationMode">
                    Official name
                </ng-template>
                <ng-template kendoGridHeaderTemplate let-club *ngIf="!teamDelegationMode">
                    Name
                </ng-template>
                <ng-template kendoGridCellTemplate let-club>
                    {{club.name}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="teamDelegationMode" title="Display name" field="displayName"></kendo-grid-column>
            <kendo-grid-column *ngIf="mergeMode" title="Gender" field="genderItemDisplayName"></kendo-grid-column>
            <kendo-grid-column title="City" field="city"></kendo-grid-column>
            <kendo-grid-column title="Country" field="country"></kendo-grid-column>
            <kendo-grid-column title="#" field="organisationId">
                <ng-template kendoGridCellTemplate let-club>
                    {{club.organisationId}} <span *ngIf="club.clubStatus=='Temporary'" style="color: darkorange; font-weight: bold;"> NEW</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="mergeMode" title="Organisation status" field="organisationStatus"></kendo-grid-column>
        </kendo-grid>
    </div>
</form>
<fibaPopupClubCreator [organisation]="this.filters.parentOrganisation.value"
                      [genderCode]="genderCode"
                      [isGenderCodeReadOnly]="isGenderCodeReadOnly"
                      [model]="clubCreated"
                      (save)="saveHandler($event)"></fibaPopupClubCreator>