import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CompetitionDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { Logger } from '@fiba/utils/logger';

/**
 * Component fibaMultiSelectCompetitionStatus
 */
@Component({
    selector: 'fibaMultiSelectCompetitionStatus',
    templateUrl: '../../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectCompetitionStatusComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})

/**
 * Class FibaMultiSelectCompetitionStatusComponent
 *
 * @param {CompetitionDataService} protected dataService
 * @returns
 */
export class FibaMultiSelectCompetitionStatusComponent extends FibaMultiSelectBaseComponent {
    constructor(protected dataService: CompetitionDataService) {
        super();
        this._valueField = 'competitionStatusId';
        this._textField = 'name';
        this.value = [];
    }

    /**
     * Gets the person status from the service
     *
     * @returns A subscription
     */
    public getSubscription(): Subscription {
        return this.dataService.fetchStatuses().subscribe(
            (data) => {
                this.items = data;
                this._data = data.slice(0);
            },
            (err) => {
                Logger.error(err);
            },
            () => { },
        );
    }
}
