import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[fibaEmailValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaEmailValidator), multi: true }
    ]
})
export class FibaEmailValidator implements Validator {

    protected pattern = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        const v = c.value;

        if (!this.pattern.test(v) && !!v && v !== '') {
            c.setErrors({ fibaEmailValidator: true });
            return {
                fibaEmailFibaValidator: true
            };
        }

        else {
            if (c.errors) {
                delete c.errors['fibaEmailValidator'];
                if (!Object.keys(c.errors).length) {
                    c.setErrors(null);
                }
            }
        }

        return null;
    }
}
