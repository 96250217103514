import { NgModule } from '@angular/core';

import { SharedModule } from '../../app/shared.module';

// Inputs
import { FibaCarouselComponent } from './components/fiba-carousel.component';
import { FibaCheckboxThreeStateComponent } from './components/fiba-checkbox-three-state.component';
import { FibaCheckboxComponent } from './components/fiba-checkbox.component';
import { FibaInputDateMonthComponent } from './components/fiba-input-date-month.component';
import { FibaInputDateTimeComponent } from './components/fiba-input-date-time.component';
import { FibaInputDateComponent } from './components/fiba-input-date.component';
import { FibaInputEmailComponent } from './components/fiba-input-email.component';
import { FibaInputPasswordComponent } from './components/fiba-input-password.component';
import { FibaInputTextNumericComponent } from './components/fiba-input-text-numeric.component';
import { FibaInputTextComponent } from './components/fiba-input-text.component';
import { FibaInputTextareaComponent } from './components/fiba-input-textarea.component';
import { FibaInputTimeComponent } from './components/fiba-input-time.component';
import { FibaInputUrlComponent } from './components/fiba-input-url.component';
import { FibaRadiobuttonComponent } from './components/fiba-radiobutton.component';
import { FibaSwitchComponent } from './components/fiba-switch.component';
import { FibaTextNumberToTimeComponent } from './components/fiba-text-number-to-time.component';
import { FibaTreelistComponent } from './components/fiba-treelist.component';
import { FibaMergeContainerComponent } from './components/fiba-merge-container.component';
// Directives
import { FibaEmailValidator } from './directives/fiba-validate-email.directive';
import { FibaEqualValidator } from './directives/fiba-validate-equal.directive';
import { FibaGreaterValidator } from './directives/fiba-validate-greater.directive';
import { FibaUrlValidator } from './directives/fiba-validate-url.directive';
import { FibaNotBlankValidator } from './directives/fiba-validate-not-blank.directive';
import { FibaAlphaNumericValidator } from './directives/fiba-validate-alphanumeric.directive';
// Pipes
import { NullReplacePipe } from '@fiba/common/pipes/nullReplace.pipe';
import { FibaInputDateTimeTmConfigurationComponent } from './components/fiba-input-date-time-tm-configuration.component';
import { FibaWysiwygComponent } from './components/fiba-wysiwyg.component';
import { FibaInputTextDoubleComponent } from './components/fiba-input-text-double.component';
import { FibaCardSliderComponent } from './components/fiba-card-slider/fiba-card-slider.component';

const FORMS_COMPONENTS: any[] = [
    // Usable game-information
    FibaInputTextComponent,
    FibaInputEmailComponent,
    FibaInputUrlComponent,
    FibaInputPasswordComponent,
    FibaInputTextareaComponent,
    FibaInputDateComponent,
    FibaCheckboxComponent,
    FibaRadiobuttonComponent,
    FibaInputTextNumericComponent,
    FibaInputTextDoubleComponent,
    FibaInputDateMonthComponent,
    FibaInputDateTimeComponent,
    FibaInputDateTimeTmConfigurationComponent,
    FibaInputTimeComponent,
    FibaTreelistComponent,
    FibaCarouselComponent,
    FibaCardSliderComponent,
    FibaTextNumberToTimeComponent,
    FibaCheckboxThreeStateComponent,
    FibaSwitchComponent,
    FibaMergeContainerComponent,
    FibaWysiwygComponent,
    // Directives
    FibaEqualValidator,
    FibaEmailValidator,
    FibaGreaterValidator,
    FibaUrlValidator,
    FibaNotBlankValidator,
    FibaAlphaNumericValidator,
    // Pipes
    NullReplacePipe,
];

// Base game-information
export { FibaInputBase } from './base/fiba-input-base';
export { FibaFormBase } from './base/fiba-form-base';
export { FibaMergeFormBase } from './base/fiba-merge-form-base';
export { FibaAutocompleteBaseComponent } from './base/fiba-autocomplete-base.component';
export { FibaEFGridBaseDirective } from './base/fiba-ef-grid-base';
export { FibaGridBase } from './base/fiba-grid-base';
export { FibaMultiInputBaseComponent } from './base/fiba-multi-input-base.component';
export { FibaMultiSelectBaseComponent } from './base/fiba-multiselect-base.component';
// export { FibaPickerBase } from './base/fiba-picker-base';
export { FibaPopupBase } from './base/fiba-popup-base';
export { FibaSelectBaseComponent, FibaAjaxSelectBaseComponent } from './base/fiba-select-base.component';
export { FibaSelectTreeViewBaseComponent, FibaAjaxSelectTreeViewBaseComponent } from './base/fiba-select-treeview-base.component';
export { FibaTooltipBase } from './base/fiba-tooltip-base';
export { FibaGSGridBase } from './base/fiba-gs-grid-base';
export { FibaTreelistComponent } from './components/fiba-treelist.component';
export { FibaCarouselComponent } from './components/fiba-carousel.component';
export { FibaCardSliderComponent } from './components/fiba-card-slider/fiba-card-slider.component';
export { FibaTextNumberToTimeComponent } from './components/fiba-text-number-to-time.component';
export { FibaCheckboxThreeStateComponent } from './components/fiba-checkbox-three-state.component';
export { FibaSwitchComponent } from './components/fiba-switch.component';
export { FibaMergeContainerComponent } from './components/fiba-merge-container.component';
export { FibaWysiwygComponent } from './components/fiba-wysiwyg.component';

// Others
export { ITreeNodeData } from './components/fiba-treelist.component';
export { getGridSortFn } from './utils/grid-sorting';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        FORMS_COMPONENTS,
    ],
    exports: [
        FORMS_COMPONENTS,
    ],
})

export class FibaFormsModule {
}
