import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ApiListResponse, PersonListItemDTO, PersonFilters, PersonDTO, PersonInformationDTO, PersonDetailsDTO, PersonContactDetailsDTO, PersonRolesDTO, PersonDocumentsAndNotesDTO, PersonStatusDTO, PlayerLicenseDTO, ThingDTO, RoleFamilies, BasketballLicenseViewDTO, PersonBasketballRestrictionsDTO, BasketballNominationsDTO, UnavailabilityDTO, PlayerCompetitionDTO, PersonBasketballTestsDTO, PersonCheckDuplicateListItemDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { PersonSanctionsDTO } from '@fiba/models/dtos/generated/person-sanctions-dto';
import { PersonFinancialDataDTO } from '@fiba/models/dtos/generated/person-financial-data-dto';
import { PlayerWithDopingControlsDTO } from '@fiba/models/dtos/generated/player-with-doping-controls-dto';
import { DopingControlDTO } from '@fiba/models/dtos/generated/doping-control-dto';
import { PersonDataService } from '@fiba/data-services/person/person-data.service';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PersonCheckDuplicateDataService extends PersonDataService {
    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataPersonStatusService: DataCachedService<PersonStatusDTO>) {
        super(http, config, dataPersonStatusService);
    }

    fetchList(filters: PersonFilters, context: string = null): Observable<ApiListResponse<PersonListItemDTO>> {
        return this.http.get(`${this.personApiEndpoint}/check-duplicate`, filters.getSearchParams());
    }

    countDuplicates(filters: PersonFilters, context: string = null): Observable<number> {
        return this.http.get(`${this.personApiEndpoint}/check-duplicate/count`, filters.getSearchParams());
    }

    fetchListPotentialCheckDuplicates(personId: number, context: string = null): Observable<ApiListResponse<PersonCheckDuplicateListItemDTO>> {
        return this.http.get(`${this.personApiEndpoint}/check-duplicate/${personId}`);
    }

    countPotentialCheckDuplicates(personId: number, context: string = null): Observable<number> {
        return this.http.get(`${this.personApiEndpoint}/check-duplicate/${personId}/count`);
    }
}
