import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FibaPopupBase } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import {AppConfig} from '@app/app.config';

@Component({
    selector: 'fibaPopupItem',
    templateUrl: './fiba-popup-item.component.html',
})
export class FibaPopupItemComponent extends FibaPopupBase<ItemDTO> {

    @Input() public categoryCode: string;

    constructor(protected injector: Injector, protected config: AppConfig) {
        super(injector);
    }
}
