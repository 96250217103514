import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment-timezone';

@Pipe({ name: 'fibaUtcOffsetOnly' })
export class FibaUtcOffsetOnlyPipe implements PipeTransform {
    transform(value): string {

        const abs = Math.abs(value);
        const hours = Math.floor(abs);
        const mins = Math.floor((abs - hours) * 60)

        return 'UTC' + (value >= 0 ? '+' : '-') + hours.toString().padStart(2, '0') + ':' + mins.toString().padStart(2, '0');


    }
}
