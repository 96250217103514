<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-numerictextbox [(ngModel)]="value"
                          id="{{name}}"
                          name="{{name}}"
                          [required]="required"
                          [disabled]="disabled"
                          placeholder="{{placeholder}}"
                          (valueChange)="handleValue($event)"
                          (blur)="onTouched($event)"
                          [autoCorrect]="autoCorrect"
                          [min]="min"
                          [max]="max"
                          [step]="step"
                          [format]="format"
                          [spinners]="showButtons"
                          (keypress)="handleKeypress($event)"></kendo-numerictextbox>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.repeatedUniformNumber">
                {{value}} is already selected.
            </li>
        </ul>
    </div>
</div>
