import { InputState } from '@fiba/models';
import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

declare module '../dtos/generated/competition-time-management-list-item-dto' {

    export interface CompetitionTimeManagementListItemDTO {

        isApplicablePreliminaryRosterInputState: InputState;

        deadlineDateTimePreliminaryRosterInputState: InputState;

        isApplicableFinalRosterInputState: InputState;

        deadlineDateTimeFinalRosterInputState: InputState;

        gameDateTimeInputState: InputState;

        isExpanded: boolean;
    }
}

export class CompetitionTimeManagementFilter extends BaseFilters {
    public competitionTeamA: Filterable = new Filterable('competitionTeamId');
    public competitionTeamB: Filterable = new Filterable('competitionTeamId');
    public dateFrom: Date;
    public dateTo: Date;
    public venue: Filterable = new Filterable('venueId');
    public window: Filterable = new Filterable('windowId');
    public roundGroupPairing: Filterable = new Filterable('id');
    public reset(): void {
        this.competitionTeamA.reset();
        this.competitionTeamB.reset();
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.venue.reset();
        this.window.reset();
        this.roundGroupPairing.reset();
    }
}
