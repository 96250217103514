<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div class="full-size">
    <textarea #input="ngModel"
              id="{{name}}"
              name="{{name}}"
              placeholder="{{placeholder}}"
              [disabled]="disabled"
              [required]="required"
              [(ngModel)]="value"
              (blur)="onTouched($event)"
              (change)="manualChange()"
              [maxlength]="maxLength"></textarea>
    <div *ngIf="ngControl?.control?.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.control.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
