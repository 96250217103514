import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EquipmentDashboardCategoriesDTO, EquipmentDashboardPartnerByCountryDTO, EquipmentDashboardPartnerByRegionDTO } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class EquipmentDashboardDataService {
    protected equipmentDashboardApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentDashboardApiEndpoint = config.equipmentDashboardApiEndpoint;
    }

    public fetchFibaPartnersByZone(): Observable<EquipmentDashboardPartnerByRegionDTO[]> {
        return this.http.get(`${this.equipmentDashboardApiEndpoint}/fiba-partner-by-region`);
    }

    public fetchFibaPartnersByCountry(): Observable<EquipmentDashboardPartnerByCountryDTO[]> {
        return this.http.get(`${this.equipmentDashboardApiEndpoint}/fiba-partner-by-country`);
    }

    public fetchFibaPartnersGroupedByCategoy(): Observable<EquipmentDashboardCategoriesDTO[]> {
        return this.http.get(`${this.equipmentDashboardApiEndpoint}/fiba-partner-grouped-by-category`);
    }
}
