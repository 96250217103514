import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ZoneDTO, ZoneMainZoneAssociationDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { ZonesAndZoneRelationsDTO } from '../../models/dtos/generated/zones-and-zone-relations-dto';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ZoneDataService {
    protected apiEndpoint: string;

    constructor(
        protected zoneDataService: DataCachedService<ZoneDTO>,
        protected zonesAndZoneRelationDataService: DataCachedService<ZonesAndZoneRelationsDTO>,
        protected zoneAssociationService: DataCachedService<ZoneMainZoneAssociationDTO>,
        config: AppConfig) {
        this.apiEndpoint = config.zoneApiEndpoint;
    }

    public fetchZonesAndZonesRelations(context?: string, topLevel?: boolean, hasCommittee?: boolean): Observable<ZonesAndZoneRelationsDTO[]> {
        const params = {};

        if (context) {
            params['context'] = context;
        }
        if (topLevel) {
            params['topLevel'] = topLevel;
        }
        if (hasCommittee !== undefined) {
            params['hasCommittee'] = hasCommittee;
        }

        return this.zonesAndZoneRelationDataService.fetchEntities(`${this.apiEndpoint}/treeview`, 'treeview', params);
    }

    public fetchZones(firstLevel = false): Observable<ZoneDTO[]> {
        return this.zoneDataService.fetchEntities(this.apiEndpoint, 'zones', { firstLevel: firstLevel.toString() });
    }

    public fetchBinnedZones(): Observable<ZoneMainZoneAssociationDTO[]> {
        return this.zoneAssociationService.fetchEntities(`${this.apiEndpoint}/groupped-ids`, 'groupped-ids');
    }

    public fetchRegionalOfficeZones(): Observable<ZoneDTO[]> {
        return this.zoneDataService.fetchEntities(`${this.apiEndpoint}/regional-offices`);
    }
}
