import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EventFilters, ApiListResponse, EventStatusDTO, EventListItemDTO, EventCompetitionInformationDTO, EventDTO, ConfigurationRoleTestResultDetailDTO, EventWithParticipantsDTO, ConfigurationRoleTestTypeDTO, ThingDTO, OfficialLicensePeriodSelectorListItemDTO, RoleDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { IFileData } from '@fiba/data-services';


import { DataCachedService } from '../utils/data-cached.service';
import { EventTechnicalDelegateParticipantDTO } from '@fiba/models/dtos/generated/event-technical-delegate-participant-dto';
import { EventLicensableParticipantDTO } from '../../models/dtos/generated/event-licensable-participant-dto';
import {map, timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class EventDataService {

    protected eventApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig, protected dataEventStatusService: DataCachedService<EventStatusDTO>,) {
        this.eventApiEndpoint = config.eventApiEndpoint;
    }

    fetchList(filters: EventFilters): Observable<ApiListResponse<EventListItemDTO>> {
        return this.http.get(this.eventApiEndpoint, filters.getSearchParams()).pipe(map(
            response => response as ApiListResponse<EventListItemDTO>));
    }

    fetchStatuses(): Observable<EventStatusDTO[]> {
        return this.dataEventStatusService.fetchEntities(`${this.eventApiEndpoint}/statuses`);
    }

    fetchOfficialLicensePeriods(testEventTypeItemCode: string): Observable<OfficialLicensePeriodSelectorListItemDTO[]> {
        return this.http.get(`${this.eventApiEndpoint}/official-license-periods/${testEventTypeItemCode}`);
    }

    downloadExport(filters: EventFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.eventApiEndpoint}/export-csv`, filters.getSearchParams())
            .pipe(timeout(300000)); // TODO: use constant or conf value
    }

    fetchEventCompetitionInformation(eventCompetitionId: number): Observable<EventCompetitionInformationDTO> {
        return this.http.get(`${this.eventApiEndpoint}/${eventCompetitionId}/competition-information`);
    }

    fetchEventInformation(eventId: number): Observable<EventDTO> {
        return this.http.get(`${this.eventApiEndpoint}/${eventId}/information`);
    }

    updateEventInformation(model: EventDTO): Observable<any> {
        return this.http.put(`${this.eventApiEndpoint}/${model.eventId}/information`, model);
    }

    createEventInformation(model: EventDTO): Observable<EventDTO> {
        return this.http.post(`${this.eventApiEndpoint}/information`, model);
    }

    deleteEvent(eventId: number): Observable<any> {
        return this.http.delete(`${this.eventApiEndpoint}/${eventId}/information`);
    }

    fetchEventParticipants(eventId: number, sortBy: string, sortOrder: string): Observable<EventDTO> {
        return this.http.get(`${this.eventApiEndpoint}/${eventId}/event-participants?sortBy=${sortBy}&sortOrder=${sortOrder}`);
    }

    updateEventParticipants(model: EventWithParticipantsDTO): Observable<EventWithParticipantsDTO> {
        return this.http.put(`${this.eventApiEndpoint}/${model.eventId}/event-participants`, model);
    }
    createEventParticipantsLicenses(model: EventWithParticipantsDTO): Observable<EventWithParticipantsDTO> {
        return this.http.put(`${this.eventApiEndpoint}/${model.eventId}/event-participants-licenses`, model);
    }

    downloadExportParticipants(eventId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.eventApiEndpoint}/${eventId}/participants-export-csv`)
            .pipe(timeout(300000)); // TODO: use constant or conf value // Edit: Indeed 06/02/2018
    }

    fetchEventConfigurationRole(eventId: number, typeItemCode: string): Observable<ConfigurationRoleTestTypeDTO[]> {
        return this.http.get(`${this.eventApiEndpoint}/${eventId}/event-configuration-role`, { eventTypeItemCode: typeItemCode });
    }

    fetchConfigurationRoleTestResultDetail(eventTypeItemCode: string): Observable<ConfigurationRoleTestResultDetailDTO[]> {
        return this.http.get(`${this.eventApiEndpoint}/event-configuration-role-test-result-detail`, { eventTypeItemCode });
    }

    fetchEventsForSession(eventTypeCode: string): Observable<EventListItemDTO[]> {
        return this.http.get(`${this.eventApiEndpoint}/sessions`, { eventTypeCode });
    }

    fetchAutoCompleteList(name: string = '', eventCategories: Array<string>): Observable<ThingDTO[]> {
        const params = { name };
        return this.http.get(this.eventApiEndpoint, params);
    }

    sendNotifications(model: EventDTO, agentPreRegistrationIds: number[]): Observable<EventDTO> {
        const listAgentPreRegistrationIds = agentPreRegistrationIds.join(' ,');
        return this.http.post(`${this.eventApiEndpoint}/send-notifications?agentPreRegistrationIds=${listAgentPreRegistrationIds}`, model);
    }

    fetchEventTechnicalDelegateParticipants(eventId: number, sortBy: string, sortOrder: string): Observable<EventDTO> {
        return this.http.get(`${this.eventApiEndpoint}/${eventId}/technical-delegate-participants?sortBy=${sortBy}&sortOrder=${sortOrder}`);
    }

    fetchEventLicensableParticipants(eventId: number, sortBy: string, sortOrder: string): Observable<EventDTO> {
        return this.http.get(`${this.eventApiEndpoint}/${eventId}/licensable-participants?sortBy=${sortBy}&sortOrder=${sortOrder}`);
    }
    createEventTechnicalDelegateParticipant(model: EventTechnicalDelegateParticipantDTO): Observable<EventTechnicalDelegateParticipantDTO> {
        return this.http.post(`${this.eventApiEndpoint}/technical-delegate-participant`, model);
    }
    deleteEventPerson(eventId: number, eventPersonId: number): Observable<any> {
        return this.http.delete(`${this.eventApiEndpoint}/${eventId}/technical-delegate-participant/${eventPersonId}`);
    }
    createParticipantTestResult(model: EventTechnicalDelegateParticipantDTO): Observable<EventTechnicalDelegateParticipantDTO> {
        return this.http.post(`${this.eventApiEndpoint}/${model.eventId}/technical-delegate-participant/${model.eventPersonId}/test-result`, model);
    }
    updateParticipantTestResult(model: EventTechnicalDelegateParticipantDTO): Observable<EventTechnicalDelegateParticipantDTO> {
        return this.http.put(`${this.eventApiEndpoint}/${model.eventId}/technical-delegate-participant/${model.eventPersonId}/test-result/${model.personTestResultId}`, model.personTestResult);
    }
    fetchLicensableRoleForTestType(testEventTypeItemCode: string): Observable<RoleDTO> {
        return this.http.get(`${this.eventApiEndpoint}/role-for-test-type/${testEventTypeItemCode}`);
    }


    createLicensableEventParticipant(model: EventTechnicalDelegateParticipantDTO): Observable<EventTechnicalDelegateParticipantDTO> {
        return this.http.post(`${this.eventApiEndpoint}/licensable-participant`, model);
    }
    deleteLicensableEventPerson(eventId: number, eventPersonId: number): Observable<any> {
        return this.http.delete(`${this.eventApiEndpoint}/${eventId}/licensable-participant/${eventPersonId}`);
    }
    createLicensableParticipantTestResult(model: EventLicensableParticipantDTO): Observable<EventLicensableParticipantDTO> {
        return this.http.post(`${this.eventApiEndpoint}/${model.eventId}/licensable-participant/${model.eventPersonId}/test-result`, model);
    }
    updateLicensableParticipantTestResult(model: EventLicensableParticipantDTO): Observable<EventLicensableParticipantDTO> {
        return this.http.put(`${this.eventApiEndpoint}/${model.eventId}/licensable-participant/${model.eventPersonId}/test-result/${model.personTestResultId}`, model.personTestResult);
    }
    generateAllLicenses(eventId: number): Observable<EventTechnicalDelegateParticipantDTO> {
        return this.http.put(`${this.eventApiEndpoint}/${eventId}/generate-all-licenses`);
    }

    exportXls(eventId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.eventApiEndpoint}/${eventId}/participants-export-excel`)
            .pipe(timeout(300000));
    }
}
