import { NgModule } from '@angular/core';

import { FibaPickerEventRoutingModule } from './fiba-picker-event-routing.module';

import { SharedModule } from '../../../app/shared.module';
import { FibaCommonModule } from '@fiba/common';
import { FibaFormsModule } from '@fiba/forms';
import { FibaCommonSelectModule } from '../../common/fiba-common-select.module';

import { FibaPopupEventComponent } from '../fiba-popup-event/fiba-popup-event.component';
import { FibaPickerEventComponent } from './fiba-picker-event.component';
import { EventSearchModule } from '@fiba/event/event-search/event-search.module';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPickerEventComponent,
];

const INTERNAL_COMPONENTS = [
    FibaPopupEventComponent, // FibaPickerEventComponent

];

@NgModule({
    imports: [
        FibaPickerEventRoutingModule,

        SharedModule,
        FibaFormsModule,
        FibaCommonModule,
        FibaCommonSelectModule,
        EventSearchModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPickerEventModule {

}
