import { Automapper } from '@fiba/utils/automapper';

export class AzureFileCreationRequest {

    public fileType: number;
    public entityReference: string = null;
    public customFileName: string;
    public lastAzureFile: string;

    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
        }
    }
}
