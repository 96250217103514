import {
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { DocumentDTO, MapPolicy } from '@fiba/models';
import { FibaInputBase } from '@fiba/forms';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import {AppConfig} from '@app/app.config';
import { FileType } from '@fiba/common/enums/fileType';

@Component({
    selector: 'fibaCompetitionSponsorStrip',
    templateUrl: './fiba-competition-sponsorstrip.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaCompetitionSponsorStripComponent),
            multi: true,
        },
    ],
})
export class FibaCompetitionSponsorStripComponent implements OnChanges {
    @Input() public competitionSponsorStripUrl: string;
    @Input() public competitionId: number = null;
    @Input() public competitionCode: string = null;
    @Input() public isEditable = false;

    @Output() public sponsorStripUrlChange: EventEmitter<string> = new EventEmitter();

    @ViewChild(TooltipDirective)
    public tooltipDir: TooltipDirective;

    public edit = false;
    public pictureUrl: string = null;

    public editCompetitionSponsorStrip: string = null;
    public isNew = true;
    public recommendedSize = '718 pixels x 65 pixels';
    public entityReference: string = null;
    public fileType: number = null;

    public readonly MapPolicy_AzureFilesEdit = MapPolicy.AzureFilesEdit;

    constructor(protected config: AppConfig) {
        this.fileType = FileType.sponsorstrips;
    }

    protected ngAfterViewChecked(): void {
        if (this.competitionId) {
            this.entityReference = this.competitionId.toString();
        }
    }

    public ngOnChanges(changes): void {
        this.getPicture();
    }

    protected getPicture() {
        if (!!this.competitionSponsorStripUrl) {
            this.pictureUrl = this.competitionSponsorStripUrl;
        } else {
            this.pictureUrl = this.getDefaultPicture();
        }
    }

    public editClick() {
        this.edit = !this.edit;
        this.editCompetitionSponsorStrip = this.pictureUrl ? this.pictureUrl : '';
        if (this.pictureUrl) {
            this.isNew = false;
        }
    }

    public saveCompetitionSponsorStripHandler(value: string) {
        this.sponsorStripUrlChange.emit(value);
    }

    public cancelCompetitionSponsorStripHandler() {
        this.edit = false;
        this.editCompetitionSponsorStrip = null;
    }

    protected getDefaultPicture(): string {
        return null;
    }

    copySponsorStripToClipboard(eventTarget: any) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.pictureUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.tooltipDir.toggle(eventTarget);
        setTimeout(() => {
            this.tooltipDir.hide();
        }, 800);
    }

    deletePicture() {
        this.editCompetitionSponsorStrip = undefined;
        this.sponsorStripUrlChange.emit(undefined);
    }
}
