import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'fibaNumericToTime' })
export class FibaNumericToTimePipe implements PipeTransform {
    transform(number: any): string {
        if (!number) {
            return ''
        }
        // Check sign of given number
        let sign = (number >= 0) ? 1 : -1;
        // Get absolute value
        number = number * sign;
        // Get hour as integer
        var hour = Math.floor(number);
        var decimalPart = number - hour;
        var min = 1 / 60;
        // Round to nearest minute
        decimalPart = min * Math.round(decimalPart / min);
        var minute = Math.floor(decimalPart * 60) + '';
        // Add padding if need
        if (minute.length < 2) {
            minute = '0' + minute;
        }
        // Add Sign in final result
        let s_sign = sign == 1 ? '+' : '-';
        let prefix = hour < 10 ? '0' : '';
        // Concate hours and minutes
        let time = s_sign + prefix + hour + ':' + minute;

        return time;
    }
}
