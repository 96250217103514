import {Component, OnInit} from '@angular/core';

import {AuthService} from '@fiba/data-services';

@Component({
    selector: 'authHandlerSilent',
    template: '',
})
export class AuthHandlerSilentComponent implements OnInit {
    constructor(protected authService: AuthService) {
    }

    public ngOnInit(): void {
        this.authService.endSilentSignin();
    }
}
