import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiListResponse, OfficialLicenseRegistrationZoneListItemDTO, OfficialLicensePeriodDTO, OfficialLicensePeriodSpotCandidatesDTO, OfficialLicensePeriodFilters, OfficialLicensePeriodListItemDTO, OfficialLicensePeriodStatusDTO, OfficialLicensePeriodWithSpotsGridDTO, OfficialLicenseRegistrationByNFListItemDTO, PersonInformationDTO, PersonDTO, PersonContactDetailsDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import { OfficialLicenseRegistrationByNFFilters } from '@fiba/models/official-license/official-license-period-registration';
import { AppConfig } from '@app/app.config';
import { OfficialLicenseGameOfficialFilters } from '../../models/official-license/official-license-game-official-filters';
import { OfficialLicensePeriodGameOfficialCandidateDTO } from '../../models/dtos/generated/official-license-period-game-official-candidate-dto';
import { OfficialGameOfficialCandidatesDTO } from '../../models/dtos/generated/official-game-official-candidates-dto';
import { PersonGameOfficialDetailsDTO } from '../../models/dtos/generated/person-game-official-details-dto';

@Injectable()
export class OfficialLicenseDataService {

    protected officialLicenseApiEndpoint: string;
    protected officialLicensePeriodApiEndpoint: string;
    protected officialLicenseRegistrationApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataOfficialLicensePeriodStatusService: DataCachedService<OfficialLicensePeriodStatusDTO>) {
        this.officialLicenseApiEndpoint = config.officialLicenseApiEndpoint;
        this.officialLicensePeriodApiEndpoint = config.officialLicensePeriodApiEndpoint;
        this.officialLicenseRegistrationApiEndpoint = config.officialLicenseRegistrationApiEndpoint;

    }

    public fetchOfficialLicensePeriodList(filters: OfficialLicensePeriodFilters): Observable<ApiListResponse<OfficialLicensePeriodListItemDTO>> {
        return this.http.get(this.officialLicensePeriodApiEndpoint, filters.getSearchParams());
    }

    public fetchOfficialLicensePeriod(officialLicensePeriodId: number): Observable<OfficialLicensePeriodDTO> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}`);
    }

    public fetchOfficialLicensePeriodSpots(officialLicensePeriodId: number): Observable<OfficialLicensePeriodDTO> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/spots`);
    }

    public fetchOfficialLicenseRegistrationByNFListItem(officialLicensePeriodId: number, filters: OfficialLicenseRegistrationByNFFilters): Observable<ApiListResponse<OfficialLicenseRegistrationZoneListItemDTO>> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/registrations`, filters.getSearchParams());
    }

    public fetchOfficialLicenseRegistrationForNFListItem(officialLicensePeriodId: number, nfId: number): Observable<OfficialLicenseRegistrationByNFListItemDTO[]> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/registrations-for-nf/${nfId}`);
    }

    public fetchPreviousOfficialLicensePeriods(): Observable<OfficialLicensePeriodDTO[]> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/previous-periods`);
    }

    public createOfficialLicensePeriod(model: OfficialLicensePeriodDTO, officialLicensePeriodIdToCopy?: number): Observable<OfficialLicensePeriodDTO> {
        let params = {};
        if (officialLicensePeriodIdToCopy) {
            params = { officialLicensePeriodIdToCopy };
        }
        return this.http.post(`${this.officialLicensePeriodApiEndpoint}`, model, params);
    }

    public updateOfficialLicensePeriod(officialLicensePeriodId: number, model: OfficialLicensePeriodDTO): Observable<any> {
        return this.http.put(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}`, model);
    }

    public updateOfficialLicensePeriodSpots(officialLicensePeriodId: number, model: OfficialLicensePeriodWithSpotsGridDTO): Observable<any> {
        return this.http.put(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/spots`, model);
    }

    public fetchOfficialLicensePeriodStatuses(): Observable<OfficialLicensePeriodStatusDTO[]> {
        return this.dataOfficialLicensePeriodStatusService.fetchEntities(`${this.officialLicensePeriodApiEndpoint}/statuses`);
    }

    public deleteOfficialLicensePeriod(officialLicensePeriodId: number): Observable<any> {
        return this.http.delete(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}`);
    }

    public fetchOfficialLicenseRegistrationCandidatesListItem(officialLicensePeriodId: number, nationalFederationOrganisationId: number): Observable<OfficialLicensePeriodSpotCandidatesDTO> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/registrations/${nationalFederationOrganisationId}/candidates`);
    }

    public updateOfficialLicenseRegistrationCandidates(statusCode: string, model: OfficialLicensePeriodSpotCandidatesDTO, candidateEventSelection: number[]): Observable<OfficialLicensePeriodSpotCandidatesDTO[]> {
        return this.http.put(`${this.officialLicenseRegistrationApiEndpoint}/bulk-registrations/candidates/${statusCode}?candidateSelection=${candidateEventSelection}`, model);
    }

    public updateOfficialLicenseRegistrationCandidatesListItem(officialLicensePeriodId: number, nationalFederationOrganisationId: number, model: OfficialLicensePeriodSpotCandidatesDTO): Observable<OfficialLicensePeriodSpotCandidatesDTO> {
        return this.http.put(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/registrations/${nationalFederationOrganisationId}/candidates`, model);
    }

    public updateOfficialLicensePeriodNationalFederationsSpots(officialLicensePeriodId: number, nfIds: number[]): Observable<number> {

        var params = { nationalFederationIds: nfIds.map(id => id.toString()) };

        return this.http.put(`${this.officialLicensePeriodApiEndpoint}/${officialLicensePeriodId}/national-federations`, null, params);
    }

    public fetchOfficialLicenseRegistrationGameOfficialCandidatesListItem(filters: OfficialLicenseGameOfficialFilters): Observable<ApiListResponse<OfficialLicensePeriodGameOfficialCandidateDTO>> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/game-official-candidates`, filters.getSearchParams());
    }
    
    public sendGameOfficialCandidatesListItem(model: OfficialGameOfficialCandidatesDTO): Observable<boolean> {
        return this.http.post(`${this.officialLicensePeriodApiEndpoint}/game-official-candidates-notifications`, model);
    }
    
    public fetchGameOfficialInformation(): Observable<PersonGameOfficialDetailsDTO> {
        return this.http.get(`${this.officialLicensePeriodApiEndpoint}/game-official-informations`);
    }

}
