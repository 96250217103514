import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FibaCommonModule } from '@fiba/common';
import { FibaFormsModule } from '@fiba/forms';
import { SharedModule } from '../../../app/shared.module';
import { FibaCommonSelectModule } from '../../common/fiba-common-select.module';

import { CompetitionSearchMiniComponent } from './components/competition-search-mini.component';
import { FibaPickerCompetitionComponent } from '@fiba/competition/common/forms/fiba-picker-competition.component';
import { FibaPopupCompetitionComponent } from '@fiba/competition/common/forms/fiba-popup-competition.component';
import { FibaCompetitionSponsorStripComponent } from './forms/fiba-competition-sponsorstrip.component';
import { FibaPopupCompetitionPictureComponent } from './forms/fiba-popup-competition-picture/fiba-popup-competition-picture.component';
import { FibaCompetitionLogoComponent } from './forms/fiba-competition-logo.component';

const COMPONENTS: any[] = [
    FibaPickerCompetitionComponent,
    FibaPopupCompetitionComponent,
    CompetitionSearchMiniComponent,
    FibaCompetitionSponsorStripComponent,
    FibaPopupCompetitionPictureComponent,
    FibaCompetitionLogoComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FibaFormsModule,
        FibaCommonModule,
        FibaCommonSelectModule,
    ],
    declarations: [
        COMPONENTS,
    ],
    exports: [
        COMPONENTS,
    ],
})
export class FibaCompetitionCommonSharedModule {
}
