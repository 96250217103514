import { Component, forwardRef, Injector, Input, OnInit  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ItemDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';

@Component({
    selector: 'fibaMultiSelectItem',
    templateUrl: '../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectItemComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaMultiSelectItemComponent extends FibaMultiSelectBaseComponent implements OnInit{

    private _displayParent = false;

    @Input() protected categoryCode;
    @Input() protected parentCode;
    @Input() protected includeActiveInactive;
    @Input() protected set displayParent(value: boolean) {
        this._displayParent = value;
        this._textField = this._displayParent ? 'cli_displayNameWithParent' : 'name';
    }

    @Input() protected showOnlyRoots = false;
    @Input() protected showOnlyChildren = false;

    constructor(protected dataService: ItemDataService) {
        super();
        this._valueField = 'itemId';
        this._textField = this._displayParent ? 'cli_displayNameWithParent' : 'name';
        this.value = [];
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public getBindData(): (data: ItemDTO[]) => void {
        return (data: ItemDTO[]) => {
            if (this.parentCode) {
                const parentId = data.find((i) => i.code === this.parentCode).itemId;
                data = data.filter((i) => i.parentItemId === parentId);
            }
            if (this.showOnlyRoots) {
                data = data.filter((i) => !i.parentItemId);
            }
            if (this.showOnlyChildren) {
                data = data.filter((i) => !!i.parentItemId);
            }
            this.items = data.map((a) => { a.cli_displayNameWithParent = (a.parentItem ? `${a.parentItem.name} / ` : '') + `${a.name}`; return a; });

            this._data = data.slice(0);
        };
    }

    public getHandleError(): (err: any) => void {
        return (err: any) => {
            Logger.error(err);
        };
    }

    public getComplete(): () => void {
        return () => { };
    }

    public getSubscription(): Subscription {
        return this.dataService.fetchItems(this.categoryCode, null, null, this.includeActiveInactive).subscribe(
            this.getBindData(),
            this.getHandleError(),
            this.getComplete(),
        );
    }

}
