import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, IncorporatedGamesDTO, CompetitionStructureDTO, RoundStructureDTO, GroupPairingStructureDTO, RoundDTO, HostStructureDTO, ConfigurationRoundPresetDTO, GroupPairingPlaceholderDTO, GameDTO, FlatStructureDTO, GameStructureDTO, ActionMessageDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { DataCachedService } from '../../utils/data-cached.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionStructureDataService {
    protected competitionApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected presetsCacheService: DataCachedService<ConfigurationRoundPresetDTO>,
        protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionRoundsStructure(competitionId: number): Observable<CompetitionStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rounds-structure`);
    }

    fetchCompetitionRoundsList(competitionId: number): Observable<RoundDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rounds`);
    }

    fetchRoundStructure(roundId: number): Observable<RoundStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/structure`);
    }

    fetchRoundRoundsStructure(roundId: number): Observable<RoundStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/rounds-structure`);
    }

    fetchRoundGrouppairingsStructure(roundId: number): Observable<RoundStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/grouppairings-structure`);
    }

    fetchGrouppairingStructure(groupPairingId: number): Observable<GroupPairingStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/groupPairing/${groupPairingId}/structure`);
    }

    fetchParentRounds(roundId: number): Observable<RoundDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/parents`);
    }

    fetchAllHostsForCompetition(competitionId: number): Observable<HostStructureDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/competition/${competitionId}/hosts`);
    }

    fetchAllHostsForRound(roundId: number): Observable<HostStructureDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/hosts`);
    }

    fetchAllHostsForGroupPairing(groupPairingId: number): Observable<HostStructureDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/grouppairing/${groupPairingId}/hosts`);
    }

    fetchConfigurationRoundPresets(): Observable<Array<ConfigurationRoundPresetDTO>> {
        return this.presetsCacheService.fetchEntities(`${this.competitionApiEndpoint}/configuration-round-presets`, 'ROUND_PRESETS');
    }

    fetchGroupPairingPlaceholders(groupPairingId: number): Observable<Array<GroupPairingPlaceholderDTO>> {
        return this.http.get(`${this.competitionApiEndpoint}/grouppairing/${groupPairingId}/placeholders`);
    }

    updateCompetitionStructure(competitionId: number, rounds: Array<RoundStructureDTO>): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/rounds-structure`, rounds);
    }

    // /!\ all the sub-rounds of the round MUST be flatenned in round.rounds
    updateSuperRound(round: RoundStructureDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/super-round/${round.roundId}`, round);
    }

    updateGroupRound(round: RoundStructureDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/group-round/${round.roundId}`, round);
    }

    updatePairingRound(round: RoundStructureDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/non-group-round/${round.roundId}`, round);
    }

    updateGroupPairingStructure(groupPairing: GroupPairingStructureDTO): Observable<string[]> {
        return this.http.put(`${this.competitionApiEndpoint}/grouppairing/${groupPairing.groupPairingId}`, groupPairing);
    }

    fetchGame(gameId: number): Observable<GameDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/game/${gameId}`);
    }

    updateGame(game: GameDTO): Observable<ActionMessageDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/game/${game.gameId}`, game);
    }

    fetchIncorporatedRounds(competitionId, roundId: number): Observable<RoundDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rounds/${roundId}/predecessors`);
    }

    fetchRoundIncorporatedGames(roundId: number): Observable<IncorporatedGamesDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/rounds/${roundId}/incorporated-games`);
    }

    fetchGroupPairingIncorporatedGames(groupPairingId: number): Observable<IncorporatedGamesDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/group-pairings/${groupPairingId}/incorporated-games`);
    }

    setRoundAsCompleted(roundId: number): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/rounds/${roundId}/set-as-completed`);
    }


}
