import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ApiListResponse, PersonDTO, ItemDTO, CoachLicenseRequestDTO, LicenseRequestUpdateReasonDTO, CoachLicenseEditRequestDTO, CoachRoleLicenseRequestStatusDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { CoachLicenseRequestListItemDTO } from '@fiba/models/dtos/generated/coach-license-request-list-item-dto';
import { CoachLicenseRequestSummaryDTO } from '@fiba/models/dtos/generated/coach-license-request-summary-dto';
import { LicenseRequestStatusDTO } from '@fiba/models/dtos/generated/license-request-status-dto';
import { CoachLicenseRequestFilters } from '@fiba/models/coach-license/coach-license-request';
import { CoachLicenseDTO } from '@fiba/models/dtos/generated/coach-license-dto';
import { DataCachedService } from '../utils/data-cached.service';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CoachLicenseDataService {
    protected coachApiEndpoint: string;
    protected coachLicenseApiEndpoint: string;
    protected coachLicenseRequestApiEndpoint: string;
    protected licenseRequestApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataLicenseRequestStatusService: DataCachedService<LicenseRequestStatusDTO>) {
        this.coachApiEndpoint = config.coachApiEndpoint;
        this.coachLicenseRequestApiEndpoint = config.coachLicenseRequestApiEndpoint;
        this.licenseRequestApiEndpoint = config.licenseRequestApiEndpoint;
        this.coachLicenseApiEndpoint = config.coachLicenseApiEndpoint;
    }

    /**
     * Fetch list of CoachLicenseRequests
     *
     * @param filters
     */
    public fetchList(filters: CoachLicenseRequestFilters): Observable<ApiListResponse<CoachLicenseRequestListItemDTO>> {
        return this.http.get(this.coachLicenseRequestApiEndpoint, filters.getSearchParams());
    }

    /**
     * Fetch license request statuses
     */
    public fetchStatuses(): Observable<CoachRoleLicenseRequestStatusDTO[]> {
        return this.http.get(this.coachLicenseRequestApiEndpoint + '/statuses');
    }

    /**
     * Fetch Coach License Requests totals by zone
     */
    public fetchSummaryByStatusAndZone(): Observable<CoachLicenseRequestSummaryDTO[]> {
        return this.http.get(`${this.coachLicenseRequestApiEndpoint}/summary?groupBy=statusAndZone`);
    }

    /**
     * Fetch Coach License Requests totals
     */
    public fetchSummaryByStatus(): Observable<CoachLicenseRequestSummaryDTO[]> {
        return this.http.get(`${this.coachLicenseRequestApiEndpoint}/summary?groupBy=status`);
    }

    /**
     * Fetch Coach License Requests totals
     */
    public fetchSummaryByCategory(): Observable<CoachLicenseRequestSummaryDTO[]> {
        return this.http.get(`${this.coachLicenseRequestApiEndpoint}/summary?groupBy=category`);
    }

    /**
     * Fetch Person Information for the coach-search passed as parameter
     *
     * @param personId
     */
    public fetchCoachInformation(personId: number): Observable<PersonDTO> {
        return this.http.get(`${this.coachApiEndpoint}/${personId}/information`);
    }

    /**
     * Create Coach License Request
     *
     * @param model
     */
    public createCoachLicenseRequest(model: CoachLicenseRequestDTO): Observable<CoachLicenseRequestDTO> {
        return this.http.post(this.coachLicenseRequestApiEndpoint, model);
    }

    /**
     * Update Coach License Request
     *
     * @param model
     */
    public updateCoachLicenseRequest(model: CoachLicenseRequestDTO): Observable<CoachLicenseRequestDTO> {
        return this.http.put(this.coachLicenseRequestApiEndpoint, model);
    }

    /**
     * Fetch Coach License Request
     *
     * @param licenseRequestId
     */
    public fetchCoachLicenseRequest(licenseRequestId: number): Observable<CoachLicenseRequestDTO> {
        return this.http.get(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}`);
    }

    public fetchCoachLicense(licenseId: number): Observable<CoachLicenseDTO> {
        return this.http.get(`${this.coachLicenseApiEndpoint}/${licenseId}`);
    }

    /**
     * Cancel Coach License Request
     *
     * @param licenseRequestId
     */
    public cancelCoachLicenseRequest(licenseRequestId: number): Observable<CoachLicenseRequestDTO> {
        return this.http.put(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}/cancel`);
    }

    /**
     * Submit Coach License Request
     *
     * @param licenseRequestId
     */
    public submitCoachLicenseRequest(licenseRequestId: number): Observable<CoachLicenseRequestDTO> {
        return this.http.put(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}/submit`);
    }

    /**
     * Request information for Coach License Request
     *
     * @param licenseRequestId
     * @param licenseStatusChangeReason
     */
    public requestInformationCoachLicenseRequest(licenseRequestId: number, licenseStatusChangeReason: LicenseRequestUpdateReasonDTO): Observable<CoachLicenseRequestDTO> {
        return this.http.put(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}/request-information`, licenseStatusChangeReason);
    }

    /**
     * Reject Coach License Request
     *
     * @param licenseRequestId
     * @param licenseStatusChangeReason
     */
    public rejectCoachLicenseRequest(licenseRequestId: number, licenseStatusChangeReason: LicenseRequestUpdateReasonDTO): Observable<CoachLicenseRequestDTO> {
        return this.http.put(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}/reject`, licenseStatusChangeReason);
    }

    /**
     * Approve Coach License Request
     *
     * @param licenseRequestId
     */
    public approveCoachLicenseRequest(licenseRequestId: number): Observable<CoachLicenseRequestDTO> {
        return this.http.put(`${this.coachLicenseRequestApiEndpoint}/${licenseRequestId}/approve`);
    }

    public createCoachLicenseEditRequest(coachLicenseId: number, dto: CoachLicenseEditRequestDTO): Observable<CoachLicenseRequestDTO> {
        return this.http.post(`${this.coachLicenseApiEndpoint}/${coachLicenseId}/requests`, dto);
    }

    /**
     * Download all documents linked to the current coach license request.
     *
     * @param coachLicenseRequestId
     */
    public downloadAllDocuments(coachLicenseRequestId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.coachLicenseRequestApiEndpoint}/${coachLicenseRequestId}/download-all-documents`, null, 'allDocumentsCoachLicenseRequest.zip');
    }

    public downloadExport(filters: CoachLicenseRequestFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.coachLicenseRequestApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }
}
