<fibaLoading></fibaLoading>
<form>
    <div class="vertical space10">
        <div class="block filled bordered with-header">
            <h3>Search</h3>
            <div class="form horizontal labels-above">

                <fibaMultiSelectItem label="Event type"
                                     [categoryCode]="ItemCategoryCodes_EventType"
                                     [parentCode]="filterTestEvents ? EventTypes_Test : null"
                                     name="eventType"
                                     [(ngModel)]="filters.type.value"
                                     [displayParent]="true"
                                     [showOnlyChildren]="true">
                </fibaMultiSelectItem>

                <fibaMultiSelectEventStatus label="Event status" name="eventStatus" [(ngModel)]="filters.status.value"></fibaMultiSelectEventStatus>
                <fibaInputDate [formSaveSuccessEvent]="" name="fromDate" label="From date" [(ngModel)]="filters.fromDate" [type]="'filter'"></fibaInputDate>
                <fibaInputDate [formSaveSuccessEvent]="" name="toDate" label="To date" [(ngModel)]="filters.toDate" [type]="'filter'"></fibaInputDate>
                <fibaSelectZone name="zone" label="Zone" [(ngModel)]="filters.zone.value"></fibaSelectZone>
                <fibaSelectCountry label="Country" name="country" [(ngModel)]="filters.country.value" [zone]="filters.zone.value"></fibaSelectCountry>
                <fibaAutocompleteCity name="city" label="City name" [(ngModel)]="cityName" (cityChange)="filters.cityName = $event"></fibaAutocompleteCity>
                <fibaInputText name="keywords" label="Keywords" [(ngModel)]="filters.keywords"></fibaInputText>
            </div>
        </div>
        <div class="button-bar margin-below-10">
            <button *fibaHideByPolicy="MapPolicy_EventsEdit"
                    type="button"
                    kendoButton
                    (click)="goToNew()"
                    [primary]="true"
                    [disabled]="false">
                <i class="fa fa-lg fa-plus-circle" aria-hidden="true"></i>
                New event
            </button>
            <div>
                <button type="submit" kendoButton
                        (click)="filter()"
                        [primary]="true">
                    <i class="fa fa-lg fa-check-circle"></i>
                    Apply filters
                </button>
                <button type="button"
                        (click)="reset()"
                        kendoButton>
                    <i class="fa fa-lg fa-refresh"></i>
                    Reset filters
                </button>
                <button type="button" kendoButton (click)="downloadHandler()" [disabled]="exportInProgress">
                    <i *ngIf="!exportInProgress" class="fa fa-upload"></i>
                    <i *ngIf="exportInProgress" class="fa fa-spinner fa-pulse fa-lg"></i>
                    Export
                </button>
            </div>
        </div>
        <span *ngIf="isLoading">Loading...</span>
        <kendo-grid *ngIf="!isLoading"
                    #grid
                    class="no-scrollbar navigable"
                    [data]="events"
                    [pageSize]="filters.take"
                    [skip]="filters.skip"
                    [sortable]="{ mode: 'single' }"
                    [sort]="sort"
                    [pageable]="true"
                    [selectable]="true"
                    (selectionChange)="handleSelChange($event)"
                    (pageChange)="handlePageChange($event)"
                    (sortChange)="handleSortChange($event)">
            <kendo-grid-column title="Type" field="type">
                <ng-template kendoGridCellTemplate let-event>
                    <span *ngIf="event.type">{{event.type?.parentItem?.description}} / {{event.type?.description}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Title" field="title" [sortable]="true"></kendo-grid-column>
            <kendo-grid-column title="From" field="fromDate" [sortable]="true" width="130px">
                <ng-template kendoGridCellTemplate let-event>
                    <span>{{event.fromDate | fibaDate}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="To" field="toDate" [sortable]="true" width="130px">
                <ng-template kendoGridCellTemplate let-event>
                    <span>{{event.toDate | fibaDate}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Zone" field="zone">
                <ng-template kendoGridCellTemplate let-event>
                    <span>{{event.zone?.officialName}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Country" field="country">

                <ng-template kendoGridCellTemplate let-event>

                    <div *ngIf="!event.eventId">
                        <ng-container *ngFor="let host of event.hosts">
                            <span *ngIf="host.country">{{host.country?.officialName}} ({{host.country.fibacode}})</span><br />
                        </ng-container>
                    </div>
                    <div *ngIf="event.eventId">
                        <span>{{event.countryName}}</span>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="City" field="city">
                <ng-template kendoGridCellTemplate let-event>
                    <div *ngIf="!event.eventId">
                        <ng-container *ngFor="let host of event.hosts">
                            <span>{{host.cityName}}</span><br />
                        </ng-container>
                    </div>
                    <div *ngIf="event.eventId">
                        <span>{{event.cityName}} </span><br />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Status" field="status" width="120px">
                <ng-template kendoGridCellTemplate let-event>
                    <span>{{event?.eventStatus?.description}}</span>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</form>