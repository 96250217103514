<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <input #input="ngModel"
           type="url"
           id="{{name}}"
           [disabled]="disabled"
           [(ngModel)]="value"
           (change)="manualChange()"
           (blur)="onTouched($event)"
           />
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.fibaUrlFibaValidator">
                {{label}} url format is invalid (Note : http:// or https:// is required).
            </li>
        </ul>
    </div>
</div>
