import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService, IFileData } from '../utils/http.service';
import {
    ApiListResponse, AgentContractShareDetailsDTO, AgentContractNewSharingDTO,
    AgentContractHistoricalDataLineDTO, AgentPreRegistrationFormListItemDTO,
    AgentPersonInformationDTO, PersonFilters, PersonListItemDTO, AgentRoleLicenseRequestDTO, AgentListItemDTO, PersonDTO, PaymentManualDTO, UserAgentInformationDTO
} from '@fiba/models';
import { AgentTestCountersDTO } from '@fiba/models/dtos/generated/agent-test-counters-dto';
import { AppConfig } from '@app/app.config';
import { timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AgentDataService {
    private readonly agentApiEndpoint: string;
    private readonly personApiEndpoint: string;
    protected agentRelationSharesApiEndpoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig) {
        this.agentApiEndpoint = config.agentApiEndpoint;
        this.personApiEndpoint = config.personApiEndpoint;
        this.agentRelationSharesApiEndpoint = config.agentRelationSharesApiEndpoint;
    }
    public fetchList(filters: PersonFilters, context: string = null): Observable<ApiListResponse<AgentListItemDTO>> {
        return this.http.get(this.agentApiEndpoint, filters.getSearchParams());
    }

    public downloadAllAgentsExcel(): Observable<IFileData> {
        return this.http.getFileData(`${this.agentApiEndpoint}/licensing-details-extract`).pipe(timeout(300000));
    }

    /**
     * Get pre registered candidates list
     */
    public fetchPreRegisteredCandidates(): Observable<ApiListResponse<AgentPreRegistrationFormListItemDTO>> {
        return this.http.get(`${this.agentApiEndpoint}/pre-registered`);
    }

    public fetchAgentRelationShareDetails(personId: number): Observable<AgentContractShareDetailsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/shared-contracts`);
    }

    public postNewAgentContractShare(agentPersonId: number, newShare: AgentContractNewSharingDTO): Observable<any> {
        return this.http.post(`${this.personApiEndpoint}/${agentPersonId}/shared-contracts`, newShare);
    }

    public fetchHistoricalData(agentPersonId: number): Observable<AgentContractHistoricalDataLineDTO[]> {
        return this.http.get(`${this.personApiEndpoint}/${agentPersonId}/shared-contracts-history`);
    }

    fetchDashboardList(context: string = null): Observable<ApiListResponse<AgentTestCountersDTO>> {
        return this.http.get(`${this.agentApiEndpoint}/dashboard`);
    }

    public fetchAgentPersonInformations(): Observable<AgentPersonInformationDTO> {
        return this.http.get(`${this.agentApiEndpoint}/informations`);
    }

    public updateAgentPersonInformations(model: AgentPersonInformationDTO): Observable<any> {
        return this.http.put(`${this.agentApiEndpoint}/informations`, model);
    }

    public confirmAgentRelationShared(agentRelationShareId: number): Observable<any> {
        return this.http.put(`${this.agentRelationSharesApiEndpoint}/${agentRelationShareId}/confirm`);
    }

    public refuseAgentRelationShared(agentRelationShareId: number): Observable<any> {
        return this.http.put(`${this.agentRelationSharesApiEndpoint}/${agentRelationShareId}/refuse`);
    }

    public performLicensePayment(licenseId: number): Observable<AgentRoleLicenseRequestDTO> {
        return this.http.post(`${this.agentApiEndpoint}/myself/license/${licenseId}/renewall`, null);
    }

    public fetchAgentRoleLicenseRequestPerson(licenseRequestId: number): Observable<AgentRoleLicenseRequestDTO> {
        return this.http.get(`${this.agentApiEndpoint}/licenses/requests/${licenseRequestId}`, null);
    }

    public renewLicense(licenseId: number): Observable<AgentRoleLicenseRequestDTO> {
        return this.http.post(`${this.agentApiEndpoint}/licenses/${licenseId}/renewall`, null);
    }

    public enterManualPayment(payment: PaymentManualDTO): Observable<any> {
        return this.http.put(`${this.agentApiEndpoint}/licenses/requests/${payment.businessItemId}/manual-payment`, payment);
    }
    public fetchUserAgentInformation(): Observable<UserAgentInformationDTO> {
        return this.http.get(`${this.agentApiEndpoint}/current-user-information`);
    }
}
