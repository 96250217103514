import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'fibaDate' })
export class FibaDatePipe implements PipeTransform {
    public transform(value: any, format: string = 'D-MMM-YYYY', allowMinMax: boolean = false): string {
        if (!allowMinMax && [1900, 9999].indexOf(moment(value).year()) > -1 || !value) {
            return '';
        }
        return moment(value).isValid() ? moment.utc(value).format(format) : value;
    }
}