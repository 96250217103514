import { Component, forwardRef, Injector, Input, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';
import { MergeSelection } from '@fiba/models/dtos/generated/merge-selection';
import { MergeSelectionOption } from '@fiba/models';

@Component({
    selector: 'fibaMergeContainer',
    templateUrl: './fiba-merge-container.component.html',
    styleUrls: ['./fiba-merge-container.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMergeContainerComponent),
            multi: true,
        },
    ],
})
export class FibaMergeContainerComponent extends FibaInputBase<any> {
    @ContentChild('first') firstValueRef: TemplateRef<any>;
    @ContentChild('second') secondValueRef: TemplateRef<any>;

    private _selectionMode: string;

    @Input()
    get selectionMode(): string {
        return this._selectionMode;
    }
    set selectionMode(value: string) {
        this._selectionMode = value;
        if (value === 'onlyBoth' && !!this.value) {
            this.value.selection = this.SelectionBoth;
        }
    }

    public valueChanged(val): void {
        if (!!val && this.selectionMode === 'onlyBoth') {
            this.value.selection = this.SelectionBoth;
        }
    }

    @Input() name: string;
    @Input() master = false;
    @Input() isPersonMode = false;
    @Input() isClubMode = false;

    @Input() disableFirst = false;
    @Input() disableSecond = false;

    SelectionFirst: number = MergeSelection.First;
    SelectionSecond: number = MergeSelection.Second;
    SelectionBoth: number = MergeSelection.Both;

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected ngAfterViewChecked(): void {
        if (this.master) {
            if (this.disableFirst && !this.disableSecond) {
                this.value.selection = this.SelectionSecond;
            }
            if (!this.disableFirst && this.disableSecond) {
                this.value.selection = this.SelectionFirst;
            }
            if (this.disableFirst && this.disableSecond) {
                this.value.selection = null;
            }
        }
    }

    public toggleFirstHandler() {
        switch (this.selectionMode) {
            case 'oneOrBoth':
                if (this.value.selection === this.SelectionBoth) {
                    this.value.selection = this.SelectionSecond;
                } else if (this.value.selection === this.SelectionSecond) {
                    this.value.selection = this.SelectionBoth;
                }
                break;
            case 'onlyOne':
                if (this.master && this.disableFirst) {

                } else {
                    this.value.selection = this.SelectionFirst;
                }
                break;
            case 'onlyBoth':
                this.value.selection = this.SelectionBoth;
            default:
                break;
        }
    }

    public toggleSecondHandler() {
        switch (this.selectionMode) {
            case 'oneOrBoth':
                if (this.value.selection === this.SelectionBoth) {
                    this.value.selection = this.SelectionFirst;
                } else if (this.value.selection === this.SelectionFirst) {
                    this.value.selection = this.SelectionBoth;
                }
                break;
            case 'onlyOne':
                if (this.master && this.disableSecond) {

                } else {
                    this.value.selection = this.SelectionSecond;
                }
                break;
            case 'onlyBoth':
                this.value.selection = this.SelectionBoth;
            default:
                break;
        }
    }

}
