import { BaseFilters, FilterableArray } from '../common/base.filters';

export class AgreementFilters extends BaseFilters {
    agreementId: number;
    agreementName: string;
    agreementStatusIds: FilterableArray = new FilterableArray('agreementStatusIds', 'equipmentAgreementStatusId');
    isFibaPartner: boolean;

    reset(): void {
        this.agreementStatusIds.reset();
        delete this.agreementId;
        delete this.agreementName;
    }
}
