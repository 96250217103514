import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiListResponse, ItemDTO, PersonDTO, PlayerLastKnownInfoDTO, PlayerLicenseDTO, PlayerLicenseListItemDTO, PlayerLicenseRequestDTO, PlayerLicenseRequestStatusDTO, PlayerLicenseStatusDTO, PlayerLicenseSummaryDTO, PlayerNationalityDTO, MinMaxSeasonDTO } from '@fiba/models';
import { PlayerLicenseFilters } from '@fiba/models/player-license/player-license';
import { DataCachedService } from '../utils/data-cached.service';
import { HttpService, IFileData } from '../utils/http.service';
import * as moment from 'moment';
import { PlayerLicenseSanctionApplicationListItemDTO } from '@fiba/models/dtos/generated/player-license-sanction-application-list-item-dto';
import { map, timeout } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PlayerLicenseDataService {

    protected playerLicenseApiEndpoint: string;
    protected playerLicenseStatusApiEndpoint: string;
    protected playerLicenseSummaryApiEndpoint: string;
    protected playerApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataPlayerLicenseStatusService: DataCachedService<PlayerLicenseStatusDTO>) {
        this.playerLicenseApiEndpoint = config.playerLicenseApiEndpoint;
        this.playerLicenseStatusApiEndpoint = config.playerLicenseStatusApiEndpoint;
        this.playerLicenseSummaryApiEndpoint = config.playerLicenseSummaryApiEndpoint;
        this.playerApiEndpoint = config.playerApiEndpoint;
    }

    /**
     * Fetch list of PlayerLicenses
     *
     * @param filters
     */
    public fetchList(filters: PlayerLicenseFilters): Observable<ApiListResponse<PlayerLicenseListItemDTO>> {
        return this.http.get(this.playerLicenseApiEndpoint, filters.getSearchParams());
    }

    /**
     * Fetch single PlayerLicense by Id
     *
     * @param licenceId
     */
    public fetchPlayerLicense(licenceId: number): Observable<PlayerLicenseDTO> {
        return this.http.get(`${this.playerLicenseApiEndpoint}/${licenceId}`);
    }

    /**
     * Fetch Person Information for the player passed as parameter
     *
     * @param personId
     */
    public fetchPlayerInformation(personId: number): Observable<PersonDTO> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/information`);
    }

    /**
     * Fetch Player Nationalities for the person passed as parameter
     *
     * @param personId
     */
    public fetchPlayerNationalities(personId: number): Observable<PlayerNationalityDTO[]> {
        // Bug #4012 fixed a 400 Bad Request cuz /players/undefined/nationalities is not supported
        if (!personId) {
            return of([] as PlayerNationalityDTO[]);
        }
        return this.http.get(`${this.playerApiEndpoint}/${personId}/nationalities`);
    }

    /**
     * Fetch Player last known info for the person passed as parameter
     *
     * @param personId
     */
    public fetchPlayerLastKnownInfo(personId: number): Observable<PlayerLastKnownInfoDTO> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/last-known-info`);
    }

    /**
     * Fetch player license nationality validity (depending on the playerId, nationality and category of license).
     *
     * @param playerLicenseId
     * @param playerId
     * @param nationalityCountryId
     * @param categoryId
     */
    public fetchPlayerLicenseNationalityValidated(playerLicenseId: number, playerId: number, nationalityCountryId: number, categoryId: number): Observable<boolean> {
        const params = {
            playerLicenseId: String(playerLicenseId),
            playerId: String(playerId),
            nationalityCountryId: String(nationalityCountryId),
            categoryId: String(categoryId),
        };
        return this.http.get(`${this.playerLicenseApiEndpoint}/player-license-nationality-validated`, params);
    }

    /**
     * Fetch player license document description (used on the grid to specify all needed documents).
     */
    public fetchLicenseRequestDocumentsDescription(): Observable<ItemDTO> {
        return this.http.get(`${this.playerLicenseApiEndpoint}/license-request-documents-description`);
    }

    /**
     * Create player license
     *
     * @param model
     */
    public createPlayerLicense(model: PlayerLicenseDTO): Observable<PlayerLicenseRequestDTO> {
        return this.http.post(this.playerLicenseApiEndpoint, model);
    }

    /**
     * Create player license status
     *
     * @param model
     * @param status
     */
    public createPlayerLicenseStatus(model: PlayerLicenseRequestStatusDTO, status: string): Observable<PlayerLicenseRequestDTO> {
        return this.http.post(`${this.playerLicenseApiEndpoint}/status/?status=${status}`, model);
    }

    /**
     * Update player license
     *
     * @param model
     */
    public updatePlayerLicense(model: PlayerLicenseDTO): Observable<PlayerLicenseRequestDTO> {
        return this.http.put(`${this.playerLicenseApiEndpoint}/${model.playerLicenseId}`, model);
    }

    /**
     * Update player license status
     *
     * @param model
     * @param status
     */
    public updatePlayerLicenseStatus(model: PlayerLicenseRequestStatusDTO, status: string): Observable<PlayerLicenseRequestDTO> {
        return this.http.put(`${this.playerLicenseApiEndpoint}/${model.playerLicenseRequest.playerLicenseId}/status/?status=${status}`, model);
    }

    public setValidityPlayerLicense(model: PlayerLicenseRequestDTO): Observable<PlayerLicenseRequestDTO> {
        return this.http.put(`${this.playerLicenseApiEndpoint}/${model.playerLicenseId}/set-validity`, model);
    }

    /**
     * Fetch player license status
     */
    public fetchStatuses(): Observable<PlayerLicenseStatusDTO[]> {
        return this.dataPlayerLicenseStatusService.fetchEntities(this.playerLicenseStatusApiEndpoint);
    }

    /**
     * Fetch Player License totals
     */
    public fetchSummaryByStatus(): Observable<PlayerLicenseSummaryDTO[]> {
        return this.http.get(`${this.playerLicenseSummaryApiEndpoint}?groupBy=status`); // TODO: add a new value in config for the endpoint
    }

    /**
     * Fetch Player License totals by zone
     */
    public fetchSummaryByStatusAndZone(): Observable<PlayerLicenseSummaryDTO[]> {
        return this.http.get(`${this.playerLicenseSummaryApiEndpoint}?groupBy=statusAndZone`); // TODO: add a new value in config for the endpoint
    }

    public fetchSummaryByCategory(): Observable<PlayerLicenseSummaryDTO[]> {
        return this.http.get(`${this.playerLicenseSummaryApiEndpoint}?groupBy=category`); // TODO: add a new value in config for the endpoint
    }

    public downloadExport(filters: PlayerLicenseFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.playerLicenseApiEndpoint}/export`, filters.getSearchParams()).pipe(timeout(300000)); // TODO: use constant or conf value
    }

    /**
     * Download all documents linked to the current player license request.
     *
     * @param playerLicenseId
     */
    public downloadAllDocuments(playerLicenseId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.playerLicenseApiEndpoint}/${playerLicenseId}/download-all-documents`, null, 'allDocumentsPlayerLicense.zip');
    }

    /**
     * Fetch player licenses already approved and with the same characteristics than the one passed as parameter
     */
    public fetchAlreadyApprovedLicenses(playerLicense: PlayerLicenseRequestDTO): Observable<PlayerLicenseDTO[]> {
        const playerLicenseIdQuery = 'playerLicenseId=' + (playerLicense.playerLicenseId || '');

        const personId: number = playerLicense.personId;
        const season: number = playerLicense.season;
        const categoryItemId: number = playerLicense.categoryItemId;
        const newLicenseStartDate: string = moment.utc(playerLicense.startDate).format('D-MMM-YYYY');

        return this.http.get(`${this.playerLicenseApiEndpoint}/already-approved-license-for-season?${playerLicenseIdQuery}&personId=${personId}&season=${season}&categoryItemId=${categoryItemId}&newLicenseStartDate=${newLicenseStartDate}`,
            null).pipe(map((response) => (response ? [response as PlayerLicenseDTO] : null)));
    }

    /**
     * Fetch player licenses already approved and with the same characteristics than the one passed as parameter
     */
    public fetchNotInFinalStatusPlayerLicenses(playerLicense: PlayerLicenseRequestDTO): Observable<PlayerLicenseDTO[]> {
        const playerLicenseIdQuery = 'playerLicenseId=' + (playerLicense.playerLicenseId || '');

        const personId: number = playerLicense.personId;
        const season: number = playerLicense.season;
        const categoryItemId: number = playerLicense.categoryItemId;
        return this.http.get(`${this.playerLicenseApiEndpoint}/not-final-status-license-for-season?${playerLicenseIdQuery}&personId=${personId}&season=${season}&categoryItemId=${categoryItemId}`);
    }

    public setOnHold(model: PlayerLicenseRequestDTO): any {
        return this.http.put(`${this.playerLicenseApiEndpoint}/${model.playerLicenseId}/set-on-hold`, model);
    }
    public backToSubmitted(model: PlayerLicenseRequestDTO): any {
        return this.http.put(`${this.playerLicenseApiEndpoint}/${model.playerLicenseId}/back-to-submitted`, model);
    }
    public deletePlayerLicenseA(playerLicenseId: number): any {
        return this.http.delete(`${this.playerLicenseApiEndpoint}/license-a/${playerLicenseId}`);
    }
    public deletePlayerLicenseB(playerLicenseId: number): any {
        return this.http.delete(`${this.playerLicenseApiEndpoint}/license-b/${playerLicenseId}`);
    }
    public deletePlayerLicenseIDCard(playerLicenseId: number): any {
        return this.http.delete(`${this.playerLicenseApiEndpoint}/license-idcard/${playerLicenseId}`);
    }
    public fetchPlayerSanctions(personId: number, licenseCategoryCode: string, startDate: Date, endDate: Date): Observable<PlayerLicenseSanctionApplicationListItemDTO[]> {
        const params = {
            licenseCategoryCode,
            startDate: moment.utc(startDate).format('D-MMM-YYYY'),
            endDate: moment.utc(endDate).format('D-MMM-YYYY')
        };
        return this.http.get(`${this.playerApiEndpoint}/${personId}/sanctions`, params);
    }

    fetchPlayerLicenseMinMaxSeason(): Observable<MinMaxSeasonDTO> {
        return this.http.get(`${this.playerLicenseApiEndpoint}/min-max-season`);
    }

    public fetchPlayerLicenseCategories(): Observable<ItemDTO[]> {
        return this.http.get(`${this.playerLicenseApiEndpoint}/categories`);
    }
}
