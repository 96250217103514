import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompetitionConfigurationSetupDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { DataCachedService } from '../../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionConfigurationDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionConfigurationSetup(competitionId: number): Observable<CompetitionConfigurationSetupDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/configuration`);
    }

    updateCompetitionConfigurationSetup(model: CompetitionConfigurationSetupDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/configuration`, model);
    }
}
