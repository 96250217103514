import { Injector, Component, Input, forwardRef, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputTextNumeric',
    templateUrl: './fiba-input-text-numeric.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputTextNumericComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaInputTextNumericComponent extends FibaInputBase<number> {
    constructor(protected injector: Injector) {
        super(injector);
    }
    @Input() min: number;
    @Input() max = 2147483647;
    @Input() step = 1;
    @Input() showButtons = false;
    @Input() format = '#';
    @Input() public placeholder: string;

    @Output() valueChange = new EventEmitter<void>();

    public autoCorrect = true;

    handleValue(value) {
        this.onChange(value);
        this.onTouched();
        this.valueChange.emit();
    }

    handleKeypress($event: KeyboardEvent) {
        // On Enter keypress, trigger blur, otherwise ngModel is not updated
        if ($event.which === 13) {
            (<HTMLElement>$event.target).blur();
        }
    }
}
