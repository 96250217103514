import { Injectable } from '@angular/core';

import { Rankable, CompetitionClassificationFunctions, CompetitionTeamDTO, RoundDTO, GroupPairingDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';

@Injectable({
    providedIn: 'root',
})
export class RankingUtilsService {
    private readonly CompetitionClassificationFunctions_Bestof = CompetitionClassificationFunctions.Bestof;
    private readonly CompetitionClassificationFunctions_AsLastGroupRound = CompetitionClassificationFunctions.AsLastGroupRound;

    /**
     * Text generation for ranking related entities.
     */
    public generateTextValue(_model: Rankable, isFixedTeam: boolean): string {
        if (isFixedTeam) {
            return null;
        } else if (_model.fromClassificationFunctionItem.code == this.CompetitionClassificationFunctions_AsLastGroupRound) {
            return _model.fromClassificationFunctionItem.name + ' ' + _model.fromRound.code;
        }else {
            return `${_model.fromRound.code}/${this.generateGroupListText(_model, isFixedTeam)}/${this.generateFunctionText(_model)}`;
        }
    }

    private generateFunctionText(_model: Rankable): string {
        return `[${_model.fromClassificationFunctionItem.code}]${_model.fromClassificationFunctionItem.code === this.CompetitionClassificationFunctions_Bestof ? '/' + _model.fromAggregateSubset : ''}/${_model.fromFinalPosition}`;
    }

    private generateGroupListText(_model: Rankable, isFixedTeam: boolean) {
        if (_model.groupPairingsInvolved.length === 1) {
            return _model.groupPairingsInvolved[0].code;
        } else if (_model.groupPairingsInvolved.length === _model.fromRound.numberOfGroups) {
            return '[*]';
        } else if (_model.groupPairingsInvolved.length > 1) {
            return `[${_model.groupPairingsInvolved.map(gp => gp.code).sort((c1, c2) => c1.localeCompare(c2)).join(',')}]`;
        } else {
            Logger.warn('no grouppairing selected !');
            return '';
        }
    }

    /**
     * Set all fields of the rankable so it is in a consistent and usable state
     */
    public cleanModel(_model: Rankable, isFixedTeam: boolean, teams: CompetitionTeamDTO[], rounds: RoundDTO[], groupPairings: GroupPairingDTO[]): void {
        if (isFixedTeam) {
            // delete calculated information
            _model.fromRoundId = null;
            _model.fromRound = null;
            _model.fromClassificationFunctionItem = null;
            _model.fromClassificationFunctionItemId = null;
            _model.fromFinalPosition = null;
            _model.fromAggregateSubset = null;

            // same with team (only id otherwise...)
            _model.competitionTeam = teams.find(t => t.competitionTeamId === _model.competitionTeamId) || null;
        } else {
            // delete competition team
            _model.competitionTeam = null;
            _model.competitionTeamId = null;

            // replace round correctly (only id otherwise...)
            _model.fromRound = rounds.find(r => r.roundId === _model.fromRoundId);
            if (!_model.fromRound) {
                Logger.warn(`No round with roundid ${_model.fromRoundId}`);
            }
            // extract itemId from fromClassificationFunctionItem
            _model.fromClassificationFunctionItemId = _model.fromClassificationFunctionItem ? _model.fromClassificationFunctionItem.itemId : null;
            if (!_model.fromClassificationFunctionItemId) {
                Logger.warn(`No item with itemid ${_model.fromClassificationFunctionItemId}`);
            }
            if (_model.fromClassificationFunctionItem && _model.fromClassificationFunctionItem.code !== this.CompetitionClassificationFunctions_Bestof) {
                _model.fromAggregateSubset = null;
            }

            // assume no group selected means all.
            if (_model.fromClassificationFunctionItem.code != this.CompetitionClassificationFunctions_AsLastGroupRound) {
                if (_model.groupPairingsInvolved.length === 0) {
                    _model.groupPairingsInvolved = groupPairings;
                }
                if (_model.groupPairingsInvolved.length === 0) {
                    Logger.warn(`no group pairings found in round ${_model.fromRoundId}!`);
                }
            }
        }
    }
}
