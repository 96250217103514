import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class NationalFederationFilters extends BaseFilters {
    context: string;
    name: string;
    organisationStatus: Filterable = new Filterable('organisationStatusId');
    membershipStatus: Filterable = new Filterable('membershipStatusId', 'nationalFederationMembershipStatusId');
    country: Filterable = new Filterable('countryId');
    relatedCountries: boolean;
    zone: FilterableArray = new FilterableArray('zoneId');
    competition: Filterable = new Filterable('competitionId');
    discipline: string;
    gender: string;
    season: string;
    ageCategory: string;

    reset(): void {
        this.zone.reset();
    }
}
