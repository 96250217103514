<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox #input="ngModel"
                    [data]="data | async"
                    [placeholder]="'E.g. Paris Charles de Gaulle Airport'"
                    clearButton="false"
                    (filterChange)="handleFilter($event)"
                    (valueChange)="handleValue($event)"
                    [filterable]="true"
                    [textField]="textField"
                    [valueField]="valueField"
                    [(ngModel)]="valueAirport"
                    [suggest]="true"
                    (change)="manualChange()"
                    (blur)="onTouched($event)"
                    id="{{name}}"
                    [allowCustom]="true"
                    [valueNormalizer]="valueNormalizer"
                    [disabled]="disabled"
                    class="no-arrow">
        <ng-template kendoComboBoxItemTemplate let-dataItem>
            <span class="template" *ngIf="dataItem.adminName1">{{ dataItem.toponymName }}, {{dataItem.adminName1}}, {{dataItem.countryName}} </span>
            <span class="template" *ngIf="!dataItem.adminName1">{{ dataItem.toponymName }}, {{dataItem.countryName}} </span>
        </ng-template>
    </kendo-combobox>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
