import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { HttpService } from '../utils/http.service';
import { Logger } from '@fiba/utils/logger';
// Models
import { PersonOfficialLicenseDTO } from '@fiba/models';
import { LicenseCreateDTO } from '@fiba/models/dtos/generated/license-create-dto';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PersonOfficialLicenseDataService {
    private readonly apiEndPoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig,
    ) {
        this.apiEndPoint = config.personApiEndpoint;
    }
    /**
     * Get official-search license for a specific person
     */
    public fetch(personId: number): Observable<PersonOfficialLicenseDTO[]> {
        if (personId) {
            return this.http.get(`${this.apiEndPoint}/${personId}/officiallicenses`);
        }
        else {
            Logger.warn('fetch Official Licenses called with invalid personId');
        }
    }

    public createOfficialLicense(model: LicenseCreateDTO): Observable<LicenseCreateDTO> {
        return this.http.post(`${this.apiEndPoint}/${model.personId}/official-licenses`, model);
    }

    public createStatisticianLicense(model: LicenseCreateDTO): Observable<LicenseCreateDTO> {
        return this.http.post(`${this.apiEndPoint}/${model.personId}/statistician-license`, model);
    }
}
