import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CancelEvent, ClearEvent, FileInfo, FileRestrictions, SuccessEvent, UploadEvent, RemoveEvent } from '@progress/kendo-angular-upload';

import { DocumentCreationRequest, DocumentCreationRequestWithBaseEntity, DocumentDTO, ItemDTO, AzureFileCreationRequest } from '@fiba/models';
import { AuthService } from '@fiba/data-services';
import { IHttpClientRequestOptions } from '@fiba/data-services/utils/http.service';
import { process } from '@progress/kendo-data-query';
import {AppConfig} from '@app/app.config';

@Component({
    selector: 'fibaInputUploadAzureFiles',
    templateUrl: './fiba-input-upload-azure-files.component.html',
    styles: [':host ::ng-deep .k-upload .k-file-size { display: none; }'],
})
export class FibaInputUploadAzureFilesComponent implements OnInit {
    public uploadSaveUrl: string = this.config.azureFileApiEndpoint;
    public uploadRemoveUrl = 'removeUrl';

    public fileName: string = null;
    public _restrictions: FileRestrictions = {
        maxFileSize: 10000000,
        allowedExtensions: ['.jpg', '.jpeg', '.png'],
    };
    public myFiles: FileInfo[] = [];

    // Logic to always keep 1 document at a time
    @ViewChild('kendoUploadCmp', {static: true}) public kendoUploadCmp;
    private lastDocumentUid: string = undefined;

    @Output() public getAzureFileUrl: EventEmitter<string> = new EventEmitter();
    @Output() public getAzureFilename: EventEmitter<string> = new EventEmitter();
    @Output() public getTitle: EventEmitter<string> = new EventEmitter();
    @Output() public fileUploaded: EventEmitter<boolean> = new EventEmitter();

    @Input() public disabled = false;
    @Input() public customFileName;
    @Input() protected isNew = true;
    @Input() public saveMethod = 'POST';
    @Input() protected fileNamePrefix: string = null;
    @Input() public recommendedSize: string = null;
    @Input() protected fileType: number = null;
    @Input() protected entityReference: string = null;
    @Input()
    protected set restrictionPicture(value: boolean) {
        if (value) {
            this._restrictions = {
                maxFileSize: 10000000, allowedExtensions: ['.jpg', '.jpeg', '.png'],
            };
        }
    }

    private azureFileUrl: string;
    private azureFilename: string;
    public  uploadErrorText: string;

    constructor(protected authService: AuthService, protected config: AppConfig) {
    }

    public ngOnInit() {
        this.uploadErrorText = null;
        if (this.fileName) {
            this.myFiles = [{ name: this.fileName, size: -1 }];
            this.fileUploaded.emit(true);
        } else {
            this.fileUploaded.emit(false);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    public uploadEventHandler(e: UploadEvent): void {
        this.uploadErrorText = null;
        this.fileUploaded.emit(false);
        e.headers = this.createRequestOptions().headers;
        e.data = this.buildDocCreateRequest();
    }

    // noinspection JSUnusedGlobalSymbols
    public clearEventHandler(e: ClearEvent): void {
        e.preventDefault();
        this.fileUploaded.emit(false);
        this.disabled = false;
    }

    // noinspection JSUnusedGlobalSymbols
    public removeEventHandler(e: RemoveEvent): void {
        this.lastDocumentUid = undefined;
    }

    // noinspection JSUnusedGlobalSymbols
    public cancelEventHandler(e: CancelEvent): void {
        e.files = undefined;
        this.fileUploaded.emit(false);
    }

    // noinspection JSUnusedGlobalSymbols
    public successEventHandler(e: SuccessEvent): void {
        if (e.operation === 'upload') {
            if (this.lastDocumentUid !== undefined) {
                this.kendoUploadCmp.removeFilesByUid(this.lastDocumentUid);
            }

            this.lastDocumentUid = e.files[0].uid;

            this.azureFileUrl = e.response.body.azureFileUrl;
            this.azureFilename = e.response.body.filename;

            this.getAzureFileUrl.emit(this.azureFileUrl);

            this.getAzureFilename.emit(this.azureFilename);

            this.fileUploaded.emit(true);
            // Timeout is needed because removeFilesByUid Triggers successEventHandler
            // with operation 'remove' qnd change the fileUpload boolean
            setTimeout(() => {
                this.fileUploaded.emit(true);
            }, 1500);

        } else if (e.operation === 'remove') {
            this.fileUploaded.emit(false);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    public selectHandler(): void {
        this.disabled = true;
    }

    private createRequestOptions(): IHttpClientRequestOptions {
        const req = this.authService.getAuthHeaders();

        req.headers.append('Access-Control-Allow-Origin', '*');

        return req;
    }

    private buildDocCreateRequest(): AzureFileCreationRequest {
        const params: AzureFileCreationRequest = new AzureFileCreationRequest();
        params.fileType = this.fileType;
        params.entityReference = this.entityReference;
        if (this.azureFileUrl !== null && this.azureFileUrl !== undefined)
            params.lastAzureFile = this.azureFileUrl;
        if (this.customFileName) {
            params.customFileName = this.customFileName;
        }
        return params;
    }

    errorEventHandler(e: any) {
        if (typeof e?.response?.error === "string") {
            this.uploadErrorText = e?.response?.error;
        }
    }

}
