import { BaseFilters, Filterable } from '../common/base.filters';

export class AgentRelationFilters extends BaseFilters {
    public context: string;
    public name: string;
    // Represented person
    public firstName: string;
    public lastName: string;
    public birthDate: Date;
    public gender: Filterable = new Filterable('genderId', 'itemId');
    public nationality: Filterable = new Filterable('nationalityId', 'countryId');
    //
    public reset(): void {
        // ATTENTION
        // DO NOT RESET THE NAME  : name = undefined

        // Primitives types
        this.firstName = undefined;
        this.lastName = undefined;
        this.birthDate = undefined;
        // Objects
        this.gender.reset();
        this.nationality.reset();
    }
}
