<div style="flex-direction:column;width: 100%;">
    <kendo-upload
            style="display:flex;"
            [saveUrl]="uploadSaveUrl"
            [removeUrl]="uploadRemoveUrl"
            [multiple]="multiple"
            [restrictions]="_restrictions"
            [disabled]="disabled"
            [saveMethod]="saveMethod"
            [(ngModel)]="myFiles"
            (upload)="uploadEventHandler($event)"
            (cancel)="cancelEventHandler($event)"
            (clear)="clearEventHandler($event)"
            (success)="successEventHandler($event)"
            #kendoUploadCmp>
        <kendo-upload-messages select="Select file...">
        </kendo-upload-messages>
    </kendo-upload>
    <div *ngIf="_restrictions && _restrictions.allowedExtensions && _restrictions.allowedExtensions.length > 0"
         style="margin-top:5px; font-size:0.8em; display: block">
        <span style="margin-top:5px; margin-right:5px">Allowed file types:</span>
        <span *ngFor="let ftype of _restrictions.allowedExtensions"
              style="margin-right:5px; margin-top:5px;">*{{ftype}}</span>
    </div>
</div>
