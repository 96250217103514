import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SanctionDataService } from '@fiba/data-services';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { SanctionDTO } from '@fiba/models';
import { Observable } from 'rxjs';


@Component({
    selector: 'fibaSelectSanctionAdminOffence',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectSanctionAdminOffenceComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectSanctionAdminOffenceComponent extends FibaAjaxSelectBaseComponent<SanctionDTO> {
    constructor(protected injector: Injector, protected dataService: SanctionDataService) {
        super(injector);
        this._valueField = 'sanctionId';
        this._textField = 'code';
    }

    public getObservable(): Observable<SanctionDTO[]> {
        return this.dataService.fetchAdministrativeOffenceSanctions();
    }
}
