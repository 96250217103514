<kendo-dialog *ngIf="active" novalidate (close)="closeForm()">
    <kendo-dialog-titlebar>
        Select an event
    </kendo-dialog-titlebar>

    <event-search [(value)]="_value"
                  [filterTestEvents]="true"
                  [fromPicker]="true"></event-search>

    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event)" [disabled]="!_value">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>