import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { CompetitionDTO } from '../dtos/generated/competition-dto';
import { TeamDelegationDetailedConfigurationDTO } from '../dtos/generated/team-delegation-detailed-configuration-dto';

declare module '../dtos/generated/competition-dto' {
    export interface CompetitionDTO {
        cli_displayName: string;
    }
}

export class CompetitionFilters extends BaseFilters {
    context: string;
    keywords: string;
    seasonFrom: string;
    seasonTo: string;
    zone: FilterableArray = new FilterableArray('zoneId');
    disciplineItem: FilterableArray = new FilterableArray('disciplineItemId', 'itemId');
    typeItem: Filterable = new Filterable('typeItemId', 'itemId');
    categoryItem: FilterableArray = new FilterableArray('categoryItemId', 'itemId');
    ageCategoryItem: FilterableArray = new FilterableArray('ageCategoryItemId', 'itemId');
    officialFlagItem: Filterable = new Filterable('officialFlagItemId', 'itemId');
    competitionStatus: FilterableArray = new FilterableArray('competitionStatusId');
    genderItem: Filterable = new Filterable('genderItemId', 'itemId');
    competitionIds: number;
    reset(): void {
        this.zone.reset();
        this.typeItem.reset();
        this.officialFlagItem.reset();
        this.genderItem.reset();
        this.keywords = undefined;
        this.competitionIds = undefined;
        this.seasonTo = undefined;
        this.competitionStatus.reset();
        this.disciplineItem.reset();
        this.ageCategoryItem.reset();
        this.categoryItem.reset();
    }
}

declare module '../dtos/generated/game-structure-dto' {
    export interface GameStructureDTO {
        cli_isIncorporatedGame: boolean;
        cli_showTooltip: boolean;
        cli_showSwitchMessage: boolean;
    }
}

declare module '../dtos/generated/competition-configuration-setup-dto' {
    export interface CompetitionConfigurationSetupDTO {
        cli_teamDelegationDetailedConfiguration: TeamDelegationDetailedConfigurationDTO[];
    }
}
