export class EntityTypes {
    public static readonly AGENT_REGISTRATIONS = 'AgentRegistrations';
    public static readonly BASKETBALL_LICENSES = 'BasketballLicenses';
    public static readonly COACH_LICENSES = 'CoachLicenses';
    public static readonly COMPETITION = 'Competition';
    public static readonly COMPETITION_TEAMS = 'CompetitionTeams';
    public static readonly TECHNICAL_MEETING_COMPETITION_TEAMS = 'TechnicalMeetingCompetitionTeams';
    public static readonly LICENSE_REQUEST = 'LicenseRequests';
    public static readonly LONG_LIST_MEMBERS = 'LongListMembers';
    public static readonly OFFICIAL_LICENSE_PERIODS = 'OfficialLicensePeriods';
    public static readonly OFFICIAL_LICENSE_REGISTRATIONS = 'OfficialLicenseRegistrations';
    public static readonly ORGANISATIONS = 'Organisations';
    public static readonly PERSON_NATIONALITIES = 'PersonNationalities';
    public static readonly PERSONS = 'Persons';
    public static readonly PLAYER_LICENSES = 'PlayerLicenses';
    public static readonly SANCTION_APPLICATIONS = 'SanctionApplications';
    public static readonly TRANSFERS = 'Transfers';
    public static readonly VENUES = 'Venues';
    public static readonly UNIFORM_SETS = 'UniformSets';
    public static readonly INSURANCE_POLICY = 'InsurancePolicies';
    public static readonly PLAYER_RELEASE = 'PlayerRelease';
    public static readonly APPROVAL_CATEGORIES = 'ApprovalCategories';
    public static readonly EQUIPMENT_AGREEMENT_REPORTS = 'EquipmentAgreementReports';
    public static readonly EQUIPMENT_ADDENDUM_REPORTS = 'EquipmentAddendumReports';
    public static readonly EQUIPMENT_PRODUCT_APPLICATIONS = 'EquipmentProductApplications';
    public static readonly EQUIPMENT_PRODUCT_TESTS = 'EquipmentProductTests';
    public static readonly EQUIPMENT_APPLICANT_QUESTIONNAIRE = 'EquipmentApplicantQuestionnaire';
}
