import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';

import { ApprovalCategoryDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { Logger } from '@fiba/utils/logger';

import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { TreeItem, TreeviewConfig, TreeviewItem } from '../components/custom-ngx-treeview';
import { EquipmentApprovalCategoryTreeViewDTO } from '../../models/dtos/generated/equipment-approval-category-tree-view-dto';
import { EquipmentApprovalCategoryAccreditationListItemDTO } from '../../models/dtos/generated/equipment-approval-category-accreditation-list-item-dto';

@Component({
    selector: 'fibaMultiSelectAprovalCategoryTreeView',
    templateUrl: '../../forms/base/fiba-multiselect-treeview.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectAprovalCategoryTreeViewComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaMultiSelectAprovalCategoryTreeViewComponent extends FibaMultiSelectBaseComponent implements OnInit {
    protected config: TreeviewConfig = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasFilter: true,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 400,
    });
    private flushable = false;
    private fillable = false;
    @Input() model: EquipmentApprovalCategoryAccreditationListItemDTO[];
    @Input() disabled: boolean;
    @Input() hasAllCheckBox: boolean = true;

    constructor(protected dataService: ApprovalCategoryDataService) {
        super();
        this._valueField = 'equipmentApprovalCategoryId';
        this._textField = 'name';
        this.value = [];
    }

    get value() {
        return this._value;
    }

    set value(value: any[]) {
        const val: any[] = FibaMultiSelectAprovalCategoryTreeViewComponent.checkValueForUndefined(value);
        if (val !== this._value) {
            this._value = val;
            this.onChange(this._value);
        }

        if (value.length === 0) {
            this.flushTreeLogic();
        } else if (this.fillable) {
            this.rebuildTree(value);
            this.fillable = false;
        }

    }

    public static getComplete(): () => void {
        return () => { /**/ };
    }

    private static checkValueForUndefined(value: any[]): any[] {
        let val: any[] = value;

        if (typeof val === 'undefined') {
            val = [];
        }

        return val;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.config.hasAllCheckBox = this.hasAllCheckBox;
    }

    public getBindData(): (data: EquipmentApprovalCategoryTreeViewDTO[]) => void {
        return (data: EquipmentApprovalCategoryTreeViewDTO[]) => {
            this._data = this.$getTree(data as EquipmentApprovalCategoryTreeViewDTO[]);
        };
    }

    public getHandleError(): (err: any) => void {
        return (err: any) => {
            Logger.error(err);
        };
    }

    public getSubscription(): Subscription {
        return this.dataService.fetchTreeviewAprovalCategories()
            .subscribe(
                this.getBindData(),
                this.getHandleError(),
                FibaMultiSelectAprovalCategoryTreeViewComponent.getComplete(),
            );
    }

    // Usued in html
    // noinspection JSUnusedGlobalSymbols
    public onSelectedChange(value): void {
        this.value = value;
    }

    private flushTreeLogic(): void {
        if (this.flushable) {
            this.rebuildTree();
            this.flushable = false;
        } else {
            this.flushable = true;
        }
    }

    private rebuildTree(nfsToCheck?: EquipmentApprovalCategoryTreeViewDTO[]): void {
        this.dataService.fetchTreeviewAprovalCategories()
            .pipe(
                first(),
            )
            .subscribe((data) => {
                this._data = this.$getTree(data as EquipmentApprovalCategoryTreeViewDTO[]);
            });
    }

    private $getTree(data: EquipmentApprovalCategoryTreeViewDTO[]): Observable<TreeviewItem[]> {
        return of(this.createTree(data));
    }

    private createTree(data: EquipmentApprovalCategoryTreeViewDTO[]): TreeviewItem[] {
        const treeView: TreeviewItem[] = [];
        data.map((EquipmentApprovalCategoryTreeViewDTO) => this.fillTreeViewParentApprovalCategory(treeView, EquipmentApprovalCategoryTreeViewDTO));

        return (treeView);
    }

    private fillTreeViewParentApprovalCategory(treeView: TreeItem[], data: EquipmentApprovalCategoryTreeViewDTO): void {
        const treeItem: TreeItem = FibaMultiSelectAprovalCategoryTreeViewComponent.createTreeViewNodeForParentApprovalCategory(data);
        data.childEquipmentApprovalCategory.forEach((equipmentApprovalCategory) => {
            this.fillTreeViewChild(treeItem.children, equipmentApprovalCategory);
        });


        treeView.push(new TreeviewItem(treeItem, false, false));
    }

    private fillTreeViewChild(treeView: TreeItem[], equipmentApprovalCategory: EquipmentApprovalCategoryTreeViewDTO): void {
        const treeItem: TreeItem = this.createTreeViewNodeForApprovalCategory(equipmentApprovalCategory);
        treeView.push(new TreeviewItem(treeItem, false, false));
    }

    private static createTreeViewNodeForParentApprovalCategory(data: EquipmentApprovalCategoryTreeViewDTO): TreeItem {
        return {
            text: data.name,
            value: undefined,
            checked: false,
            children: [] as TreeItem[],
        };
    }

    private createTreeViewNodeForApprovalCategory(data: EquipmentApprovalCategoryTreeViewDTO): TreeItem {
        return {
            text: data.name,
            value: data,
            checked: this.isChecked(data),
            children: [] as TreeItem[],
        };
    }
    

    private isChecked(data: EquipmentApprovalCategoryTreeViewDTO): boolean {
        if (this.model) {
            var organisationEquipmentApprovalCategory = this.model.find(x => x.equipmentApprovalCategoryId == data.equipmentApprovalCategoryId);
            if (organisationEquipmentApprovalCategory) {
                data.organisationEquipmentApprovalCategoryId = organisationEquipmentApprovalCategory.organisationEquipmentApprovalCategoryId
                //data.organisationId = organisationEquipmentApprovalCategory.organisationId
                //data.relationTypeItemId = organisationEquipmentApprovalCategory.relationTypeItemId
                data.organisationEquipmentApprovalCategoryId = organisationEquipmentApprovalCategory.organisationEquipmentApprovalCategoryId
                return true;
            }
        }
        return false ;
    }

    
}
