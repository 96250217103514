import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgentRegistrationFormFilters, AgentRegistrationFormListItemDTO, AgentRegistrationStatusDTO, ApiListResponse, ThingDTO, NotificationDTO, AgentRegistrationFormStatusDTO } from '@fiba/models';

import { IFileData } from '@fiba/data-services';
import { AgentRegistrationFormPublicDTO, PaymentManualDTO } from '@fiba/models';
import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import { timeout, map } from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class AgentRegistrationDataService {
    protected agentRegistrationApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataAgentRegistrationStatusService: DataCachedService<AgentRegistrationStatusDTO>) {
        this.agentRegistrationApiEndpoint = `${config.agentApiEndpoint}/registrations`;
    }

    public fetchList(filters: AgentRegistrationFormFilters): Observable<ApiListResponse<AgentRegistrationFormListItemDTO>> {
        return this.http.get(this.agentRegistrationApiEndpoint, filters.getSearchParams()).pipe(map(
            (response) => response as ApiListResponse<AgentRegistrationFormListItemDTO>));
    }

    public fetchStatuses(): Observable<AgentRegistrationFormStatusDTO[]> {
        return this.dataAgentRegistrationStatusService.fetchEntities(`${this.agentRegistrationApiEndpoint}/form-statuses`);
    }

    public downloadExport(filters: AgentRegistrationFormFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.agentRegistrationApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000)); // TODO: use constant or conf value
    }

    public deleteAgentRegistration(agentRegistrationId: number): Observable<any> {
        return this.http.delete(`${this.agentRegistrationApiEndpoint}/${agentRegistrationId}/information`);
    }

    public fetchAgentRegistration(agentRegistrationId: number): Observable<AgentRegistrationFormPublicDTO> {
        return this.http.get(`${this.agentRegistrationApiEndpoint}/${agentRegistrationId}/information`);
    }

    public createAndApproveAgentRegistration(dto: AgentRegistrationFormPublicDTO): Observable<any> {
        return this.http.post(`${this.agentRegistrationApiEndpoint}/information`, dto);
    }

    public approveAgentRegistration(dto: AgentRegistrationFormPublicDTO): Observable<any> {
        return this.http.put(`${this.agentRegistrationApiEndpoint}/${dto.formId}/approve`, dto);
    }

    public requestInformationAgentRegistration(dto: AgentRegistrationFormPublicDTO): Observable<any> {
        return this.http.put(`${this.agentRegistrationApiEndpoint}/${dto.formId}/request-information`, dto);
    }

    public rejectAgentRegistration(dto: AgentRegistrationFormPublicDTO): Observable<any> {
        return this.http.put(`${this.agentRegistrationApiEndpoint}/${dto.formId}/reject`, dto);
    }
    public approveRequest(registrationId: number, notification: NotificationDTO): Observable<any> {
        return this.http.post(`${this.agentRegistrationApiEndpoint}/${registrationId}/approve`, notification);
    }

    public enterManualPayment(payment: PaymentManualDTO): Observable<any> {
        return this.http.put(`${this.agentRegistrationApiEndpoint}/${payment.businessItemId}/manual-payment`, payment);
    }

    public getMyFileFromBackend(documentId: number, pwc: boolean = false): Observable<IFileData> {
        return this.http.getFileData(`${this.agentRegistrationApiEndpoint}/public-document/${documentId}?pwc=${pwc}`);
    }
    public deleteAgentRegistrationForm(formId: number): Observable<any> {
        return this.http.delete(`${this.agentRegistrationApiEndpoint}/form/${formId}`);
    }
}
