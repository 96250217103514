<div class="site no-menu">
    <top-menu></top-menu>
    <div class="page-content anim02">
        <fibaNotificationDisplayWrapper></fibaNotificationDisplayWrapper>

        <div *ngIf="termsAndConditions">
            <h1>FIBA Information system - General terms and conditions of use</h1>
            <p class="tc-version">Version <i>{{termsAndConditions.version}}</i>, last updated
                on {{termsAndConditions.lastModificationDateTime | fibaDate}}</p>
            <div [innerHTML]="termsAndConditions.officialText" class="tc-content"></div>
            <div class="controls-container">
                <button (click)="acceptTermsAndConditions()" [disabled]="acceptRequestRunning" [primary]="true" kendoButton
                        type="button">Accept
                </button>
            </div>
        </div>
    </div>
</div>
