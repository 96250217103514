import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, FibaPartnerFilters, EquipmentFibaPartnerListItemDTO, EquipmentPaymentFilters, EquipmentPaymentListItemDTO, EquipmentPartnerCompanyDTO } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FibaPartnerDataService {
    protected equipmentFibaPartnerApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentFibaPartnerApiEndpoint = config.equipmentFibaPartnerApiEndpoint;
    }

    public fetchList(filters: FibaPartnerFilters): Observable<ApiListResponse<EquipmentFibaPartnerListItemDTO>> {
        return this.http.get(`${this.equipmentFibaPartnerApiEndpoint}/list-fiba-partner`, filters.getSearchParams());
    }

    public getUserOrganisationId(): Observable<number> {
        return this.http.get(`${this.equipmentFibaPartnerApiEndpoint}/organisation-id`);
    }

    public getPayments(filters: EquipmentPaymentFilters): Observable<ApiListResponse<EquipmentPaymentListItemDTO>> {
        return this.http.get(`${this.equipmentFibaPartnerApiEndpoint}/payments`, filters.getSearchParams());
    }

    public fetchEquipmentPartnerDetails(organisationId: number): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.get(`${this.equipmentFibaPartnerApiEndpoint}/${organisationId}`);
    }

    public updateEquipmentPartnerDetails(model: EquipmentPartnerCompanyDTO, organisationId: number): Observable<any> {
        return this.http.put(`${this.equipmentFibaPartnerApiEndpoint}/${organisationId}/details`, model);
    }

    public createEquipmentPartnerDetails(model: EquipmentPartnerCompanyDTO, organisationId: number): Observable<any> {
        return this.http.post(`${this.equipmentFibaPartnerApiEndpoint}/${organisationId}/details`, model);
    }
}
