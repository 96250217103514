import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { Logger } from '@fiba/utils/logger';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';

const APP_VERSION_HEADER_KEY = 'X-FIBA-MAP-Version';

let lastAppVersion: string;

export interface IFileData {
    blob: Blob;
    name: string;
}

export enum IHttpClientResponseType {
    array = 'arraybuffer',
    blob = 'blob',
    json = 'json',
    text = 'text',
    html = 'text/html',
}

export interface IHttpClientRequestOptions {
    headers?: HttpHeaders;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | 'text/html' | any;
    // observe?: 'body' | 'response';
    reportProgress?: boolean;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {

    public static extractFormErrorMsgsCb(response: HttpResponse<string> | HttpErrorResponse): never {

        function isNotHttpErrorResponse(response: HttpResponse<string> | HttpErrorResponse): response is HttpResponse<string> {
            return ((response as HttpErrorResponse).error === undefined);
        }

        function getErrorMessage(errors: any): any[] {
            const errorMsgs = [];
            if (errors) {
                switch (typeof (errors)) {
                    case 'string':
                        errorMsgs.push(errors);
                        break;
                    case 'object':
                        let error = '<ul>';
                        for (const key of Object.keys(errors)) {
                            if (key !== 'stackTrace') {
                                error = error + '<li>' + errors[key] + '</li>';
                                //errorMsgs.push(getErrorMessage(errors[key]));
                            }

                        }
                        error = error + '</ul>';
                        errorMsgs.push(error);
                        break;
                    case 'boolean':
                        break;
                    default: {
                        errorMsgs.push('This error mesage should not appear, report it as a bug and specify how to recreate it properly');
                        Logger.info('Add a case for:', typeof (errors));
                        break;
                    }
                }
            }
            return errorMsgs;

        }

        let errorMsgs = [];
        let errors;

        if (isNotHttpErrorResponse(response) && response.body) {
            const body = response.body;

            if (body.length > 0) {
                try {
                    errors = JSON.parse(body);
                    errorMsgs = [];
                    if (response.status === 400) {
                        for (const key in errors) {
                            errorMsgs.push(errors[key][0]);
                        }
                        Logger.warn('Bad request error from backend', errors);
                    } else {
                        errorMsgs = [errors.message + errors.exceptionMessage ? ': ' + errors.exceptionMessage : ''];
                        Logger.error('Error from backend', errors);
                    }

                } catch (e) {
                    Logger.error('Error from backend', body);
                    errorMsgs = [body];
                }
            } else {
                Logger.error('Error from backend', response);
                errorMsgs = [response.status + ': ' + response.statusText];
            }
        } else {
            if ((response as HttpErrorResponse).error) {
                errors = (response as HttpErrorResponse).error;
                errorMsgs = getErrorMessage(errors);
            } else {
                if (response.statusText) {
                    errorMsgs.push(response.statusText);
                } else {
                    errorMsgs.push('This error message should not appear, report it as a bug and specify how to recreate it properly');
                }
            }
        }

        throw errorMsgs;

    }

    public static extractErrorMsgCb(response: string): never {
        throw new Error(`Error from backend: ${JSON.stringify(response)}`);
    }

    protected static toURLSearchParams(params: { [key: string]: string | string[] }): HttpParams {
        let httpParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key];
                if (Array.isArray(value)) {
                    for (const innerValue of value) {
                        httpParams = httpParams.append(key, innerValue);
                    }
                } else {
                    httpParams = httpParams.append(key, value);
                }
            }
        }
        return httpParams;
    }

    protected static checkAppVersion(response: HttpResponse<string>): void { // TODO: use our own LocalStorage service
        const currentAppVersion = response.headers.get(APP_VERSION_HEADER_KEY);

        if (!lastAppVersion) {
            lastAppVersion = currentAppVersion;
        }

        // FIXME: Check if version > current and not !==
        if (currentAppVersion !== lastAppVersion) {
            location.reload();
        }
    }

    constructor(protected http: HttpClient, protected authService: AuthService) {

    }

    public get<T>(url: string, params?: { [key: string]: string | string[] }, responseType: IHttpClientResponseType = null): Observable<T> {
        return this._get(url, params, responseType);
    }

    public getFileData(url: string, params?: { [key: string]: string | string[] }, defaultName?: string): Observable<IFileData> {
        return this.getWithFileName(url, params, IHttpClientResponseType.blob)
            .pipe(map((res) => ({
                blob: (res as HttpResponse<any>).body,
                name: res.name || defaultName,
            })));
    }

    public post<T>(url: string, data: any, params?: { [key: string]: string | string[] }): Observable<T> {
        const req: IHttpClientRequestOptions = this.authService.getAuthHeaders();
        req.headers = req.headers.append('content-type', 'application/json');

        if (params) {
            req.params = HttpService.toURLSearchParams(params);
        }

        const body = JSON.stringify(data);

        return this.http.post(url, body, req)
            .pipe(map((response) => response as T),
                catchError((error) => HttpService.extractFormErrorMsgsCb(error)),
            );

    }

    public postForm<T>(url: string, formData: FormData): Observable<T> {
        const req = this.authService.getAuthHeaders();
        req.headers = req.headers.append('Access-Control-Allow-Origin', '*');


        return this.http.post(url, formData, req)
            .pipe(map((response) => response as T),
                catchError((error) => HttpService.extractFormErrorMsgsCb(error)),
            );
    }

    public put<T>(url: string, data: any = null, params?: { [key: string]: string | string[] }): Observable<T> {
        const req: IHttpClientRequestOptions = this.authService.getAuthHeaders();
        req.headers = req.headers.append('content-type', 'application/json');
        req.headers = req.headers.append('Access-Control-Allow-Origin', '*');

        if (params) {
            req.params = HttpService.toURLSearchParams(params);
        }

        const body = JSON.stringify(data);

        return this.http.put<T>(url, body, req).pipe(catchError((error) => HttpService.extractFormErrorMsgsCb(error)));
    }

    public delete(url: string): Observable<any> {
        const req = this.authService.getAuthHeaders();


        return this.http.delete(url, req)
            .pipe(catchError((error) => HttpService.extractFormErrorMsgsCb(error)));
    }

    protected buildOptions(params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): IHttpClientRequestOptions {

        const req: IHttpClientRequestOptions = this.authService.getAuthHeaders();
        if (params) {
            req.params = HttpService.toURLSearchParams(params);
        }
        if (responseType !== undefined) {
            req.responseType = responseType;
        }

        // https://github.com/angular/angular/issues/18586
        const options = {
            ...req,
            observe: 'response' as 'body',
        };

        return options;
    }

    protected _get<T>(url: string,
        params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): Observable<T> {
        return this.http.get(url, this.buildOptions(params, responseType))
            .pipe(
                tap(HttpService.checkAppVersion),
                map((res) => (res.body as any) as T),
                first(),
                catchError(HttpService.extractFormErrorMsgsCb),
            );
        // .do(HttpService.checkAppVersion)
        // .map((res: any) => {
        //     return res.body as T;
        // })
        // .catch(HttpService.extractFormErrorMsgsCb);
    }

    protected getWithFileName(url: string,
        params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): Observable<any> {
        return this.http.get<HttpResponse<string>>(url, this.buildOptions(params, responseType))
            .pipe(
                tap((response) => HttpService.checkAppVersion(response)),
                map((res: any) => {
                    res.name = this.getFileNameFromHttpResponse(res);
                    return res;
                }),
                catchError((error) => HttpService.extractFormErrorMsgsCb(error)));
    }

    protected getFileNameFromHttpResponse(httpResponse: any): string {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
            const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }
        return undefined;
    }

}
