import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, MapRoleDTO, UserAuthorisationsDTO, UserAuthorisationUtils, UserFilters, UserInformationDTO, UserListItemDTO, UserNationalFederationDTO, UserPersonInformationDTO, UserStatusDTO, UserTopMenuInfoDTO, UserTraceDTO, UserTraceFilters, UserDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import { UserAgentInformationDTO } from '@fiba/models/dtos/generated/user-agent-information-dto';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';
import { UserProfileDTO } from '../../models/dtos/generated/user-profile-dto';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    protected userApiEndpoint: string;
    protected userStatusApiEndpoint: string;
    protected userRolesApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataUserStatusService: DataCachedService<UserStatusDTO>) {
        this.userApiEndpoint = config.userApiEndpoint;
        this.userStatusApiEndpoint = config.userStatusApiEndpoint;
        this.userRolesApiEndpoint = config.userRolesApiEndpoint;
    }

    public fetchList(filters: UserFilters): Observable<ApiListResponse<UserListItemDTO>> {
        return this.http.get(this.userApiEndpoint, filters.getSearchParams()).pipe(map((response) => {
            const res = response as ApiListResponse<UserListItemDTO>;

            res.data.forEach((user: UserListItemDTO, i: number, array: UserListItemDTO[]) => {
                user.cli_organisationList = user.organisations.filter((v) => v !== undefined).join(', ');
            });

            return res;
        }));
    }

    public fetchUserInformation(userId: number): Observable<UserInformationDTO> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/information`).pipe(map((response) => {
            const user = response as UserInformationDTO;

            user.cli_expirationDateType = user.expirationDate ? 'endOf' : 'never';

            return user;
        }));
    }

    public fetchUserPersonInformation(userId: number): Observable<UserPersonInformationDTO> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/person-information`).pipe(map((response) => {
            const personInformation = response as UserPersonInformationDTO;

            personInformation.cli_personRolesFormatted = '';
            personInformation.personRoles.map((pr) => {
                personInformation.cli_personRolesFormatted +=
                    `${pr.organisation ? pr.organisation.displayName + ' - ' : ''} ${pr.role.name} - ${pr.role.roleType.name}\n`;
            });

            return personInformation;
        }));
    }

    public fetchUserAuthorisations(userId): Observable<UserAuthorisationsDTO> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/authorisations`).pipe(map((response) => {
            const res = response as UserAuthorisationsDTO;

            for (const ua of res.userAuthorisations) { // TODO : Refactor (Bad practice, DTOs emitted by the APIs should be usable as-is, and not transformed)
                UserAuthorisationUtils.computeDisplayFields(ua);
                UserAuthorisationUtils.arrangeIds(ua);
            }

            return res;
        }));
    }

    public fetchUserByPersonId(personId): Observable<UserDTO[]> {
        return this.http.get(`${this.userApiEndpoint}/person/${personId}`);
    }

    public fetchUserByZoneAuthorize(userId): Observable<UserDTO> {
        return this.http.get(`${this.userApiEndpoint}/user-authorize/${userId}`);
    }

    public fetchUserTraces(userId, filters: UserTraceFilters): Observable<ApiListResponse<UserTraceDTO>> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/traces`, filters.getSearchParams());
    }

    public fetchUserProfile(userId): Observable<UserProfileDTO> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/user-profile`);
    }

    public createInformation(model: UserInformationDTO): Observable<UserInformationDTO> {
        return this.http.post(this.userApiEndpoint, model);
    }

    public updateInformation(model: UserInformationDTO): Observable<any> {
        return this.http.put(`${this.userApiEndpoint}/${model.userId}`, model);
    }

    public updateCurrentUserPassword(model: string): Observable<any> {
        return this.http.put(`${this.userApiEndpoint}/change-password`, model);
    }

    public fetchStatuses(): Observable<UserStatusDTO[]> {
        return this.dataUserStatusService.fetchEntities(this.userStatusApiEndpoint);
    }

    public fetchRoles(): Observable<MapRoleDTO[]> {
        return this.http.get(this.userRolesApiEndpoint);
    }

    public fetchNationalFederation(userId): Observable<UserNationalFederationDTO> {
        return this.http.get(`${this.userApiEndpoint}/${userId}/national-federation`);
    }

    public updateUserAuthorisations(model: UserAuthorisationsDTO): Observable<any> {
        return this.http.put(this.userApiEndpoint + '/' + model.userId + '/authorisations', model);
    }

    public validateEmailFormat(email: string): boolean {
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    public copyToClipboard(password: string): void {
        window.prompt('Please, press Ctrl+C, Enter in order to copy the generated password.', password);
    }

    public generatePassword(): string {
        let password = '';
        const length = 10;

        const charsNotUpper = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>';
        const charsSpecial = '!@#$%^&*()-+<>[]{}';
        const charsUpper = 'ABCDEFGHIJKLMNOPRSTUVWXYZ';
        const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOPRSTUVWXYZ1234567890';

        password = charsNotUpper.charAt(Math.floor(Math.random() * charsNotUpper.length));
        password += charsUpper.charAt(Math.floor(Math.random() * charsUpper.length));
        password += charsSpecial.charAt(Math.floor(Math.random() * charsSpecial.length));

        for (let x = 0; password.length <= length; x++) {
            const i = Math.floor(Math.random() * chars.length);
            password += chars.charAt(i);
        }

        return password;
    }

    public selfResetPassword(): Observable<any> {
        return this.http.post(`${this.userApiEndpoint}/self-reset-password`, '');
    }
    public isCorrectMfaAccount(): Observable<boolean> {
        return this.http.get(`${this.userApiEndpoint}/mfa-account`);
    }

    public isGeneralMessage(): Observable<boolean> {
        return this.http.get(`${this.userApiEndpoint}/general-message`);
    }

    public fetchTopMenuInfo(): Observable<UserTopMenuInfoDTO> {
        return this.http.get(`${this.userApiEndpoint}/topmenu-info`);
    }
    public copyUserAuthorisations(fromUserId: number, destUserId: number): Observable<any> {
        return this.http.put(`${this.userApiEndpoint}/${destUserId}/copy-user-authorisations/${fromUserId}`);
    }
}
