import { Component, Input, Injector } from '@angular/core';

import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { FibaPopupBase } from '@fiba/forms';
import { ClubTempDTO, City, OrganisationDTO, ItemCategoryCodes } from '@fiba/models';
import { ClubDataService, ItemDataService, CountryDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaPopupClubCreator',
    templateUrl: './fiba-popup-club-creator.component.html',
})
export class FibaPopupClubCreatorComponent extends FibaPopupBase<ClubTempDTO> {
    private genderItemId: number = null;
    protected description: string;
    nationalFederation: OrganisationDTO = null;
    disabled = false;
    okButtonDisabled = false;
    selectedCity: City = null;

    public ItemCategoryCodes_ClubGender: string = ItemCategoryCodes.ClubGender;

    constructor(
        protected dataService: ClubDataService,
        protected countryDataService: CountryDataService,
        protected itemDataService: ItemDataService,
        protected notificationService: NotificationService,
        protected injector: Injector) {
        super(injector);
        this.description = 'club';
    }

    @Input() protected set organisation(value: OrganisationDTO) {
        if (value) {
            this.nationalFederation = value;
            this.disabled = true;
        } else {
            this.nationalFederation = null;
        }
    }

    @Input() protected set genderCode(value: string) {
        if (value) {
            this.getGenderItemByCode(value);
        }
    }
    @Input() public isGenderCodeReadOnly = false;

    public onModelSet(model: ClubTempDTO) {
        if (model) {
            if (!model.genderItemId) {
                model.genderItemId = this.genderItemId;
            }
        }
    }

    private getGenderItemByCode(genderCode: string): void {
        this.itemDataService.fetchItems(ItemCategoryCodes.ClubGender).subscribe(
            (items) => {
                const genderItem = items.find(i => i.code === genderCode);
                if (genderItem) {
                    this.genderItemId = genderItem.itemId;
                    if (this._model) {
                        this._model.genderItemId = this.genderItemId;
                    }
                }
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, `Error retrieving club gender for code ${genderCode}`, error);
            }
        );
    }

    handleCountrySelect(event: number) {
        if (event) {
            this.countryDataService.fetchCountryNationalFederation(event).subscribe(
                (result) => {
                    this.nationalFederation = result;
                },
                (error) => { },
                () => { }
            );
        }
        else {
            this.nationalFederation = null;
        }
    }

    closeForm(): void {
        this.nationalFederation = null;
        super.closeForm();
    }
    protected handlerValueChange(e): void {
        this._model = e;
    }

    protected onSave(e: any): void {
        e.preventDefault();
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsTouched();
        }

        if (this.ngForm.valid) {
            this.okButtonDisabled = true;
            this._model.displayName = this._model.officialName;
            if (!this._model.nationalFederationId && this.nationalFederation) {
                this._model.nationalFederationId = this.nationalFederation.organisationId;
            }
            this.dataService.createTemporaryClub(this._model).subscribe(
                response => {
                    this.createSuccess(response);

                },
                error => {
                    this.createError(error);
                    this.okButtonDisabled = false;
                },
                () => {
                    this.createComplete();
                    this.okButtonDisabled = false;
                }
            );
        }
    }

    protected createSuccess(response): void {
        this._model = response;
    }

    /**
     * Called when create API returns an error.
     *
     * @param error
     */
    protected createError(error) {
        this.notificationService.emitNotification(NotificationType.Error, `Create ${this.getDescription()} failed: ${error}`);
    }


    protected createComplete() {
        this.save.emit(this.selectedCity);
        this.active = false;
        this._model = null;

        this.notificationService.emitNotification(NotificationType.Success, `Created new ${this.getDescription()} successfully`);
    }

    protected getDescription(): string {
        return this.description ? this.description : this.model.constructor.name;
    }

    updateTimeZone(value) {
        this._model.ianatimeZone = value;
    }
}
