<div class="horizontal mergeContainer">

    <div (click)="toggleFirstHandler()"
         class="form bordered filled horizontal labels-left w40 space10 mergeSelectable"
         [ngClass]="{'selected': (value && selectionMode !== 'none' && (value.selection == SelectionFirst || value.selection == SelectionBoth))}">
        <ng-template [ngTemplateOutlet]="firstValueRef" [ngTemplateOutletContext]="{value: value}">
        </ng-template>
    </div>

    <div class="equaldiv">
        <i *ngIf="value && value.equal == true" class="fa fa-2x fa-check-circle" aria-hidden="true" [ngStyle]="{ 'color': 'darkgreen' }"></i>
        <i *ngIf="value && value.equal == false" class="fa fa-2x fa-exclamation-circle" aria-hidden="true" [ngStyle]="{ 'color': 'orange' }"></i>
    </div>

    <div (click)="toggleSecondHandler()" class="form bordered filled horizontal labels-left w40 space10 mergeSelectable"
         [ngClass]="{'selected': (value && selectionMode !== 'none' && (value.selection == SelectionSecond || value.selection == SelectionBoth))}">
        <ng-template [ngTemplateOutlet]="secondValueRef" [ngTemplateOutletContext]="{value: value}">
        </ng-template>
    </div>

    <div *ngIf="value && selectionMode !== 'none'" class="form selectionDiv" [ngClass]="{'noMargin': master}">
        <div class="horizontal space10">
            <fibaRadiobutton [name]="name"
                             *ngIf="selectionMode == 'onlyOne' || selectionMode == 'oneOrBoth'"
                             [label]="master
                                ? (isPersonMode
                                    ? ('Keep first person' + (value?.firstValue ? ' id ' + value?.firstValue : '') + ', delete second person' + (value?.secondValue ? ' id ' + value?.secondValue : ''))
                                    : (isClubMode ? ('Keep first club' + (value?.firstValue ? ' id ' + value?.firstValue : '') + ', delete second club' + (value?.secondValue ? ' id ' + value?.secondValue : '')) : 'Master 1' ))
                                : 'Only 1'"
                             [(ngModel)]="value.selection"
                             [disabled]="disableFirst"
                             [option]="0"></fibaRadiobutton>

            <fibaRadiobutton [name]="name"
                             *ngIf="selectionMode == 'onlyOne' || selectionMode == 'oneOrBoth'"
                             [label]="master
                                ? (isPersonMode
                                    ? ('Keep second person' + (value?.secondValue ? ' id ' + value?.secondValue : '') + ', delete first person' + (value?.firstValue ? ' id ' + value?.firstValue : ''))
                                    : (isClubMode ? ('Keep second club' + (value?.secondValue ? ' id ' + value?.secondValue : '') + ', delete first club' + (value?.firstValue ? ' id ' + value?.firstValue : '')) : 'Master 2' ))
                                : 'Only 2'"
                             [(ngModel)]="value.selection"
                             [disabled]="disableSecond"
                             [option]="1"></fibaRadiobutton>

            <fibaRadiobutton [name]="name"
                             label="Both"
                             *ngIf="selectionMode == 'onlyBoth' || selectionMode == 'oneOrBoth'"
                             [(ngModel)]="value.selection"
                             [option]="2"></fibaRadiobutton>
        </div>
    </div>
</div>