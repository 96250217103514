import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FinalRosterStatusDTO, FinalRosterMemberStatusDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FinalRosterDataService {
    protected finalRosterStatusApiEndpoint: string;
    protected finalRosterMemberStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataMemberStatusService: DataCachedService<FinalRosterMemberStatusDTO>,
        protected dataFinalRosterStatusService: DataCachedService<FinalRosterStatusDTO>,) {
        this.finalRosterStatusApiEndpoint = config.finalRosterStatusApiEndpoint;
        this.finalRosterMemberStatusApiEndpoint = config.finalRosterMemberStatusApiEndpoint;
    }

    fetchStatuses(): Observable<FinalRosterStatusDTO[]> {
        return this.dataFinalRosterStatusService.fetchEntities(this.finalRosterStatusApiEndpoint);
    }

    fetchMemberStatuses(): Observable<FinalRosterMemberStatusDTO[]> {
        return this.dataMemberStatusService.fetchEntities(this.finalRosterMemberStatusApiEndpoint);
    }
}
