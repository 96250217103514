import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, EquipmentTestInstituteListItemDTO, TestInstituteFilters } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';
import { EquipmentTestInstituteDTO } from '../../models/dtos/generated/equipment-test-institute-dto';

@Injectable({
    providedIn: 'root',
})
export class TestInstituteDataService {
    protected equipmentTestInstituteApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentTestInstituteApiEndpoint = config.equipmentTestInstituteApiEndpoint;
    }

    public fetchList(filters: TestInstituteFilters): Observable<ApiListResponse<EquipmentTestInstituteListItemDTO>> {
        return this.http.get(`${this.equipmentTestInstituteApiEndpoint}/list-test-institute`, filters.getSearchParams());
    }

    public fetchTestInstituteDetails(organisationId: number): Observable<EquipmentTestInstituteDTO> {
        return this.http.get(`${this.equipmentTestInstituteApiEndpoint}/${organisationId}`);
    }

    public updateTestInstituteDetails(model: EquipmentTestInstituteDTO, organisationId: number): Observable<any> {
        return this.http.put(`${this.equipmentTestInstituteApiEndpoint}/${organisationId}/details`, model);
    }

    public createTestInstituteDetails(model: EquipmentTestInstituteDTO, organisationId:number): Observable<any> {
        return this.http.post(`${this.equipmentTestInstituteApiEndpoint}/${organisationId}/details`, model);
    }
}
