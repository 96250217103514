import { Injectable } from '@angular/core';

import { HttpService } from '../utils/http.service';
import { Observable } from 'rxjs';
import { ApiListResponse, FormRegistrationGameInformationFormDetailDTO, FormRegistrationGameInformationFormListItemDTO } from '@fiba/models';

import { FormRegistrationAction } from '@fiba/models/constants/generated/form-registration-action';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';
import { FormRegistrationGameInformationFormFilters } from '../../models/form-registration-game-information-form/form-registration-game-information-form';


@Injectable({
    providedIn: 'root',
})
export class GameInformationFormRegistrationDataService {
    protected gameInformationFormRegistrationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.gameInformationFormRegistrationApiEndpoint = config.gameInformationFormRegistrationApiEndpoint;
    }

    fetchList(filters: FormRegistrationGameInformationFormFilters): Observable<ApiListResponse<FormRegistrationGameInformationFormListItemDTO>> {
        return this.http.get(this.gameInformationFormRegistrationApiEndpoint, filters.getSearchParams()).pipe(map(
            response => response as ApiListResponse<FormRegistrationGameInformationFormListItemDTO>));
    }

    fetchGameInformationFormRegistration(gameInformationFormRegistrationId: number): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.get(this.gameInformationFormRegistrationApiEndpoint + '/' + gameInformationFormRegistrationId);
    }

    fetchInitialVersion(entityItemId?: number, gameId?: number, bubbleId?: number, competitionTeamId?: number, groupPairingPlaceholderId?: number, windowId?: number, hostCountryId?: number, hostCityName?: string): Observable<FormRegistrationGameInformationFormDetailDTO> {
        const params = {};
        if (entityItemId) {
            params['entityItemId'] = entityItemId;
        }
        if (gameId) {
            params['gameId'] = gameId;
        }
        if (bubbleId) {
            params['bubbleId'] = bubbleId;
        }
        if (competitionTeamId) {
            params['competitionTeamId'] = competitionTeamId;
        }
        if (groupPairingPlaceholderId) {
            params['groupPairingPlaceholderId'] = groupPairingPlaceholderId;
        }
        if (windowId) {
            params['windowId'] = windowId;
        }
        if (hostCountryId) {
            params['hostCountryId'] = hostCountryId;
        }
        if (hostCityName) {
            params['hostCityName'] = hostCityName;
        }
        return this.http.get(this.gameInformationFormRegistrationApiEndpoint + '/initial-version', params);
    }

    createGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.post(this.gameInformationFormRegistrationApiEndpoint, model);
    }

    createSubmitGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.post(this.gameInformationFormRegistrationApiEndpoint + '?action=' + FormRegistrationAction.Submit, model);
    }

    updateGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId, model);
    }

    submitGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Submit, model);
    }

    reopenGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Reopen, model);
    }

    approveGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Approve, model);
    }

    rejectGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Rejected, model);
    }

    requestInformationGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.PendingUpdates, model);
    }

    cancelGameInformationFormRegistration(model: FormRegistrationGameInformationFormDetailDTO): Observable<FormRegistrationGameInformationFormDetailDTO> {
        return this.http.put(this.gameInformationFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Cancel, model);
    }

}
