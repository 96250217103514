import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[fibaEqualValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaEqualValidator), multi: true }
    ]
})
export class FibaEqualValidator implements Validator {
    constructor(
        @Attribute('fibaEqualValidator') public fibaEqualValidator: string,
        @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) {return false;}
        return this.reverse === 'true';
    }

    validate(me: AbstractControl): { [key: string]: any } {
        // self value
        const myValue = me.value;

        // control value
        const other = me.root.get(this.fibaEqualValidator);

        if (!myValue && (!other || !other.value)) {
            return null;
        }

        // value not equal
        if (other && myValue !== other.value && !this.isReverse) {
            return {
                fibaEqualValidator: true
            };
        }

        // value equal and reverse
        if (other && myValue === other.value && this.isReverse) {
            if (other.errors) {
                delete other.errors['fibaEqualValidator'];
                if (!Object.keys(other.errors).length) {
                    other.setErrors(null);
                }
            }
        }

        // value not equal and reverse
        if (other && myValue !== other.value && this.isReverse) {
            other.setErrors({ fibaEqualValidator: true });
        }

        return null;
    }
}
