import { Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'fibaWysiwyg',
    templateUrl: './fiba-wysiwyg.component.html',
    styleUrls: ['./fiba-wysiwyg.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaWysiwygComponent),
            multi: true,
        },
    ],
})
export class FibaWysiwygComponent{
    @Input() public value: string;
    @Input() public disabled: boolean = false;
    @Output() public valueChange: EventEmitter<number> = new EventEmitter();

    constructor(protected injector: Injector) {
       
    }

    public valueChangeHandler(value: any) {
        this.valueChange.emit(value);
    }
}
