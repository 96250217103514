import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ItemDataService {
    protected apiEndpoint: string;

    constructor(
        protected dataService: DataCachedService<ItemDTO>,
        protected config: AppConfig,
        private http: HttpService) {
        this.apiEndpoint = config.itemApiEndpoint;
    }

    fetchItems(categoryCode: string, parentCode?: string, clearCache?: boolean, includeActiveInactive: boolean = false): Observable<ItemDTO[]> {
        if (clearCache)
            this.dataService.clearCache();

        return this.dataService.fetchEntities(this.apiEndpoint + '/' + categoryCode + '?includeActiveInactive=' + includeActiveInactive, categoryCode);
    }

    fetchGameInformationFormTypeItems(): Observable<ItemDTO[]> {
        return this.dataService.fetchEntities(this.apiEndpoint + '/game-information-form-types');
    }
    
    fetchInvestigationItems(): Observable<ItemDTO[]> {
        return this.dataService.fetchEntities(this.apiEndpoint + '/investigation-items');
    }

    fetchItemByCategoryAndCode(categoryCode: string, itemCode: string): Observable<ItemDTO> {
        return this.dataService.fetchEntity(this.apiEndpoint + '/' + categoryCode + '/item-code/' + itemCode, categoryCode);
    }

    fetchItem(itemId: number): Observable<ItemDTO> {
        return this.http.get(`${this.apiEndpoint}` + '/configuration/' + itemId);
    }

    updateItem(itemId: number, itemDto: ItemDTO): Observable<any> {
        return this.http.put(`${this.apiEndpoint}` + '/configuration/' + itemId, itemDto);
    }

    createItem(itemDto: ItemDTO): Observable<ItemDTO> {
        return this.http.post(this.apiEndpoint + '/configuration/', itemDto);
    }

    fetchTemplateEntityItems(): Observable<ItemDTO[]> {
        return this.http.get(this.apiEndpoint + '/template-entities');
    }
}
