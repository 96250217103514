import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { Observable } from 'rxjs';
import { FormRegistrationWithDataToPushDTO } from '../../models/dtos/generated/form-registration-with-data-to-push-dto';
import { HttpService } from '../utils/http.service';


@Injectable({
    providedIn: 'root',
})
export class FormRegistrationCopyToEntityDataService {
    
    protected formRegistrationBaseApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.formRegistrationBaseApiEndpoint = config.formRegistrationBaseApiEndpoint;
    }


    fetchFormRegistrationDataToPush(formRegistrationId: number): Observable<FormRegistrationWithDataToPushDTO> {
        return this.http.get(this.formRegistrationBaseApiEndpoint + '/' + formRegistrationId + '/data-to-push');
    }
    pushDataUpdate(model: FormRegistrationWithDataToPushDTO): Observable<FormRegistrationWithDataToPushDTO> {
        return this.http.put(this.formRegistrationBaseApiEndpoint + '/' + model.formRegistrationId + '/data-to-push', model);
    }

}
