import { Component, EventEmitter, forwardRef, Injector, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { CompetitionDataService } from '@fiba/data-services';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ThingDTO } from '@fiba/models';
import { CompetitionStandingHeadersAdminDataService } from '../../../data-services/competition/standing-headers-admin-data.service';

@Component({
    selector: 'fibaSelectCompetition',
    templateUrl: '../../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectCompetitionComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectCompetitionComponent extends FibaAjaxSelectBaseComponent<ThingDTO> {

    @Output() public valueChange: EventEmitter<string> = new EventEmitter();

    constructor(protected injector: Injector, protected dataService: CompetitionStandingHeadersAdminDataService) {
        super(injector);
        this._valueField = 'name';
        this._textField = 'name';
    }

    public getBindData(): (data: ThingDTO[]) => void {
        return (data: ThingDTO[]) => {
            this._originalItems = data.map((item) => {
                return item;
            });
            this._filteredItems = data.slice(0);
        };
    }

    protected ngOnChanges(event): void {
        this.valueChange.emit(event);
    }

    public getObservable(): Observable<ThingDTO[]> {
        return this.dataService.fetchCompetitionsCodes();
    }
}
