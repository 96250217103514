import { Component, Input, Output, EventEmitter, Injector } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';

import { FibaPopupBase } from '@fiba/forms';
import { ThingDTO } from '@fiba/models';

@Component({
    selector: 'fibaPopupCompetition',
    templateUrl: './fiba-popup-competition.component.html',
})
export class FibaPopupCompetitionComponent extends FibaPopupBase<ThingDTO> {
    constructor(protected injector: Injector)
    {
        super(injector);
    }

    _value: ThingDTO = null;

    protected onSave(e): void {
        this._model = this._value;
        super.onSave(e);
    }

    protected handlerValueChange(e): void {
        this._model = e;
    }
}
