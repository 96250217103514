<button type="button"
        (click)="open()"
        kendoButton
        [primary]="true">
    Import Games plan
</button>

<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        Upload games structure file
    </kendo-dialog-titlebar>

    <kendo-upload style="display:flex;"
                  [saveUrl]="uploadSaveUrl"
                  [saveHeaders]="uploadSaveheaders"
                  [multiple]="false"
                  [autoUpload]="false"
                  [restrictions]="_restrictions"
                  [saveMethod]="saveMethod"
                  (upload)="uploadEventHandler($event)"
                  (success)="successEventHandler($event)"
                  (error)="errorEventHandler($event)"
                  (cancel)="cancelEventHandler($event)"
                  (clear)="clearEventHandler($event)"
                  (complete)="completeEventHandler($event)">
    </kendo-upload>

    <div *ngIf="_restrictions && _restrictions.allowedExtensions && _restrictions.allowedExtensions.length > 0" style="margin-top:5px; font-size:0.8em; display: block">
        <span style="margin-top:5px; margin-right:5px">Allowed file types:</span>
        <span *ngFor="let ftype of _restrictions.allowedExtensions" style="margin-right:5px; margin-top:5px;">*.{{ftype}}</span>
    </div>

    <div *ngIf="errorMessage">
        <span style="font-weight: bold; color: red">{{ errorMessage }}</span>
    </div>

    <kendo-dialog-actions>
        <button [disabled]="uploadInProgress" type="button" class="k-button" (click)="onCancel($event)">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>