<kendo-dialog *ngIf="active" (close)="closeForm()" novalidate>
    <kendo-dialog-titlebar>
        Select a competition
    </kendo-dialog-titlebar>

    <competition-search-mini [(value)]="_value">
    </competition-search-mini>

    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event, editForm)" [disabled]="!_value">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>