<kendo-dialog *ngIf="active" (close)="closeForm()" novalidate>
    <kendo-dialog-titlebar>
        Select a national federation
    </kendo-dialog-titlebar>

    <national-federation-search-mini [contextCode]="contextCode" [zone]="_zone" [(value)]="_value">
    </national-federation-search-mini>

    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event)" [disabled]="!_value">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>