import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClubDTO, ClubListItemDTO, ClubFilters, ApiListResponse, ThingDTO, ClubStatusDTO, OrganisationDTO, ZoneDTO, ClubDetailsDTO, ClubTempDTO, CompetitionDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import * as moment from 'moment';
import { AppConfig } from '@app/app.config';
import { MergeClubsDTO } from '../../models/dtos/generated/merge-clubs-dto';

@Injectable({
    providedIn: 'root',
})
export class ClubDataService {
    protected clubApiEndpoint: string;
    protected clubAutocompleteApiEndpoint: string;
    protected clubStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataClubStatusService: DataCachedService<ClubStatusDTO>) {
        this.clubApiEndpoint = config.clubApiEndpoint;
        this.clubAutocompleteApiEndpoint = config.clubAutocompleteApiEndpoint;
        this.clubStatusApiEndpoint = config.clubStatusApiEndpoint;
    }

    fetchList(filters: ClubFilters): Observable<ApiListResponse<ClubListItemDTO>> {
        return this.http.get(this.clubApiEndpoint, filters.getSearchParams());
    }

    fetchClub(clubId: number, nameHistoryYear: number = null): Observable<ClubDTO> {
        return this.http.get(`${this.clubApiEndpoint}/${clubId}` + (nameHistoryYear ? ("?nameHistoryYear=" + nameHistoryYear) : ""));
    }

    fetchClubDetails(organisationId: number): Observable<ClubDetailsDTO> {
        return this.http.get(`${this.clubApiEndpoint}/${organisationId}/details`);
    }

    fetchClubCompetitions(organisationId: number): Observable<CompetitionDTO[]> {
        return this.http.get(`${this.clubApiEndpoint}/${organisationId}/competitions`);
    }

    fetchStatuses(): Observable<ClubStatusDTO[]> {
        return this.dataClubStatusService.fetchEntities(this.clubStatusApiEndpoint);
    }

    fetchAutoCompleteList(
        name: string = '',
        organisation: OrganisationDTO = null,
        zone: ZoneDTO = null,
        licenseCategoryCode: string = null,
        season: number = null,
        genderCode: string = null,
        includeAllStatuses: boolean = false): Observable<ThingDTO[]> {
        const params = { name };
        if (organisation) {
            params['organisationId'] = String(organisation.organisationId);
        }
        if (zone) {
            params['zoneId'] = String(zone.zoneId);
        }
        if (licenseCategoryCode) {
            params['licenseCategoryCode'] = licenseCategoryCode;
        }
        if (season) {
            params['season'] = String(season);
        }
        if (genderCode) {
            params['genderCode'] = genderCode;
        }
        params['includeAllStatuses'] = includeAllStatuses;

        return this.http.get(this.clubAutocompleteApiEndpoint, params);
    }

    updateClubDetails(model: ClubDetailsDTO): Observable<any> {
        return this.http.put(`${this.clubApiEndpoint}/${model.organisationId}/details`, model);
    }

    downloadExport(filters: ClubFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.clubApiEndpoint}/export-csv`, filters.getSearchParams());
    }

    createTemporaryClub(model: ClubTempDTO): Observable<ClubTempDTO> {
        return this.http.post(this.clubApiEndpoint, model);
    }

    fetchClubHasBanOnPeriod(clubId: number, fromDate: Date, toDate: Date): Observable<boolean> {
        const params = {};
        if (fromDate) {
            params['fromDate'] = moment(fromDate).format('YYYY-MM-DD');;
        }
        if (toDate) {
            params['toDate'] = moment(toDate).format('YYYY-MM-DD');;
        }
        return this.http.get(`${this.clubApiEndpoint}/${clubId}/has-ban-on-period`, params);
    }

    mergeClubs(model: MergeClubsDTO): Observable<number> {
        return this.http.put(`${this.clubApiEndpoint}/merges`, model);
    }
    fetchClubsToMerge(firstClubId: number, secondClubId: number): Observable<MergeClubsDTO> {
        return this.http.get(`${this.clubApiEndpoint}/merges/?firstClubId=${firstClubId}&secondClubId=${secondClubId}`);
    }


}
