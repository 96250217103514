import { Pipe, PipeTransform } from '@angular/core';
import { PersonDTO } from '@fiba/models';

@Pipe({ name: 'fibaPersonName' })
export class FibaPersonNamePipe implements PipeTransform {
    transform(person: PersonDTO): string {
        if (!person) {
            return '';
        }
        let formattedLastName = '';
        if (person.lastName && person.lastName.length > 0) {
            formattedLastName = person.lastName.toLocaleUpperCase() + ', ';
        }

        return formattedLastName + person.firstName;
    }
}
