import { Component, Input } from '@angular/core';

@Component({
    selector: 'fibaTextNumberToTime',
    templateUrl: './fiba-text-number-to-time.component.html',
})
export class FibaTextNumberToTimeComponent {
    @Input() public label: string;

    @Input() set hours(value: number) {

        if (value != null && value != undefined) {
            value %= 24;
            const hoursOnly: number = Math.floor(Math.abs(value));
            const minutesOnly: number = Math.floor((Math.abs(value) - hoursOnly) * 60);
            this.timeText = (value >= 0 ? '+' : '-') + hoursOnly.toLocaleString('en', { minimumIntegerDigits: 1 }) + ':' + minutesOnly.toLocaleString('en', { minimumIntegerDigits: 2 });
        } else {
            this.timeText = '';
        }
    }

    public timeText = '';

    constructor() {
    }

}
