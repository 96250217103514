import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { Observable, Subscription } from 'rxjs';
import { Logger } from '@fiba/utils/logger';
import { FibaInputBase } from './fiba-input-base';

@Directive()
export abstract class FibaSelectTreeViewBaseComponent<T> extends FibaInputBase<T> implements OnInit {
    public _textField: string;
    public _valueField: string;
    public _filteredItems: T[];
    public _childrenField: string;
    public isClosed: boolean = true;
    @Input() public type = 'filter'; // filter or input
    @Input() public valuePrimitive: boolean = undefined; // Use int (true) or object (false)
    @Input() public placeholder: string;
    protected _originalItems: T[];
    @Input() protected modelId: number;

    @Output() protected change: EventEmitter<any> = new EventEmitter();
    @Output() protected modelIdChange: EventEmitter<any> = new EventEmitter();

    public ngOnInit(): void {
        super.ngOnInit();

        if (!this.placeholder) {
            this.placeholder = this.type === 'filter' ? 'All' : 'Type or select';
        }

        if (this.valuePrimitive == undefined) {
            this.valuePrimitive = this.type !== 'filter';
        }
    }

    public handleFilter(value): void {
        this.onTouched();
        this.handleRestrictedItems();
        this.handleFilterItems(value);
    }

    public handleValue(value): void {
        this.isClosed = true;
        let data: any[] = this._filteredItems;
        let result: any = this.valuePrimitive ? data.find(element => element.itemId === value) : data.find(element => element.itemId === value.itemId);
        if ((this.valuePrimitive && result != undefined && result.items.length > 0) || (!this.valuePrimitive && value != undefined && value.items?.length > 0)) {
            this.value = undefined;
            this._value = undefined;
            this.name = undefined;
            this.isClosed = false;
        }
        else {

            this.manualChange();
            this.onTouched();
            if (!value || value.length === 0) {
                this.handleRestrictedItems();
            }

            if (!this.valuePrimitive) { // If we manage objects, copy the object id in modelId and emit it
                this.modelId = value ? value[this._valueField] : undefined;
                this.modelIdChange.emit(this.modelId);
            }
            this.change.emit(value);
        }
    }


    public handleClose(event): void {
        if (!this.isClosed) {
            event.preventDefault();
        }
    }

    public manualChange(): void {
        this.onChange(this.value);
    }

    protected handleRestrictedItems(): void {
        this._filteredItems = this._originalItems;
    }

    private handleFilterItems(value: string): void {
        if (this._filteredItems) {
            if (!this._textField) {
                this._filteredItems = this._filteredItems.filter((s) => String(s).toLowerCase().indexOf(value.toLowerCase()) !== -1);
            } else {
                this._filteredItems = this._filteredItems.filter((s) => String(s[this._textField]).toLowerCase().indexOf(value.toLowerCase()) !== -1);
            }
        }
    }
}

@Directive()
export abstract class FibaAjaxSelectTreeViewBaseComponent<T> extends FibaSelectTreeViewBaseComponent<T> implements OnInit, OnDestroy {
    @Output() public dataBound: EventEmitter<any> = new EventEmitter();
    protected subscription: Subscription;
    @Input() protected initializedDefaultValueCreation = false;

    public ngOnInit(): void {
        super.ngOnInit();
        this.getSubscription();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getBindData(): (data: T[]) => void {
        return (data: T[]) => {
            this._originalItems = data; // TODO: if T has a displayOrder, use it to order
            this.handleRestrictedItems();
            if (this.initializedDefaultValueCreation) {
                this.value = this.getDefaultValueCreation();
            }
        };
    }

    public getDefaultValueCreation(): number | T {
        return undefined;
    }

    public getHandleError(): (err: any) => void {
        return (err: any) => {
            Logger.error(err);
        };
    }

    public getComplete(): () => void {
        return () => {
            this.dataBound.emit();
        };
    }

    public abstract getObservable(): Observable<T[]>;

    protected getSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.getObservable().subscribe(
            this.getBindData(),
            this.getHandleError(),
            this.getComplete()
        );
    }

    protected handleRestrictedItems(): void {
        if (this._originalItems) {
            this._filteredItems = this._originalItems.slice(0);
        }
    }
}
