import { BaseFilters, Filterable } from '../common/base.filters';

export class EquipmentPaymentFilters extends BaseFilters {
    paymentId: number;
    paymentKey: string;
    amount: number;
    paymentStatus: Filterable = new Filterable('paymentStatusId');

    reset(): void {
        delete this.paymentId;
        delete this.amount;
        delete this.paymentKey;
        this.paymentStatus.reset();
    }
}


