import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SanctionDataService } from '@fiba/data-services';
import { FibaPickerBase } from '@fiba/forms/base/fiba-picker-base';
import { SanctionDTO, ThingDTO } from '@fiba/models';
import { NotificationService } from '@fiba/utils/notification.service';
import {ComboBoxComponent} from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';



@Component({
    selector: 'fibaPickerSanction',
    templateUrl: './fiba-picker-sanction.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaPickerSanctionComponent),
            multi: true,
        },
        SanctionDataService,
    ],
    host: { class: 'fiba-input' },
})
export class FibaPickerSanctionComponent extends FibaPickerBase<SanctionDTO> implements OnInit {
    @ViewChild('pickerCombobox', {static: true}) public comboBox: ComboBoxComponent;
    public listItems: SanctionDTO[] = [];
    @Input() protected delay = 300;

    constructor(protected injector: Injector,
                protected sanctionDataService: SanctionDataService,
                protected notificationService: NotificationService) {
        super(injector);
        this.idField = 'sanctionId';
    }

    public ngOnInit() {
        super.ngOnInit();

        this.comboBox.filterChange.pipe(
            debounceTime(this.delay),
            switchMap((searchValue) => this.fetchAutocompleteObservable(searchValue))
        )
            .subscribe((result) => {
                this.listItems = result;
            });
    }

    public initFilterChange(): void {
        this.comboBox.filterChange.pipe(debounceTime(this.delay))
            .subscribe(
                (searchValue) => {
                    this.filterList(searchValue);
                });
    }

    protected filterList(value: string = null) {
        this.fetchAutocompleteObservable(value).subscribe(
            (data) => {
                this.listItems = data;
            },
        );
    }

    private fetchAutocompleteObservable(value: string = null): Observable<SanctionDTO[]> {
        return this.sanctionDataService.fetchAutoCompleteList(value);
    }

    public valueChanged(val: SanctionDTO): void {
        if (val) {
            this.listItems = [val];
            this._value = val;
            this.modelChange.emit(val);
            this.modelIdChange.emit(val.sanctionId);
        }
    }
}
