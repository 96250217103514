import * as _ from 'lodash';

export interface TreeviewSelection {
    checkedItems: TreeviewItem[];
    uncheckedItems: TreeviewItem[];
}

export interface TreeItem {
    text: string;
    value: any;
    disabled?: boolean;
    checked?: boolean;
    collapsed?: boolean;
    children?: TreeItem[];
    isTopLevelParentWithChildZones?: boolean;
}

export class TreeviewItem {
    public internalChildren: TreeviewItem[];
    public text: string;
    public value: any;
    public isTopLevelParentWithChildZones: boolean;

    private internalDisabled = false;
    private internalChecked = true;
    private internalCollapsed = false;
    private readonly parentInSelection: boolean = true;

    // Forcing parent check logic
    private readonly parentCanBeSelectedAlone: boolean = false;
    private readonly itemCheck: boolean = false;

    constructor(item: TreeItem, autoCorrectChecked = false, parentInSelection: boolean = true, parentCanBeSelectedAlone: boolean = false) {

        if (_.isNil(item)) {
            throw new Error('Item must be defined');
        }
        if (_.isString(item.text)) {
            this.text = item.text;
        } else {
            throw new Error('A text of item must be string object');
        }

        this.parentCanBeSelectedAlone = parentCanBeSelectedAlone;
        this.itemCheck = item.checked;

        this.value = item.value;
        if (_.isBoolean(item.checked)) {
            this.checked = item.checked;
        }
        if (_.isBoolean(item.collapsed)) {
            this.collapsed = item.collapsed;
        }
        if (_.isBoolean(item.disabled)) {
            this.disabled = item.disabled;
        }
        if (_.isBoolean(item.isTopLevelParentWithChildZones)) {
            this.isTopLevelParentWithChildZones = item.isTopLevelParentWithChildZones;
        }
        if (!_.isNil(item.children) && item.children.length > 0) {
            this.children = item.children.map((child) => {
                if (this.disabled === true) {
                    child.disabled = true;
                }

                return new TreeviewItem(child, autoCorrectChecked, parentInSelection, parentCanBeSelectedAlone);
            });
        }

        if (autoCorrectChecked) {
            this.correctChecked();
        }

        this.parentInSelection = parentInSelection;
    }

    get checked(): boolean {
        return this.internalChecked;
    }

    set checked(value: boolean) {
        if (!this.internalDisabled) {
            if (this.internalChecked !== value) {
                this.internalChecked = value;
            }
        }
    }

    get indeterminate(): boolean {
        return this.checked === undefined;
    }

    public setCheckedRecursive(value: boolean) {
        if (!this.internalDisabled) {
            this.internalChecked = value;
            if (!_.isNil(this.internalChildren)) {
                this.internalChildren.forEach((child) => child.setCheckedRecursive(value));
            }
        }
    }

    get disabled(): boolean {
        return this.internalDisabled;
    }

    set disabled(value: boolean) {
        if (this.internalDisabled !== value) {
            this.internalDisabled = value;
            if (!_.isNil(this.internalChildren)) {
                this.internalChildren.forEach((child) => child.disabled = value);
            }
        }
    }

    get collapsed(): boolean {
        return this.internalCollapsed;
    }

    set collapsed(value: boolean) {
        if (this.internalCollapsed !== value) {
            this.internalCollapsed = value;
        }
    }

    public setCollapsedRecursive(value: boolean) {
        this.internalCollapsed = value;
        if (!_.isNil(this.internalChildren)) {
            this.internalChildren.forEach((child) => child.setCollapsedRecursive(value));
        }
    }

    get children(): TreeviewItem[] {
        return this.internalChildren;
    }

    set children(value: TreeviewItem[]) {
        if (this.internalChildren !== value) {
            if (!_.isNil(value) && value.length === 0) {
                throw new Error('Children must be not an empty array');
            }
            this.internalChildren = value;
            if (!_.isNil(this.internalChildren)) {
                let checked = null;
                this.internalChildren.forEach((child) => {
                    if (checked === null) {
                        checked = child.checked;
                    } else {
                        if (child.checked !== checked) {
                            checked = undefined;
                            return;
                        }
                    }
                });

                // CUSTOM BLOCK OF CODE By pass standard check
                if (this.parentCanBeSelectedAlone) {
                    this.internalChecked = this.itemCheck;
                } else {
                    this.internalChecked = checked;
                }
            }
        }
    }

    public getSelection(): TreeviewSelection {
        let checkedItems: TreeviewItem[] = [];
        let uncheckedItems: TreeviewItem[] = [];

        if (_.isNil(this.internalChildren)) {
            if (this.internalChecked) {
                checkedItems.push(this);
            } else {
                uncheckedItems.push(this);
            }
        } else {
            if (!!this.isTopLevelParentWithChildZones && this.internalChecked) {
                checkedItems.push(this);
            }
            
            for (const child of this.internalChildren) {

                // CUSTOM BLOCK OF CODE That force logic for MultiSelectRole
                if (this.parentInSelection) {
                    if (child.internalChildren) {
                        if (child.checked) {
                            checkedItems.push(child);
                        } else {
                            uncheckedItems.push(child);
                        }
                    }
                }

                const selection = child.getSelection();
                checkedItems = _.concat(checkedItems, selection.checkedItems);
                uncheckedItems = _.concat(uncheckedItems, selection.uncheckedItems);
            }
        }

        return {
            checkedItems,
            uncheckedItems,
        };
    }

    public correctChecked() {
        this.internalChecked = this.getCorrectChecked();
    }

    private getCorrectChecked(): boolean {
        let checked: boolean = null;
        if (!_.isNil(this.internalChildren)) {
            for (const child of this.internalChildren) {
                child.internalChecked = child.getCorrectChecked();
                if (checked === null) {
                    checked = child.internalChecked;
                } else if (checked !== child.internalChecked) {
                    checked = undefined;
                    break;
                }
            }
        } else {
            checked = this.checked;
        }

        return checked;
    }
}
