import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormRegistrationSiteVisitListItemDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';
import { CompetitionWithWindowsDTO } from '../../models/dtos/generated/competition-with-windows-dto';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionTechnicalDelegateDashboardDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCurrentUserTechnicalDelegateDashboardSiteVisitFormRegistrations(): Observable<FormRegistrationSiteVisitListItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/current-user/technical-delegate-dashboard-site-visit-form-registrations`);
    }


}
