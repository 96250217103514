export class SortUtils {
    public static sortByThenBy<T>(a: T, b: T, mainSortValueResolver: (x: T) => number, secondarySortValueResolver: (x: T) => number)
    {
        const mainValueA = mainSortValueResolver(a);
        const mainValueB = mainSortValueResolver(b);
        const secondaryValueA = secondarySortValueResolver(a);
        const secondaryValueB = secondarySortValueResolver(b);

        if (mainValueA === mainValueB) {
            return secondaryValueA < secondaryValueB ? -1 : (secondaryValueA > secondaryValueB) ? 1 : 0;
        } else {
            return mainValueA < mainValueB? -1 : (mainValueA > mainValueB) ? 1 : 0;
        }
    }
}
