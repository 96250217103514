<ng-template [ngIf]="model">
    <div kendoTooltip
         [tooltipTemplate]="template"
         [position]="position"
         filter="i">
        <i class="fa fa-fw" [ngClass]="icon" aria-hidden="true"></i>
    </div>
    <ng-template #template let-anchor>
        <div class="tooltip-mw400">
            <div [hidden]="!model">
                <strong *ngIf="!displayInnerHtml">{{ model }}</strong>
                <strong *ngIf="displayInnerHtml" [innerHTML]="model"></strong>
            </div>
        </div>
    </ng-template>
</ng-template>

