import { Component, forwardRef, Injector, Input, OnInit  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ItemDataService, OfficialLicenseRequestDataService, SanctionDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { Logger } from '@fiba/utils/logger';

@Component({
    selector: 'fibaMultiSelectGameOfficialRole',
    templateUrl: '../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectGameOfficialRoleComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaMultiSelectGameOfficialRoleComponent extends FibaMultiSelectBaseComponent {

    @Input() protected officialLicensePeriodId;
    constructor(protected dataService: OfficialLicenseRequestDataService) {
        super();
        this._textField = 'role.name';
        this._valueField = 'role.roleId';
        this.value = [];
    }

    public getSubscription(): Subscription {
        return this.dataService.getRoleForOfficialLicensePeriod(this.officialLicensePeriodId).subscribe(
            (data) => {
                this.items = data;
                this._data = data.slice(0);
            },
            (err) => {
                Logger.error(err);
            },
            () => { },
        );
    }

}
