import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core.module';
import { AuthGuard, AuthGuardNonSpecialCases, AuthGuardWithToken, AuthService } from '@fiba/data-services';

import { NotificationService } from '@fiba/utils/notification.service';
import { AppComponent } from './app.component';
import { HttpInterceptorProviders } from '../@fiba/data-services/utils/interceptos';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        CoreModule,
    ],
    providers: [
        AuthService,
        NotificationService,
        AuthGuard,
        AuthGuardNonSpecialCases,
        AuthGuardWithToken,
        HttpInterceptorProviders
    ],
})
export class AppModule {
}
