import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@fiba/data-services';

export interface TabListItem {
    name: string;
    route?: string;
    order: number;
    icon?: string;
    selected?: boolean;
    active?: boolean;
    children?: TabListItem[];
    displayChildrenOnNextLine?: boolean;
}

@Component({
    selector: 'fibaTabList',
    templateUrl: './tablist.component.html',
    styleUrls: ['./tablist.component.css'],
})
export class TabListComponent {
    public _tabs: TabListItem[];
    public _subTabs: TabListItem[];
    protected tabsTmp: TabListItem[] = [];
    protected route: string; // last segment of current route

    @Input()
    protected set tabs(tabs: TabListItem[]) {
        if (this.activatedRoute.firstChild) {
            this.route = this.activatedRoute.firstChild.routeConfig.path;
        } else {
            this.route = null;
        }
        this.processTabs(tabs);
    }

    constructor(
        protected authService: AuthService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router) { }

    private static insertTabListItem(item: TabListItem, tabs: TabListItem[]): void {
        let i;
        for (i = 0; i < tabs.length; i++) {
            if (tabs[i].order > item.order) {
                break;
            }
        }
        tabs.splice(i, 0, item);
    }

    // Check if tab is authorized, and if so, add it to targetTabs
    private getAuthorizedTab(tab: TabListItem): Promise<TabListItem> {
        if (tab.route) { // Tab has a route => no children
            return this.authService.isNavigationAuthorized(tab.route, this.activatedRoute)
                .then((authorized) => {
                    if (authorized) {
                        return tab;
                    } else {
                        return null;
                    }
                });
        } else if (tab.children) { // Tab has children
            const promises = [] as Array<Promise<TabListItem>>;
            for (const subTab of tab.children) {
                promises.push(this.getAuthorizedTab(subTab));
            }
            let listSubTabs = [];
            return Promise.all(promises).then((subTabs) => {
                const tabsTmp = [] as TabListItem[];
                let selected = false;
                for (const subTab of subTabs) {
                    if (!!subTab) {
                        listSubTabs.push(subTab);
                        TabListComponent.insertTabListItem(subTab, tabsTmp);
                        if (subTab.route === this.route) {
                            selected = true;
                        }
                    }
                }
                if (tabsTmp.length > 0) {
                    if (selected && tab) {
                        this._subTabs = tab.displayChildrenOnNextLine ? listSubTabs : null;
                    }
                    return {
                        name: tab.name,
                        order: tab.order,
                        children: tabsTmp,
                        selected,
                        active: selected,
                        displayChildrenOnNextLine: tab.displayChildrenOnNextLine
                    } as TabListItem;
                } else {
                    return null;
                }
            });
        }
    }
    
    protected processTabs(tabs: TabListItem[]): void {
        this._tabs = [];
        for (const tab of tabs) {
            this.getAuthorizedTab(tab).then((tabListItem) => {
                if (!!tabListItem) {
                    TabListComponent.insertTabListItem(tabListItem, this._tabs);
                }
            });
        }
    }

    openParent(tabName: string): void {
        for (const tab of this._tabs) {
            tab.selected = (tab.name === tabName);
        }
        let tab = this._tabs.find(x => x.name == tabName)
        this._subTabs = tab.displayChildrenOnNextLine ? tab.children : null;
    }

    handleChildTabChange(tabName: string): void {
        this._tabs.map(x => { x.active = false });
        this._subTabs = null;
    }

    switchActiveParent(tabName: string): void {
        for (const tab of this._tabs) {
            tab.active = (tab.name === tabName);
        }
    }

    public getSelectedParentTabName() {
        return (this._tabs.find(x => x.selected)).name;
    }
}
