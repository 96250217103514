import {Component, Input, OnInit} from '@angular/core';
import {User} from 'oidc-client';

import {DialogAction} from '@progress/kendo-angular-dialog';

import {AuthService, UserDataService} from '@fiba/data-services';
import {UserTopMenuInfoDTO} from '@fiba/models';
import {DialogActionService} from '@fiba/utils/dialog-action.service';
import {NotificationService, NotificationType} from '@fiba/utils/notification.service';
import {Logger} from '@fiba/utils/logger';
import {filter, first, flatMap} from 'rxjs/operators';
import { Router } from '@angular/router';

const RESET_PASSWORD = 'resetPassword';
const LOGOUT = 'logout';

const CHANGE_PASSWORD_CONFIRMATION_TITLE = 'Confirmation required';
const CHANGE_PASSWORD_CONFIRMATION_MESSAGE =
    'You will be logged out in order to change your password : do you want to continue ?';

interface IDropdownUserMenuItem {
    text: string;
    name: string;
    showCondition: () => boolean;
}

@Component({
    selector: 'userDropdownMenu',
    templateUrl: './user-dropdown-menu.component.html',
    styleUrls: ['./user-dropdown-menu.component.css'],
})
export class UserDropdownMenuComponent implements OnInit {
    @Input() public user: User;
    public menuItems: IDropdownUserMenuItem[] = [];
    @Input() private userInfo: UserTopMenuInfoDTO;
    private readonly allMenuItems: IDropdownUserMenuItem[] = [
        {
            text: 'Logout',
            name: LOGOUT,
            showCondition: () => false,  // removed as per #2856
        },
        {
            text: 'Change password', // #2856
            name: RESET_PASSWORD,
            showCondition: () => this.userInfo && this.userInfo.canChangePassword,
        },
    ];

    constructor(
        protected authService: AuthService,
        protected userDataService: UserDataService,
        protected router: Router,
        protected notificationService: NotificationService,
        protected dialogActionService: DialogActionService,) {
    }

    public ngOnInit(): void {
        this.menuItems = this.allMenuItems.filter((item) => item.showCondition());
    }

    // noinspection JSUnusedGlobalSymbols
    public menuItemClickHandler(item): void {
        if (item) {
            if (item.name === RESET_PASSWORD) {
                this.resetPassword();
            } else if (item.name === LOGOUT) {
                this.authService.logout();
            }
        }
    }

    private resetPassword(): void {
        this.dialogActionService.showConfirmation(
            CHANGE_PASSWORD_CONFIRMATION_TITLE,
            CHANGE_PASSWORD_CONFIRMATION_MESSAGE,
            false,
        ).pipe(
            first(),
            filter((result) => result && (result as DialogAction).text === DialogActionService.ANSWER_YES),
            flatMap((result) => this.userDataService.selfResetPassword()), // TODO : Replace this by pipe(flatMap) ? (search for other flatMap in app for syntax)
        ).subscribe(
            (confirmed) => {
                this.router.navigate(['change-password']);
            },
            (error) => {
                this.handleError(error);
            },
        );
    }

    private handleError(error: any): void {
        this.notificationService.emitNotification(NotificationType.Warning, error);
        Logger.error(error);
    }
}
