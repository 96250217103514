<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-datepicker [(ngModel)]="dateToDisplay"
                      id="{{name}}"
                      [disabled]="disabled"
                      [format]="'dd-MMM-yyyy'"
                      (valueChange)="handleValue()"></kendo-datepicker>

    <!--<p>control touched : {{ngControl.control.touched}}, pristine : {{ngControl.control.pristine}}</p>-->

    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>

