import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CountryDTO, NationalFederationDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class CountryDataService {
    protected apiEndpoint: string;

    constructor(protected dataService: DataCachedService<CountryDTO>, protected config: AppConfig, protected http: HttpService) {
        this.apiEndpoint = config.countryApiEndpoint;
    }

    fetchCountries(includeInactive: boolean = false): Observable<CountryDTO[]> {
        return this.dataService.fetchEntities(`${this.apiEndpoint}?includeInactive=${includeInactive}`, `countries?includeInactive=${includeInactive}`);
    }
    fetchCountriesWithCallingCode(): Observable<CountryDTO[]> {
        return this.dataService.fetchEntities(`${this.apiEndpoint}/calling-code`);
    }
    fetchCountryNationalFederation(countryId: number): Observable<NationalFederationDTO> {
        return this.http.get(`${this.apiEndpoint}/${countryId}/national-federation`);
    }
}
