// Une classe légère
export class ThinModel {
    private _id: number;
    public name: string;
    public code: string;
    public set id(value: number) {
        this._id = value;
    }
    public get id(): number {
        return this._id;
    }
    constructor(id: number=0, name: string = '', code: string = '') {
        this.name = name;
        this._id = id;
        this.code = code;
    }
}
