import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ApiListResponse, BoxScoreDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { IFileData } from '@fiba/data-services';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class GameSanctionsDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionOperationGameSanctions(gameId: number): Observable<BoxScoreDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/sanctions`);
    }

    updateCompetitionOperationGameSanctions(model: BoxScoreDTO): Observable<BoxScoreDTO> {
        if (!!model?.gameId) {
            return this.http.put(`${this.competitionApiEndpoint}/games/${model.gameId}/sanctions`, model);
        } else {
            Logger.error('update game-sanctions: invalid model');
        }
    }

}
