import { BaseFilters } from '../common/base.filters';


export class InsurancePolicyFilters extends BaseFilters {
    context: string;
    validFromDate: Date;
    validToDate: Date;
    insurancePolicyStatusId: number;
    policyNumber: string;
    reset(): void {
        this.context = undefined;
        this.validFromDate = undefined;
        this.validToDate = undefined;
        this.insurancePolicyStatusId = undefined;
        this.policyNumber = undefined;
    }
}
