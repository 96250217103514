import { Injector, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FibaSelectBaseComponent } from '@fiba/forms';
import { ThingDTO } from '@fiba/models';


@Component({
    selector: 'fibaSelectThing',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectThingComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectThingComponent extends FibaSelectBaseComponent<ThingDTO> {
    constructor(protected injector: Injector) {
        super(injector);
        this.valuePrimitive = true;
        this._textField = 'name';
        this._valueField = 'id';
    }

    @Input() set valueField(value: string) {
        this._valueField = value;
    }

    @Input() protected set items(value: ThingDTO[]) {
        this._originalItems = value || [];
        this._filteredItems = this._originalItems.slice(0);
    }

    protected handleRestrictedItems(): void {
        this._filteredItems = this._originalItems;
    }
}
