import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PersonCoachLicenseDTO } from '@fiba/models';
import { HttpService } from '../utils/http.service';
import { Logger } from '@fiba/utils/logger';
import { LicenseCreateDTO } from '@fiba/models/dtos/generated/license-create-dto';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PersonCoachLicenseDataService {
    private readonly personCoachLicenseApiEndpoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig,) {
        this.personCoachLicenseApiEndpoint = config.personApiEndpoint;
    }

    /**
     * Get coach-search license and linked requests for a specific person
     */
    public fetchPersonCoachLicenses(personId: number): Observable<PersonCoachLicenseDTO[]> {
        if (personId) {
            return this.http.get(`${this.personCoachLicenseApiEndpoint}/${personId}/coachlicenses`);
        } else {
            Logger.warn('fetchPersonCoachLicenses called with invalid personId');
        }
    }
    /**
     * Create Coach License Request
     *
     * @param model
     */
    public createCoachLicense(model: LicenseCreateDTO): Observable<LicenseCreateDTO> {
        return this.http.post(`${this.personCoachLicenseApiEndpoint}/${model.personId}/coach-license`, model);
    }
}
