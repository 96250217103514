<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        Create a club
    </kendo-dialog-titlebar>
    <form #form="ngForm" (ngSubmit)="onSave($event)" autocomplete="off">
        <div class="labels-left space10">
            <fibaInputText name="officialName" label="Club name" [(ngModel)]="_model.officialName" [required]="true"></fibaInputText>
            <fibaAutocompleteCity name="city"
                                  label="City"
                                  [(ngModel)]="selectedCity"
                                  (cityChange)="_model.cityName = $event"
                                  [useIanatimeZone]="true"
                                  (ianatimeZone)="updateTimeZone($event)"
                                  [required]="true">
            </fibaAutocompleteCity>

            <fibaSelectCountry label="Country" name="country" (ngModelChange)="handleCountrySelect($event)" [(ngModel)]="_model.countryId" type="input" [required]="true" [disabled]="disabled"></fibaSelectCountry>
            <fibaPickerNationalFederation name="nationalfederation" (modelIdChange)="_model.nationalFederationId = $event" [disabled]="true" label="National federation" [(ngModel)]="nationalFederation" [required]="true"></fibaPickerNationalFederation>
            <fibaSelectItem label="Gender"
                            [categoryCode]="ItemCategoryCodes_ClubGender" 
                            name="gender"
                            [disabled]="isGenderCodeReadOnly"
                            [(ngModel)]="_model.genderItemId" 
                            type="input"
                            [required]="true"></fibaSelectItem>
        </div>
    </form>
    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event)" [disabled]="!_model || okButtonDisabled">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>
