<form novalidate autocomplete="off">
    <div class="vertical space10">
        <div class="form vertical labels-left space10">
            <fibaInputText name="name" label="Name" [(ngModel)]="filters.name"></fibaInputText>
            <fibaSelectCountry name="country" label="Country" [(ngModel)]="filters.country.value"></fibaSelectCountry>
            <fibaMultiSelectZone name="zone" label="Zone" [(ngModel)]="filters.zone.value"></fibaMultiSelectZone>
        </div>
        <div class="button-bar">
            <button type="submit" kendoButton
                    (click)="filter()"
                    [primary]="true">
                <i class="fa fa-lg fa-search"></i>
                Apply filters
            </button>
        </div>
        <span [hidden]="!isLoading">Loading...</span>
        <kendo-grid [hidden]="isLoading"
                    #grid
                    class="no-scrollbar navigable"
                    [data]="nationalFederations"
                    [pageSize]="filters.take"
                    [skip]="filters.skip"
                    [sortable]="{ mode: 'single' }"
                    [sort]="sort"
                    [pageable]="true"
                    [selectable]="true"
                    (selectionChange)="handleSelChange($event)"
                    (pageChange)="handlePageChange($event)"
                    (sortChange)="handleSortChange($event)">
            <kendo-grid-column title="Name" field="name"></kendo-grid-column>
            <kendo-grid-column title="City" field="city"></kendo-grid-column>
            <kendo-grid-column title="Country" field="country"></kendo-grid-column>
            <kendo-grid-column title="#" field="organisationId"></kendo-grid-column>
        </kendo-grid>
    </div>
</form>