import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ApiListResponse, GameAssignmentsDTO, GameAssignmentConfigurationDTO, ActionMessageDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionGameAssignmentDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionGameAssignments(competitionId: number, gameId: number): Observable<GameAssignmentsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/game-assignments/${gameId}`);
    }

    fetchGameAssignmentConfiguration(competitionId: number): Observable<GameAssignmentConfigurationDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/game-assignments/configurations`);
    }

    updateCompetitionGameAssignments(model: GameAssignmentsDTO): Observable<ActionMessageDTO> {
        const filteredModel = model;
        filteredModel.gameAssignments = model.gameAssignments.filter(x => x.personId);

        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/game-assignments`, filteredModel);
    }
}
