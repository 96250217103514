import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse, CompetitionDTO, CompetitionStatusDTO, ThingDTO, CompetitionInformationDTO, CompetitionInformationSummaryDTO, RoundInformationSummaryDTO, WindowDTO, VenueDTO, RoleFamilies, CompetitionDocumentGuidelineDTO, PreliminaryRosterForPersonDTO, PersonDTO, PersonListItemDTO, RoundDTO, CompetitionListItemDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import { AppConfig } from '@app/app.config';
import { PlayerFilters } from '../../models/competition/player-filters';


const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionDataService {
    protected competitionApiEndpoint: string;
    protected competitionStatusApiEndpoint: string;
    protected competitionAutocompleteApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataCompetitionStatusService: DataCachedService<CompetitionStatusDTO>) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.competitionStatusApiEndpoint = config.competitionStatusApiEndpoint;
        this.competitionAutocompleteApiEndpoint = config.competitionAutocompleteApiEndpoint;
    }

    fetchCompetition(competitionId: number): Observable<CompetitionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}`);
    }

    fetchCompetitionInformationSummary(competitionId: number): Observable<CompetitionInformationSummaryDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/summary`);
    }

    fetchRoundInformationSummary(roundId: number): Observable<RoundInformationSummaryDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/summary`);
    }

    fetchAutoCompleteList(keywords: string): Observable<ThingDTO[]> {
        const params = { keywords, take: String(COMPETITION_AUTOCOMPLETE_LIST_SIZE) };
        return this.http.get(this.competitionAutocompleteApiEndpoint, params);
    }

    fetchStatuses(): Observable<CompetitionStatusDTO[]> {
        return this.dataCompetitionStatusService.fetchEntities(this.competitionStatusApiEndpoint);
    }

    fetchCompetitionWindows(competitionId: number): Observable<WindowDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/windows`);
    }

    fetchCompetitionRoundGroupPairing(competitionId: number): Observable<ThingDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/round-grouppairings`);
    }
    
    fetchCompetitionRounds(competitionId: number): Observable<RoundDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rounds`);
    }
    
    fetchCompetitionGroupPairing(competitionId: number): Observable<ThingDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/grouppairings`);
    }

    fetchCompetitionVenues(competitionId: number): Observable<VenueDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/venues`);
    }

    fetchDocumentGuideline(competitionId: number): Observable<CompetitionDocumentGuidelineDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/document-guideline`);
    }

    copyCompetition(model: CompetitionInformationDTO, copyCompetitionId: number): Observable<CompetitionInformationDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/${copyCompetitionId}/copy`, model);
    }

    fetchNextMissingRosterForPlayer(personId: number, playerLicenseId: number): Observable<PreliminaryRosterForPersonDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/rosters/${personId}/missing-next-rosters/${playerLicenseId}`);
    }

    fetchNextRosterForPlayer(personId: number, playerLicenseId: number): Observable<PreliminaryRosterForPersonDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/rosters/${personId}/next-rosters/${playerLicenseId}`);
    }

    generateNextRosterForPlayer(preliminaryRosterForPerson: PreliminaryRosterForPersonDTO): Observable<PreliminaryRosterForPersonDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/rosters/${preliminaryRosterForPerson.personId}/generate-next-rosters`, preliminaryRosterForPerson);
    }

    removeNextRosterForPlayer(preliminaryRosterForPerson: PreliminaryRosterForPersonDTO): Observable<PreliminaryRosterForPersonDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/rosters/${preliminaryRosterForPerson.personId}/remove-next-rosters`, preliminaryRosterForPerson);
    }

    isClubCompetition(competitionId: number): Observable<boolean> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/is-club-competition`);
    }

    completeCompetition(competitionId: number): Observable<void> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/complete`);
    }

    reopenCompetition(competitionId: number): Observable<void> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/reopen`);
    }

    closeCompetition(competitionId: number): Observable<void> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/close`);
    }

    searchCompetitionPlayers(competitionId: number, filters: PlayerFilters): Observable<ApiListResponse<PersonListItemDTO>> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/search-preliminary-roster-player`, filters.getSearchParams())
    }
}
