import { Injector, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CompetitionPickerDataService, ItemDataService } from '@fiba/data-services';
import { FibaLoadingService } from '@fiba/loading';
import { CompetitionFilters, CompetitionListItemDTO, ItemCategoryCodes, ItemDTO, MapPolicy, ZoneDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

@Directive()
export class CompetitionSearchBaseComponent implements OnInit {
    public readonly ItemCategoryCodes_CompetitionOfficialFlag = ItemCategoryCodes.CompetitionOfficialFlag;
    public readonly ItemCategoryCodes_CompetitionGender = ItemCategoryCodes.CompetitionGender;
    public readonly ItemCategoryCodes_CompetitionType = ItemCategoryCodes.CompetitionType;
    public readonly ItemCategoryCodes_CompetitionCategory = ItemCategoryCodes.CompetitionCategory;
    public readonly ItemCategoryCodes_CompetitionDiscipline = ItemCategoryCodes.CompetitionDiscipline;
    public readonly ItemCategoryCodes_CompetitionAgeCategory = ItemCategoryCodes.CompetitionAgeCategory;
    public readonly competitionEditPolicy: MapPolicy = MapPolicy.CompetitionsEdit;

    public isLoading: boolean;
    public filters: CompetitionFilters;
    public sort: SortDescriptor[] = [];
    public competitions: GridDataResult;

    protected fetchSub: Subscription;
    protected currentCompetitionListItem: CompetitionListItemDTO = null;
    protected router: Router;
    protected notificationService: NotificationService;
    protected dataService: CompetitionPickerDataService;
    protected simpleStorageService: SimpleStorageService;
    protected itemDataService: ItemDataService;
    protected disciplineItems: ItemDTO[] = [];
    protected authService: AuthService;
    protected newFilters = true;
    protected fibaLoadingService: FibaLoadingService;

    public minSeason: number = null;
    public maxSeason: number = null;

    protected userZones: ZoneDTO[] = null; // TODO: hackish... the default filters should be saved instead of the parameters to build them

    constructor(protected injector: Injector, protected pageSize: number, protected filterStorageKey: string = null) {
        this.notificationService = injector.get(NotificationService);
        this.dataService = injector.get(CompetitionPickerDataService);
        this.itemDataService = injector.get(ItemDataService);
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.simpleStorageService = injector.get(SimpleStorageService);
        this.fibaLoadingService = injector.get(FibaLoadingService);

        const savedFilters = (filterStorageKey ? this.simpleStorageService.get(filterStorageKey) : null);
        if (savedFilters) {
            this.filters = savedFilters.clone();
            this.newFilters = false;
        } else {
            this.filters = new CompetitionFilters(this.pageSize);
            this.newFilters = true;
        }
    }

    public ngOnInit() {
        Promise.all([
            this.authService.getUserOrganisationPromise(),
            this.authService.getCompetitionAccessZonesPromise(),
        ]).then((promiseValues) => {
            const [organisation, competitionAccess] = promiseValues;
            if (organisation && organisation.zone) {
                this.userZones = [organisation.zone];
            } else if (!competitionAccess.hasGlobalAccess) {
                this.userZones = competitionAccess.zones;
            }
            if (this.newFilters) {
                this.setDefaultFilter();
            }
            this.fetchList();
        });
    }

    protected fetchList(displayLoading: boolean = true): void {
        this.isLoading = displayLoading;
        this.fibaLoadingService.show(800, 'Loading');
        // TODO : * Review this (and all other) unsubscribe() usage. We might not need the subscription, which would make code much simpler everywhere. Also need some factoring, as this code is copied all around. Some solution involving switchMap should be considered. YV

        if (this.fetchSub) {
            this.fetchSub.unsubscribe();
        }
        this.fetchSub = this.dataService.fetchList(this.filters).subscribe(
            (val) => {
                this.competitions = val;
                this.fibaLoadingService.hide();
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
                Logger.error(error);
                this.fibaLoadingService.hide();
            },
            () => {
                this.isLoading = false;
            },
        );
    }

    public filter(): void {
        this.filters.skip = 0;
        this.fetchList(true);
    }

    // Grid events
    public handlePageChange(event: PageChangeEvent): void {
        this.filters.skip = event.skip;
        this.fetchList(false);
    }

    public handleSortChange(sort: SortDescriptor[]): void {
        Logger.debug(sort);
        this.sort = sort;
        this.filters.skip = 0;
        this.filters.sortBy = sort[0].field;
        this.filters.sortOrder = sort[0].dir;
        this.fetchList();
    }

    public handleSelChange(event: SelectionEvent): void {
        const firstSelectedRowDataItem = event?.selectedRows?.[0]?.dataItem;

        this.currentCompetitionListItem = firstSelectedRowDataItem;
    }

    public reset(): void {
        this.filters.reset();
        this.setDefaultFilter();
        if (this.filterStorageKey) {
            this.simpleStorageService.delete(this.filterStorageKey);
        }
    }

    protected setDefaultFilter() {
        this.filters.seasonFrom = String(new Date().getFullYear());
        if (this.userZones && this.userZones.length > 0) {
            this.filters.zone.setFilterValue(this.userZones);
        }
    }
}
