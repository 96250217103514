<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div class="fiba-multiselect">
    <ng-template [ngIf]="type=='filter'">
        <kendo-multiselect id="{{name}}"
                           [placeholder]="'All'"
                           [data]="_data"
                           [textField]="_textField"
                           [valueField]="_valueField"
                           [(ngModel)]="value"
                           (valueChange)="handleValue($event)"
                           filterable="true"
                           (filterChange)="handleFilter($event)"
                           [disabled]="disabled">
        </kendo-multiselect>
    </ng-template>
    <ng-template [ngIf]="type=='input'">
        <kendo-multiselect #input="ngModel"
                           id="{{name}}"
                           [placeholder]="'Type or select'"
                           [data]="_data"
                           [textField]="_textField"
                           [valueField]="_valueField"
                           [(ngModel)]="value"
                           [valuePrimitive]="false"
                           (valueChange)="handleValue($event)"
                           filterable="true"
                           (filterChange)="handleFilter($event)"
                           [disabled]="disabled">
        </kendo-multiselect>
        <div *ngIf="input.errors" class="validation-errors">
            <ul>
                <li [hidden]="!input.errors.required">
                    {{label}} is required
                </li>
            </ul>
        </div>
    </ng-template>
</div>
