import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthService} from '@fiba/data-services';

import {LocalStorage} from '@fiba/utils/local-storage.service';
import {Logger} from '@fiba/utils/logger';
import {NotificationService, NotificationType} from '@fiba/utils/notification.service';
import 'reflect-metadata';
import 'zone.js';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    protected subscriptions: Subscription[];

    constructor(authService: AuthService, notificationService: NotificationService) {
        this.subscriptions = [];

        // Store URL in local storage for redirection after login
        if (window.location.pathname.indexOf('auth-handler') === -1) {
            LocalStorage.setItem('redirectUrl', window.location.pathname);
        }

        // Listen for authentication failures
        this.subscriptions.push(authService.getUserUnauthorizedEmitter().subscribe((url) => {
            notificationService.emitNotification(NotificationType.Error, 'Unauthorized access to: ' + url);
        }));

        // Listen for access token renewal failures
        this.subscriptions.push(authService.getAccessTokenRenewalErrorEvent().subscribe((error) => {
            Logger.error('Access token renewal failed: ', error);
            authService.getUserPromise().then((user) => {
                Logger.debug('Last user info was: ', user);
            });
            notificationService.emitNotification(NotificationType.Warning, 'Access token renewal failed. Please manually refresh the page.');
        }));

        // Display version number
        document.getElementById('version-number').style.display = 'block';

    }

    public ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

//     private test(): void {
//
//         const platform = platformBrowserDynamic([
//             {
//                 provide: AppConfig,
//                 useValue: devConfig
//             }
//         ]);
//
// // Enable either Hot Module Reloading or production mode
//         if (module['hot']) {
//             module['hot'].accept();
//             module['hot'].dispose(() => { });
//         } else {
//             enableProdMode();
//         }
//
// // Store URL in local storage for redirection after login
//         if (window.location.pathname.indexOf('auth-handler') === -1) {
//             localStorage.setItem('redirectUrl', window.location.pathname);
//         }
//
// // Bootstrap the application
//         platform.bootstrapModule(AppModule);
//
//     }
}
