export class OfficialLicenseRequestActions {

    public static readonly Completed: string = 'completed';
    public static readonly Save: string = 'save';
    public static readonly RecommendedByRO: string = 'recommendedByRO';
    public static readonly NotRecommendedByRO: string = 'notRecommendedByRO';
    public static readonly PendingInformationNF: string = 'pendingInformationNF';
    public static readonly PendingInformationRO: string = 'pendingInformationRO';
    public static readonly PendingInformationHQ: string = 'pendingInformationHQ';
    public static readonly SupportedByHQ: string = 'supportedByHQ';
    public static readonly NotSupportedByHQ: string = 'notSupportedByHQ';
    public static readonly ApprovedByFIBA: string = 'approvedByFIBA';
    public static readonly RejectedByFIBA: string = 'rejectedByFIBA';
}
