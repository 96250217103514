import { UserAuthorisationDTO, UserAuthorisationContext } from '../dtos/dtos.index';

export class UserAuthorisationUtils {
    
    static arrangeIds(ua: UserAuthorisationDTO): void {
        for (var i = 0; i < ua.mapRole.length; i++) {
            ua.mapRoleCode = !!ua.mapRole ? ua.mapRole[i].mapRoleCode : null;
            ua.zoneId = !!ua.zone ? ua.zone.zoneId : null;
            ua.competitionId = !!ua.competition ? ua.competition.competitionId : null;
        }
    }
    static equalsInsert(ua: UserAuthorisationDTO, ua2: UserAuthorisationDTO): boolean {
        if (!ua || !ua2) {
            return false;
        }
        return ua2.userId === ua.userId &&
            ua2.mapRoleCode === ua.mapRoleCode &&
            ua2.isGlobalAuthorisation === ua.isGlobalAuthorisation &&
            ua2.zoneId === ua.zoneId &&
            ua2.competitionId === ua.competitionId;
    }
    static computeDisplayFields(ua: UserAuthorisationDTO): void {
        if (ua && ua.mapRole) {
            if (ua.mapRole.length) {
                for (var i = 0; i < ua.mapRole.length; i++) {
                    if (ua.isGlobalAuthorisation) {
                        ua.context = UserAuthorisationContext.Global;
                        ua.cli_applicableContext = 'Global';
                    } else if (ua.zone && ua.zone.zoneId) {
                        ua.context = UserAuthorisationContext.Zone;
                        ua.cli_applicableContext = ua.zone.officialName;
                    } else if (ua.competition && ua.competition.competitionId) {
                        ua.context = UserAuthorisationContext.Competition;
                        ua.cli_applicableContext = `${ua.competition.season} - ${ua.competition.officialName} (${ua.competition.code})`;
                    }
                }
            }
        }
    }
}
