import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Logger } from '@fiba/utils/logger';
import { FibaInputBase } from './fiba-input-base';

@Directive()
export abstract class FibaSelectBaseComponent<T> extends FibaInputBase<T> implements OnInit {
    public _textField: string;
    public _valueField: string;
    public _originalItems: T[];
    public _filteredItems: T[];

    @Input() public type = 'filter'; // filter or input
    @Input() public valuePrimitive: boolean = undefined; // Use int (true) or object (false)
    @Input() public clearButton: boolean = true;
    @Input() public placeholder: string;
    @Input() protected modelId: number;

    @Output() protected change: EventEmitter<any> = new EventEmitter();
    @Output() protected modelIdChange: EventEmitter<any> = new EventEmitter();

    public static getBaseTemplate(): string {
        throw new Error('This method was disabled because it was not called from anywhere and required a webpack loader to import the .html file (which broke the build)');
        // return require('./fiba-select-base.component.html');
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (!this.placeholder) {
            this.placeholder = this.type === 'filter' ? 'All' : 'Type or select';
        }

        if (this.valuePrimitive === undefined) {
            this.valuePrimitive = this.type !== 'filter';
        }
    }

    public handleFilter(value): void {
        this.onTouched();
        this.handleRestrictedItems();
        this.handleFilterItems(value);
    }

    public handleValue(value): void {
        this.manualChange();
        this.onTouched();
        if (!value || value.length === 0) {
            this.handleRestrictedItems();
        }

        if (!this.valuePrimitive) { // If we manage objects, copy the object id in modelId and emit it
            this.modelId = value ? value[this._valueField] : undefined;
            this.modelIdChange.emit(this.modelId);
        }

        this.change.emit(value);
    }

    public manualChange(): void {
        this.onChange(this.value);
    }

    private handleFilterItems(value: string): void {
        if (this._filteredItems) {
            if (!this._textField) {
                this._filteredItems = this._filteredItems.filter((s) => String(s).toLowerCase().indexOf(value.toLowerCase()) !== -1);
            } else {
                this._filteredItems = this._filteredItems.filter((s) => String(s[this._textField]).toLowerCase().indexOf(value.toLowerCase()) !== -1);
            }
        }
    }

    protected handleRestrictedItems(): void {
        this._filteredItems = this._originalItems;
    }
}

@Directive()
export abstract class FibaAjaxSelectBaseComponent<T> extends FibaSelectBaseComponent<T> implements OnInit, OnDestroy {
    protected subscription: Subscription;
    @Input() protected initializedDefaultValueCreation = false;
    @Input() public clearButton: boolean = true;

    @Output() public dataBound: EventEmitter<any> = new EventEmitter();

    public ngOnInit(): void {
        super.ngOnInit();
        this.getSubscription();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public getBindData(): (data: T[]) => void {
        return (data: T[]) => {
            this._originalItems = data; // TODO: if T has a displayOrder, use it to order
            this.handleRestrictedItems();
            if (this.initializedDefaultValueCreation) {
                this.value = this.getDefaultValueCreation();
            }
        };
    }

    public getDefaultValueCreation(): number | T {
        return undefined;
    }

    public getHandleError(): (err: any) => void {
        return (err: any) => {
            Logger.error(err);
        };
    }

    public getComplete(): () => void {
        return () => {
            this.dataBound.emit();
        };
    }

    protected getSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.getObservable().subscribe(
            this.getBindData(),
            this.getHandleError(),
            this.getComplete(),
        );
    }

    public abstract getObservable(): Observable<T[]>;

    protected handleRestrictedItems(): void {
        if (this._originalItems) {
            this._filteredItems = this._originalItems.slice(0);
        }
    }
}
