import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { UserAuthorisationDTO, UserInformationDTO, UserListItemDTO, UserPersonInformationDTO } from '../dtos/dtos.index';

export class UserFilters extends BaseFilters {
    lastName: string;
    firstName: string;
    email: string;
    displayOnlyBackOfficeUser: boolean;
    displayOnlyFrontOfficeUser: boolean;
    organisation: Filterable = new Filterable('organisationId');
    nationalFederationId: number;
    userStatus: FilterableArray = new FilterableArray('userStatusId');
    personStatus: FilterableArray = new FilterableArray('personStatusId');
    personRoleFamily: Filterable = new Filterable('roleFamilyId');
    personRole: Filterable = new Filterable('roleId');

    reset(): void {
        delete this.lastName;
        delete this.firstName;
        delete this.email;
        delete this.nationalFederationId;
        this.organisation.reset();
        this.userStatus.reset();
        this.personStatus.reset();
        this.personRoleFamily.reset();
        this.personRole.reset();
    }
}

export class UserAuthorisationFilters extends BaseFilters {
    userId: number;
    roles: FilterableArray = new FilterableArray('mapRoleCode');
    zones: Filterable = new Filterable('zoneId');
    competition: Filterable = new Filterable('competitionId');
    isGlobalAuthorisation: boolean;

    reset(): void {
        delete this.isGlobalAuthorisation;
        this.roles.reset();
        this.zones.reset();
        this.competition.reset();
    }
}

export class UserTraceFilters extends BaseFilters {
    userId: number;
    dateFrom: Date;
    dateTo: Date;
    connectionStatus: Filterable = new Filterable('connectionStatusId', 'itemId');

    reset(): void {
        delete this.dateFrom;
        delete this.dateTo;
        this.connectionStatus.reset();
    }
}

declare module '../dtos/generated/user-authorisation-dto' {
    export interface UserAuthorisationDTO {
        cli_applicableContext: string;
    }
}
declare module '../dtos/generated/user-information-dto' {
    export interface UserInformationDTO {
        cli_expirationDateType: string;
        cli_confirmPassword: string;
    }
}
declare module '../dtos/generated/user-person-information-dto' {
    export interface UserPersonInformationDTO {
        cli_personRolesFormatted: string;
    }
}
declare module '../dtos/generated/user-list-item-dto' {
    export interface UserListItemDTO {
        cli_organisationList: string;
    }
}
