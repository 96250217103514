import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { User, UserManager } from 'oidc-client';
import { Observable, of } from 'rxjs';

import { MapPolicy, OrganisationDTO, UserAccessDTO, ZoneDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

interface IExtraQueryParams {
    selfResetToken: string;
}

@Injectable()
export class AuthService {
    protected initialized: boolean;
    protected mgr: UserManager;
    protected userLoggedEvent: EventEmitter<User>;
    protected userUnauthorizedEvent: EventEmitter<string>;
    protected accessTokenRenewalErrorEvent: EventEmitter<any>;
    protected currentUser: User;
    protected isSigninRequested: boolean;
    protected policiesPromise: Promise<Map<MapPolicy, string[]>>; // key: MapPolicy, value: claimIds
    protected userAccessPromise: Promise<UserAccessDTO>;
    protected userPromise: Promise<User>;
    private configOption: {
        name: string;
        caption: string;
        style: {
            color: string;
        };
    };

    constructor(
        protected injector: Injector,
        protected http: HttpClient,
        protected router: Router,
        protected config: AppConfig) {
        // Log.logger = window.console; // uncomment to get log output from oidc-client

        this.initialized = false;
        this.mgr = new UserManager(this.config.oidcSettings);
        this.userLoggedEvent = new EventEmitter<User>();
        this.userUnauthorizedEvent = new EventEmitter<string>();
        this.accessTokenRenewalErrorEvent = new EventEmitter<any>();
        this.currentUser = null;
        this.isSigninRequested = false;
        this.configOption = {
            name: this.config.name,
            caption: '',
            style: {
                color: '',
            },
        };

        switch (this.configOption.name) {
            case 'DEV': {
                this.configOption.style.color = 'red';
                this.configOption.caption = 'Development';
                break;
            }
            case 'STG': {
                if (window.location.href.indexOf('staging.fiba.basketball') != -1) {
                    this.configOption.style.color = 'pink';
                    this.configOption.caption = 'Staging on .net8';
                } else {
                    this.configOption.style.color = 'blue';
                    this.configOption.caption = 'Staging';
                }
                break;
            }
            case 'INT': {
                this.configOption.style.color = 'yellow';
                this.configOption.caption = 'Integration';
                break;
            }
            case 'TRN': {
                this.configOption.style.color = 'green';
                this.configOption.caption = 'Training';
                break;
            }
            default:
                break;
        }
    }

    public static getAuthGuardDiToken(): any {
        return AuthGuard;
    }

    public getConfigOption() {
        return this.configOption;
    }

    public _init(): void {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        this._fetchInitInformation();

        this.userPromise = this.mgr.getUser().then(
            (user) => this.setUser(user, Boolean(user)),
            (error) => {
                Logger.error(error);
            },
        ) as Promise<User>;

        // Uncomment to automatically renew the access token (non functionnal)
        /*this.mgr.events.addAccessTokenExpiring((unused) => {
            this.mgr.signinSilent().then(
                (user) => {
                    this.setUser(user);
                },
                (error) => {
                    this.accessTokenRenewalErrorEvent.emit(error);
                }
            );
        });*/

        // Automatically refresh the page (and re-authenticate using the IS cookie) when the current access token expires
        this.mgr.events.addAccessTokenExpired((unused) => {
            this.mgr.removeUser();
            this.startSignin();
        });

        this.mgr.events.addUserUnloaded(() => {
            this.setUser(null);
        });
    }

    public getAuthHeaders() {
        let headers: HttpHeaders;
        const user = this._getUser();

        if (user) {
            headers = new HttpHeaders({
                Authorization: user.token_type + ' ' + user.access_token,
            });
        }

        return { headers };
    }

    public getUserPromise(): Promise<User> {
        return this.userPromise;
    }

    public getUserOrganisationPromise(): Promise<OrganisationDTO> {
        return this.userAccessPromise.then((userAccess) => userAccess.organisation);
    }

    public getIsFrontOfficeUserPromise(): Promise<boolean> {
        return this.getUserOrganisationPromise().then((organisation) => Boolean(organisation));
    }

    public getIsAgentUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isAgent);
    }

    public getIsTechnicalMeetingOperatorUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isTechnicalMeetingOperator);
    }
    public getIsHistoricalInsuranceDataManagerUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isHistoricalInsuranceDataManager);
    }

    public getIsFibaPartnerUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isFibaPartner);
    }

    public getHasAgentActiveRoleUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.hasAgentActiveRole);
    }

    public getIsTestInstituteUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isTestInstitute);
    }

    public getIsAdminUserPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.isAdmin);
    }

    public getHasAcceptedValidTermsAndConditionsPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.hasAcceptedValidTermsAndConditions);
    }

    public getMustChangePasswordPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.mustChangePassword);
    }

    public getCompetitionAccessZonesPromise(): Promise<{ hasGlobalAccess: boolean; zones: ZoneDTO[] }> {
        return this.userAccessPromise.then((userAccess) => ({
            hasGlobalAccess: userAccess.hasGlobalCompetitionAccess,
            zones: userAccess.competitionAccessZones,
        }));
    }

    public getPlayerLicenseManagerGlobalPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.hasGlobalPlayerLicenseManagerAccess);
    }

    public getGlobalTransferRequestHQManagerPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.hasGlobalTransferRequestHQManager);
    }

    public getHasFormRegistrationTemplateEditPromise(): Promise<boolean> {
        return this.userAccessPromise.then((userAccess) => userAccess.hasFormRegistrationTemplateEdit);
    }

    public getUserLoggedEmitter(): EventEmitter<User> {
        return this.userLoggedEvent;
    }

    public getUserUnauthorizedEmitter(): EventEmitter<string> {
        return this.userUnauthorizedEvent;
    }

    public getAccessTokenRenewalErrorEvent(): EventEmitter<any> {
        return this.accessTokenRenewalErrorEvent;
    }

    public _isLoggedIn(): boolean {
        return (this.currentUser !== null);
    }

    public _getUser(): User {
        return this.currentUser;
    }

    public logout(): void {
        this.startSignout();
    }

    public startSignin(selfResetToken: string = null): void {
        if (!this.isSigninRequested) {
            this.isSigninRequested = true;
            const extraQueryParams = { selfResetToken: undefined } as IExtraQueryParams;
            if (selfResetToken && typeof (selfResetToken) === 'string') {
                extraQueryParams.selfResetToken = selfResetToken;
            }

            this.mgr.signinRedirect({ extraQueryParams }).then(() => {
                this.isSigninRequested = false;
            }).catch((err) => {
                Logger.error(err);
                this.isSigninRequested = false;
            });
        }
    }

    public endSignin(): Promise<User> {
        return this.mgr.signinRedirectCallback().then((user) => {
            Logger.debug(user);
            this.setUser(user);
            return user;
        }).catch((err) => {
            Logger.error(err);
        }) as Promise<User>;
    }

    public endSilentSignin(): Promise<any> {
        return this.mgr.signinSilentCallback();
    }

    public startSignout(): void {
        this.mgr.signoutRedirect().then((resp) => {

        }).catch((err) => {
            Logger.error(err);
        });
    }

    public endSignout(): void {
        this.mgr.signoutRedirectCallback().then((resp) => {

        }).catch((err) => {
            Logger.error(err);
        });
    }

    public startTermAndConditionsAcceptanceProcess(): void {
        this.router.navigate(['terms-and-conditions']);
    }

    public startChangeUserPasswordProcess(): void {
        this.router.navigate(['change-password']);
    }

    public _fetchInitInformation(): Promise<UserAccessDTO> {
        // TODO : need to clean refactor the below blocks, nesting subscriptions/promises is not recommended,
        // also I suspect that the various 'unsubscribe' are unnecessary.

        this.policiesPromise = new Promise<Map<MapPolicy, string[]>>((resolvePolicies, rejectPolicies) => {
            this.userAccessPromise = new Promise<UserAccessDTO>((resolveUserAccess, rejectUserAccess) => {
                const firstLoginSub = this.userLoggedEvent.subscribe((unused) => {
                    const fetchPoliciesSub = this.fetchPolicies().subscribe(
                        (policies) => {
                            resolvePolicies(policies);
                            fetchPoliciesSub.unsubscribe();
                        },
                        (error) => {
                            rejectPolicies(error);
                            fetchPoliciesSub.unsubscribe();
                        },
                    );

                    const fetchCurrentUserAccessSub = this.fetchUserAccessInformation().subscribe(
                        (userAccess) => {
                            resolveUserAccess(userAccess);
                            fetchCurrentUserAccessSub.unsubscribe();
                        },
                        (error) => {
                            resolveUserAccess(error);
                            fetchCurrentUserAccessSub.unsubscribe();
                        },
                    );

                    firstLoginSub.unsubscribe();
                });
            });
        });

        return this.userAccessPromise;
    }

    public isUserAuthorized(policy: MapPolicy): Promise<boolean> {
        return this.policiesPromise.then((policies) => {
            if (this._isLoggedIn()) {
                const requiredClaims = policies.get(policy);
                if (!requiredClaims) {
                    throw new Error('No information received from the API about policy: ' + MapPolicy[policy]);
                }

                const profile = this.currentUser.profile;
                for (const claimCode of requiredClaims) {
                    if (!profile.hasOwnProperty(claimCode)) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        });
    }

    public isNavigationAuthorized(path: string, fromRoute: ActivatedRoute = null): Promise<boolean> {
        let routeConfig: Route;
        let fullPath: string;

        if (fromRoute === null) {
            routeConfig = this.getRouteConfig(path, this.router.config);
            fullPath = path;
        } else {
            routeConfig = this.getRouteConfig(path, fromRoute.routeConfig.children);
            fullPath = fromRoute.snapshot.pathFromRoot.map((r) => (r.url.length ? r.url[0].path : null)).filter((p) => Boolean(p)).concat(path).join('/');
        }

        if (!routeConfig) {
            Logger.warn('No route config found for path: ' + fullPath);
            return Promise.resolve(false);
        }

        const rawValues: boolean[] = [];
        const promises: Array<Promise<boolean>> = [];
        if (routeConfig.canActivate) {
            for (const authGuardDiToken of routeConfig.canActivate) {
                const authGuard = this.injector.get(authGuardDiToken);
                const ret = authGuard.canActivate(routeConfig as ActivatedRouteSnapshot, { url: fullPath } as RouterStateSnapshot, false);
                if (ret instanceof Promise) {
                    promises.push(ret);
                } else {
                    rawValues.push(ret as boolean);
                }
            }
        }

        if (promises.length === 0) {
            return Promise.resolve(rawValues.reduce((val, acc) => (val && acc), true));
        }

        for (const val of rawValues) {
            promises.push(Promise.resolve(val)); // add raw boolean values as resolved promises to the existing ones
        }
        return Promise.all(promises).then((values) => Promise.resolve<boolean>(values.reduce((val, acc) => (val && acc), true)));
    }

    protected setUser(user: User, emitEvent = true): User {
        if (this.currentUser === null || user === null) {
            this.currentUser = user;
        } else {
            Object.assign(this.currentUser, user); // update the old instance since some dependencies may keep a reference to it
        }
        if (emitEvent) {
            this.userLoggedEvent.emit(this.currentUser);
        }
        return this.currentUser;
    }

    protected clearState(): void {
        this.mgr.clearStaleState().then(() => {
            Logger.debug('Auth: clearState success');
        }).catch((e: Error) => {
            Logger.error('Auth: clearState error: ' + e.message);
        });
    }

    protected fetchPolicies(): Observable<Map<MapPolicy, string[]>> {
        const req = this.getAuthHeaders();

        // data services cannot be used since they may depend on this service
        return this.http.get<any>(this.config.policiesApiEndpoint, req).pipe(
            map((response) => {
                const res = response;

                const policies = new Map<MapPolicy, string[]>();
                res.forEach((val) => {
                    const policy: MapPolicy = MapPolicy[val.mapPolicyCode as string];
                    if (!policy) {
                        Logger.warn(`A policy returned by the API is unknown to the front-end code: ${val.mapPolicyCode}`);
                    }
                    const claims: string[] = val.mapClaims.map((claim) => claim.mapClaimCode);
                    const parentClaims: string[] = (val.mapParentPolicy ? val.mapParentPolicy.mapClaims.map((claim) => claim.mapClaimCode) : []);
                    for (const claimCode of parentClaims) {
                        if (claims.indexOf(claimCode) === -1) {
                            claims.push(claimCode);
                        }
                    }

                    policies.set(policy, claims);
                });

                // DEBUG only
                if (Logger.getLogLevel() >= Logger.DEBUG) {
                    const policyDebugPromises = [];
                    policies.forEach((unused, policy) => {
                        policyDebugPromises.push(this.isUserAuthorized(policy).then((isAuthorized) => MapPolicy[policy] + ' => ' + (isAuthorized ? 'OK' : 'UNAUTHORIZED')));
                    });
                    Promise.all(policyDebugPromises).then((policiesDebug) => {
                        Logger.debug(policiesDebug);
                    });
                }

                return policies;
            }),
            catchError(this.extractErrorMsgCb));
    }

    protected fetchUserAccessInformation(): Observable<UserAccessDTO> {
        const req = this.getAuthHeaders();

        // data services cannot be used since they may depend on this service
        return this.http.get(this.config.currentUserAccessApiEndpoint, req).pipe(map((response) => response as UserAccessDTO),
            catchError(this.extractErrorMsgCb))
            ;
    }

    protected getRouteConfig(path: string, routes: Route[]): Route {
        let pathFragment: string;
        let nextPath: string;
        let slashIndex = path.indexOf('/');

        if (slashIndex === 0) {
            // remove first slash
            path = path.substring(1);
            slashIndex = path.indexOf('/');
        }

        if (slashIndex >= 0) {
            pathFragment = path.substring(0, slashIndex);
            nextPath = path.substring(slashIndex + 1);
        } else {
            pathFragment = path;
            nextPath = '';
        }

        // TODO: handle string URL parameters
        if (pathFragment.match(/^\d+$/)) { // if pathFragment is an integer
            for (const route of routes) {
                if (route.path.indexOf(':') === 0) {
                    if (nextPath !== '' && route.children) {
                        return this.getRouteConfig(nextPath, route.children);
                    } else {
                        return route;
                    }
                }
            }
        } else {
            for (const route of routes) {
                if (route.path === pathFragment && !route.redirectTo) {
                    if (pathFragment === '') {
                        return route;
                    } else if (nextPath !== '' && route.children) {
                        return this.getRouteConfig(nextPath, route.children);
                    } else {
                        return route;
                    }
                }
            }
        }

        for (const route of routes) {
            if (route.path === '' && route.children) {
                return this.getRouteConfig(path, route.children);
            }
        }

        return null;
    }

    private extractErrorMsgCb(response: string): Observable<any> {
        return of(`Error from backend: ${response}`);
    }
}

class AuthGuardBase implements CanActivate, CanActivateChild {
    constructor(
        protected authService: AuthService,
        protected enforceTermsAndConditions = true,
        protected enforceChangePassword = true,
        protected checkForToken: boolean = false) {
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, emitEvents = true): Promise<boolean> {
        this.authService._init();
        return this.authService.getUserPromise().then(() => {
            if (this.authService._isLoggedIn()) {
                let termsAndConditionsAcceptedPromise: Promise<boolean>;
                if (this.enforceTermsAndConditions) {
                    termsAndConditionsAcceptedPromise = this.authService.getHasAcceptedValidTermsAndConditionsPromise()
                        .then((termsAndConditionsAccepted) => {
                            if (!termsAndConditionsAccepted) {
                                this.authService.startTermAndConditionsAcceptanceProcess();
                            }
                            return termsAndConditionsAccepted;
                        });
                } else {
                    termsAndConditionsAcceptedPromise = Promise.resolve(true);
                }

                let userMustChangePasswordPromise: Promise<boolean>;
                if (this.enforceChangePassword) {
                    userMustChangePasswordPromise = this.authService.getMustChangePasswordPromise()
                        .then((mustChangePassword) => {
                            if (mustChangePassword) {
                                this.authService.startChangeUserPasswordProcess();
                            }
                            return true;
                        })
                        .then(() => termsAndConditionsAcceptedPromise);
                } else {
                    userMustChangePasswordPromise = termsAndConditionsAcceptedPromise;
                }

                const policy: MapPolicy = (route.data ? route.data.policy : null);
                let authorizedByPolicyPromise: Promise<boolean>;
                if (policy !== null && policy !== undefined) {
                    authorizedByPolicyPromise = this.authService.isUserAuthorized(policy).then((userAuthorized) => {
                        if (!userAuthorized && emitEvents) {
                            this.authService.getUserUnauthorizedEmitter().emit(state.url);
                        }
                        return userAuthorized;
                    });
                } else {
                    authorizedByPolicyPromise = Promise.resolve(true);
                }

                let authorizedForFront: Promise<boolean>;
                if (route.data && route.data.hideIfFront === true) {
                    authorizedForFront = this.authService.getIsFrontOfficeUserPromise()
                        .then((isFrontOffice) => !isFrontOffice);
                } else {
                    authorizedForFront = Promise.resolve(true);
                }

                return Promise.all(
                    [userMustChangePasswordPromise,
                        authorizedByPolicyPromise,
                        authorizedForFront])
                    .then((conditions) => conditions.every((c) => c));
            } else {
                const token = this.checkForToken ? route.queryParams.token : undefined;
                if (token) {
                    this.authService.startSignin(token);
                } else {
                    this.authService.startSignin(); // Start login process  /!\ side-effect /!\
                }
                return false;
            }
        }, (error) => {
            Logger.error(error);
            return false;
        });
    }
}

@Injectable()
export class AuthGuard extends AuthGuardBase {
    constructor(authService: AuthService) {
        super(authService);
    }
}

@Injectable()
export class AuthGuardNonSpecialCases extends AuthGuardBase {
    constructor(authService: AuthService) {
        super(authService, false, false, false);
    }
}

@Injectable()
export class AuthGuardWithToken extends AuthGuardBase {
    constructor(authService: AuthService) {
        super(authService, false, false, true);
    }
}

