import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GamePlayByPlayDTO, GameActionDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';
import { Logger } from '../../../utils/logger';

@Injectable({
    providedIn: 'root',
})
export class GamePlayByPlayDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    public fetchGamePlayByPlayAction(gameActionId: number): Observable<GameActionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/game-action/${gameActionId}`);
    }

    public updateGamePlayByPlayAction(gameActionId: number, gameAction: GameActionDTO): Observable<GameActionDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/games/game-action/${gameActionId}`, gameAction);
    }

    public createGamePlayByPlayAction(gameId: number, gameAction: GameActionDTO): Observable<GameActionDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/games/${gameId}/game-action`, gameAction);
    }

    public fetchGamePlayByPlay(gameId: number): Observable<GamePlayByPlayDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/timeline-play-by-play`);
    }

    public deleteGameAction(gameId: number, gameActionId: number): Observable<any> {
        return this.http.delete(`${this.competitionApiEndpoint}/games/${gameId}/game-action/${gameActionId}`);
    }

    public deleteCompetitionOperationGamePBP(gameId: number): Observable<GamePlayByPlayDTO> {
        if (!!gameId) {
            return this.http.delete(`${this.competitionApiEndpoint}/games/${gameId}/pbp`);
        } else {
            Logger.error('delete game-pbp: invalid gameId');
        }
    }
}
