import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, SanctionApplicationDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { SanctionApplicationFilters } from '@fiba/models/sanction-application/sanction-application';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class SanctionApplicationDataService {
    protected sanctionApplicationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.sanctionApplicationApiEndpoint = config.sanctionApplicationApiEndpoint;
    }

    public fetchList(filters: SanctionApplicationFilters): Observable<ApiListResponse<SanctionApplicationDTO>> {
        return this.http.get(this.sanctionApplicationApiEndpoint, filters.getSearchParams());
    }
    public fetchSanctionApplicationInformation(sanctionApplicationId: number): Observable<SanctionApplicationDTO> {
        return this.http.get(`${this.sanctionApplicationApiEndpoint}/${sanctionApplicationId}/informations`);
    }
    public updateSanctionApplicationInformation(model: SanctionApplicationDTO): Observable<any> {
        return this.http.put(`${this.sanctionApplicationApiEndpoint}/${model.sanctionApplicationId}/informations`, model);
    }
    public createSanctionApplicationInformation(model: SanctionApplicationDTO): Observable<any> {
        return this.http.post(`${this.sanctionApplicationApiEndpoint}/informations`, model);
    }
    public deleteSanctionApplication(sanctionApplicationId: number): Observable<any> {
        return this.http.delete(`${this.sanctionApplicationApiEndpoint}/${sanctionApplicationId}`);
    }
    public downloadExport(filters: SanctionApplicationFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.sanctionApplicationApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }
}
