export class Season {
    seasonId: string = null;
    seasonDescription: string = null;

    constructor(source?: any) {
        if (source) {
            if (source.sourceId) {
                this.seasonId = String(source.seasonId);
                this.seasonDescription = String(source.seasonDescription);
            } else {
                this.seasonId = String(source);
                this.seasonDescription = String(source);
            }
        }
    }
}
