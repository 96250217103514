import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApiListResponse,
    ItemDTO, TransferInvestigationDTO, TransferLocRequestDTO,
    TransferRequestDTO,
    TransferRequestListItemDTO,
    TransferRequestStatusDTO, ThingDTO, PersonDTO, ConfigurationTransferRequestBusinessRuleSelectorDTO, NotificationDTO, TransferRequestInvestigationDTO,
    TransferRequestStatusHistoryDTO
} from '@fiba/models';

import { TransferNbaLeaguesCountersDTO } from '@fiba/models/dtos/generated/transfer-nba-leagues-counters-dto';
import { TransferRequestFilters } from '@fiba/models/transfer-request/transfer-request';
import { DataCachedService } from '../utils/data-cached.service';
import { HttpService, IFileData } from '../utils/http.service';
import { TransferRequestFoDashboardDTO } from '@fiba/models/dtos/generated/transfer-request-fo-dashboard-dto';
import { TransferRequestInvestigationStatusDTO } from '@fiba/models/dtos/generated/transfer-request-investigation-status-dto';
import { TransferAgentInvestigationDashboardDTO } from '@fiba/models/dtos/generated/transfer-agent-investigation-dashboard-dto';
import { PlayerWithConditionalTransfersInformationsDTO } from '@fiba/models/dtos/generated/player-with-conditional-transfers-informations-dto';
import { timeout } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class TransferRequestDataService {


    protected transferRequestApiEndpoint: string;
    protected personApiEndpoint: string;
    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataTransferRequestStatusService: DataCachedService<TransferRequestStatusDTO>) {
        this.transferRequestApiEndpoint = config.transferRequestApiEndpoint;
        this.personApiEndpoint = config.personApiEndpoint;
    }

    public fetchList(filters: TransferRequestFilters): Observable<ApiListResponse<TransferRequestListItemDTO>> {
        return this.http.get(this.transferRequestApiEndpoint, filters.getSearchParams());
    }

    public fetchTransferRequestsForPerson(personId: number): Observable<TransferRequestListItemDTO[]> {
        return this.http.get(`${this.transferRequestApiEndpoint}/player/${personId}`);
    }

    public fetchPlayerWithConditionalTransfersInformations(personId: number): Observable<PlayerWithConditionalTransfersInformationsDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/player/${personId}/conditional-transfers-informations`);
    }

    public fetchStatuses(): Observable<TransferRequestStatusDTO[]> {
        return this.dataTransferRequestStatusService.fetchEntities(`${this.transferRequestApiEndpoint}/statuses`);
    }

    public fetchInvestigationStatuses(): Observable<TransferRequestInvestigationStatusDTO[]> {
        return this.dataTransferRequestStatusService.fetchEntities(`${this.transferRequestApiEndpoint}/investigation-statuses`);
    }

    public fetchConfigurationTransferRequestBusinessRules(isDistinct: boolean = false, includeInactive: boolean = false): Observable<ConfigurationTransferRequestBusinessRuleSelectorDTO[]> {
        const params: any = {};
        if (isDistinct) {
            params.isDistinct = isDistinct;
        }
        if (includeInactive) {
            params.includeInactive = includeInactive;
        }
        return this.http.get(`${this.transferRequestApiEndpoint}/configuration-business-rules`, params);
    }

    public fetchTransferRequest(transferRequestId: number): Observable<TransferRequestDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/${transferRequestId}`);
    }

    public createTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.post(`${this.transferRequestApiEndpoint}`, model);
    }

    public submitNewTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.post(`${this.transferRequestApiEndpoint}/submit`, model);
    }

    public updateTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}`, model);
    }

    public submitTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/submit`, model);
    }

    public deleteTransferRequest(model: TransferRequestDTO): Observable<boolean> {
        return this.http.delete(`${this.transferRequestApiEndpoint}/${model.transferRequestId}`);
    }

    public sendReminderTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/send-reminder`, model);
    }

    public lastReminderTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/last-reminder`, model);
    }

    public fibaAssistanceTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/fiba-assistance`, model);
    }

    public withdrawTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/withdraw`, model);
    }

    public preauthoriseTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/preauthorise`, model);
    }

    public agreeTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/agree`, model);
    }

    public grantLocAsNoReplyTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/grant-loc-as-no-reply`, model);
    }

    public disagreeTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/disagree`, model);
    }

    public grantLocTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/grant-loc`, model);
    }
    // NFR accepts the refusal of the NFO
    public acceptReplyTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/accept-reply`, model);
    }
    // Fiba intervention confirm the refusal
    public confirmDecisionRefusalByDisputeReplyTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/intervention/confirm`, model);
    }
    // Fiba intervention change the NF decision to accepted/autorised
    public rejectDecisionRefusalByDisputeReplyTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/intervention/reject`, model);
    }

    public refuseLocTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/refuse-loc`, model);
    }

    public forwardTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/forward`, model);
    }

    public forwardToNbaOrNbaglTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/forward-nba-nbagl`, model);
    }

    public cancelTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/cancel`, model);
    }

    public acknowledgeDisagreementTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/acknowledge-disagreement`, model);
    }

    public rollbackStatusTransferRequest(model: TransferRequestDTO, transferRequestStatusHistoryId: number): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/rollback-status/${transferRequestStatusHistoryId}`);
    }

    public disputeReplyTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/dispute-reply`, model);
    }

    public pendingInformationTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/pending-information`, model);
    }

    public pendingDocumentTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/pending-document`, model);
    }

    public resubmitAgreedTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/resubmit-agreed`, model);
    }

    public generateTransferRequestLoC(model: TransferRequestDTO): Observable<any> {
        return this.http.get(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/generate-loc`);
    }

    public getTransferRequestType(personId: number): Observable<ItemDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/type/${personId}`);
    }

    public fetchTransferNbaForBoDashboard(organisationCode: string): Observable<TransferNbaLeaguesCountersDTO> {
        const params = {
            organisationCode,
        };
        return this.http.get(`${this.transferRequestApiEndpoint}/nba-leagues-counters`, params);
    }

    public fetchTransferLocRequestForBoDashboard(): Observable<TransferLocRequestDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/loc-requests-counters`);
    }

    public fetchTransferInvestigationForBoDashboard(): Observable<TransferInvestigationDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/investigations-counters`);
    }

    public fetchAgentTransferInvestigationForBoDashboard(): Observable<TransferAgentInvestigationDashboardDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/agent-investigations-counters`);
    }

    public fetchPaymentStatus(viewMode: string): Observable<Map<string, ThingDTO[]>> {
        const params = {
            view: viewMode
        };
        return this.http.get(`${this.transferRequestApiEndpoint}/configurations/payments/statuses`, params);
    }

    public fetchTransferRequestDataForFoDashboard(): Observable<TransferRequestFoDashboardDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/fo-dashboard-counters`);
    }

    public fetchPersonInformation(personId: number): Observable<PersonDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/transfer-information`);
    }

    public downloadExport(filters: TransferRequestFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.transferRequestApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }

    public forwardToNfTransferRequest(model: TransferRequestDTO): Observable<TransferRequestDTO> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${model.transferRequestId}/forward-to-nf`, model);
    }
    public fetchInvestigationNotificationTemplate(transferRequestId: number, businessRuleItemId: number, nfOrganisationId: number): Observable<NotificationDTO> {
        const params: any = {};
        if (businessRuleItemId) {
            params.businessRuleItemId = String(businessRuleItemId);
        }
        if (nfOrganisationId) {
            params.nfOrganisationId = String(nfOrganisationId);
        }
        return this.http.get(`${this.transferRequestApiEndpoint}/${transferRequestId}/investigation-notification-template`, params);
    }
    public sendInvestigationNotification(transferRequestId: number, investigationId: number, businessRuleItemId: number, nfOrganisationId: number): Observable<TransferRequestInvestigationDTO> {
        const params: any = {};
        if (businessRuleItemId) {
            params.businessRuleItemId = String(businessRuleItemId);
        }
        if (nfOrganisationId) {
            params.nfOrganisationId = String(nfOrganisationId);
        }
        return this.http.put(`${this.transferRequestApiEndpoint}/${transferRequestId}/send-investigation-notification/${investigationId}`, null, params);
    }

    public fetchSanctionNotificationTemplate(transferRequestId: number, sanctionApplicationId: number): Observable<NotificationDTO> {
        return this.http.get(`${this.transferRequestApiEndpoint}/${transferRequestId}/sanction-notification-template/${sanctionApplicationId}`);
    }

    public sendSanctionNotification(transferRequestId: number, sanctionApplicationId: number): Observable<Date> {
        return this.http.put(`${this.transferRequestApiEndpoint}/${transferRequestId}/send-sanction-notification/${sanctionApplicationId}`, null);
    }
}
