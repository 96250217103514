<div class="site no-menu">
    <top-menu></top-menu>
    <div class="page-content anim02">
        <fibaNotificationDisplayWrapper></fibaNotificationDisplayWrapper>
        <div>
            <form #form="ngForm" (ngSubmit)="save()" *ngIf="model" [hidden]="isLoading" novalidate>
                    <div class="vertical space10">
                        <div class="horizontal space10 fill-all">
                            <div class="vertical space10 w50 mw450">
                                <div>
                                    <div class="horizontal space10 mw450 fill-all">
                                        <div class="form vertical space10 mw450 w40 fill-all">
                                            <div class="block filled bordered with-header fill-all">
                                                <h3>User account</h3>
                                                <div class="form vertical labels-left fill-all labels-lg">
                                                    <div class="horizontal labels-left fill-all labels-lg">
                                                        <fibaInputPassword (ngModelChange)="onChangePassword($event)" [(ngModel)]="model.password"
                                                                           fibaEqualValidator="confirmPassword"
                                                                           label="Password"
                                                                           name="password"
                                                                           reverse="true"></fibaInputPassword>
                                                        <span *ngIf="model.totalRules > 0">
                                                            {{model.totalValidRules}}
                                                            / {{model.totalRules}}
                                                        </span>
                                                    </div>
                                                    <fibaInputPassword [(ngModel)]="model.cli_confirmPassword" fibaEqualValidator="password"
                                                                       label="Confirm password"
                                                                       name="confirmPassword"></fibaInputPassword>
                                                    <button (click)="generatePassword()" kendoButton type="button">
                                                        <i class="fa fa-key"></i>Generate password
                                                    </button>
                                                    <div class="horizontal fill-all space10 password-rules">
                                                        <div *ngIf="passwordValidationRules.MustHaveRules.RulesResult.length > 0">
                                                            <div class="horizontal space10 mw300 fill-all">
                                                                <div class="block filled bordered with-header mw300 w40 fill-all">
                                                                    <h3>{{passwordValidationRules.MustHaveRules.Name}}</h3>
                                                                    <div class="form vertical">
                                                                        <span>{{passwordValidationRules.MustHaveRules.Description}}</span>
                                                                        <div *ngFor="let rule of passwordValidationRules.MustHaveRules.RulesResult">
                                                                            <span>
                                                                                <i [ngClass]="{'fa-check pw-valid': rule.isValid,'fa-times pw-invalid':!rule.isValid}"
                                                                                   class="fa"></i>{{rule.getMessage()}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="passwordValidationRules.MustHaveRules.RulesResult.length > 0">
                                                            <div class="horizontal space10 mw300 fill-all">
                                                                <div class="block filled bordered with-header mw300 w40 fill-all">
                                                                    <h3>{{passwordValidationRules.NiceToHaveRules.Name}}</h3>
                                                                    <div class="form vertical">
                                                                        <span>{{passwordValidationRules.NiceToHaveRules.Description}}</span>
                                                                        <div *ngFor="let rule of passwordValidationRules.NiceToHaveRules.RulesResult">
                                                                            <span>
                                                                                <i [ngClass]="{'fa-check pw-valid': rule.isValid,'fa-times pw-invalid':!rule.isValid}"
                                                                                   class="fa"></i>{{rule.getMessage()}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="button-bar right">
                            <div>
                                <button [primary]="true" kendoButton type="submit">
                                    <i class="fa fa-lg fa-check-circle"></i>Save
                                </button>
                                <button (click)="logout()" kendoButton type="button">
                                    <i class="fa fa-lg fa-ban"></i>Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
        </div>
    </div>
</div>
