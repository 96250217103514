import { Automapper } from '@fiba/utils/automapper';

export class AirportResult {
    totalResultsCount: number = null;
    geonames: Airport[];

    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
            this.geonames = !source.geonames ? [] : Automapper.autoMapArray(Airport, source.geonames);
        }
    }
}

export class Airport {
    geonameId: number = null;
    countryName: string = null;
    adminName1: string = null;
    toponymName: string = null;
    displayName: string;

    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
            if (this.adminName1) {
                this.displayName = `${this.toponymName}, ${this.adminName1}, ${this.countryName}`;
            } else {
                this.displayName = `${this.toponymName}, ${this.countryName}`;
            }
        }
    }
}
