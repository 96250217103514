import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ClubDataService } from '@fiba/data-services';
import { FibaPickerBase } from '@fiba/forms/base/fiba-picker-base';
import { ClubDTO, ClubListItemDTO, OrganisationDTO, ThingDTO, ZoneDTO, OrganisationStatusCodes } from '@fiba/models';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';

import { DialogService } from '@progress/kendo-angular-dialog';

import { FibaPopupClubComponent } from '../../components/club/fiba-popup-club/fiba-popup-club.component';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'fibaPickerClub',
    templateUrl: './fiba-picker-club.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaPickerClubComponent),
            multi: true,
        },
        ClubDataService,
    ],
    host: { class: 'fiba-input' },
})
export class FibaPickerClubComponent extends FibaPickerBase<ClubDTO> implements OnInit {

    // Override the declaration, getter and setter to use only the DTO Object type
    protected _value: ClubDTO; // Value to bind to the input
    public _isGenderCodeReadOnly = true;
    public get value() {
        return this._value;
    }

    public set value(value: ClubDTO) {
        let val: ClubDTO = value;
        if (typeof val === 'undefined') {
            val = null;
        }
        if (val !== this._value) {
            this._value = val;
            this.valueChanged(val);
        }
    }

    @ViewChild('pickerCombobox', { static: true }) public comboBox: ComboBoxComponent;
    public listItems: ThingDTO[] = [];

    @Input() public mergeMode: boolean = false;
    @Input() public teamDelegationMode: boolean = false;
    @Input() protected delay = 300;
    public simpleValue: ThingDTO;

    public _placeholder = 'Search clubs...';
    @Input()
    public set placeholder(value: string) {
        this._placeholder = value;
    }

    protected _organisation: OrganisationDTO;
    @Input()
    protected set organisation(value: OrganisationDTO) {
        this._organisation = value;
    }

    public _zone: ZoneDTO;
    @Input()
    public set zone(value: ZoneDTO) {
        this._zone = value;
    }

    protected _licenseCategoryCode: string;
    @Input()
    protected set licenseCategoryCode(value: string) {
        this._licenseCategoryCode = value;
    }

    protected _season: number;
    @Input()
    protected set season(value: number) {
        this._season = value;
    }

    protected _genderCode: string;

    @Input() public set isGenderCodeReadOnly(value: boolean) {
        this._isGenderCodeReadOnly = value;
    }
    public get isGenderCodeReadOnly(): boolean {
        return this._isGenderCodeReadOnly && !!this._genderCode;
    }

    @Input() public set genderCode(value: string) {
        this._genderCode = value;
    }

    @Input() protected allowCreateTemporaryClub = false;
    @Input() protected showClubsForAllNf = false;
    @Input() public nameHistoryYear: number = null;

    /* display a "new" keyword next to the picker if the selected club is in status Temporary */
    @Input() public displayNewTag = true;
    public readonly OrganisationStatusCodes_Temporary = OrganisationStatusCodes.Temporary;

    constructor(
        protected injector: Injector,
        protected clubDataService: ClubDataService,
        protected notificationService: NotificationService,
        private dialogService: DialogService) {
        super(injector);
        this.idField = 'organisationId';
    }

    public ngOnInit() {
        super.ngOnInit();
        if (this.modelId) {
            this.clubDataService.fetchClub(this.modelId, this.nameHistoryYear).subscribe(
                (response) => {
                    this.value = response;

                    this.modelId = response ? response[this.idField] : null;
                    this.modelIdChange.emit(this.modelId);
                    this.modelChange.emit(this.value);
                },
                (error) => { },
                () => { },
            );
        }
        this.comboBox.filterChange.pipe(
            debounceTime(this.delay),
            switchMap((searchValue) => this.fetchAutocompleteObservable(searchValue))
        )
            .subscribe(
                (result) => {
                    this.listItems = result;
                },
            );
    }

    public filterHandler(value) {
        this.filterList(value);
    }

    protected filterList(value: string = null) {
        this.fetchAutocompleteObservable(value).subscribe(
            (data) => {
                this.listItems = data;
            },
        );
    }

    private fetchAutocompleteObservable(value: string = null): Observable<ThingDTO[]> {
        const genderCode = this._isGenderCodeReadOnly ? this._genderCode : null;
        return this.clubDataService.fetchAutoCompleteList(
            value,
            this._organisation,
            this._zone,
            this._licenseCategoryCode,
            this._season,
            genderCode,
            this.mergeMode
        )
    }

    protected updatePickerClub(club: ThingDTO = null) {
        if (club) {
            this.clubDataService.fetchClub(club.id, this.nameHistoryYear).subscribe(
                (response) => {
                    this.value = response;
                    super.saveHandler(response);
                },
                (error) => {
                    this.notificationService.emitNotification(NotificationType.Error, `Fetch club ${club.id} failed`);
                },
                () => { },
            );
        } else {
            super.saveHandler(null);
        }
    }

    /**
     * Callback after value is changed. Needed when picker is initialized with existing value
     *
     * @param val
     */
    public valueChanged(val: ClubDTO) {
        if (val) {
            this.simpleValue = { id: val.organisationId, name: val.displayName } as ThingDTO;

            // the list of Items must contain the new value, otherwise the
            // value change will be ignored by the combobox
            this.listItems = [this.simpleValue];
        } else {
            this.simpleValue = null;
            this.listItems = null;
        }
    }

    /**
     * Called when user clicks on autocomplete list item, or reset
     *
     * @param newValue ThingDTO that contains the item clicked on, or null if reset
     */
    public valueChangeHandler(newValue: ThingDTO): void {
        this.updatePickerClub(newValue);
    }

    /**
     * Called when the user clicks on the OK button of the popup
     *
     * @param item
     */
    public popupSaveHandler(item: ClubListItemDTO) {
        this.simpleValue = { id: item.organisationId, name: item.name } as ThingDTO;

        this.updatePickerClub(this.simpleValue);
    }

    public editHandler() {
        super.editHandler();
        this.showPopup();
    }

    public showPopup(): void {
        const dialogRef = this.dialogService.open({
            // Show component
            content: FibaPopupClubComponent,
        });
        const popup = dialogRef.content.instance;

        popup.model = this.tmpValue;
        popup.isNew = true;
        popup.organisation = this._organisation;
        popup.zone = this._zone;
        popup.contextCode = this.contextCode;
        popup.allowCreateTemporaryClub = this.allowCreateTemporaryClub;
        popup.showClubsForAllNf = this.showClubsForAllNf;
        popup.licenseCategoryCode = this._licenseCategoryCode;
        popup.season = this._season;
        popup.genderCode = this._genderCode;
        popup.isGenderCodeReadOnly = this.isGenderCodeReadOnly;
        popup.mergeMode = this.mergeMode;
        popup.teamDelegationMode = this.teamDelegationMode;

        popup.save.subscribe(() => {
            this.popupSaveHandler(popup._model);
        });
    }
}
