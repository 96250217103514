import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { CompetitionTeamsRegistrationDTO, CompetitionTeamDocumentDTO, CompetitionTeamStatusDTO, CompetitionTeamDocumentStatusDTO, ItemDTO, TeamRegistrationSummaryDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { DataCachedService } from '../../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionTeamsRegistrationDataService {
    protected competitionApiEndpoint: string;
    protected competitionTeamStatusApiEndpoint: string;
    protected competitionAutocompleteApiEndpoint: string;
    protected competitionTeamDocumentStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataCompetitionTeamStatusService: DataCachedService<CompetitionTeamStatusDTO>,
        protected dataCompetitionTeamDocumentStatusService: DataCachedService<CompetitionTeamDocumentStatusDTO>) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.competitionTeamStatusApiEndpoint = config.competitionTeamStatusApiEndpoint;
        this.competitionAutocompleteApiEndpoint = config.competitionAutocompleteApiEndpoint;
        this.competitionTeamDocumentStatusApiEndpoint = config.competitionTeamDocumentStatusApiEndpoint;
    }

    fetchCompetitionTeamsRegistration(competitionId: number): Observable<CompetitionTeamsRegistrationDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/teams-registration`);
    }

    /**
     * Fetch competition team status
     */
    fetchCompetitionTeamStatuses(): Observable<CompetitionTeamStatusDTO[]> {
        return this.dataCompetitionTeamStatusService.fetchEntities(this.competitionTeamStatusApiEndpoint);
    }

    /**
     * Fetch competition team document status
     */
    fetchCompetitionTeamDocumentStatuses(): Observable<CompetitionTeamDocumentStatusDTO[]> {
        return this.dataCompetitionTeamDocumentStatusService.fetchEntities(this.competitionTeamDocumentStatusApiEndpoint);
    }

    fetchCompetitionTeamsRegistrationDocuments(competitionId: number, competitionTeamId: number): Observable<CompetitionTeamDocumentDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/teams-registration/${competitionTeamId}/documents`);
    }

    fetchCompetitionTeamRegistrationDocumentsDescription(): Observable<ItemDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/competition-team-registration-documents-description`);
    }

    createCompetitionTeamsRegistration(model: CompetitionTeamsRegistrationDTO): Observable<CompetitionTeamsRegistrationDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/teams-registration`, model);
    }

    updateCompetitionTeamsRegistration(model: CompetitionTeamsRegistrationDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/teams-registration`, model);
    }

    /**
     * supposed to be used by a NF front user. Will return the state of the team registration for the next competition deadline.
     */
    fetchNextNFTeamRegistrationSummary(competitionId: number): Observable<TeamRegistrationSummaryDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/teams-registration/next-summary`);
    }
}
