import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RosterOverviewGameGameDTO, RosterOverviewStructureDTO, RosterOverviewWindowGameDTO, RosterOverviewRoundRoundDTO, RosterOverviewWindowWindowDTO, RosterOverviewCompetitionCompetitionDTO} from '@fiba/models';
import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class RostersOverviewDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    public fetchStructure(competitionId: number): Observable<RosterOverviewStructureDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosterstructure`);
    }

    public fetchRosterOverviewGameGame(competitionId: number, roundId: number, gameDay: number): Observable<RosterOverviewGameGameDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosteroverview`, {
            gameDay: gameDay.toString(),
            roundId: roundId.toString(),
        });
    }

    public fetchRosterOverviewWindowGame(competitionId: number, windowId: number, gameDay: number): Observable<RosterOverviewWindowGameDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosteroverview`, {
            gameDay: gameDay.toString(),
            windowId: windowId.toString(),
        });
    }

    public fetchRosterOverviewWindowWindow(competitionId: number, windowId: number): Observable<RosterOverviewWindowWindowDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosteroverview`, {
            windowId: windowId.toString(),
        });
    }

    public fetchRosterOverviewRoundRound(competitionId: number, roundId: number): Observable<RosterOverviewRoundRoundDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosteroverview`, {
            roundId: roundId.toString(),
        });
    }

    public fetchRosterOverviewCompetitionCompetition(competitionId: number): Observable<RosterOverviewCompetitionCompetitionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rosteroverview`);
    }
}
