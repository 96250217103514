import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentDTO, DocumentGenericDTO, ItemDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class DocumentDataService {

    protected apiEndpoint: string;
    protected azureDocumentApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.apiEndpoint = config.documentApiEndpoint;
        this.azureDocumentApiEndpoint = config.azureDocumentApiEndpoint;
    }

    public getMyFileFromBackend(documentId: number, pwc: boolean = false): Observable<IFileData> {
        return this.http.getFileData(`${this.apiEndpoint}/${documentId}?pwc=${pwc}`);
    }

    public editDocument(documentId: number, documentTmpId: number): Observable<DocumentDTO> {
        return this.http.put(`${this.apiEndpoint}/${documentId}/edit-document-from/${documentTmpId}`, null);
    }

    public deleteDocument(documentId: number): Observable<any> {
        return this.http.delete(`${this.apiEndpoint}/${documentId}`);
    }

    public updloadDocument(files: File[], title: string, item: ItemDTO, entityType: string, entityId: number): Observable<DocumentDTO> {
        const formData: FormData = new FormData();

        formData.append('documentId', undefined);
        formData.append('entityType', entityType);
        formData.append('entityDocumentType', null);
        formData.append('entityId', String(entityId));
        formData.append('title', title);
        formData.append('typeItemId', String(item.itemId));

        for (const file of files) {
            formData.append('files', file, file.name);
        }

        return this.http.postForm(`${this.apiEndpoint}`, formData);
    }

    // Azure documents

    public getMyAzureFileFromBackend(documentId: number, pwc: boolean = false): Observable<IFileData> {
        return this.http.getFileData(`${this.azureDocumentApiEndpoint}/${documentId}?pwc=${pwc}`);
    }
    public uploadDocumentToAzureStorage(files: File[], title: string, item: ItemDTO, entityType: string, entityId: number, documentId: number): Observable<DocumentDTO> {
        const formData: FormData = new FormData();

        formData.append('documentId', String(documentId));
        formData.append('entityType', entityType);
        formData.append('entityDocumentType', null);
        formData.append('entityId', String(entityId));
        formData.append('title', title);
        formData.append('typeItemId', String(item.itemId));

        for (const file of files) {
            formData.append('files', file, file.name);
        }

        return this.http.postForm(`${this.azureDocumentApiEndpoint}`, formData);
    }

    public deleteDocumentFromAzureStorage(documentId: number): Observable<any> {
        return this.http.delete(`${this.azureDocumentApiEndpoint}/${documentId}`);
    }
}
