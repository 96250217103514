import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, CompetitionDTO, CompetitionGameManagementDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionGameManagementDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionGameManagement(competitionId: number): Observable<CompetitionGameManagementDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/game-management`);
    }

    updateCompetitionGameManagement(model: CompetitionGameManagementDTO): Observable<any> {
        // Not to be used, game management screen does not provide a Save option
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/game-management`, model);
    }
}
