import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiListResponse, OfficialRegistrationListItemDTO, OfficialRegistrationStatusDTO, TechnicalDelegateEventCandidateFilters, OfficialRegistrationEditDTO, OfficialRegistrationsEditDTO, EventListItemDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class OfficialRegistrationDataService {
    protected officialRegistrationApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataOfficialRegistrationStatusService: DataCachedService<OfficialRegistrationStatusDTO>) {
        this.officialRegistrationApiEndpoint = config.officialRegistrationApiEndpoint;
    }

    public fetchOfficialRegistrationList(filters: TechnicalDelegateEventCandidateFilters): Observable<ApiListResponse<OfficialRegistrationListItemDTO>> {
        return this.http.get(this.officialRegistrationApiEndpoint, filters.getSearchParams());
    }

    public addOfficialRegistrations(eventId: number, model: OfficialRegistrationsEditDTO): Observable<OfficialRegistrationsEditDTO> {
        return this.http.post(`${this.officialRegistrationApiEndpoint}/${eventId}/`, model);
    }

    public notifyOfficialRegistration(eventId: number, model: OfficialRegistrationsEditDTO): Observable<OfficialRegistrationsEditDTO> {
        return this.http.put(`${this.officialRegistrationApiEndpoint}/${eventId}/notify`, model);
    }

    public deleteOfficialRegistration(eventId: number, officialRegistrationId: number): Observable<any> {
        return this.http.delete(`${this.officialRegistrationApiEndpoint}/${eventId}/${officialRegistrationId}`);
    }

    public fetchEvents(): Observable<EventListItemDTO[]> {
        return this.http.get(`${this.officialRegistrationApiEndpoint}/events`);
    }

    public fetchStatuses(): Observable<OfficialRegistrationStatusDTO[]> {
        return this.http.get(`${this.officialRegistrationApiEndpoint}/status`);
    }

}
