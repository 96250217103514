import { Automapper } from '@fiba/utils/automapper';

export class CityResult {
    totalResultsCount: number = null;
    geonames: City[];

    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
            this.geonames = !source.geonames ? [] : Automapper.autoMapArray(City, source.geonames);
        }
    }
}

export class City {
    geonameId: number = null;
    countryName: string = null;
    adminName1: string = null;
    name: string = null;
    timeZoneId: string;
    gmtOffset: number;
    dstOffset: number;
    displayName: string;

    constructor(source?: any) {
        if (source) {
            Automapper.autoMap(this, source);
            this.timeZoneId = source.timezone.timeZoneId;
            this.gmtOffset = source.timezone.gmtOffset;
            this.dstOffset = source.timezone.dstOffset;
            if (this.adminName1) {
                this.displayName = `${this.name}, ${this.adminName1}, ${this.countryName}`;
            } else {
                this.displayName = `${this.name}, ${this.countryName}`;
            }
        }
    }
}
