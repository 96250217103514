<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox #pickerCombobox
                    [data]="listItems"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [placeholder]="_placeholder"
                    [filterable]="true"
                    [(ngModel)]="simpleValue"
                    (valueChange)="valueChangeHandler($event)"
                    (cancel)="cancelHandler()"
                    [disabled]="disabled"
                    class="no-arrow fiba-picker"></kendo-combobox>
    <button kendoButton type="button"
            (click)="editHandler()"
            class="icon-only"
            [disabled]="disabled">
        <i class="fa fa-lg fa-search"></i>
    </button>

    <span *ngIf="displayNewTag && value && value.clubStatus && value.clubStatus.code == OrganisationStatusCodes_Temporary"
          style="color: darkorange; font-weight: bold;">
        NEW
    </span>

    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
