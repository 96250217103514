import { AgentDataService } from './agent/agent-data.service';
import { AgentPreRegistrationDataService } from './agent/agent-pre-registration-data.service';
import { AgentRegistrationDataService } from './agent/agent-registration-data.service';
import { AgentRelationDataService } from './agent/agent-relation-data.service';
import { AgreementDataService } from './equipment/agreement-data.service';
import { ApprovalCategoryDataService } from './equipment/approval-category-data.service';
import { CityDataService } from './common/city-data.service';
import { ClassificationService } from './competition/operation/classification.service';
import { ClubDataService } from './organisation/club-data.service';
import { CoachLicenseDataService } from './coach-license/coach-license-data.service';
import { CompetitionConfigurationDataService } from './competition/setup/competition-configuration-data.service';
import { CompetitionConfigurationRoleGameAssignmentRoleDataService } from './competition/preparation/competition-configuration-role-game-assignment-role-data.service';
import { CompetitionDataService } from './competition/competition-data.service';
import { CompetitionFinalRosterDataService } from './competition/operation/competition-final-roster-data.service';
import { CompetitionGameAssignmentDataService } from './competition/preparation/competition-game-assignment-data.service';
import { CompetitionGameInformationFormDataService } from './competition/competition-game-information-form-data.service';
import { CompetitionGameManagementDataService } from './competition/competition-game-management-data.service';
import { CompetitionInformationDataService } from './competition/competition-information-data.service';
import { CompetitionNFNominationDataService } from './competition/preparation/competition-nf-nomination-data.service';
import { CompetitionNominationDataService } from './competition/preparation/competition-nomination-data.service';
import { CompetitionPickerDataService } from './common/competition-data.service';
import { CompetitionRankingDataService } from './competition/setup/competition-ranking-data.service';
import { CompetitionStructureDataService } from './competition/setup/competition-structure-data.service';
import { CompetitionTeamFormRegistrationDataService } from './competition-team-form-registration/competition-team-form-registration-data.service';
import { CompetitionTeamsDataService } from './competition/competition-teams-data.service';
import { CompetitionTeamsDelegationDataService } from './competition/preparation/competition-teams-delegation-data.service';
import { CompetitionTeamsRegistrationDataService } from './competition/preparation/competition-teams-registration-data.service';
import { CompetitionTechnicalDelegateDashboardDataService } from './competition/competition-technical-delegate-dashboard-data.service';
import { CompetitionTechnicalDelegateReportingFormDataService } from './competition/competition-technical-delegate-reporting-form-data.service';
import { CompetitionTechnicalMeetingConfigurationDataService } from './competition/preparation/competition-technical-meeting-configuration-data.service';
import { CompetitionTechnicalMeetingDataService } from './competition/operation/competition-technical-meeting-data.service';
import { CompetitionTimelineConfigurationService } from './competition/competition-timeline-configuration.service';
import { CompetitionTimeManagementDataService } from './competition/competition-time-management-data.service';
import { ConfigurationContactCategoryDataService } from './common/configuration-contact-email-category-data.service';
import { CountryDataService } from './common/country-data.service';
import { DashboardCompetitionDataService } from './competition/dashboard-competition-data.service';
import { DataCachedService } from './utils/data-cached.service';
import { DocumentDataService } from './common/document-data.service';
import { EquipmentDashboardDataService } from './equipment/equipment-dashboard-data.service';
import { EquipmentProductDataService } from './equipment/equipment-product-data.service';
import { EventDataService } from './event/event-data.service';
import { FeeDataService } from './equipment/equipment-fee-data.service';
import { FibaPartnerDataService } from './equipment/fiba-partner-data.service';
import { FinalRosterConfigurationDataService } from './competition/final-roster-configuration-data.service';
import { FinalRosterDataService } from './competition/final-roster-data.service';
import { FinancialDataPackageDataService } from './financial-data-package/financial-data-package-data.service';
import { FinancialReportingDataService } from './financial-reporting/financial-reporting-data.service';
import { FormRegistrationCopyToEntityDataService } from './form-registration/form-registration-copy-to-entity-data.service';
import { FormRegistrationDataService } from './form-registration/form-registration-data.service';
import { FormRegistrationStatusDataService } from './form-registration/form-registration-status-data.service';
import { GameDetailsDataService } from './competition/operation/game-details-data.service';
import { GameInformationFormRegistrationDataService } from './game-information-form-registration/game-information-form-registration-data.service';
import { GameOfficialDataService } from './game-official/game-official-data.service';
import { GamePlayByPlayDataService } from './competition/operation/game-play-by-play-data.service';
import { GameResultsDataService } from './competition/operation/game-results-data.service';
import { GameSanctionsDataService } from './competition/operation/game-sanctions-data.service';
import { GameStatisticsDataService } from './competition/operation/game-statistics-data.service';
import { HttpService } from './utils/http.service';
import { InsurancePolicyDataService } from './competition/insurance-policy/insurance-policy-data.service';
import { ItemDataService } from './common/item-data.service';
import { NationalFederationDataService } from './organisation/national-federation-data.service';
import { OfficialLicenseDataService } from './official-license/official-license-data.service';
import { OfficialLicenseRequestDataService } from './official-license/official-license-registration-data.service';
import { OfficialRegistrationDataService } from './technical-delegate/official-registration-data.service';
import { OrganisationDataService } from './organisation/organisation-data.service';
import { PartnerApplicationDataService } from './equipment/partner-application-data.service';
import { PaymentDataService } from './payment/payment-data.service';
import { PersonAgentLicenseDataService } from './agent/person-agent-license-data.service';
import { PersonCheckDuplicateDataService } from './person/person-check-duplicate-data.service';
import { PersonCoachLicenseDataService } from './coach-license/person-coach-license-data.service';
import { PersonDataService } from './person/person-data.service';
import { PersonGenericLicenseDataService } from './person/person-generic-license-data.service';
import { PersonOfficialLicenseDataService } from './official-license/person-official-license-data.service';
import { PlayerLicenseDataService } from './player-license/player-license-data.service';
import { ProductApplicationDataService } from './equipment/product-application-data.service';
import { RankingUtilsService } from './competition/ranking-utils.service';
import { ReportDataService } from './common/report-data.service';
import { ReportsInfoDataService } from './competition/operation/reports-info-data.service';
import { RoleDataService } from './person/role-data.service';
import { RoleFamilyDataService } from './person/role-family-data.service';
import { RoleTypeDataService } from './person/role-type-data.service';
import { RostersOverviewDataService } from './competition/operation/rosters-overview-data.service';
import { SanctionApplicationDataService } from './sanction-application/sanction-application-data.service';
import { SanctionDataService } from './sanction/sanction-data.service';
import { SiteVisitDataService } from './site-visit/site-visit-data.service';
import { TechnicalDelegateReportingFormRegistrationDataService } from './technical-delegate-reporting-form/technical-delegate-reporting-form-registration-data.service';
import { TechnicalMeetingStatusDataService } from './competition/technical-meeting-status-data.service';
import { TermsAndConditionsDataService } from './common/terms-and-conditions-data.service';
import { TestInstituteDataService } from './equipment/test-institute-data.service';
import { TransferRequestDataService } from './transfer-request/transfer-request-data.service';
import { TransferRequestDocumentStatusDataService } from './common/transfer-request-document-status-data.service';
import { UserDataService } from './user/user-data.service';
import { VenueDataService } from './competition/venue-data.service';
import { VenueFormRegistrationDataService } from './venue-form-registration/venue-form-registration-data.service';
import { WabcCourseDataService } from './wabc/wabc-course-data.service';
import { WabcMemberDataService } from './wabc/wabc-member-data.service';
import { WindowsConfigurationService } from './competition/windows-configuration-data.service';
import { ZoneDataService } from './common/zone-data.service';


export const DATA_SERVICES_PROVIDERS: any[] = [
    AgentDataService,
    AgentPreRegistrationDataService,
    AgentRegistrationDataService,
    AgentRelationDataService,
    AgreementDataService,
    ApprovalCategoryDataService,
    CityDataService,
    ClassificationService,
    ClubDataService,
    CoachLicenseDataService,
    CompetitionConfigurationDataService,
    CompetitionConfigurationRoleGameAssignmentRoleDataService,
    CompetitionDataService,
    CompetitionFinalRosterDataService,
    CompetitionGameAssignmentDataService,
    CompetitionGameInformationFormDataService,
    CompetitionGameManagementDataService,
    CompetitionInformationDataService,
    CompetitionNFNominationDataService,
    CompetitionNominationDataService,
    CompetitionPickerDataService,
    CompetitionRankingDataService,
    CompetitionStructureDataService,
    CompetitionTeamFormRegistrationDataService,
    CompetitionTeamsDataService,
    CompetitionTeamsDelegationDataService,
    CompetitionTeamsRegistrationDataService,
    CompetitionTechnicalDelegateDashboardDataService,
    CompetitionTechnicalDelegateReportingFormDataService,
    CompetitionTechnicalMeetingConfigurationDataService,
    CompetitionTechnicalMeetingDataService,
    CompetitionTimelineConfigurationService,
    CompetitionTimeManagementDataService,
    ConfigurationContactCategoryDataService,
    CountryDataService,
    DashboardCompetitionDataService,
    DataCachedService,
    DocumentDataService,
    EquipmentDashboardDataService,
    EquipmentProductDataService,
    EventDataService,
    FeeDataService,
    FibaPartnerDataService,
    FinalRosterConfigurationDataService,
    FinalRosterDataService,
    FinancialDataPackageDataService,
    FinancialReportingDataService,
    FormRegistrationCopyToEntityDataService,
    FormRegistrationDataService,
    FormRegistrationStatusDataService,
    GameDetailsDataService,
    GameInformationFormRegistrationDataService,
    GameOfficialDataService,
    GamePlayByPlayDataService,
    GameResultsDataService,
    GameSanctionsDataService,
    GameStatisticsDataService,
    HttpService,
    InsurancePolicyDataService,
    ItemDataService,
    NationalFederationDataService,
    OfficialLicenseDataService,
    OfficialLicenseRequestDataService,
    OfficialRegistrationDataService,
    OrganisationDataService,
    PartnerApplicationDataService,
    PaymentDataService,
    PersonAgentLicenseDataService,
    PersonCheckDuplicateDataService,
    PersonCoachLicenseDataService,
    PersonDataService,
    PersonGenericLicenseDataService,
    PersonOfficialLicenseDataService,
    PlayerLicenseDataService,
    ProductApplicationDataService,
    RankingUtilsService,
    ReportDataService,
    ReportsInfoDataService,
    RoleDataService,
    RoleFamilyDataService,
    RoleTypeDataService,
    RostersOverviewDataService,
    SanctionApplicationDataService,
    SanctionDataService,
    SiteVisitDataService,
    TechnicalDelegateReportingFormRegistrationDataService,
    TechnicalMeetingStatusDataService,
    TermsAndConditionsDataService,
    TestInstituteDataService,
    TransferRequestDataService,
    TransferRequestDocumentStatusDataService,
    UserDataService,
    VenueDataService,
    VenueFormRegistrationDataService,
    WabcCourseDataService,
    WabcMemberDataService,
    WindowsConfigurationService,
    ZoneDataService,
];

export { AgentDataService } from './agent/agent-data.service';
export { AgentPreRegistrationDataService } from './agent/agent-pre-registration-data.service';
export { AgentRegistrationDataService } from './agent/agent-registration-data.service';
export { AgentRelationDataService } from './agent/agent-relation-data.service';
export { AgreementDataService } from './equipment/agreement-data.service';
export { ApprovalCategoryDataService } from './equipment/approval-category-data.service';
export { AuthService, AuthGuard, AuthGuardNonSpecialCases, AuthGuardWithToken } from './auth/auth.service';
export { CityDataService } from './common/city-data.service';
export { ClassificationService } from './competition/operation/classification.service';
export { ClubDataService } from './organisation/club-data.service';
export { CoachLicenseDataService } from './coach-license/coach-license-data.service';
export { CompetitionConfigurationDataService } from './competition/setup/competition-configuration-data.service';
export { CompetitionConfigurationRoleGameAssignmentRoleDataService } from './competition/preparation/competition-configuration-role-game-assignment-role-data.service';
export { CompetitionDataService } from './competition/competition-data.service';
export { CompetitionFinalRosterDataService } from './competition/operation/competition-final-roster-data.service';
export { CompetitionGameAssignmentDataService } from './competition/preparation/competition-game-assignment-data.service';
export { CompetitionGameInformationFormDataService } from './competition/competition-game-information-form-data.service';
export { CompetitionGameManagementDataService } from './competition/competition-game-management-data.service';
export { CompetitionInformationDataService } from './competition/competition-information-data.service';
export { CompetitionNFNominationDataService } from './competition/preparation/competition-nf-nomination-data.service';
export { CompetitionNominationDataService } from './competition/preparation/competition-nomination-data.service';
export { CompetitionPickerDataService } from './common/competition-data.service';
export { CompetitionRankingDataService } from './competition/setup/competition-ranking-data.service';
export { CompetitionStructureDataService } from './competition/setup/competition-structure-data.service';
export { CompetitionTeamFormRegistrationDataService } from './competition-team-form-registration/competition-team-form-registration-data.service'
export { CompetitionTeamsDataService } from './competition/competition-teams-data.service';
export { CompetitionTeamsDelegationDataService } from './competition/preparation/competition-teams-delegation-data.service';
export { CompetitionTeamsRegistrationDataService } from './competition/preparation/competition-teams-registration-data.service';
export { CompetitionTechnicalDelegateDashboardDataService } from './competition/competition-technical-delegate-dashboard-data.service';
export { CompetitionTechnicalDelegateReportingFormDataService } from './competition/competition-technical-delegate-reporting-form-data.service';
export { CompetitionTechnicalMeetingConfigurationDataService } from './competition/preparation/competition-technical-meeting-configuration-data.service';
export { CompetitionTechnicalMeetingDataService } from './competition/operation/competition-technical-meeting-data.service';
export { CompetitionTimelineConfigurationService } from './competition/competition-timeline-configuration.service';
export { CompetitionTimeManagementDataService } from './competition/competition-time-management-data.service';
export { ConfigurationContactCategoryDataService } from './common/configuration-contact-email-category-data.service';
export { CountryDataService } from './common/country-data.service';
export { DashboardCompetitionDataService } from './competition/dashboard-competition-data.service';
export { DocumentDataService } from './common/document-data.service';
export { DocumentStatusDataService } from './common/document-status-data.service';
export { EquipmentDashboardDataService } from './equipment/equipment-dashboard-data.service';
export { EquipmentProductDataService } from './equipment/equipment-product-data.service';
export { EventDataService } from './event/event-data.service';
export { FeeDataService } from './equipment/equipment-fee-data.service';
export { FibaPartnerDataService } from './equipment/fiba-partner-data.service';
export { FinalRosterConfigurationDataService } from './competition/final-roster-configuration-data.service';
export { FinalRosterDataService } from './competition/final-roster-data.service';
export { FinancialDataPackageDataService } from './financial-data-package/financial-data-package-data.service';
export { FinancialReportingDataService } from './financial-reporting/financial-reporting-data.service';
export { FormRegistrationCopyToEntityDataService } from './form-registration/form-registration-copy-to-entity-data.service';
export { FormRegistrationDataService } from './form-registration/form-registration-data.service';
export { FormRegistrationStatusDataService } from './form-registration/form-registration-status-data.service';
export { GameDetailsDataService } from './competition/operation/game-details-data.service';
export { GameInformationFormRegistrationDataService } from './game-information-form-registration/game-information-form-registration-data.service';
export { GameOfficialDataService } from './game-official/game-official-data.service';
export { GamePlayByPlayDataService } from './competition/operation/game-play-by-play-data.service';
export { GameResultsDataService } from './competition/operation/game-results-data.service';
export { GameSanctionsDataService } from './competition/operation/game-sanctions-data.service';
export { GameStatisticsDataService } from './competition/operation/game-statistics-data.service';
export { IFileData } from './utils/http.service';
export { InsurancePolicyDataService } from './competition/insurance-policy/insurance-policy-data.service';
export { ItemDataService } from './common/item-data.service';
export { NationalFederationDataService } from './organisation/national-federation-data.service';
export { OfficialLicenseDataService } from './official-license/official-license-data.service';
export { OfficialLicenseRequestDataService } from './official-license/official-license-registration-data.service';
export { OfficialRegistrationDataService } from './technical-delegate/official-registration-data.service';
export { OrganisationDataService } from './organisation/organisation-data.service';
export { PartnerApplicationDataService } from './equipment/partner-application-data.service';
export { PaymentDataService } from './payment/payment-data.service';
export { PersonAgentLicenseDataService } from './agent/person-agent-license-data.service';
export { PersonCheckDuplicateDataService } from './person/person-check-duplicate-data.service';
export { PersonCoachLicenseDataService } from './coach-license/person-coach-license-data.service';
export { PersonDataService } from './person/person-data.service';
export { PersonGenericLicenseDataService } from './person/person-generic-license-data.service';
export { PersonOfficialLicenseDataService } from './official-license/person-official-license-data.service';
export { PlayerLicenseDataService } from './player-license/player-license-data.service';
export { ProductApplicationDataService } from './equipment/product-application-data.service';
export { RankingUtilsService } from './competition/ranking-utils.service';
export { ReportDataService } from './common/report-data.service';
export { ReportsInfoDataService } from './competition/operation/reports-info-data.service';
export { RoleDataService } from './person/role-data.service';
export { RoleFamilyDataService } from './person/role-family-data.service';
export { RoleTypeDataService } from './person/role-type-data.service';
export { RostersOverviewDataService } from './competition/operation/rosters-overview-data.service';
export { SanctionApplicationDataService } from './sanction-application/sanction-application-data.service';
export { SanctionDataService } from './sanction/sanction-data.service';
export { SiteVisitDataService } from './site-visit/site-visit-data.service';
export { TechnicalDelegateReportingFormRegistrationDataService } from './technical-delegate-reporting-form/technical-delegate-reporting-form-registration-data.service';
export { TechnicalMeetingStatusDataService } from './competition/technical-meeting-status-data.service';
export { TermsAndConditionsDataService } from './common/terms-and-conditions-data.service';
export { TestInstituteDataService } from './equipment/test-institute-data.service';
export { TransferRequestDataService } from './transfer-request/transfer-request-data.service';
export { TransferRequestDocumentStatusDataService } from './common/transfer-request-document-status-data.service';
export { UserDataService } from './user/user-data.service';
export { VenueDataService } from './competition/venue-data.service';
export { VenueFormRegistrationDataService } from './venue-form-registration/venue-form-registration-data.service';
export { WabcCourseDataService } from './wabc/wabc-course-data.service';
export { WabcMemberDataService } from './wabc/wabc-member-data.service';
export { WindowsConfigurationService } from './competition/windows-configuration-data.service';
export { ZoneDataService } from './common/zone-data.service';
