<form>
    <div class="vertical space10">
        <div class="block filled bordered with-header">
            <h3>Search</h3>
            <div class="form horizontal labels-above">

                <fibaInputText name="code" label="Code" [(ngModel)]="filters.code"></fibaInputText>

                <fibaInputText name="articleNumber" label="Article Number" [(ngModel)]="filters.articleNumber"></fibaInputText>

                <fibaInputText name="keyword" label="Keyword" [(ngModel)]="filters.keyword"></fibaInputText>

                <fibaSelectZone name="zone" label="Zone" [(ngModel)]="filters['zone']" [(modelId)]="filters['zoneId']"></fibaSelectZone>

            </div>
        </div>
        <div class="button-bar">
            <div>
                <button type="submit" kendoButton
                        (click)="filter()"
                        [primary]="true">
                    <i class="fa fa-lg fa-check-circle"></i>
                    Apply filters
                </button>
                <button type="button"
                        (click)="reset()"
                        kendoButton>
                    <i class="fa fa-lg fa-refresh"></i>
                    Reset filters
                </button>
            </div>
        </div>
        <span *ngIf="isLoading">Loading...</span>
        <kendo-grid *ngIf="!isLoading"
                    #grid
                    class="no-scrollbar navigable"
                    [data]="sanctions"
                    [pageSize]="filters.take"
                    [skip]="filters.skip"
                    [sortable]="{ mode: 'single' }"
                    [sort]="sort"
                    [pageable]="true"
                    [selectable]="true"
                    (selectionChange) ="handleSelChange($event)"
                    (pageChange)="handlePageChange($event)">
            <kendo-grid-column title="Sanction Code" field="code">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.code}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Article Number" field="articleNumber">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.articleNumber}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Zone" field="zone.officialName">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.zone?.officialName}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Fiba Regulation" field="fibaregulation">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.fibaregulation}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Fine Text" field="fineText">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.fineText}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Amount" field="fineDefaultAmount">
                <ng-template kendoGridCellTemplate let-sanction>
                    <span>{{sanction.fineDefaultAmount}}</span>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</form>