import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { HttpService } from '../utils/http.service';
import { Logger } from '@fiba/utils/logger';
// Models
import { LicenseCreateDTO } from '@fiba/models/dtos/generated/license-create-dto';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PersonGenericLicenseDataService {
    private readonly apiEndPoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig,
    ) {
        this.apiEndPoint = config.personApiEndpoint;
    }

    public createGenericLicense(model: LicenseCreateDTO): Observable<LicenseCreateDTO> {
        return this.http.post(`${this.apiEndPoint}/${model.personId}/generic-licenses`, model);
    }
}
