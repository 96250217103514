import { Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';

export enum Direction { UNKNOWN, NEXT, PREV }

@Component({
    selector: 'fibaCardSlider',
    templateUrl: './fiba-card-slider.component.html',
    styleUrls: ['./fiba-card-slider.component.css'],
})

export class FibaCardSliderComponent implements OnInit {
    @ContentChild(TemplateRef) template: TemplateRef<any>;
    private firstInit = true;
    private _isSettingSlide = false;
    private _initSlide: any = null;
    private _preselectedSlide: any = null;
    @Input() emitOnSlidesSet = true;

    @Input()
    protected set initSlide(value: any) {
        this._initSlide = value;
        this.selectSlide(false);
    }
    protected get initSlide(): any {
        return this._initSlide;
    }

    @Input()
    protected set preSelectedSlide(value: any) {
        this._preselectedSlide = value;
        this.preSelectSlide();
    }

    @Input()
    protected set slides(value: Array<any>) {
        this.model = [];
        value.forEach(x => {
            this.model.push({ selected: false, preselected: false, slide: x });
        });
        this.selectSlide();
        this.preSelectSlide();
        this.totalCards = this.model.length;
    };

    @Input() protected preSelectComponent;

    private selectSlide(emit: boolean = true): void {
        if (!this._isSettingSlide) {
            this._isSettingSlide = true;
            const selectedSlide = this.model.find(s => s.slide === this.initSlide);
            const index = this.model.findIndex(s => s.slide === this.initSlide);
            if (selectedSlide) {
                this.model.forEach(s => s.selected = false);
                selectedSlide.selected = true;
                if (this.firstInit && !!this.cardsPerPage) {
                    this.currentPage = Math.ceil(index / this.cardsPerPage);
                    this.populatePagePosition();
                }
                if (emit && this.emitOnSlidesSet) {
                    this.selectedSlide.emit(this.model.find(s => s.selected).slide);
                }
            }
            this._isSettingSlide = false;
        }
    }

    @Input()
    protected set size(value) {
        switch (value) {
            case 'small':
                this.width = 100;
                break;
            default:
            case 'normal':
                this.width = 200;
                break;
            case 'large':
                this.width = 300;
                break;
            case 'x-large':
                this.width = 400;
                break;
        }
    }

    @Input()
    protected set minHeight(value: number) {
        if (value) {
            this._minHeight = value;
        }
    }

    @Output() protected selectedSlide = new EventEmitter<any>();
    public model: { selected: boolean; preselected: boolean; slide: any }[] = [];
    public width = 200;
    public _minHeight = 20;
    private _shouldPreSelectSlide: boolean = false;


    private preSelectSlide(): void {
        if (!this._isSettingSlide && !!this.cardsPerPage) {
            this._isSettingSlide = true;
            if (this.model) {
                const preselectedSlide = this.model.find(s => s.slide === this._preselectedSlide);
                const index = this.model.findIndex(s => s.slide === this._preselectedSlide);
                if (!!preselectedSlide) {
                    this.model.forEach(s => s.preselected = false);
                    preselectedSlide.preselected = true;
                    const selectedSlide = this.model.find(s => s.slide === this._preselectedSlide);
                    if (selectedSlide && !!this.cardsPerPage) {
                        this.currentPage = Math.ceil(index / this.cardsPerPage);
                        this.populatePagePosition();
                    }
                }
            }
            this._isSettingSlide = false;
        }
        else {
            this._shouldPreSelectSlide = true;
        }
    }



    private preSelectHandler(): boolean {
        return !this.preSelectComponent || this.preSelectComponent.canLeave();
    }

    async selectHandler(e, slide: any) {
            e.preventDefault();
            if (await this.preSelectHandler()) {
                this.firstInit = false;
                //this.animationInAction = true;
                this.model.forEach(s => {
                    s.selected = s.slide === slide.slide;
                    if (s.selected) {
                        this.selectedSlide.emit(s.slide);
                    }
                });
            }
    }

    //////////////////////////////////////////////////////////////////////
    // Code Slider
    //////////////////////////////////////////////////////////////////////
    totalCards: number;
    currentPage: number = 1;
    card_margin_right: number = 20; // same margin as in fiba-card-slider.component.css for class .card
    pagePosition: string = "0%";
    cardsPerPage: number;
    totalPages: number;
    overflowWidth: string;
    cardWidth: string;
    @HostListener("window:resize") windowResize() {
        let newCardsPerPage = this.getCardsPerPage();
        if (newCardsPerPage != this.cardsPerPage) {
            this.cardsPerPage = newCardsPerPage;
            this.initializeSlider();
            if (this.currentPage > this.totalPages) {
                this.currentPage = this.totalPages;
                this.populatePagePosition();
            }
        }
    }
    private cardSliderContainer: ElementRef;
    @ViewChild('cardSliderContainer', { static: false, read: ElementRef }) set content(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.cardSliderContainer = content;
            this.cardsPerPage = this.getCardsPerPage();
            if (this._shouldPreSelectSlide) {
                this.preSelectSlide();
                this._shouldPreSelectSlide = false;
            }
            this.initializeSlider();
        }
    }

    ngOnInit() {
        this.cardsPerPage = this.getCardsPerPage();
        this.initializeSlider();
    }

    initializeSlider() {
        this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
        this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages > 1 ? this.totalPages *
            this.card_margin_right : 0}px)`;
        this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
            this.card_margin_right}px) / ${this.cardsPerPage})`;
        this.movePage();
    }

    getCardsPerPage() {
        return Math.floor(this.cardSliderContainer?.nativeElement?.offsetWidth / this.width);
    }

    changePage(incrementor) {
        this.currentPage += incrementor;
        this.populatePagePosition();
    }

    populatePagePosition() {
        this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${(this.card_margin_right + 10) * (this.currentPage - 1)}px)`;
    }

    private movePage() {
        let index = this.model.findIndex(s => !!s.selected);
        if (!index || index == -1) {
            index = this.model.findIndex(s => !!s.preselected);
        }
        if (index >= 0  && !!this.cardsPerPage) {
            this.currentPage = Math.ceil((index+1) / this.cardsPerPage);
            this.populatePagePosition();
        }
    }

}


