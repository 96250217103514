import { EventEmitter, Injector, Input, Output, ViewChild, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ThinModel } from '@fiba/models';

@Directive()
export abstract class FibaPopupBase<T> {
    @Input() public contextCode: string;
    @Input() public viewMessagesData: ThinModel[];

    public active = false;
    public _model: T;
    @Input() public isNew = false;

    @Input()
    public set model(pn: T) {
        this.active = pn !== undefined && pn !== null;
        this._model = pn;
        if (this.onModelSet) {
            this.onModelSet(this._model);
        }
    }

    @Output() public cancel: EventEmitter<any> = new EventEmitter();
    @Output() public save: EventEmitter<T> = new EventEmitter();

    @ViewChild('form') public ngForm: NgForm;

    constructor(protected injector: Injector) {
        this.viewMessagesData = [];
    }

    public onModelSet(model: T): void {
    }

    protected onSave(e): void {
        if (e) {
            e.preventDefault();
        }
        if (!this.ngForm || this.ngForm.valid) {
            this.save.emit(this._model);
            this.active = false;
        } else {
            // Mark all inputs as touched
            for (const key in this.ngForm.form.controls) {
                this.ngForm.form.controls[key].markAsTouched();
            }
        }
    }

    onCancel(e): void {
        e.preventDefault();
        this.closeForm();
    }

    closeForm(): void {
        this.active = false;
        this.cancel.emit();
    }

    public ToText(code: string, value: string) {
        if (!!code) {
            if (!!this.viewMessagesData) {
                // list of values
                for (const text of this.viewMessagesData) {
                    if (text.code === code) {
                        value = text.name;
                        break;
                    }
                }
            }
        }
        return value;
    }
}
