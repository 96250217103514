import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, EquipmentProductDTO, EquipmentProductListItemDTO } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';
import { EquipmentProductFilters } from '../../models/equipment/equipment-product';

interface IApprovalCategoriesParams {
    approvalCategoriesIds?: number[];
    organisationId?: number;
    agreementId?: number;
    all?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class EquipmentProductDataService {
    protected equipmentProductApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentProductApiEndpoint = config.equipmentProductApiEndpoint;
    }

    public fetchList(filters: EquipmentProductFilters): Observable<ApiListResponse<EquipmentProductListItemDTO>> {
        return this.http.get(`${this.equipmentProductApiEndpoint}/list-product`, filters.getSearchParams());
    }
    
    public fetchEquipmentProduct(productId: number): Observable<EquipmentProductDTO> {
        return this.http.get(`${this.equipmentProductApiEndpoint}/${productId}`);
    }

    public updateEquipmentProduct(equipmentProductDto: EquipmentProductDTO, statusCode: string = ""): Observable<any> {
        return this.http.put(`${this.equipmentProductApiEndpoint}?statusCode=${statusCode}`, equipmentProductDto);
    }

    public setSelectedTestInstitute(testInstituteId: number, equipmentProductApplicationId: number): Observable<boolean> {
        return this.http.put(`${this.equipmentProductApiEndpoint}/${equipmentProductApplicationId}/set-selected-test-institute`, testInstituteId);
    }

    public fetchListByApprovalCategory(approvalCategoriesIds: number[], organisationId: number, all: boolean, agreementId: number): Observable<EquipmentProductDTO[]> {
        const params = {} as IApprovalCategoriesParams;
        params.approvalCategoriesIds = approvalCategoriesIds;
        params.all = all;
        if (agreementId !== undefined)
            params.agreementId = agreementId;
        if (organisationId !== undefined)
            params.organisationId = organisationId;
        return this.http.get(`${this.equipmentProductApiEndpoint}/by-approval-category`, params as { [key: string]: string | string[] });
    }
    
}
