import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RoleFamilyDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RoleFamilyDataService {
    protected apiEndpoint: string;

    constructor(
        protected dataService: DataCachedService<RoleFamilyDTO>,
        protected config: AppConfig) {
        this.apiEndpoint = config.roleFamilyApiEndpoint;
    }

    fetchRoleFamilies(): Observable<RoleFamilyDTO[]> {
        return this.dataService.fetchEntities(this.apiEndpoint, 'roleFamilies');
    }

    fetchRoleFamily(familyRoleCode: string): Observable<RoleFamilyDTO> {
        return this.dataService.fetchEntity(`${this.apiEndpoint}/${familyRoleCode}`, `${familyRoleCode}_RoleFamily`);
    }

    fetchTreeviewRoleFamilies(familyCode, typeCode): Observable<RoleFamilyDTO[]> {
        let params = {};
        if (typeCode) {
            params = { typeCode };
        } else if (familyCode) {
            params = { familyCode };
        }

        return this.dataService.fetchEntities(this.apiEndpoint + '/treeview', 'treeview', params);
    }

}
