import { BaseFilters } from '../common/base.filters';

export class ProductApplicationFilters extends BaseFilters {
    company: string;
    approvalCategoryId: number;
    productApplicationStatusId: number;
    reset(): void {
        delete this.company;
        delete this.approvalCategoryId;
        delete this.productApplicationStatusId;
    }
}
