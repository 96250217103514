import { Component, forwardRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


import { CompetitionPickerDataService } from '@fiba/data-services';
import { FibaPickerBase } from '@fiba/forms/base/fiba-picker-base';
import { CompetitionDTO, MapContexts, ThingDTO } from '@fiba/models';

import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import {ComboBoxComponent} from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';


@Component({
    selector: 'fibaPickerCompetition',
    templateUrl: './fiba-picker-competition.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaPickerCompetitionComponent),
            multi: true,
        },
        CompetitionPickerDataService,
    ],
    host: { class: 'fiba-input' },
})
export class FibaPickerCompetitionComponent extends FibaPickerBase<CompetitionDTO> implements OnInit {
    @ViewChild('pickerCombobox', {static: true}) public comboBox: ComboBoxComponent;
    public listItems: ThingDTO[] = [];
    @Input() protected delay = 300;
    @Input() protected context = MapContexts.CompetitionsGlobal;
    public simpleValue: ThingDTO;

    constructor(
        protected injector: Injector,
        protected competitionService: CompetitionPickerDataService,
        protected notificationService: NotificationService) {

        super(injector);
        this.idField = 'competitionId';
    }

    public ngOnInit() {
        super.ngOnInit();

        this.comboBox.filterChange.pipe(
            debounceTime(this.delay),
            switchMap((searchValue) => this.fetchAutocompleteObservable(searchValue))
        )
            .subscribe((result) => {
                this.listItems = result;
            });
    }

    public filterHandler(value) {
        this.filterList(value);
    }

    protected filterList(value: string = null) {
        this.fetchAutocompleteObservable(value).subscribe(
            (data) => {
                this.listItems = data;
            },
        );
    }

    private fetchAutocompleteObservable(value: string = null): Observable<ThingDTO[]> {
        return this.competitionService.fetchAutoCompleteList(value);

    }

    protected updatePickerCompetition(competition: ThingDTO = null) {
        if (competition) {
            this.competitionService.fetchCompetition(competition.id, this.context).subscribe(
                (response) => {
                    this.value = response;
                    super.saveHandler(response);
                },
                (error) => {
                    this.notificationService.emitNotification(NotificationType.Error, `Fetch competition ${competition.id} failed`);
                },
                () => { },
            );
        } else {
            super.saveHandler(null);
        }
    }

    /**
     * Callback after value is changed. Needed when picker is initialized with existing value
     *
     * @param val
     */
    public valueChanged(val: CompetitionDTO) {
        if (val) {
            this.simpleValue = { id: val.competitionId, name: val.officialName } as ThingDTO;

            // the list of Items must contain the new value, otherwise the
            // value change will be ignored by the combobox
            this.listItems = [this.simpleValue];
        } else {
            this.simpleValue = null;
        }
    }

    /**
     * Called when user clicks on autocomplete list item, or reset
     *
     * @param newValue ThingDTO that contains the item clicked on, or null if reset
     */
    public valueChangeHandler(newValue: ThingDTO): void {
        this.updatePickerCompetition(newValue);
    }

    /**
     * Called when the user clicks on the OK button of the popup
     *
     * @param competition
     */
    public popupSaveHandler(competition: CompetitionDTO) {
        this.simpleValue = {
            id: competition.competitionId, name: competition.officialName,
        } as ThingDTO;
        this.updatePickerCompetition(this.simpleValue);
    }
}
