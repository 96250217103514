import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompetitionGameInformationFormDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionGameInformationFormDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionGameInformationForms(competitionId: number, windowId: number): Observable<CompetitionGameInformationFormDTO> {
        const params = {};
        if (windowId) {
            params['windowId'] = windowId;
        }
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/game-information-forms`, params);
    }

    deleteBubbleGameInformationForms(competitionId: number, bubbleId: number): Observable<CompetitionGameInformationFormDTO> {
        return this.http.delete(`${this.competitionApiEndpoint}/${competitionId}/game-information-forms/bubbles/${bubbleId}`);
    }

    deleteWindowGameInformationForms(competitionId: number, windowId: number): Observable<CompetitionGameInformationFormDTO> {
        return this.http.delete(`${this.competitionApiEndpoint}/${competitionId}/game-information-forms/windows/${windowId}`);
    }
}
