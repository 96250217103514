import {Injector, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputPassword',
    templateUrl: './fiba-input-password.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputPasswordComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaInputPasswordComponent extends FibaInputBase<string> {
    constructor(protected injector: Injector) {
        super(injector);
    }

    @Input() protected fibaEqualValidator: string;

    onPasswordChanged($event): void {
        this.value = $event;
        this.manualChange();
    }
}
