import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

declare module '../dtos/generated/event-list-item-dto' {
    export interface EventListItemDTO {
        cli_displayName: string;
    }
}

export class EventFilters extends BaseFilters {
    keywords: string;
    cityName: string;
    fromDate: Date;
    toDate: Date;
    country: Filterable = new Filterable('countryId');
    zone: Filterable = new Filterable('zoneId');
    type: FilterableArray = new FilterableArray('typeItemId', 'itemId');
    status: FilterableArray = new FilterableArray('statusId', 'eventStatusId');
    testEventsOnly: Boolean;

    reset(): void {
        this.keywords = undefined;
        this.cityName = undefined;
        this.fromDate = undefined;
        this.toDate = undefined;
        this.country.reset();
        this.zone.reset();
        this.type.reset();
        this.status.reset();
    }
}

export class EventParticipantFilters extends BaseFilters {
    lastName: string;
    firstName: string;

    reset(): void {
        delete this.lastName;
        delete this.firstName;
    }
}

declare module '../dtos/generated/official-license-period-selector-list-item-dto' {
    export interface OfficialLicensePeriodSelectorListItemDTO {
        cli_displayName: string;
    }
}
