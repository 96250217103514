import { InputState } from '@fiba/models';
import { BaseFilters, Filterable } from '../common/base.filters';
import { CompetitionGameManagementListItemDTO } from '../dtos/generated/competition-game-management-list-item-dto';

declare module '../dtos/generated/competition-game-management-list-item-dto' {

    export interface CompetitionGameManagementListItemDTO {

        isApplicablePreliminaryRosterInputState: InputState;

        deadlineDateTimePreliminaryRosterInputState: InputState;

        isApplicableFinalRosterInputState: InputState;

        isApplicableTechnicalMeetingInputState: InputState;

        deadlineDateTimeFinalRosterInputState: InputState;

        gameDateTimeInputState: InputState;

        isExpanded: boolean;
    }
}

export class CompetitionGameManagementFilter extends BaseFilters {
    public competitionTeamA: Filterable = new Filterable('competitionTeamId');
    public competitionTeamB: Filterable = new Filterable('competitionTeamId');
    public dateFrom: Date;
    public dateTo: Date;
    public venue: Filterable = new Filterable('venueId');
    public window: Filterable = new Filterable('windowId');
    public roundGroupPairing: Filterable = new Filterable('id');

    public reset(): void {
        this.competitionTeamA.reset();
        this.competitionTeamB.reset();
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.venue.reset();
        this.window.reset();
        this.roundGroupPairing.reset();
    }
}
