import { NgModule } from '@angular/core';
import { FibaPickerNationalFederationComponent } from './fiba-picker-national-federation.component';
import { SharedModule } from '../../../../app/shared.module';
import { FibaFormsModule } from '@fiba/forms';
import { FibaPopupNationalFederationModule } from '../../components/national-federation/fiba-grid-national-federation-membership-status-history/fiba-popup-national-federation/fiba-popup-national-federation.module';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPickerNationalFederationComponent,
];

const INTERNAL_COMPONENTS = [];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaPopupNationalFederationModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPickerNationalFederationModule {
}
