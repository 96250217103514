import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BoxScoreDTO, GameStatisticDTO, GameUploadMessageDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';

import { IFileData } from '@fiba/data-services';
import { HttpService } from '../../utils/http.service';
import { AppConfig } from '@app/app.config';
import { GameUploadLogDTO } from '../../../models/dtos/generated/game-upload-log-dto';
import { GameWithGameUploadMessageDTO } from '../../../models/dtos/generated/game-with-game-upload-message-dto';
import { GameWithMapAndLiveStatDataDTO } from '../../../models/dtos/generated/game-with-map-and-live-stat-data-dto';
import { GameUploadMessagesDTO } from '../../../models/dtos/generated/game-upload-messages-dto';

@Injectable({
    providedIn: 'root',
})
export class GameStatisticsDataService {
    protected competitionApiEndpoint: string;
    protected documentApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.documentApiEndpoint = config.documentApiEndpoint;
    }

    public fetchCompetitionOperationGameStatistics(gameId: number): Observable<BoxScoreDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/statistics`);
    }
    public fetchImportedDataComparison(gameId: number): Observable<GameWithMapAndLiveStatDataDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/imported-data-comparison`);
    }

    public fetchCompetitionOperationTeamStatistics(gameId: number): Observable<GameStatisticDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/team-statistics`);
    }

    public updateCompetitionOperationGameStatistics(model: BoxScoreDTO): Observable<BoxScoreDTO> {
        if (!!model?.gameId) {
            return this.http.put(`${this.competitionApiEndpoint}/games/${model.gameId}/boxscore`, model);
        } else {
            Logger.error('update game-statistics: invalid model');
        }
    }

    public getMyFileFromBackend(documentId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.documentApiEndpoint}/${documentId}`);
    }

    public deleteCompetitionOperationGameStatistics(gameId: number): Observable<any> {
        if (!!gameId) {
            return this.http.delete(`${this.competitionApiEndpoint}/games/${gameId}/boxscore`);
        } else {
            Logger.error('delete game-statistics: invalid gameId');
        }
    }

    public getGameUploadLogs(gameId: number): Observable<GameUploadMessagesDTO> {
        if (!!gameId) {
            return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/gameuploadlogs`);
        }
    }
    public updateGameUploadMessages(gameId: number, isGameUploadLocked: boolean, model: GameUploadMessageDTO[]) {
        if (!!gameId) {
            let gameDto = {
                gameId: gameId,
                isGameUploadLocked: isGameUploadLocked,
                gameUploadMessages: model
            } as GameWithGameUploadMessageDTO;
            return this.http.put(`${this.competitionApiEndpoint}/games/${gameId}/gameuploadlogs`, gameDto);
        } else {
            Logger.error('update gameuploadlogs: invalid model');
        }
    }


    public recalculateGameStats(gameId: number): Observable<BoxScoreDTO> {
        if (!!gameId) {
            return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/recalculate-game-stats`);
        }
    }

    public updateGameStatisticsStatus(model: BoxScoreDTO, newStatusCode: string): Observable<BoxScoreDTO> {
        if (!!model.gameId) {
            model.gameStatisticStatusCode = newStatusCode;
            return this.http.put(`${this.competitionApiEndpoint}/games/${model.gameId}/boxscore-status`, model);
        } else {
            Logger.error('update boxscore-status: invalid model');
        }
    }
}
