export class TransferRequestActions {
    public static readonly TRANSFER_REQUEST_ACTION_ACCEPT_REPLY = 'acceptReply';
    public static readonly TRANSFER_REQUEST_ACTION_AGREE = 'agree';
    public static readonly TRANSFER_REQUEST_ACTION_AGREE_AS_NO_REPLY = 'agreeAsNoReply';
    public static readonly TRANSFER_REQUEST_ACTION_APPROVE_DECISION = 'approveDecision';
    public static readonly TRANSFER_REQUEST_ACTION_CANCEL = 'cancel';
    public static readonly TRANSFER_REQUEST_ACTION_DELETE = 'delete';
    public static readonly TRANSFER_REQUEST_ACTION_DISAGREE = 'disagree';
    public static readonly TRANSFER_REQUEST_ACTION_DISPUTE_REPLY = 'disputeReply';
    public static readonly TRANSFER_REQUEST_ACTION_FIBA_ASSISTANCE = 'fibaAssistance';
    public static readonly TRANSFER_REQUEST_ACTION_DISPUTE_REPLY_CONFIRM_DECISION_REFUSAL = 'confirmDecisionRefusalByDisputeReply';
    public static readonly TRANSFER_REQUEST_ACTION_DISPUTE_REPLY_REJECT_DECISION_REFUSAL = 'rejectDecisionRefusalByDisputeReply';
    public static readonly TRANSFER_REQUEST_ACTION_FORWARD = 'forward';
    public static readonly TRANSFER_REQUEST_ACTION_FORWARD_TO_NF = 'forwardToNationalFederation';
    public static readonly TRANSFER_REQUEST_ACTION_GENERATE_LOC = 'generateLoC';
    public static readonly TRANSFER_REQUEST_ACTION_GRANT_LOC = 'grantLoc'
    public static readonly TRANSFER_REQUEST_ACTION_LAST_REMINDER = 'lastreminder';
    public static readonly TRANSFER_REQUEST_ACTION_PREAUTHORISE = 'preauthorise';
    public static readonly TRANSFER_REQUEST_ACTION_REFUSE_LOC = 'refuseLoc'
    public static readonly TRANSFER_REQUEST_ACTION_REJECT_DECISION = 'rejectDecision';
    public static readonly TRANSFER_REQUEST_ACTION_REQUEST_INFORMATION = 'requestInformation';
    public static readonly TRANSFER_REQUEST_ACTION_REQUEST_DOCUMENT = 'requestDocument';
    public static readonly TRANSFER_REQUEST_ACTION_RESUBMIT_AGREED = 'resubmitAgreed';    
    public static readonly TRANSFER_REQUEST_ACTION_SAVE = 'save';
    public static readonly TRANSFER_REQUEST_ACTION_SEND_REMINDER = 'sendreminder';
    public static readonly TRANSFER_REQUEST_ACTION_SUBMIT = 'submitted';
    public static readonly TRANSFER_REQUEST_ACTION_WITHDRAW = 'withdraw';
    public static readonly TRANSFER_REQUEST_ACTION_ACKNOWLEDGE_DISAGREEMENT = 'acknowledgeDisagreement';
    public static readonly TRANSFER_REQUEST_ACTION_ROLLBACK_STATUS = 'rollbackStatus';
}
