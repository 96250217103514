import { Input, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AddEvent, RemoveEvent, RowArgs } from '@progress/kendo-angular-grid';
import { EditEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/edit-event-args.interface';

/**
 * Editable and Filterable (client-side only) grid
 */
@Directive()
export abstract class FibaEFGridBaseDirective<T> implements ControlValueAccessor {
    public _model: T[] = [];
    public isNew = false;
    @Input() public disabled: boolean;
    @Input() public label: string;

    protected mySelection: number[] = [];

    public mySelectionKey(context: RowArgs): number {
        return context.index;
    }

    public _model_filtered: T[];

    public get model() {
        return this._model;
    }

    public set model(model: T[]) {
        this._model = model;
        this.onModelChanged(); // As of now (TS 2.3), while targetting ES5, we cannot use super.model while in an overridden getter/setter. Override this method instead
    }

    protected onModelChanged(): void {
        this.loadGroup();
        this.sortGroup();
    }

    protected loadGroup() { }

    protected sortGroup() { }

    public writeValue(value: T[]): void {
        this.model = value;
        this.refreshFilteredData();
    }

    public onChange = (_: any) => { };
    public onTouched: any = () => { };

    public registerOnChange(fn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    protected newModel(): T { return {} as T; }

    // Grid editing
    public editDataItem: T;
    protected editRowId: number;

    public addHandler($event: AddEvent): void {
        if (!this._model) {
            this._model = [];
        }
        this.editDataItem = this.newModel();
        this.isNew = true;
        this.editRowId = this._model_filtered.length;
    }

    public editHandler($event: EditEvent): void {
        const { dataItem, rowIndex } = $event;
        this.editDataItem = JSON.parse(JSON.stringify(dataItem));
        this.isNew = false;
        this.editRowId = this.editDataItem['sourceRowIndex'];
    }

    public cancelHandler(): void {
        this.editDataItem = undefined;
    }

    public saveHandler(row: T): void {
        if (this.isNew) {
            this._model.push(row);
        } else {
            this._model[this.editRowId] = row;
        }
        this.editDataItem = undefined;
        this.onChange(this._model);
        this.refreshFilteredData();
    }

    public removeHandler($event: RemoveEvent): void {
        let rowIndex = parseInt($event.dataItem.sourceRowIndex);
        if (!rowIndex) {
            rowIndex = $event.rowIndex;
        }
        this._model.splice(rowIndex, 1);
        this.onChange(this._model);
        this.refreshFilteredData();
    }

    protected abstract refreshFilteredData(): void;

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
