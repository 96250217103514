import { Component, Input } from '@angular/core';

@Component({
    selector: 'fibaAuditFooter',
    templateUrl: './fiba-audit-footer.component.html',
    styleUrls: ['./fiba-audit-footer.component.css'],
})
export class FibaAuditFooterComponent {
    @Input() public model: any;
    @Input() public entityName: string;
    @Input() public displayUsername: boolean = false;
}
