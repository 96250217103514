import { BaseFilters, FilterableArray } from '../common/base.filters';

export class TestInstituteFilters extends BaseFilters {
    name: string;
    approvalCategoryId: FilterableArray = new FilterableArray('approvalCategoryId', 'equipmentApprovalCategoryId');
    countryId: number;
    regionId: number;
    reset(): void {
        this.approvalCategoryId.reset();
        delete this.name;
        delete this.countryId;
        delete this.regionId;
    }
}
