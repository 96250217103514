import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

/**
 * This component was created to allow redirection via the canActivate AuthGuard configured for the route(s) that use this component.
 * It is not supposed to contain anything or have any other use other than being a dummy route target for app-routing.module or other route configs.
 **/

@Component({
    template: '<div>Please wait, you should be redirected shortly...</div>',
})
export class DummyRoutingComponent implements OnInit {
    // We could make the class abstract and have different component for different routes if needed.
    protected redirectionUrl = '/home';

    constructor(protected router: Router) {
    }

    public ngOnInit() {
        this.router.navigateByUrl(this.redirectionUrl);
    }
}
