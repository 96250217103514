import { BaseFilters, Filterable } from '../common/base.filters';
import { ThingDTO } from '..';

export class CompetitionTechnicalMeetingConfigurationFilter extends BaseFilters {
    competitionTeamA: Filterable = new Filterable('competitionTeamId');
    competitionTeamB: Filterable = new Filterable('competitionTeamId');
    games: ThingDTO[];
    dateFrom: Date;
    dateTo: Date;
    technicalMeetingStatusCode: string;
    gameDateFrom: Date;
    gameDateTo: Date;
    windowId: number;
    gameCountryId: number;
    addressCountryId: number;
    reset(): void {
        this.competitionTeamA.reset();
        this.competitionTeamB.reset();
        this.games = [];
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.technicalMeetingStatusCode = undefined;
        this.gameDateFrom = undefined;
        this.gameDateTo = undefined;
        this.windowId = undefined;
        this.gameCountryId = undefined;
        this.addressCountryId = undefined;
    }
}
