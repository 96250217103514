import { Component, Input, ViewEncapsulation } from '@angular/core';

import { FibaTooltipBase } from '@fiba/forms';

@Component({
    selector: 'fibaTooltip',
    templateUrl: './fiba-tooltip.component.html',
    styleUrls: ['./fiba-tooltip.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FibaTooltipComponent extends FibaTooltipBase<string> {
    @Input()
    public set content(value: string) {
        if (value) {
            this.model = value;
        } else {
            this.model = undefined;
        }
    }
    @Input() displayInnerHtml = false;

    @Input() icon = 'fa-info-circle';

    @Input() position = 'bottom';

    constructor() {
        super();
    }
}
