import { BaseFilters } from '../common/base.filters';

export class FeeFilters extends BaseFilters {
    year: string;
    level: number;
    approvalCategory: number;
    reset(): void {
        delete this.level;
        delete this.approvalCategory;
        delete this.year;
    }
}
