import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FibaFormsModule } from '@fiba/forms';
import { SharedModule } from '../../../app/shared.module';

import { FibaMultiSelectCompetitionStatusComponent } from '@fiba/competition/common/forms/fiba-multiselect-competition-status.component';
import { FibaSelectCompetitionComponent } from '@fiba/competition/common/forms/fiba-select-competition.component';
import { FibaSelectTeamComponent } from '@fiba/competition/common/forms/fiba-select-team.component';
import { FibaSelectUniformNumberComponent } from '@fiba/competition/common/forms/fiba-select-uniform-number.component';
import { FibaSelectCompetitionRoundsComponent } from './forms/fiba-select-competition-rounds.component';

const COMPONENTS: any[] = [
    FibaMultiSelectCompetitionStatusComponent,
    FibaSelectTeamComponent,
    FibaSelectCompetitionComponent,
    FibaSelectCompetitionRoundsComponent,
    FibaSelectUniformNumberComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FibaFormsModule,
    ],
    declarations: [
        COMPONENTS,
    ],
    exports: [
        COMPONENTS,
    ],
})
export class FibaCompetitionCommonSelectModule {
}
