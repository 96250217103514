import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { ItemDataService } from '@fiba/data-services';
import { ItemDTO } from '@fiba/models';
import { FibaSelectItemComponent } from './fiba-select-item.component';
import {map} from 'rxjs/operators';

@Component({
    selector: 'fibaSelectCustomItem',
    templateUrl: './fiba-select-custom-item.component.html',
    styleUrls: ['./fiba-select-custom-item.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectCustomItemComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectCustomItemComponent extends FibaSelectItemComponent {

    @Input() hideArrow = true;

    constructor(protected injector: Injector, protected dataService: ItemDataService) {
        super(injector, dataService);
    }

    public valueNormalizer(text: Observable<string>): Observable<ItemDTO> {
        return text.pipe(map((txt: string) => ({
                itemId: null,
                description: txt,
                name: txt,
            } as ItemDTO)));
    }
}
