import { BaseFilters, Filterable } from '../common/base.filters';
import { FinancialDataPackageListItemDTO, ZoneDTO, InputState } from '@fiba/models';
import { ItemDTO } from '@fiba/models/dtos/generated/item-dto';

export class PaymentFilters extends BaseFilters {
    paymentId: number;
    doneOnDateFrom: Date;
    doneOnDateTo: Date;
    amount: number;
    providerItem: Filterable = new Filterable('providerItemId','itemId');
    processTypeItem: Filterable = new Filterable('processTypeItemId', 'itemId');
    meanItem: Filterable = new Filterable('meanItemId', 'itemId');
    currencyItem: Filterable = new Filterable('currencyItemId', 'itemId');
    paymentStatus: Filterable = new Filterable('paymentStatusId');
    paymentKey: string;

    reset(): void {
        delete this.paymentId;
        delete this.doneOnDateFrom;
        delete this.doneOnDateTo;
        delete this.amount;
        delete this.paymentKey;
        this.providerItem.reset();
        this.processTypeItem.reset();
        this.meanItem.reset();
        this.currencyItem.reset();
        this.paymentStatus.reset();
    }
}
