import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
@Pipe({
    name: 'fibaSafeHtml',
})
export class FibaSafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}