import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, EquipmentPartnerApplicationListItemDTO, EquipmentPartnerCompanyRegistrationStatusDTO, PartnerApplicationFilters } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';
import { EquipmentFibaPartnerInformationDTO } from '../../models/dtos/generated/equipment-fiba-partner-information-dto';
import { EquipmentPartnerCompanyDTO } from '../../models/dtos/generated/equipment-partner-company-dto';

@Injectable({
    providedIn: 'root',
})
export class PartnerApplicationDataService {
    protected equipmentPartnerApplicationApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentPartnerApplicationApiEndpoint = config.equipmentPartnerApplicationApiEndpoint;
    }

    public fetchList(filters: PartnerApplicationFilters): Observable<ApiListResponse<EquipmentPartnerApplicationListItemDTO>> {
        return this.http.get(`${this.equipmentPartnerApplicationApiEndpoint}/list-partner-application`, filters.getSearchParams());
    }

    public getFibaPartnerByEquipmentPartnerCompanyID(organisationId): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.get(`${this.equipmentPartnerApplicationApiEndpoint}/${organisationId}`);
    }

    public getFibaPartner(): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.get(`${this.equipmentPartnerApplicationApiEndpoint}/new`);
    }

    public fetchFibaPartner(organisationId :number): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.get(`${this.equipmentPartnerApplicationApiEndpoint}/${organisationId}`);
    }

    public createFibaPartner(model: EquipmentPartnerCompanyDTO, status: string): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.post(`${this.equipmentPartnerApplicationApiEndpoint}?status=${status}`, model);
    }

    public updateFibaPartner(model: EquipmentPartnerCompanyDTO, status: string): Observable<EquipmentPartnerCompanyDTO> {
        return this.http.put(`${this.equipmentPartnerApplicationApiEndpoint}?status=${status}`, model);
    }

    public fetchStatuses(): Observable<EquipmentPartnerCompanyRegistrationStatusDTO[]> {
        return this.http.get(`${this.equipmentPartnerApplicationApiEndpoint}/equipmentpartnercompanyregistrationstatus`);
    }
}
