import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Observable } from 'rxjs';

import {
    AgentPersonRelationFilters,
    ApiListResponse, BasketballNominationsDTO,
    GameOfficialFilters, GameOfficialListItemDTO,
    PersonBasketballRestrictionsDTO, PersonBasketballTestsDTO,
    PersonContactDetailsDTO, PersonDetailsDTO,
    PersonDocumentsAndNotesDTO, PersonDTO, PersonFilters,
    PersonInformationDTO, PersonListItemDTO,
    PersonRolesDTO, PersonStatusDTO,
    PlayerCompetitionDTO, PlayerLicenseDTO,
    ThingDTO, UnavailabilityDTO, MergePersonsDTO, LicenseEditDTO, ItemDTO, PreliminaryRosterMemberDTO, PersonCompetitionDTO,
} from '@fiba/models';
import { AgentPersonRelationViewDTO } from '@fiba/models/dtos/generated/agent-person-relation-view-dto';
import { DopingControlDTO } from '@fiba/models/dtos/generated/doping-control-dto';
import { PersonFinancialDataDTO } from '@fiba/models/dtos/generated/person-financial-data-dto';
import { PersonSanctionsDTO } from '@fiba/models/dtos/generated/person-sanctions-dto';
import { PlayerWithDopingControlsDTO } from '@fiba/models/dtos/generated/player-with-doping-controls-dto';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService, IFileData } from '../utils/http.service';
import { CoachListItemDTO } from '@fiba/models/dtos/generated/coach-list-item-dto';
import { LicenseListItemDTO } from '@fiba/models/dtos/generated/license-list-item-dto';
import { map, timeout } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';
import { PlayerAwardDTO } from '../../models/dtos/generated/player-award-dto';
import { AwardFilters } from '../../models/person/award-filters';
import { PersonRolesWithUserDTO } from '../../models/dtos/generated/person-roles-with-user-dto';

const PERSON_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class PersonDataService {

    protected baseUrlEndpoint: string;
    protected personApiEndpoint: string;
    protected personAutocompleteApiEndpoint: string;
    //protected personAutocompleteApiV2Endpoint: string;
    protected personStatusApiEndpoint: string;
    protected playerApiEndpoint: string;
    protected basketballPersonsApiEndpoint: string;
    protected basketballLicenseApiEndpoint: string;
    protected coachApiEndpoint: string;
    protected coachAutocompleteApiEndpoint: string;
    protected coachAutocompleteApiV2Endpoint: string;
    protected gameOfficialApiEndpoint: string;
    protected gameOfficialAutocompleteApiEndpoint: string;
    protected agentRelationsApiEndpoint: string;
    protected agentRelationSharesApiEndpoint: string;
    protected agentApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataPersonStatusService: DataCachedService<PersonStatusDTO>) {
        this.baseUrlEndpoint = config.baseUrl;
        this.personApiEndpoint = config.personApiEndpoint;
        this.personAutocompleteApiEndpoint = config.personAutocompleteApiEndpoint;
        //this.personAutocompleteApiV2Endpoint = config.personAutocompleteApiV2Endpoint;
        this.personStatusApiEndpoint = config.personStatusApiEndpoint;
        this.playerApiEndpoint = config.playerApiEndpoint;
        this.basketballPersonsApiEndpoint = config.basketballPersonsApiEndpoint;
        this.basketballLicenseApiEndpoint = config.basketballLicenseApiEndpoint;
        this.coachApiEndpoint = config.coachApiEndpoint;
        this.coachAutocompleteApiEndpoint = config.coachAutocompleteApiEndpoint;
        this.coachAutocompleteApiV2Endpoint = config.coachAutocompleteApiV2Endpoint;
        this.gameOfficialApiEndpoint = config.gameOfficialApiEndpoint;
        this.gameOfficialAutocompleteApiEndpoint = config.gameOfficialAutocompleteApiEndpoint;
        this.agentRelationsApiEndpoint = config.agentRelationsApiEndpoint;
        this.agentRelationSharesApiEndpoint = config.agentRelationSharesApiEndpoint;
        this.agentApiEndpoint = config.personApiEndpoint;
    }

    public fetchList(filters: PersonFilters, context: string = null): Observable<ApiListResponse<PersonListItemDTO>> {
        return this.http.get(this.personApiEndpoint, filters.getSearchParams());
    }

    public fetchPerson(personId: number, mapContext?: string, roleId?: number, startDate?: Date, endDate?: Date): Observable<PersonDTO> {
        const params: any = {};
        if (mapContext) {
            params.context = mapContext;
        }
        if (roleId) {
            params.roleId = String(roleId);
        }
        if (startDate) {
            params.startDate = moment(startDate).format('MM-DD-YYYY');
        }
        if (endDate) {
            params.endDate = moment(endDate).format('MM-DD-YYYY');
        }
        return this.http.get(`${this.personApiEndpoint}/${personId}`, params);
    }

    public fetchPersonInformation(personId: number): Observable<PersonInformationDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-information`);
    }

    public fetchPersonSanctions(personId: number): Observable<PersonSanctionsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-sanctions`);
    }

    public fetchPersonContact(personId: number): Observable<PersonContactDetailsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-contact`);
    }

    public fetchPersonDetails(personId: number): Observable<PersonDetailsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-details`);
    }

    public fetchPersonRestrictions(personId: number): Observable<PersonBasketballRestrictionsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-restrictions`);
    }

    public fetchPersonTests(personId: number): Observable<PersonBasketballTestsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-tests`);
    }

    public fetchPersonRoles(personId: number): Observable<PersonRolesDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-roles`);
    }

    public fetchPersonRolesWithUser(personId: number): Observable<PersonRolesWithUserDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-roles-with-user`);
    }

    public fetchPersonActiveLicensableRoles(personId: number): Observable<PersonRolesDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-active-licensable-roles`);
    }

    public fetchPersonDocuments(personId: number): Observable<PersonDocumentsAndNotesDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/person-documents`);
    }
    public fetchPersonLicenses(personId: number, excludeDeletedLicenses = false): Observable<LicenseListItemDTO[]> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/licenses`, (excludeDeletedLicenses ? { excludeDeleted: 'true' } : null));
    }
    public fetchPlayerLicenses(personId: number, excludeDeletedLicenses = false): Observable<PlayerLicenseDTO[]> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/licenses`, (excludeDeletedLicenses ? { excludeDeleted: 'true' } : null));
    }

    public fetchPersonForCompetition(
        personId: number,
        competitionId: number,
        competitionTeamId: number,
        preliminaryRosterConfigurationId: number): Observable<PersonDTO> {

        const params: any = {};
        if (competitionId) {
            params.competitionId = String(competitionId);
        }
        if (competitionTeamId) {
            params.competitionTeamId = String(competitionTeamId);
        }
        if (preliminaryRosterConfigurationId) {
            params.preliminaryRosterConfigurationId = String(preliminaryRosterConfigurationId);
        }

        return this.http.get(`${this.personApiEndpoint}/${personId}/competition-member`, params);
    }

    public fetchPersonBasketballAgentContracts(personId: number): Observable<PersonInformationDTO> {
        return this.http.get(`${this.basketballPersonsApiEndpoint}/${personId}/agent-contracts`);
    }

    public fetchPersonLicenseForEdit(personId: number, licenseId: number): Observable<LicenseEditDTO> {
        return this.http.get(`${this.basketballPersonsApiEndpoint}/${personId}/licenses/${licenseId}`);
    }

    public updatePersonLicenseForEdit(license: LicenseEditDTO): Observable<LicenseEditDTO> {
        return this.http.put(`${this.basketballPersonsApiEndpoint}/${license.personId}/licenses/${license.licenseId}`, license);
    }

    public updatePersonLicenseForCancel(license: LicenseEditDTO): Observable<LicenseEditDTO> {
        return this.http.put(`${this.basketballPersonsApiEndpoint}/${license.personId}/licenses/${license.licenseId}/cancel`, license);
    }

    public fetchAgentRelations(filters: AgentPersonRelationFilters, context: string = null): Observable<ApiListResponse<AgentPersonRelationViewDTO>> {
        return this.http.get(this.agentRelationsApiEndpoint, filters.getSearchParams());
    }

    public createAgentRelation(model: AgentPersonRelationViewDTO): Observable<AgentPersonRelationViewDTO> {
        return this.http.post(`${this.agentRelationsApiEndpoint}/contract-information`, model);
    }

    public updateAgentRelation(model: AgentPersonRelationViewDTO): Observable<AgentPersonRelationViewDTO> {
        return this.http.put(`${this.agentRelationsApiEndpoint}/${model.agentRelationId}/contract-information`, model);
    }

    public deleteAgentRelation(agentRelationId: number): Observable<any> {
        return this.http.delete(`${this.agentRelationsApiEndpoint}/${agentRelationId}/contract-information`);
    }
    public deleteAgentRelationShare(agentRelationShareId: number): Observable<any> {
        return this.http.delete(`${this.agentRelationSharesApiEndpoint}/${agentRelationShareId}/information`);
    }

    public checkPersonHasExistingRelations(model: AgentPersonRelationViewDTO): Observable<boolean> {
        const params: any = {};
        if (model.startDate) {
            params.startDate = moment(model.startDate).format('MM-DD-YYYY');
        }
        if (model.endDate) {
            params.endDate = moment(model.endDate).format('MM-DD-YYYY');
        }

        return this.http.get(`${this.personApiEndpoint}/${model.relatedPersonId}/has-existing-relations`, params);
    }

    public fetchPersonBasketballLicenses(personId: number): Observable<PersonInformationDTO> {
        return this.http.get(`${this.basketballPersonsApiEndpoint}/${personId}/licenses`);
    }

    public createPersonInformation(model: PersonInformationDTO): Observable<PersonInformationDTO> {
        return this.http.post(`${this.personApiEndpoint}/person-information`, model);
    }

    public updatePersonInformation(model: PersonInformationDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-information`, model);
    }

    public updateAccompagnyingPersonInformation(model: PreliminaryRosterMemberDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/accompanying-person-informations`, model);
    }

    public updatePersonSanctions(model: PersonSanctionsDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-sanctions`, model);
    }

    public updatePersonContact(model: PersonContactDetailsDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-contact`, model);
    }

    public updatePersonDetail(model: PersonDetailsDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-details`, model);
    }

    public updatePersonRestrictions(model: PersonBasketballRestrictionsDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-restrictions`, model);
    }

    public updatePersonTests(model: PersonBasketballTestsDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-tests`, model);
    }

    public updatePersonRoles(model: PersonRolesDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-roles`, model);
    }

    public addPersonRole(personId: number, personRoleCode: string): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${personId}/person-role`, personRoleCode);
    }

    public updatePersonDocuments(model: PersonDocumentsAndNotesDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/person-documents`, model);
    }

    public fetchStatuses(): Observable<PersonStatusDTO[]> {
        return this.dataPersonStatusService.fetchEntities(this.personStatusApiEndpoint);
    }

    public fetchAutoCompleteList(name: string, roleFamilyId?: number, roleId?: number, currentDate?: Date, personId: boolean = null, organisationId: number = null): Observable<ThingDTO[]> {
        const params: any = {
            name, take: String(PERSON_AUTOCOMPLETE_LIST_SIZE),
        };

        if (!!roleFamilyId) {
            params.roleFamilyId = String(roleFamilyId);
        }

        if (!!roleId) {
            params.roleId = String(roleId);
        }

        if (!!personId) {
            params.personId = personId;
        }

        if (!!currentDate) {
            params.roleValidForDate = currentDate.toISOString();
        }

        if (organisationId !== null && organisationId !== undefined) {
            
            params.organisationId = organisationId;
        }

        return this.http.get(this.personAutocompleteApiEndpoint, params);
    }

    public fetchAutoCompleteListCoach(name: string, roleId?: number, licenseCategoryId: number = null): Observable<ThingDTO[]> {
        const params: any = { name, take: String(PERSON_AUTOCOMPLETE_LIST_SIZE) };

        if (!!roleId) {
            params.roleId = String(roleId);
        }
        if (!!licenseCategoryId) {
            params.licenseCategoryId = String(licenseCategoryId);
        }
        return this.http.get(this.coachAutocompleteApiEndpoint, params);
    }

    public fetchAutoCompleteListGameOfficial(name: string, roleTypeId?: number[]): Observable<ThingDTO[]> {
        const params = { name, take: String(PERSON_AUTOCOMPLETE_LIST_SIZE) };
        if (!!roleTypeId) {
            params['roleTypeId'] = String(roleTypeId);
        }

        return this.http.get(this.coachAutocompleteApiEndpoint, params);
    }

    public downloadExport(filters: PersonFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.personApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }

    public fetchBasketballNominations(personId: number): Observable<BasketballNominationsDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/basketball-nominations`);
    }

    public fetchPersonsUnavailabilities(personIds: number[], startDate: Date, endDate: Date): Observable<UnavailabilityDTO[]> {
        const params: any = {};
        params.personIds = String(personIds);
        params.startDate = String(startDate);
        params.endDate = String(endDate);

        return this.http.get(`${this.personApiEndpoint}/unavailabilities`, params);
    }

    public fetchPlayerCompetitions(personId: number): Observable<PlayerCompetitionDTO[]> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/competitions`);
    }
    public fetchPersonCompetitions(personId: number, teamDelegationRoleCodes: string[]): Observable<PersonCompetitionDTO[]> {
        const params: any = {};
        params.teamDelegationRoleCodes = teamDelegationRoleCodes;
        return this.http.get(`${this.personApiEndpoint}/${personId}/competitions`,params);
    }

    public fetchPersonFinancialData(personId: number): Observable<PersonFinancialDataDTO> {
        return this.http.get(`${this.personApiEndpoint}/${personId}/financial-data`);
    }

    public updatePersonFinancialData(model: PersonFinancialDataDTO): Observable<any> {
        return this.http.put(`${this.personApiEndpoint}/${model.personId}/financial-data`, model);
    }

    public fetchPlayerWithDopingControls(personId: number): Observable<PlayerWithDopingControlsDTO> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/doping-controls`, null).pipe(map((response) => {
            const playerWithDopingControl = response as PlayerWithDopingControlsDTO;
            playerWithDopingControl.dopingControls.forEach((dopingControl: DopingControlDTO, i: number, array: DopingControlDTO[]) => {
                const currentDopingControl = playerWithDopingControl.dopingControls[i];
                currentDopingControl.cli_laboratoryOrganisationsFormatted = currentDopingControl.laboratoryOrganisation ? currentDopingControl.laboratoryOrganisation.officialName : '';
                currentDopingControl.cli_countryFormatted = currentDopingControl.country ? currentDopingControl.country.officialName : '';
                currentDopingControl.cli_competitionFormatted = currentDopingControl.competition ? currentDopingControl.competition.code : '';
            });
            return playerWithDopingControl;
        }));
    }

    public updatePlayerWithDopingControls(model: PlayerWithDopingControlsDTO): Observable<any> {
        return this.http.put(`${this.playerApiEndpoint}/${model.personId}/doping-controls`, model);
    }

    // public fetchListCoach(filters: PersonFilters, context: string = null): Observable<ApiListResponse<PersonListItemDTO>> {
    public fetchListCoach(filters: PersonFilters, context: string = null): Observable<ApiListResponse<CoachListItemDTO>> {
        return this.http.get(this.coachApiEndpoint, filters.getSearchParams());
    }

    public fetchListGameOfficial(filters: GameOfficialFilters, context: string = null): Observable<ApiListResponse<GameOfficialListItemDTO>> {
        return this.http.get(this.gameOfficialApiEndpoint, filters.getSearchParams());
    }
    public fetchListOfficialLicense(filters: PersonFilters, context: string = null): Observable<ApiListResponse<PersonListItemDTO>> {
        return this.http.get(this.personApiEndpoint, filters.getSearchParams());
    }
    // Merge
    public mergePersons(model: MergePersonsDTO): Observable<number> {
        return this.http.put(`${this.personApiEndpoint}/merges`, model);
    }
    public fetchPersonsToMerge(firstPersonId: number, secondPersonId: number): Observable<MergePersonsDTO> {
        return this.http.get(`${this.personApiEndpoint}/merges/?firstPersonId=${firstPersonId}&secondPersonId=${secondPersonId}`);
    }

    // Includes items with Inactive status
    public fetchNationalityAcquiredByItems(currentItemCode: string): Observable<ItemDTO[]> {
        return this.http.get(this.personApiEndpoint + '/items/nationality-acquired-by', { currentItemCode });
    }

    // Removed for PBI #12259
    // AutoComplete FUZZY
    //public fetchAutoCompleteListV2(name: string, roleFamilyId?: number, roleId?: number): Observable<ThingDTO[]> {
    //    const params: any = {
    //        name, take: String(PERSON_AUTOCOMPLETE_LIST_SIZE),
    //    };

    //    if (!!roleFamilyId) {
    //        params.roleFamilyId = String(roleFamilyId);
    //    }

    //    if (!!roleId) {
    //        params.roleId = String(roleId);

    //    }

    //    return this.http.get(this.personAutocompleteApiV2Endpoint, params);
    //}

    public fetchAutoCompleteListCoachV2(name: string, roleId?: number, licenseCategoryId: number = null): Observable<ThingDTO[]> {
        const params: any = { name, take: String(PERSON_AUTOCOMPLETE_LIST_SIZE) };

        if (!!roleId) {
            params.roleId = String(roleId);
        }
        if (!!licenseCategoryId) {
            params.licenseCategoryId = String(licenseCategoryId);
        }
        return this.http.get(this.coachAutocompleteApiV2Endpoint, params);
    }

    public fetchPlayerAwards(filters: AwardFilters, personId: number): Observable<ApiListResponse<PlayerAwardDTO>> {
        return this.http.get(`${this.playerApiEndpoint}/${personId}/awards`, filters.getSearchParams()).pipe(
            map(response => response as ApiListResponse<PlayerAwardDTO>));
    }
}
