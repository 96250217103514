<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox 
        #pickerCombobox
        [data]="listItems"
        [textField]="'name'"
        [valueField]="'id'"
        [placeholder]="'Search competition...'"
        [filterable]="true"
        [(ngModel)]="simpleValue"
        (valueChange)="valueChangeHandler($event)"
        (cancel)="cancelHandler()"
        [disabled]="disabled"
        class="no-arrow"
        ></kendo-combobox>
    <button kendoButton type="button"
            (click)="editHandler()"
            class="icon-only"
            [disabled]="disabled">
        <i class="fa fa-lg fa-search"></i>
    </button>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>

<fibaPopupCompetition 
                 [model]="tmpValue"
                 (save)="popupSaveHandler($event)"
                 (cancel)="cancelHandler()"
                 [isNew]="true"
                 >
</fibaPopupCompetition>
