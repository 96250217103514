<ng-container *ngIf="model.length > 0">
    <div #cardSliderContainer class="container fibacarousel mw400" >
        <a (click)="currentPage != 1 && changePage(-1)"
           *ngIf="totalPages > 1 && currentPage > 1"
           class="navigation previous clickable">
            <i class="fa fa-chevron-left"></i>
        </a>
        <a (click)="currentPage != totalPages && changePage(+1)"
           class="navigation next clickable right"
           *ngIf="totalPages > 1 && currentPage < totalPages">
            <i class="fa fa-chevron-right"></i>
        </a>

        <div class="cards" [ngClass]="{'w-100': totalPages === 1}">
            <div class="overflow" [ngClass]="{'cards-centered': totalPages === 1}" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
                <div class="card"
                     *ngFor="let slide of model; let index = index"
                     (click)="selectHandler($event,slide)"
                     [ngClass]="{ 'selected': slide.selected, 'pre-selected' : slide.preselected }"
                     [ngStyle]="{ 'margin-right':card_margin_right + 'px', 'width':cardWidth, 'min-height': _minHeight + 'px' }">
                    <ng-template [ngTemplateOutlet]="template"
                                 [ngTemplateOutletContext]="{slide: slide.slide}">
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
