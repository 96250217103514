import { Pipe, PipeTransform } from '@angular/core';
import { ClubDTO } from '@fiba/models';

@Pipe({ name: 'clubFormat' })
export class ClubFormatPipe implements PipeTransform {
    transform(club: ClubDTO): string {
        if (club) {
            let clubFormatted = club.officialName;
            if (club.cityName) {
                clubFormatted += ', ' + club.cityName;
            }
            if (club.country && club.country.fibacode) {
                clubFormatted += ', ' + club.country.fibacode;
            }
            if (club.womenSeniorLeagueItem) {
                clubFormatted += ', ' + club.womenSeniorLeagueItem.name;
            }
            if (club.menSeniorLeagueItem) {
                clubFormatted += ', ' + club.menSeniorLeagueItem.name;
            }
            return clubFormatted;
        }
        return '';
    }
}
