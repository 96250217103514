import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AuthService, ItemDataService } from '@fiba/data-services';
import { FibaPopupBase } from '@fiba/forms';
import { CoreDisplayTexts, ItemCategoryCodes, ThingDTO } from '@fiba/models';
import { ErrorEvent, FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'fibaPopupImport',
    templateUrl: './fiba-popup-import.component.html',
    styleUrls: ['./fiba-popup-import.component.css'],
})
export class FibaPopupImportComponent extends FibaPopupBase<ThingDTO> {

    @Input() public competitionId: number;
    @Input() public useServiceBus: boolean;
    @Output() public fileImported: EventEmitter<boolean> = new EventEmitter();
    public uploadSaveUrl: string;
    public uploadSaveheaders: HttpHeaders;
    public uploadInProgress = false;
    public errorMessage: string;

    saveMethod = 'POST';
    @Input() protected gameId: number;

    private readonly competitionApiEndpoint: string;
    private accessToken: string;
    private uploadFinished = false;
    private busImportRoute = 'import';
    private privateImportRoute = 'private-import';
    private manualGameImport: string;
    private automaticalyGameImport: string;

    public _restrictions: FileRestrictions = {
        maxFileSize: 10000000,
        allowedExtensions: ['json'],
    };

    constructor(protected injector: Injector, protected config: AppConfig, protected authService: AuthService, protected itemDataService: ItemDataService) {
        super(injector);
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        authService.getUserPromise().then((user) => {
            this.accessToken = user.access_token;
        });
        this.itemDataService.fetchItemByCategoryAndCode(ItemCategoryCodes.CoreDisplayText, CoreDisplayTexts.ManualGameUploadImportTooltip).subscribe(
            (item) => {
                this.manualGameImport = item.description;
            });
        this.itemDataService.fetchItemByCategoryAndCode(ItemCategoryCodes.CoreDisplayText, CoreDisplayTexts.GameImportQueueTooltip).subscribe(
            (item) => {
                this.automaticalyGameImport = item.description;
            });
    }

    public open() {
        this.active = true;
        this.errorMessage = null;
        this.uploadFinished = false;

        this.uploadSaveUrl = this.competitionApiEndpoint + '/' + this.competitionId + '/games/' + this.gameId + '/' + (this.useServiceBus ? this.busImportRoute : this.privateImportRoute);

        this.uploadSaveheaders = new HttpHeaders({
            ['Authorization']: 'Bearer ' + this.accessToken,
        });
    }

    public successEventHandler(event) {
        this.active = false;
    }

    public errorEventHandler(event: ErrorEvent) {
        this.errorMessage = (event.response as any).error; // TODO: Weird fix CEDRIC
    }

    public cancelEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public clearEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public completeEventHandler(event) {
        this.uploadFinished = true;
        this.uploadInProgress = false;
        this.fileImported.emit(true);
    }

    // noinspection JSUnusedGlobalSymbols
    public uploadEventHandler(e: UploadEvent) {
        this.uploadInProgress = true;
    }

    closeForm(): void {
        if (this.uploadInProgress === false) {
            this.active = false;
            this.cancel.emit();

            if (this.uploadFinished) {
                // Trigger reload of the model in the parent
                this.fileImported.emit(true);
            }
        }
    }

    public getTooltipContent(useServiceBus : boolean): string {

        if (useServiceBus)
            return this.automaticalyGameImport;
        else
            return this.manualGameImport;
    }
}
