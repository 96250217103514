import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, Observable, combineLatest, of } from 'rxjs';

import { ZoneDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { ZoneDTO } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';

import * as _ from 'lodash';
import { ZoneRelationDTO } from '../../models';
import { first } from 'rxjs/operators';

import { TreeItem, TreeviewConfig, TreeviewItem } from '../components/custom-ngx-treeview';

interface IZoneRelation {
    firstZoneId: number;
    secondZoneId: number;
    zoneId: number;
    officialName: string;
}

interface IZonesAndZoneRelations {
    zones: any[];
    zoneRelations: IZoneRelation[];
}

interface IZone {
    officialName: string;
    zoneId: number;
    isTopLevelParentWithChildZones: boolean;
}

@Component({
    selector: 'fibaMultiSelectZone',
    templateUrl: '../../forms/base/fiba-multiselect-treeview.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectZoneComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaMultiSelectZoneComponent extends FibaMultiSelectBaseComponent implements OnInit {

    @Output() public zoneChange = new EventEmitter<boolean>();
    @Input() public contextCode = '';
    @Input() public hasCommittee: boolean = undefined;
    @Input() public topLevel = false;

    public initialChangeDone: boolean = false;

    public static getComplete(): () => void {
        return () => { /**/ };
    }

    private static checkValueForUndefined(value: any[]): any[] {
        let val: any[] = value;

        if (typeof val === 'undefined') {
            val = [];
        }

        return val;
    }

    config: TreeviewConfig = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasFilter: true,
        hasCollapseExpand: true,
        decoupleChildFromParent: true,
        maxHeight: 400,
    });

    private zones: IZone[];
    private zoneRelations: IZoneRelation[];

    private fillable = true;
    private flushable = true;
    private isInit = true;   // Avoid flush to work

    get value() {
        return this._value;
    }

    set value(value: any[]) {
        const val: any[] = FibaMultiSelectZoneComponent.checkValueForUndefined(value);

        if (val !== this._value) {
            this._value = val;
            this.onChange(this._value);
            this.zoneChange.emit(true);
        }

        if (value.length === 0 && !this.isInit) {
            this.flushTreeLogic();
        } else if (this.fillable) {
            this.fillTreeLogic(value);
        } else {
            setTimeout(() => {
                this.isInit = false;
            }, 1000);
        }
    }

    constructor(protected dataService: ZoneDataService) {
        super();
        this._valueField = 'zoneId';
        this._textField = 'officialName';
        this.value = [];
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.fillable = true;
        this.isInit = true;
    }

    public getBindData(): (data: IZonesAndZoneRelations[]) => void {
        return (data: IZonesAndZoneRelations[]) => {
            this._data = this.$getTree(data as any as IZonesAndZoneRelations);
        };
    }

    public getHandleError(): (err: any) => void {
        return (err: any) => {
            Logger.error(err);
        };
    }

    public getSubscription(): Subscription {
        return this.dataService.fetchZonesAndZonesRelations(this.contextCode, this.topLevel, this.hasCommittee)
            .subscribe(
                this.getBindData(),
                this.getHandleError(),
                FibaMultiSelectZoneComponent.getComplete(),
            );
    }

    // Usued in html
    // noinspection JSUnusedGlobalSymbols
    public onSelectedChange(value): void {
        if (this.initialChangeDone) {
            this.value = value;
        } else {
            this.initialChangeDone = true;
        }
    }

    private fillTreeLogic(value): void {
        this.rebuildTree(value);
        this.fillable = false;
        this.isInit = true;
    }

    private flushTreeLogic(): void {
        if (this.flushable) {
            this.rebuildTree();
            this.flushable = false;
        } else {
            this.flushable = true;
        }
    }

    private rebuildTree(zoneToCheck?: IZone[]): void {
        this.dataService.fetchZonesAndZonesRelations(this.contextCode, this.topLevel, this.hasCommittee)
            .pipe(
                first(),
            )
            .subscribe((data) => {
                this._data = this.$getTree(data as any as IZonesAndZoneRelations, zoneToCheck);
            });
    }

    /* --------------- DECLARATIVE WAY ------------------ */

    private $getTree(zonesAndZoneRelations: IZonesAndZoneRelations, zoneToCheck?: IZone[]): Observable<TreeviewItem[]> {
        return combineLatest(
            this.$cleanZoneRelations(zonesAndZoneRelations.zoneRelations as ZoneRelationDTO),
            this.$cleanZone(zonesAndZoneRelations.zones as ZoneDTO[]),
            (cleanedZR, cleanedZones) => this.$createTree(cleanedZR, cleanedZones, zoneToCheck));
    }

    private $createTree(cleanedZR, cleanedZones, zoneToPreCheck?: IZone[]): TreeviewItem[] {
        const treeView: TreeviewItem[] = [];

        // treeView is mutating inside $createTreeRecusively
        this.$getOrphansFromZones(cleanedZR, cleanedZones)
            .map((orphanZone) => this.$fillTreeViewRecursively(treeView,
                orphanZone.zoneId, cleanedZR,
                cleanedZones, zoneToPreCheck));

        return (treeView);
    }

    private getTreeNotRecursive(cleanedZR, cleanedZones, zoneToPreCheck): TreeviewItem[] {
        const treeView: TreeviewItem[] = [];

        this.$getOrphansFromZones(cleanedZR, cleanedZones).forEach((parent) => {
            const parentTreeItem: TreeItem = this.$createTreeViewNodeFromZoneId(parent.zoneId, cleanedZones, zoneToPreCheck);
            this.getChild(parent.zoneId, cleanedZR).forEach((child) => {
                const childTreeItem: TreeItem = this.$createTreeViewNodeFromZoneId(child.secondZoneId, cleanedZones, zoneToPreCheck);
                this.getChild(child.secondZoneId, cleanedZR).forEach((grandChild) => {
                    const grandChildTreeItem: TreeItem = this.$createTreeViewNodeFromZoneId(grandChild.secondZoneId, cleanedZones, zoneToPreCheck);
                    childTreeItem.children.push(grandChildTreeItem);
                });
                parentTreeItem.children.push(childTreeItem);
            });
            treeView.push(new TreeviewItem(parentTreeItem, false, true, true));
        });

        return treeView;
    }

    private $fillTreeViewRecursively(treeView: TreeItem[], zoneId: number, cleanedZR: IZoneRelation[],
        cleanedZones: IZone[], zoneToCheck?: IZone[]): void {
        const treeItem: TreeItem = this.$createTreeViewNodeFromZoneId(zoneId, cleanedZones, zoneToCheck);

        this.getChild(zoneId, cleanedZR).forEach((child) => {
            this.$fillTreeViewRecursively(treeItem.children, child.secondZoneId, cleanedZR, cleanedZones, zoneToCheck);
        });
        treeView.push(new TreeviewItem(treeItem, false, true, true));
    }

    private $createTreeViewNodeFromZoneId(zoneId: number, cleanedZones: IZone[], zoneToCheck: IZone[]): TreeItem {
        return {
            text: this.$getZoneOfficialNameFromZoneId(cleanedZones, zoneId),
            value: this.$getZoneFromZoneId(cleanedZones, zoneId),
            isTopLevelParentWithChildZones: this.$getZoneIsTopLevelParentWithChildZonesFromZoneId(cleanedZones, zoneId),
            checked: this.isPrechecked(zoneId, zoneToCheck),
            children: [] as TreeItem[],
        };
    }

    private $getOrphansFromZones(cleanedZR: IZoneRelation[], cleanedZones: IZone[]): IZone[] {
        const orphans: IZone[] = cleanedZones.filter((zone) => this.isOprhan(zone, cleanedZR));
        return (orphans);
    }

    private $cleanZoneRelations(zoneRelations: ZoneRelationDTO): Observable<IZoneRelation[]> {
        const model: IZoneRelation = {
            firstZoneId: undefined,
            secondZoneId: undefined,
            zoneId: undefined,
            officialName: undefined,
        };
        return of(this.pickFieldsFromObjects<IZoneRelation>(zoneRelations, model));
    }

    private $cleanZone(zones: ZoneDTO[]): Observable<IZone[]> {
        const model: IZone = {
            officialName: undefined,
            zoneId: undefined,
            isTopLevelParentWithChildZones: undefined
        };
        return of(this.pickFieldsFromObjects<IZone>(zones, model));
    }

    private $getZoneFromZoneId(cleanedZones: IZone[], zoneId: number): any {
        return _.find(cleanedZones, (zone) => zone.zoneId === zoneId);
    }

    private $getZoneOfficialNameFromZoneId(cleanedZones: IZone[], zoneId): string {
        return this.$getZoneFromZoneId(cleanedZones, zoneId).officialName;
    }

    private $getZoneIsTopLevelParentWithChildZonesFromZoneId(cleanedZones: IZone[], zoneId): boolean {
        return this.$getZoneFromZoneId(cleanedZones, zoneId).isTopLevelParentWithChildZones;
    }

    private isPrechecked(zoneId: number, zoneToCheck: IZone[]): boolean {
        if (zoneToCheck) {
            const predicat = _.find(zoneToCheck, (zone) => zone.zoneId === zoneId);
            return (predicat !== undefined);
        } else {
            return false;
        }
    }

    /* -------------- UTIL TO BOTH WAYS -------------- */

    private getChild(zoneId: number, zoneRelations: IZoneRelation[]): IZoneRelation[] {
        return (zoneRelations.filter((czr) => czr.firstZoneId === zoneId));
    }

    private isOprhan(zone: IZone, cleanedZR: IZoneRelation[]): boolean {
        const notOrphansZoneArray = _.filter(cleanedZR, (zr) => zr.secondZoneId === zone.zoneId);
        return (notOrphansZoneArray.length === 0);
    }

    private pickFieldsFromObjects<T>(array: any, model: T): T[] {
        return array.map((item) => _.pick(item, _.keys(model))) as T[];
    }

    /* ---------- IMPERATIVE WAY -------------*/

    private getTree(): TreeviewItem[] {
        const zonesAndZoneRelations = this._data as any as IZonesAndZoneRelations;
        this.zoneRelations = this.cleanZoneRelations(zonesAndZoneRelations.zoneRelations as ZoneRelationDTO);
        this.zones = this.cleanZone(zonesAndZoneRelations.zones as ZoneDTO[]);

        return this.createTree();
    }

    private cleanZoneRelations(zoneRelations: ZoneRelationDTO): IZoneRelation[] {
        const model: IZoneRelation = {
            firstZoneId: undefined,
            secondZoneId: undefined,
            zoneId: undefined,
            officialName: undefined,
        };

        return this.pickFieldsFromObjects<IZoneRelation>(zoneRelations, model);
    }

    private getOrphansFromZones(): ZoneDTO[] {
        const orphans: IZone[] = this.zones.filter((zone) => this.isOprhan(zone, this.zoneRelations));
        return (orphans);
    }

    private cleanZone(zones: ZoneDTO[]): IZone[] {
        const model: IZone = {
            officialName: undefined,
            zoneId: undefined,
            isTopLevelParentWithChildZones: undefined,
        };

        return this.pickFieldsFromObjects<IZone>(zones, model);
    }

    private createTree(): TreeviewItem[] {
        const treeView: TreeviewItem[] = [];

        this.getOrphansFromZones().forEach((orphanZone) => {
            this.createTreeRecursively(treeView, orphanZone.zoneId);
        });

        return treeView;
    }

    private createTreeRecursively(treeView: TreeItem[], zoneId: number): void {
        const treeItem: TreeItem = this.createTreeViewNodeFromZoneId(zoneId);

        this.getChild(zoneId, this.zoneRelations).forEach((child) => {
            this.createTreeRecursively(treeItem.children, child.secondZoneId);
        });

        treeView.push(new TreeviewItem(treeItem));
    }

    private createTreeViewNodeFromZoneId(zoneId: number): TreeItem {
        return {
            text: this.getZoneOfficialNameFromZoneId(zoneId),
            value: this.getZoneFromZoneId(zoneId),
            isTopLevelParentWithChildZones: this.getZoneIsTopLevelParentWithChildZonesFromZoneId(zoneId),
            checked: false,
            children: [] as TreeItem[],
        };
    }

    private getZoneOfficialNameFromZoneId(zoneId): string {
        return this.getZoneFromZoneId(zoneId).officialName;
    }

    private getZoneIsTopLevelParentWithChildZonesFromZoneId(zoneId): boolean {
        return this.getZoneFromZoneId(zoneId).isTopLevelParentWithChildZones;
    }

    private getZoneFromZoneId(zoneId: number): any {
        return _.find(this.zones, (zone) => zone.zoneId === zoneId);
    }
}
