import {NgModule} from '@angular/core';

import {SharedModule} from '../../shared.module';
import {AuthHandlerRoutingModule} from './auth-handler-routing.module';

import {AuthHandlerComponent} from '../auth-handler.component';

const EXTERNAL_COMPONENTS: any[] = [
    AuthHandlerComponent,
];

const INTERNAL_COMPONENTS = [];

@NgModule({
    imports: [
        AuthHandlerRoutingModule,
        SharedModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class AuthHandlerModule {

}
