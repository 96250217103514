<div kendoTooltip showOn="none">

    <img *ngIf="pictureUrl" [src]="pictureUrl" [ngStyle]="{'max-width':'430px', 'max-height':'39px'}" />

    <ng-container *ngIf="isEditable">
        <button *fibaHideByPolicy="MapPolicy_AzureFilesEdit"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-pencil'"
                [look]="'bare'"
                (click)="editClick()"
                id="buttonEdit"></button>
        <button *ngIf="pictureUrl"
                #anchor
                title="URL copied"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-copy'"
                [look]="'bare'"
                (click)="copySponsorStripToClipboard(anchor)"
                id="buttonCopy"></button>
        <button *ngIf="pictureUrl"
                title="Remove picture"
                type="button"
                kendoButton
                [primary]="true"
                [iconClass]="'fa fa-lg fa-trash'"
                [look]="'bare'"
                (click)="deletePicture()"
                id="buttonDelete"></button>
    </ng-container>

</div>

<fibaPopupCompetitionPicture (save)="saveCompetitionSponsorStripHandler($event)"
                             (cancel)="cancelCompetitionSponsorStripHandler()"
                             [model]="editCompetitionSponsorStrip"
                             [entityReference]="entityReference"
                             [fileType]="fileType"
                             [recommendedSize]="recommendedSize"
                             [isNew]="isNew"></fibaPopupCompetitionPicture>
