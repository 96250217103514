import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { CompetitionTeamStatusDTO, CompetitionTeamDTO, CompetitionTeamListItemDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionTeamsDataService {
    protected competitionTeamsStatusApiEndpoint: string;
    protected competitionApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataCompetitionTeamStatusService: DataCachedService<CompetitionTeamStatusDTO>, ) {
        this.competitionTeamsStatusApiEndpoint = config.competitionTeamStatusApiEndpoint;
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchStatuses(): Observable<CompetitionTeamStatusDTO[]> {
        return this.dataCompetitionTeamStatusService.fetchEntities(this.competitionTeamsStatusApiEndpoint);
    }

    fetchCompetitionTeams(competitionId: number, onlyApproved: boolean = false, includeVirtual: boolean = false): Observable<CompetitionTeamDTO[]> {
        const params = {};
        if (onlyApproved) {
            params['onlyApproved'] = String(onlyApproved);
        }
        if (includeVirtual) {
            params['includeVirtual'] = String(includeVirtual);
        }

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/teams`, params);
    }

    fetchDrawRoundTeams(roundId: number): Observable<CompetitionTeamDTO[]> {
        const params = {};
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/draw-teams`, params);
    }

    fetchCompetitionTeamsListItems(competitionId: number, onlyApproved: boolean = false): Observable<CompetitionTeamListItemDTO[]> {
        const params = {};
        if (onlyApproved) {
            params['onlyApproved'] = String(onlyApproved);
        }

        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/registered-teams`, params);
    }

    fetchRoundCompetitionTeamsFromGroupPairingId(groupPairingId: number, onlyOtherGroups: boolean = false): Observable<CompetitionTeamDTO[]> {
        const params = {};
        if (onlyOtherGroups) {
            params['onlyOtherGroups'] = String(onlyOtherGroups);
        }

        return this.http.get(`${this.competitionApiEndpoint}/group-pairings/${groupPairingId}/round-teams`, params);
    }

    fetchRoundCompetitionTeamsFromRoundDrawId(roundDrawId: number, onlyOtherGroups: boolean = false): Observable<CompetitionTeamDTO[]> {
        const params = {};
        if (onlyOtherGroups) {
            params['onlyOtherGroups'] = String(onlyOtherGroups);
        }

        return this.http.get(`${this.competitionApiEndpoint}/round-draw/${roundDrawId}/round-teams`, params);
    }
}
