import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[fibaAlphaNumericValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaAlphaNumericValidator), multi: true }
    ]
})
export class FibaAlphaNumericValidator implements Validator {
    @Input('fibaAlphaNumericValidator') allowDash: boolean = false; // Param�tre personnalis�

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let pattern = this.allowDash ? /[^a-zA-Z\d-]/i : /[^a-zA-Z\d]/i;
        const v = c.value;
        if (v && pattern.test(v)) {
            c.setErrors({ fibaAlphaNumericValidator: true });
            return {
                fibaAlphaNumericValidator: true
            };
        }

        else {
            if (c.errors) {
                delete c.errors['fibaAlphaNumericValidator'];
                if (!Object.keys(c.errors).length) {
                    c.setErrors(null);
                }
            }
        }

        return null;
    }
}