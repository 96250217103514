import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared.module';
import {TopMenuComponent} from './topmenu.component';
import {UserDropdownMenuComponent} from './user-dropdown-menu/user-dropdown-menu.component';

const EXTERNAL_COMPONENTS: any[] = [
    TopMenuComponent,
];

const INTERNAL_COMPONENTS: any[] = [
    UserDropdownMenuComponent,
];

@NgModule({
    imports: [
        SharedModule.forRoot(),
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
    providers: [],
})
export class TopMenuModule {
}
