import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { HttpService } from '@fiba/data-services/utils/http.service';
import { SiteVisitFilters } from '@fiba/models/site-visit/site-visit-filters';
import { ApiListResponse, FormRegistrationSiteVisitDetailDTO, FormRegistrationSiteVisitListItemDTO } from '@fiba/models';
import { FormRegistrationAction } from '../../models/constants/generated/form-registration-action';

@Injectable({
    providedIn: 'root',
})
export class SiteVisitDataService {

    protected formRegistrationSiteVisitApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {

        this.formRegistrationSiteVisitApiEndpoint = config.formRegistrationSiteVisitApiEndpoint;
    }

    fetchList(filters: SiteVisitFilters): Observable<ApiListResponse<FormRegistrationSiteVisitListItemDTO>> {
        return this.http.get(this.formRegistrationSiteVisitApiEndpoint, filters.getSearchParams()).pipe(
            map(response => response as ApiListResponse<FormRegistrationSiteVisitListItemDTO>));
    }

    fetchSiteVisitFormRegistration(venueFormRegistrationId: number): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.get(this.formRegistrationSiteVisitApiEndpoint + '/' + venueFormRegistrationId);
    }

    fetchInitialVersion(venueId?: number, venueFormRegistrationId?: number): Observable<FormRegistrationSiteVisitDetailDTO> {
        const params = {};
        if (venueId) {
            params['venueId'] = venueId;
        }
        if (venueFormRegistrationId) {
            params['venueFormRegistrationId'] = venueFormRegistrationId;
        }
        return this.http.get(this.formRegistrationSiteVisitApiEndpoint + '/initial-version', params);
    }

    createSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.post(this.formRegistrationSiteVisitApiEndpoint, model);
    }

    createSubmitSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.post(this.formRegistrationSiteVisitApiEndpoint + '?action=' + FormRegistrationAction.Submit, model);
    }

    updateSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.put(this.formRegistrationSiteVisitApiEndpoint + '/' + model.formRegistrationId, model);
    }

    submitSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.put(this.formRegistrationSiteVisitApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Submit, model);
    }

    reopenSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.put(this.formRegistrationSiteVisitApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Reopen, model);
    }

    cancelSiteVisitFormRegistration(model: FormRegistrationSiteVisitDetailDTO): Observable<FormRegistrationSiteVisitDetailDTO> {
        return this.http.put(this.formRegistrationSiteVisitApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Cancel, model);
    }

}
