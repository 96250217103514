import {Component, OnInit} from '@angular/core';
import {User} from 'oidc-client';

import {AuthService, UserDataService} from '@fiba/data-services';
import {UserTopMenuInfoDTO} from '@fiba/models';
import {Logger} from '@fiba/utils/logger';

@Component({
    selector: 'top-menu',
    templateUrl: './topmenu.component.html',
    styleUrls: ['./topmenu.component.css'],
})
export class TopMenuComponent implements OnInit {
    public user: User;
    public userInfo: UserTopMenuInfoDTO;
    public optionStyles: any;

    constructor(
        protected authService: AuthService,
        protected userService: UserDataService) {
        this.user = null;
    }

    public ngOnInit(): void {
        this.authService.getUserPromise().then((user) => {
            this.user = user;
        });

        this.userService.fetchTopMenuInfo().subscribe(
            (userInfo) => {
                this.userInfo = userInfo;
            },
            (error) => {
                Logger.warn(error);
            },
        );
        // Get do not return environnment name
        this.optionStyles = this.authService.getConfigOption();
    }

    logout(): void {
        this.authService.logout();
    }
}
