import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from '@angular/router';
import {BreadcrumbService} from '@fiba/utils/breadcrumb.service';
import {Logger} from '@fiba/utils/logger';
import {filter} from 'rxjs/operators';


const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
const HIDDEN_IN_BREADCRUMB = 'hiddenInBreadcrumb';

interface IBreadcrumb {
    label: string;
    params: Params;
    url: string;
    level: number;
}

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
    providers: [BreadcrumbService],
})
export class BreadcrumbComponent implements OnInit {

    public breadcrumbs: IBreadcrumb[] = [];
    private overrides: string[] = []; // parts of the breadcrumb that are provided by each page
    private menu = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private breadcrumbService: BreadcrumbService,
    ) {
    }

    public ngOnInit() {
        this.breadcrumbService.breadcrumbSet$.subscribe(
            (val) => {
                this.overrides[val.level] = val.name;
                this.updateBreadcrumb(undefined);
            });

        // Subscribe to the NavigationEnd event
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(this.updateBreadcrumb);

        // Call it for the first page we arrive on
        this.updateBreadcrumb(undefined);
    }

    private updateBreadcrumb = (event) => {
        if (event && event.urlAfterRedirects) {
            if (event.urlAfterRedirects.split('/').length <= 3) {
                // reset overrides on leaving a details screen
                this.overrides = [];
            } else {
                // reset last breadcrumb
                this.overrides[event.urlAfterRedirects.split('/').length - 1] = undefined;
            }
        }

        // set breadcrumbs
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root, 0);

        if (this.breadcrumbs) {
            for (const bc of this.breadcrumbs) {
                if (this.overrides[bc.level]) {
                    bc.label = this.overrides[bc.level];
                }
            }
        }

        //update Title
        this.titleService.setTitle(this.buildTitle());
    };

    private buildTitle(): string {
        if (!this.breadcrumbs) {
            return 'FIBA MAP';
        }

        if (this.breadcrumbs[1] && this.breadcrumbs[1].label === 'Home') {
            return 'Home - FIBA MAP';
        }

        const breadcrumbs: IBreadcrumb[] = this.breadcrumbs.slice(0); // Copy breadcrumbs array
        breadcrumbs.pop();
        breadcrumbs.reverse().pop();

        return breadcrumbs.map((bc) => bc.label).join('/') + ' - FIBA MAP';
    }

    /**
     * Returns array of IBreadcrumb objects that represent the breadcrumb
     *
     * @class DetailComponent
     * @method getBreadcrumbs
     * @param route
     * @param level
     * @param url
     * @param breadcrumbs
     */
    private getBreadcrumbs(route: ActivatedRoute, level: number, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        //get the child routes
        const children: ActivatedRoute[] = route.children;

        //return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }

        //iterate over each children
        for (const child of children) {
            //verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }

            //verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot || !child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                Logger.warn('Missing breadcrumb info on route ' + child.routeConfig.path);
                continue;
            }

            //get the route's URL segment
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

            if (child.snapshot && (!child.snapshot.data.hasOwnProperty(HIDDEN_IN_BREADCRUMB) || child.snapshot.data[HIDDEN_IN_BREADCRUMB] === false)) {
                //append route URL to URL
                url += `/${routeURL}`;

                //add breadcrumb
                const breadcrumb: IBreadcrumb = {
                    label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                    params: child.snapshot.params,
                    url,
                    level,
                };

                breadcrumbs.push(breadcrumb);
            }

            //recursive
            return this.getBreadcrumbs(child, level + 1, url, breadcrumbs);
        }
    }

}
