<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-combobox id="{{name}}"
                    [placeholder]="placeholder"
                    [data]="_filteredItems"
                    [textField]="_textField"
                    [valueField]="_valueField"
                    [valuePrimitive]="valuePrimitive"
                    [(ngModel)]="value"
                    [suggest]="true"
                    [filterable]="true"
                    [clearButton]="clearButton"
                    [disabled]="disabled"
                    (filterChange)="handleFilter($event)"
                    (valueChange)="handleValue($event)"
                    class="fiba-select">
        {{_textField}}
    </kendo-combobox>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.licenseANationality">
                Player license A cannot be requested for the same nationality as the NF country.
            </li>
        </ul>
    </div>
</div>
