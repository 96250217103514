import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class PlayerReleaseFilters extends BaseFilters {
    public playerLastName: string;
    public playerFirstName: string;
    public dateOfBirth: Date;
    public releaseFrom: Date;
    public releaseTo: Date;
    public outdated: boolean = true;
    public toNationalFederation: Filterable = new Filterable('toNationalFederationId', 'organisationId');
    public fromNationalFederation: Filterable = new Filterable('fromNationalFederationId', 'organisationId');
    public competition: Filterable = new Filterable('competitionId');
    public club: Filterable = new Filterable('playerReleaseClubId', 'organisationId');
    public status: FilterableArray = new FilterableArray('playerReleaseStatusId');

    public reset(): void {
        delete this.playerLastName;
        delete this.playerFirstName;
        delete this.dateOfBirth;
        delete this.releaseFrom;
        delete this.releaseTo;
        delete this.outdated;
        this.toNationalFederation.reset();
        this.fromNationalFederation.reset();
        this.competition.reset();
        this.club.reset();
        this.status.reset();
    }
}

declare module '../dtos/generated/player-release-dto' {
    export interface PlayerReleaseDTO {
        cli_OriginalStatusCode: string;
    }
}
