import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TechnicalMeetingStatusDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class TechnicalMeetingStatusDataService {
    protected technicalMeetingStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataTechnicalMeetingStatusService: DataCachedService<TechnicalMeetingStatusDTO>) {
        this.technicalMeetingStatusApiEndpoint = config.technicalMeetingStatusApiEndpoint;
    }

    fetchStatuses(): Observable<TechnicalMeetingStatusDTO[]> {
        return this.dataTechnicalMeetingStatusService.fetchEntities(this.technicalMeetingStatusApiEndpoint);
    }
}
