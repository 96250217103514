import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { EventDTO } from '..';

export class TechnicalDelegateEventCandidateFilters extends BaseFilters {
    event: Filterable = new Filterable('eventId');
    officialRegistrationStatus: Filterable = new Filterable('officialRegistrationStatusId');
    activeOnly: boolean;
    reset(): void {
        this.event.reset();
        this.officialRegistrationStatus.reset();
        delete this.activeOnly;
    }
}
