import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[fibaGreaterValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaGreaterValidator), multi: true }
    ]
})
export class FibaGreaterValidator implements Validator {
    constructor(
        @Attribute('fibaGreaterValidator') public fibaGreaterValidator: string,
        @Attribute('lowestDate') public lowestDate: string) {
    }

    private get isLowest(): boolean {
        if (!this.lowestDate) {return false;}
        return this.lowestDate === 'true';
    }

    private deleteOtherError(other: AbstractControl): void {
        if (other && other.errors) {
            delete other.errors['fibaGreaterValidator'];
            if (!Object.keys(other.errors).length) {
                other.setErrors(undefined);
            }
        }
    }

    validate(me: AbstractControl): { [key: string]: any } {
        // self value
        const myValue = me.value;

        // control value
        const other = me.root.get(this.fibaGreaterValidator);

        // if any of the 2 fields is empty, it's ok.
        if (!myValue || !other || !other.value) {
            this.deleteOtherError(other);
            return undefined;
        }

        // this value must be greater or equal than the reverse
        if (!this.isLowest) {
            if (myValue < other.value) {
                return {
                    fibaGreaterValidator: true
                };
            }
            else {
                this.deleteOtherError(other);
                return undefined;
            }
        }
        else {
            // reverse value must be lower or equal
            if (myValue > other.value) {
                return { fibaGreaterValidator: true };
            }
            else {
                this.deleteOtherError(other);
                return undefined;
            }
        }
    }
}
