import { Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputTime',
    templateUrl: './fiba-input-time.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputTimeComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaInputTimeComponent extends FibaInputBase<string> {

    public mask = '00:00';

    @Output() public change: EventEmitter<string> = new EventEmitter();
    @Input() public disabledTime = false;
    @Input() public utcOffset: number = null;

    constructor(protected injector: Injector) {
        super(injector);
    }

    public handleValue(value) {
        this.onChange(value);
        this.onTouched();
    }

    public handleTimeChange(value) {
        if (this._value || this._value === 0) {
            this._value = value;
            this.onChange(value);
            this.change.emit(this._value as string);
        }
        this.onTouched();
    }

    public get value(): string {
        return this._value as string;
    }

    public set value(value: string) {
        if (value) {
            this._value = value;
        }
    }
}
