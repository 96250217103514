import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SanctionDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { Logger } from '@fiba/utils/logger';

/**
 * Component fibaMultiSelectSanctionBanApplicability
 */
@Component({
    selector: 'fibaMultiSelectSanctionBanApplicability',
    templateUrl: '../../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectSanctionBanApplicabilityComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})


/**
 * Class FibaMultiSelectSanctionBanApplicabilityComponent
 *
 * @param {UserDataService} protected dataService
 * @returns
 */
export class FibaMultiSelectSanctionBanApplicabilityComponent extends FibaMultiSelectBaseComponent {

    private _sanctionApplicabilityItemCode: string;

    @Input() set sanctionApplicabilityItemCode(value: string) {
        this._sanctionApplicabilityItemCode = value;
        //this.value = undefined;
        this.getSubscription();
    }

    constructor(protected dataService: SanctionDataService) {
        super();
        this._valueField = 'itemId';
        this._textField = 'name';
    }

    /**
     * Get the sanction ban applicabilities from the service
     *
     * @returns A subscription
     */
    public getSubscription(): Subscription {
        return this.dataService.fetchBanApplicabilityItems(this._sanctionApplicabilityItemCode).subscribe(
            (data) => {
                this.items = data;
                this._data = data.slice(0);
            },
            (err) => {
                Logger.error(err);
            },
            () => { },
        );
    }
}
