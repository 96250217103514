<fibaLoading></fibaLoading>
<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{ isNew ? 'Add new picture' : 'Edit picture' }}
    </kendo-dialog-titlebar>
    <form #form="ngForm" novalidate [ngClass]="{'submitted': submitted}" autocomplete="off">
        <div style="width: 410px; height: auto;" id="image">
            <fibaInputUploadAzureFiles id="upload"
                                       (getAzureFileUrl)="getAzureFileUrl($event)"
                                       (getAzureFilenamel)="getAzureFilename($event)"
                                       (fileUploaded)="fileUploadedHandler($event)"
                                       [restrictionPicture]="true"
                                       [entityReference]="entityReference"
                                       [fileType]="fileType"
                                       [recommendedSize]="recommendedSize"
                                       [isNew]="isNew"></fibaInputUploadAzureFiles>
            <img *ngIf="_model" [src]="_model" style="max-width:400px;" />
        </div>
    </form>
    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button class="k-button k-primary" [disabled]="!fileUploaded" (click)="onSave($event)">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>