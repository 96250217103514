import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { ItemDTO } from '@fiba/models';

export class VenueFilters extends BaseFilters {
    context: string;
    // Basic
    name: string;
    status: Filterable = new Filterable('statusId', 'venueStatusId');
    addressLine: string;
    addressZipCode: string;
    addressCity: string;
    addressCountry: Filterable = new Filterable('addressCountryId', 'countryId');
    distanceKm: number;
    // Advanced
    ownerOrganisation: Filterable = new Filterable('ownerOrganisationId', 'organisationId');
    playingClub: Filterable = new Filterable('playingClubOrganisationId', 'organisationId');
    zone: FilterableArray = new FilterableArray('zoneId');
    competition: Filterable = new Filterable('competitionId');
    capacityType: string;
    capacityComparisonType: string;
    capacity: number;
    venueId: number;
    mergeMode: boolean;

    reset(): void {
        this.name = undefined;
        this.status.reset();
        this.addressLine = undefined;
        this.addressZipCode = undefined;
        this.addressCity = undefined;
        this.addressCountry.reset();
        this.distanceKm = undefined;

        this.ownerOrganisation.reset();
        this.playingClub.reset();
        this.zone.reset();
        this.competition.reset();
        this.capacityType = undefined;
        this.capacityComparisonType = undefined;
        this.capacity = undefined;
        this.venueId = undefined;
        this.mergeMode = undefined;
    }
}

export class VenueCompetitionFilters extends BaseFilters {
    competitionCode: string;
    description: string;
    typeItem: ItemDTO;
    season: number;
    startDate: Date;
    endDate: Date;

    reset(): void {
        delete this.competitionCode;
        delete this.description;
        delete this.typeItem;
        delete this.season;
        delete this.startDate;
        delete this.endDate;
    }
}
