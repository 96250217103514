import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OfficialNominationsDTO, OfficialNominationCompetitionDTO, ConfigurationRoleCompetitionRoleDTO, ItemDTO, CompetitionNominationInformationForNFDTO, GameHostedCarouselItemDTO, GameAssignmentsDTO, GameAssignmentConfigurationDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionNFNominationDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionInformation(competitionId: number): Observable<CompetitionNominationInformationForNFDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nf-nomination-information`);
    }

    fetchHostedGamesForNationalFederation(competitionId: number, nationalFederationId: number): Observable<GameHostedCarouselItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nominations-for-nf/${nationalFederationId}/hosted-game`);
    }

    fetchNominationsForNationalFederationByGame(competitionId: number, nationalFederationId: number, gameId: number): Observable<GameAssignmentsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nominations-for-nf/${nationalFederationId}/hosted-game/${gameId}`);
    }

    fetchNominationsConfigurations(competitionId: number): Observable<GameAssignmentConfigurationDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nominations-for-nf/configurations/`);
    }

    updateOfficialNominations(competitionId: number, nationalFederationId: number, gameId: number, model: GameAssignmentsDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/nominations-for-nf/${nationalFederationId}/hosted-game/${gameId}`, model);
    }

    fetchConfigurationRoleCompetitionRoleItems(roleCode: string = null): Observable<ItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/nominations/competition-role-items`);
    }

    fetchConfigurationRoleCompetitionRoles(roleCode: string = null): Observable<ConfigurationRoleCompetitionRoleDTO[]> {
        let params = null;
        if (roleCode) {
            params = { roleCode: String(roleCode) };
        }
        return this.http.get(`${this.competitionApiEndpoint}/nominations/competition-role-relationships`, params);
    }
}
