import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FibaSelectBaseComponent } from '@fiba/forms';

@Component({
    selector: 'fibaSelectTimezone',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectTimezoneComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectTimezoneComponent extends FibaSelectBaseComponent<string> {
    constructor(protected injector: Injector) {
        super(injector);
        this.valuePrimitive = true;
        this._textField = '';
        this._valueField = '';
    }

    @Input() protected set items(value: string[]) {
        this._originalItems = value || [];
        this._filteredItems = this._originalItems.slice(0);
    }

    protected handleRestrictedItems(): void {
        this._filteredItems = this._originalItems;
    }
}
