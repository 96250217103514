import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../../app/shared.module';
import { FibaCommonModule } from '@fiba/common';
import { FibaCommonSelectModule } from '../../../../common/fiba-common-select.module';
import { FibaFormsModule } from '@fiba/forms';
import { ClubSearchMiniModule } from './club-search-mini/club-search-mini.module';

import { FibaPopupClubComponent } from './fiba-popup-club.component';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPopupClubComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonModule,
        FibaCommonSelectModule,
        ClubSearchMiniModule,
    ],
    declarations: [
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPopupClubModule {
}
