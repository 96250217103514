import {Injectable} from '@angular/core';
import {PasswordValidationRules} from '@fiba/models';

@Injectable({
    providedIn: 'root',
})
export class UserPasswordService {
    protected _passwordValidationRules: PasswordValidationRules = new PasswordValidationRules();

    public get PasswordValidationRules(): PasswordValidationRules {
        return this._passwordValidationRules;
    }

    CheckRules(password: string): void {
        this._passwordValidationRules.CheckRules(password);
    }

    validateEmailFormat(email: string): boolean {
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    showGeneratedPasswordPrompt(password: string): string {
        return window.prompt('Please, press Ctrl+C, Enter in order to copy the generated password.', password);
    }

    generatePassword(): string {
        let password = '';
        const length = 10;

        const charsNotUpper = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>';
        const charsSpecial = '!@#$%^&*()-+<>[]{}';
        const charsUpper = 'ABCDEFGHIJKLMNOPRSTUVWXYZ';
        const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOPRSTUVWXYZ1234567890';

        password = charsNotUpper.charAt(Math.floor(Math.random() * charsNotUpper.length));
        password += charsUpper.charAt(Math.floor(Math.random() * charsUpper.length));
        password += charsSpecial.charAt(Math.floor(Math.random() * charsSpecial.length));

        for (let x = 0; password.length <= length; x++) {
            const i = Math.floor(Math.random() * chars.length);
            password += chars.charAt(i);
        }

        return password;
    }
}
