<kendo-dialog *ngIf="active" (close)="closeForm()">
    <kendo-dialog-titlebar>
        Reject
    </kendo-dialog-titlebar>
    <form #form="ngForm" class="mw600" novalidate [ngClass]="{'submitted': submitted}">
        <div class="form horizontal fill-alll labels-above">
            <div class="form horizontal">
                <label attr.for="pre">Preset</label>
                <kendo-combobox [data]="presets"
                                [(ngModel)]="preset"
                                name="pre"
                                placeholder="Select..."
                                (ngModelChange)="changePreset()"
                                clearButton="false">
                </kendo-combobox>
                <fibaInputTextarea label="Reject reason"
                                   name="reasonOfReject"
                                   class="player-license-reason"
                                   [required]="true"
                                   [(ngModel)]="_model.reasonOfReject"></fibaInputTextarea>
            </div>
        </div>      
    </form>
    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button class="k-button bg-gold" (click)="onSave($event)">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>
