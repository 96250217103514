import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { CompetitionInformationSummaryDTO, CompetitionListItemDashboardDTO, RosterOverviewGameGameDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class DashboardCompetitionDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    public fetchCompetitionListForDashboard(): Observable<CompetitionListItemDashboardDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/dashboard-list`);
    }

    public fetchClubCompetitionListForDashboard(): Observable<CompetitionListItemDashboardDTO[]> {
        const params = { getClubCompetition: true.toString() };
        return this.http.get(`${this.competitionApiEndpoint}/dashboard-list`, params);
    }

    public fetchCompetitionListForTdDashboard(): Observable<CompetitionInformationSummaryDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/td-portal`);
    }

    public fetchCompetitionListForTmoDashboard(): Observable<CompetitionInformationSummaryDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/tm-operator-portal`);
    }

    public fetchRostersForRostersOverviewGameGame(roundId: number): Observable<RosterOverviewGameGameDTO> {
        if (roundId) {
            return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/rosters`);
        } else {
            // TODO : throw error
            throw new Error(`Falsy roundId argument:${roundId}`);
        }
    }
}
