import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoutingUtils } from '../../../app/services/routing-utils.service';
import { AuthService } from '@fiba/data-services';

import { FibaPickerEventComponent } from './fiba-picker-event.component';

const routes: Routes = [
    {
        path: '',
        component: FibaPickerEventComponent,
    },
];

RoutingUtils.setGuardsOnRouteTree({ children: routes }, AuthService.getAuthGuardDiToken());

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FibaPickerEventRoutingModule {
}
