import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../../../app/shared.module';
import { FibaCommonModule } from '@fiba/common';
import { FibaCommonSelectModule } from '../../../../../common/fiba-common-select.module';
import { FibaFormsModule } from '@fiba/forms';
import { FibaPopupClubCreatorModule } from '../../fiba-popup-club-creator/fiba-popup-club-creator.module';

import { ClubSearchMiniComponent } from './club-search-mini.component';

const EXTERNAL_COMPONENTS: any[] = [
    ClubSearchMiniComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonModule,
        FibaCommonSelectModule,
        FibaPopupClubCreatorModule,
    ],
    declarations: [
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class ClubSearchMiniModule {
}
