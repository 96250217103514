import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, SanctionDTO, SanctionFilters, ThingDTO, ItemDTO } from '@fiba/models';

import { IFileData } from '@fiba/data-services';
import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';
const SANCTION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class SanctionDataService {
    protected sanctionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig, protected dataService: DataCachedService<SanctionDTO>) {
        this.sanctionApiEndpoint = config.sanctionApiEndpoint;
    }

    public fetchList(filters: SanctionFilters): Observable<ApiListResponse<SanctionDTO>> {
        return this.http.get(this.sanctionApiEndpoint, filters.getSearchParams());
    }
    public fetchAllSanctions(): Observable<SanctionDTO[]> {
        return this.dataService.fetchEntities(this.sanctionApiEndpoint+'/all');
    }
    public fetchAdministrativeOffenceSanctions(): Observable<SanctionDTO[]> {
        return this.dataService.fetchEntities(this.sanctionApiEndpoint +'/administrative-offence');
    }

    public fetchAutoCompleteList(name: string): Observable<SanctionDTO[]> {
        const params = { name, take: String(SANCTION_AUTOCOMPLETE_LIST_SIZE) };
        return this.http.get(this.sanctionApiEndpoint + '/autocomplete', params);
    }

    public fetchBanApplicabilityItems(sanctionApplicabilityItemCode: string): Observable<ItemDTO[]> {
        return this.http.get(this.sanctionApiEndpoint + '/ban-applicability/' + sanctionApplicabilityItemCode);
    }

    public fetchSanctionByCode(sanctionCode: string): Observable<SanctionDTO> {
        return this.http.get(this.sanctionApiEndpoint + '/sanction-code/' + sanctionCode);
    }

}
