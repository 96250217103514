import { Component, Input, Output, EventEmitter, Injector } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { FibaPopupBase } from '@fiba/forms';
import { DocumentDataService } from '@fiba/data-services';
import { ItemDataService } from '@fiba/data-services';
import { FibaLoadingService } from '@fiba/loading';

@Component({
    selector: 'fibaPopupCompetitionPicture',
    templateUrl: './fiba-popup-competition-picture.component.html',
})
export class FibaPopupCompetitionPictureComponent extends FibaPopupBase<string> {

    @Input() recommendedSize: string = null;
    @Input() entityReference: string = null;
    @Input() fileType: number = null;

    protected filename: string = null;
    fileUploaded = false;

    constructor(
        protected dataService: DocumentDataService,
        protected sanitizer: DomSanitizer,
        protected itemDataService: ItemDataService,
        protected injector: Injector,
        protected fibaLoadingService: FibaLoadingService) {
        super(injector);

        this._model = '';
    }

    public getAzureFileUrl(pictureUrl: string) {
        // get url with a cachebreaker
        this._model = pictureUrl + "?" + new Date().getTime();
    }
    protected onSave(e): void {
        // get url without cachebreaker
        this._model = this._model?.split('?')[0];
        super.onSave(e);
    }

    fileUploadedHandler(value: boolean) {
        this.fileUploaded = value;
    }
}
