<fibaLoading></fibaLoading>
<form novalidate autocomplete="off">
    <div class="vertical space10">
        <div class="horizontal space10">
            <div class="form vertical labels-left space10">
                <fibaSelectItem id="selectOfficialFlagItem" name="officialFlagItem" [categoryCode]="ItemCategoryCodes_CompetitionOfficialFlag" label="Official" [(ngModel)]="filters.officialFlagItem.value"></fibaSelectItem>
                <fibaSelectCompetitionSeason label="Season from" name="seasonFrom" [(ngModel)]="filters.seasonFrom" fibaGreaterValidator="seasonTo" lowestDate="true"></fibaSelectCompetitionSeason>
                <fibaSelectCompetitionSeason label="Season to" name="seasonTo" [(ngModel)]="filters.seasonTo" fibaGreaterValidator="seasonFrom"></fibaSelectCompetitionSeason>
            </div>
            <div class="form vertical labels-left space10" style="margin-left: 5px">
                <fibaSelectItem id="Type" name="Type" [categoryCode]="ItemCategoryCodes_CompetitionType" label="Type" [(ngModel)]="filters.typeItem.value"></fibaSelectItem>
                <fibaMultiSelectZone name="zone" label="Zone" [(ngModel)]="filters.zone.value"></fibaMultiSelectZone>
                <fibaSelectItem label="Gender" [categoryCode]="ItemCategoryCodes_CompetitionGender" name="gender" [(ngModel)]="filters.genderItem.value"></fibaSelectItem>
            </div>
            <div class="form vertical labels-left space10">
                <fibaInputText label="Keywords" name="keywords" [(ngModel)]="filters.keywords"></fibaInputText>
            </div>
        </div>
        <div class="button-bar">
            <button type="button" kendoButton
                    (click)="filter()"
                    [primary]="true">
                <i class="fa fa-lg fa-search"></i>
                Apply filters
            </button>
        </div>
        <span [hidden]="!isLoading">Loading...</span>
        <kendo-grid [hidden]="isLoading"
                    #grid
                    class="no-scrollbar navigable"
                    [data]="competitions"
                    [pageSize]="filters.take"
                    [skip]="filters.skip"
                    [sortable]="{ mode: 'single' }"
                    [sort]="sort"
                    [pageable]="true"
                    [selectable]="true"
                    (selectionChange)="handleSelChange($event)"
                    (pageChange)="handlePageChange($event)"
                    (sortChange)="handleSortChange($event)">
            <kendo-grid-column title="Season" field="season"></kendo-grid-column>
            <kendo-grid-column title="Code" field="code"></kendo-grid-column>
            <kendo-grid-column title="Name" field="officialName"></kendo-grid-column>
            <kendo-grid-column title="Type" field="type"></kendo-grid-column>
            <kendo-grid-column title="Status" field="competitionStatus"></kendo-grid-column>
            <kendo-grid-column title="Discipline" field="discipline"></kendo-grid-column>
            <kendo-grid-column title="Begin" field="startDate">
                <ng-template kendoGridCellTemplate let-competition>
                    {{competition.startDate | fibaDate}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="End" field="endDate">
                <ng-template kendoGridCellTemplate let-competition>
                    {{competition.endDate | fibaDate}}
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</form>