import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import {OrganisationFilters, OrganisationListItemDTO } from '@fiba/models';
import { OrganisationDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaSelectOrganisation',
    templateUrl: '../../forms/base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectOrganisationComponent),
            multi: true
        }
    ], 
    host: {'class': 'fiba-input'},
})
export class FibaSelectOrganisationComponent extends FibaAjaxSelectBaseComponent<OrganisationListItemDTO> {

    constructor(protected injector: Injector, protected dataService: OrganisationDataService) {
        super(injector);
        this._valueField = 'organisationId';
        this._textField = 'name';
    }

    getObservable(): Observable<OrganisationListItemDTO[]> {
        return this.dataService.fetchOrganisationsAgencies(new OrganisationFilters(0, 0));
    }
}
