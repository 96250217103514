import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompetitionOperationClassificationCompetitionDTO, CompetitionOperationClassificationGroupDTO, CompetitionOperationClassificationGroupUpdateDTO, CompetitionOperationClassificationRoundDTO, StructureClassificationCompetitionDTO } from '@fiba/models';
import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ClassificationService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    public fetchCompetitionStructure(competitionId: number): Observable<StructureClassificationCompetitionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/structure-classification`);
    }

    public fetchCompetitionClassification(competitionId: number): Observable<CompetitionOperationClassificationCompetitionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/classification`);
    }

    public fetchGroupPairingClassification(groupPairingId: number): Observable<CompetitionOperationClassificationGroupDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/grouppairing/${groupPairingId}/classification`);
    }

    public fetchRoundClassification(roundId: number): Observable<CompetitionOperationClassificationRoundDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/classification`);
    }

    public requestGroupClassificationRecalculation(groupPairingId: number): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/grouppairing/${groupPairingId}/classification/recompute`, null);
    }

    public updateGroupClassification(groupPairingId: number, data: CompetitionOperationClassificationGroupUpdateDTO): Observable<CompetitionOperationClassificationGroupDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/grouppairing/${groupPairingId}/classification`, data);
    }

}
