import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface FibaLoadingOption {
    show: boolean;
    threshold: number;
    loadingText: string;
}

const LOADING_TEXT:string = 'Loading...'

@Injectable()
export class FibaLoadingService {

    public loadingSubject: BehaviorSubject<FibaLoadingOption> = new BehaviorSubject<FibaLoadingOption>({ show: false, threshold: 800, loadingText: null });

    show(threshold: number = 0, loadingText: string = LOADING_TEXT) {
        this.loadingSubject.next({ show: true, threshold, loadingText });
    }

    hide() {
        this.loadingSubject.next({ show: false, threshold: 0, loadingText: null });
    }

    getMessage(): Observable<any> {
        return this.loadingSubject.asObservable();
    }
}
