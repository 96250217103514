import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FinalRosterConfigurationDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FinalRosterConfigurationDataService {
    protected competitionApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchFinalRosterConfigurationsForCompetition(competitionId: number): Observable<FinalRosterConfigurationDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/final-roster-configuration`);
    }
}
