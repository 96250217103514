<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <input type="text"
           id="{{name}}"
           [disabled]="disabled"
           [(ngModel)]="value"
           [pattern]="pattern"
           (change)="manualChange()"
           (blur)="onTouched($event)"
           [maxlength]="maxLength" />
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
