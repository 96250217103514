import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { ItemDataService, SanctionDataService } from '@fiba/data-services';
import { MapPolicy, SanctionDTO, SanctionFilters } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';

const SANCTION_PAGE_SIZE = 10;
const STORAGE_KEY = 'SanctionSearchComponent';

@Component({
    selector: 'sanction-search',
    templateUrl: './sanction-search.component.html',
})

export class SanctionSearchComponent implements OnInit {

    public readonly MapPolicy_SanctionsEdit = MapPolicy.SanctionsEdit;

    public filters: SanctionFilters;
    public sort: SortDescriptor[] = [];
    public isLoading: boolean;
    public zone;
    sanctions: GridDataResult;
    protected fetchSub: Subscription;
    protected filterStorageKey: string;

    @Input() public value: SanctionDTO;
    @Output() public valueChange: EventEmitter<SanctionDTO> = new EventEmitter<SanctionDTO>();

    constructor(
        protected dataService: SanctionDataService,
        protected itemDataService: ItemDataService,
        protected router: Router,
        protected notificationService: NotificationService,
        protected simpleStorageService: SimpleStorageService) {

    }

    public ngOnInit(): void {

        this.filterStorageKey = STORAGE_KEY;

        const savedFilters = this.simpleStorageService.get(this.filterStorageKey);
        if (savedFilters) {
            this.filters = savedFilters.clone();
        } else {
            this.filters = new SanctionFilters(SANCTION_PAGE_SIZE);
        }

        this.fetchList();
    }

    protected fetchList(displayLoading: boolean = true): void {
        // TODO : * Review this (and all other) unsubscribe() usage. We might not need the subscription, which would make code much simpler everywhere. Also need some factoring, as this code is copied all around. Some solution involving switchMap should be considered. YV
        this.isLoading = displayLoading;
        if (this.fetchSub) {
            this.fetchSub.unsubscribe();
        }
        this.fetchSub = this.dataService.fetchList(this.filters).subscribe(
            (val) => {
                this.sanctions = val;
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
                Logger.error(error);
            },
            () => {
                this.fetchSub = null;
                this.isLoading = false;
            },
        );
    }

    public filter(): void {
        this.filters.skip = 0;
        this.fetchList(true);
        this.simpleStorageService.set(this.filterStorageKey, this.filters.clone());
    }

    /**
     * Resets the filters
     */
    public reset(): void {
        this.filters.reset();
        this.simpleStorageService.delete(this.filterStorageKey);
    }

    // Grid sanctions
    public handlePageChange(sanction: PageChangeEvent): void {
        this.filters.skip = sanction.skip;
        this.fetchList(false);
    }

    public handleSelChange(event: SelectionEvent): void {
        const firstSelectedRowDataItem = event?.selectedRows?.[0]?.dataItem;

        this.valueChange.emit(firstSelectedRowDataItem);
    }

}
