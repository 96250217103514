<kendo-dialog *ngIf="active" (close)="closeForm()" novalidate>
    <kendo-dialog-titlebar>
        Select a club
    </kendo-dialog-titlebar>

    <club-search-mini [contextCode]="contextCode"
                      [(value)]="_value"
                      [organisation]="_organisation"
                      [zone]="_zone"
                      [licenseCategoryCode]="_licenseCategoryCode"
                      [season]="_season"
                      [genderCode]="genderCode"
                      [isGenderCodeReadOnly]="isGenderCodeReadOnly"
                      [allowCreateTemporaryClub]="allowCreateTemporaryClub"
                      [showClubsForAllNf]="showClubsForAllNf"
                      [mergeMode]="mergeMode"
                      [teamDelegationMode]="teamDelegationMode">
    </club-search-mini>

    <kendo-dialog-actions>
        <button type="button" class="k-button" (click)="onCancel($event)">Cancel</button>
        <button name="okButton" class="k-button bg-gold" (click)="onSave($event)" [disabled]="!_value">OK</button>
    </kendo-dialog-actions>
</kendo-dialog>