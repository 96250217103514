import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class SanctionFilters extends BaseFilters {
    code: string;
    articleNumber: number;
    keyword: string;
    regionId: number;

    reset(): void {
        delete this.code;
        delete this.articleNumber;
        delete this.keyword;
        delete this.regionId;
    }
}
