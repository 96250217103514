
export interface IAuditable {
    lastModificationDateTime?: Date;
    lastModificationUserName?: string;
}

export class AuditInfos implements IAuditable {
    public lastModificationDateTime: Date;
    public lastModificationUserName: string;
}
