<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <kendo-dateinput [(ngModel)]="value"
                     [disabled]="disabled"
                     [format]="'MMM-yyyy'"
                     (valueChange)="handleValue($event)"
    ></kendo-dateinput>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>

