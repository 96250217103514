import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, CompetitionDTO, ThingDTO, CompetitionListItemDTO, CompetitionFilters, CompetitionInformationDTO, MinMaxSeasonDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionPickerDataService {
    protected competitionApiEndpoint: string;
    protected competitionStatusApiEndpoint: string;
    protected competitionAutocompleteApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.competitionStatusApiEndpoint = config.competitionStatusApiEndpoint;
        this.competitionAutocompleteApiEndpoint = config.competitionAutocompleteApiEndpoint;
    }

    fetchList(filters: CompetitionFilters): Observable<ApiListResponse<CompetitionListItemDTO>> {
        return this.http.get(this.competitionApiEndpoint, filters.getSearchParams());
    }

    fetchCompetition(competitionId: number, context: string = null): Observable<CompetitionDTO> {
        let params;
        if (context) {
            params = {
                context: context
            };
        }
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}`, params);
    }

    fetchCompetitionMinMaxSeason(): Observable<MinMaxSeasonDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/min-max-season`);
    }

    fetchAutoCompleteList(keywords: string): Observable<ThingDTO[]> {
        const params = { keywords, take: String(COMPETITION_AUTOCOMPLETE_LIST_SIZE) };
        return this.http.get(this.competitionAutocompleteApiEndpoint, params);
    }

    downloadExport(filters: CompetitionFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.competitionApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }
}
