import { Injectable, EventEmitter } from '@angular/core';

import { Guid } from '@fiba/utils/guid';

import { NotifierServiceBase } from './notifier-base.service';

export enum NotificationType {
    Error,
    Warning,
    Success,
    Info,
    FileDownloaded
}

export class Notification {
    guid: string;
    type: string;
    message: string;
    time: Date;
    fileName: string;
    url: string;
    ttl: number;

    constructor(type: NotificationType, message: string, filename?: string, url?: string, ttl: number = 15) {
        this.guid = Guid.generate();
        this.type = NotificationType[type];
        this.message = message;
        this.time = new Date();
        this.fileName = filename;
        this.url = url;
        this.ttl = ttl;
    }
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends NotifierServiceBase<Notification> {

    constructor() {
        super();
    }

    emitNotification(type: NotificationType, message: string, ttl: number = 15): void {
        const notif = new Notification(type, message, null, null, ttl);
        this.emit(notif);
    }

    emitFileNotification(filename: string, url: string, ttl: number = 60): void {
        const message = 'File ready to download : ';
        const notif = new Notification(NotificationType.FileDownloaded, message, filename, url, ttl);
        this.emit(notif);
    }
}
