import { Injector, Component, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import { FibaSelectItemComponent } from './fiba-select-item.component';

@Component({
    selector: 'fibaSelectItemFiltered',
templateUrl: '../../forms/base/fiba-select-base.component.html',    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectItemFilteredComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectItemFilteredComponent extends FibaSelectItemComponent {
    @Input() protected forbiddenIds: number[];
    @Input() protected allowedIds: number[];

    getBindData(): (data: ItemDTO[]) => void {
        return (data: ItemDTO[]) => {
            if (this.allowedIds && this.allowedIds.length > 0) {
                data = data.filter((item: ItemDTO) => this.arrayContains(this.allowedIds, item.itemId));
            } else if (this.forbiddenIds && this.forbiddenIds.length > 0) {
                data = data.filter((item: ItemDTO) => !this.arrayContains(this.forbiddenIds, item.itemId));
            }
            this.getBindDataCallbackBase(data);
        };
    }

    private arrayContains(array: number[], element: number): boolean {
        return array?.indexOf(element) > -1;
    }
}
