import { Injector, Component, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { TemplateDTO } from '@fiba/models';
import { TemplateAdminDataService } from '../../data-services/administration/template-admin-data.service';

@Component({
    selector: 'fibaSelectTemplate',
    templateUrl: '../../forms/base/fiba-select-base.component.html', providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectTemplateComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectTemplateComponent extends FibaAjaxSelectBaseComponent<TemplateDTO> implements OnInit {

    @Input() onlyParents: boolean = true;

    constructor(protected injector: Injector, protected dataService: TemplateAdminDataService) {
        super(injector);
        this._valueField = 'templateId';
        this._textField = 'code';
    }

    getObservable(): Observable<TemplateDTO[]> {
        return this.dataService.fetchTemplatesForSelector(this.onlyParents);
    }
}
