import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { RoleFamilyDTO, RoleTypeDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RoleTypeDataService {
    protected apiEndpoint: string;

    constructor(protected dataService: DataCachedService<RoleTypeDTO>, protected config: AppConfig) {
        this.apiEndpoint = config.roleTypeApiEndpoint;
    }

    fetchRoleTypes(): Observable<RoleTypeDTO[]> {
        return this.dataService.fetchEntities(this.apiEndpoint, 'roleTypes');
    }

}
