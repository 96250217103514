import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OfficialLicenseRequestDTO, OfficialLicenseRequestGridItemDTO, ThinModel, RoleListItemDTO, StatusDTO, OfficialLicenseRequestActions, OfficialLicensePeriodRoleDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import { OfficialLicensePeriodSpotConfigDTO } from '@fiba/models/dtos/generated/official-license-period-spot-config-dto';
import { AppConfig } from '@app/app.config';
import { OfficialLicenseRequestTypes } from '../../models/constants/official-license-request-types';

@Injectable({
    providedIn: 'root',
})
export class OfficialLicenseRequestDataService {
    protected officialLicenseApiEndpoint: string;
    protected officialLicenseRegistrationApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataOfficialLicenseRequestNFStatusService: DataCachedService<StatusDTO>) {
        this.officialLicenseApiEndpoint = config.officialLicenseApiEndpoint;
        this.officialLicenseRegistrationApiEndpoint = config.officialLicenseRegistrationApiEndpoint;
    }

    public fetchOfficialLicenseRequest(officialLicenseRequestId: number): Observable<OfficialLicenseRequestDTO> {
        return this.http.get(`${this.officialLicenseRegistrationApiEndpoint}/${officialLicenseRequestId}`);
    }

    public createOfficialLicenseRequestAndAction(action: string, model: OfficialLicenseRequestDTO): Observable<OfficialLicenseRequestDTO> {
        return this.http.post(`${this.officialLicenseRegistrationApiEndpoint}/${action}`, model);
    }

    public createOfficialLicenseRequest(model: OfficialLicenseRequestDTO): Observable<OfficialLicenseRequestDTO> {
        return this.http.post(`${this.officialLicenseRegistrationApiEndpoint}`, model);
    }

    public updateOfficialLicenseRequestAndAction(action: string, model: OfficialLicenseRequestDTO): Observable<OfficialLicenseRequestDTO> {

        let routeDetail: string;

        switch (action) {
            case OfficialLicenseRequestActions.Save:
            case OfficialLicenseRequestActions.Completed:
                routeDetail = "edit";
                break;
            case OfficialLicenseRequestActions.RecommendedByRO:
            case OfficialLicenseRequestActions.NotRecommendedByRO:
            case OfficialLicenseRequestActions.PendingInformationNF:
                routeDetail = "edit-ro";
                break;
            case OfficialLicenseRequestActions.SupportedByHQ:
            case OfficialLicenseRequestActions.NotSupportedByHQ:
            case OfficialLicenseRequestActions.PendingInformationRO:
                routeDetail = "edit-hq";
                break;
            case OfficialLicenseRequestActions.ApprovedByFIBA:
            case OfficialLicenseRequestActions.RejectedByFIBA:
            case OfficialLicenseRequestActions.PendingInformationHQ:
                routeDetail = "edit-fiba";
                break;
        }

        return this.http.put(`${this.officialLicenseRegistrationApiEndpoint}/${model.officialLicenseRequestId}/${routeDetail}/${action}`, model);
    }

    public updateOfficialLicenseRequest(model: OfficialLicenseRequestDTO): Observable<OfficialLicenseRequestDTO> {
        return this.http.put(`${this.officialLicenseRegistrationApiEndpoint}/${model.officialLicenseRequestId}`, model);
    }

    public fetchOfficialLicenseRequestStatuses(): Observable<StatusDTO[]> {
        return this.dataOfficialLicenseRequestNFStatusService.fetchEntities(`${this.officialLicenseRegistrationApiEndpoint}/statuses?officialLicenseRequestStatus=${OfficialLicenseRequestTypes.NationalFederation}`);
    }

    public fetchOfficialLicenseRequestStatusesByType(officialLicenseRequestType: OfficialLicenseRequestTypes, isCandidateList: boolean = false, isGameOfficialList: boolean = false, roleCode: string = null): Observable<StatusDTO[]> {
        return this.http.get(`${this.officialLicenseRegistrationApiEndpoint}/statuses?officialLicenseRequestType=${officialLicenseRequestType}&isCandidateList=${isCandidateList}&isGameOfficialList=${isGameOfficialList}&roleCode=${roleCode}`);
    }

    public fetchOfficialLicenseRequestForPerson(personId: number): Observable<OfficialLicenseRequestGridItemDTO[]> {
        return this.http.get(`${this.officialLicenseRegistrationApiEndpoint}/person/${personId}`);
    }

    public getRoleForOfficialLicensePeriodSpot(paramOfficialLicensePeriodSpotId: number): Observable<OfficialLicensePeriodSpotConfigDTO> {
        return this.http.get(`${this.officialLicenseApiEndpoint}/OfficialLicensePeriodSpot/${paramOfficialLicensePeriodSpotId}/config`);
    }
    public getRoleForOfficialLicensePeriod(paramOfficialLicensePeriodId: number): Observable<OfficialLicensePeriodRoleDTO[] > {
        return this.http.get(`${this.officialLicenseApiEndpoint}/OfficialLicensePeriodSpotConfigurationRoles/${paramOfficialLicensePeriodId}`);
    }

    public getRoleForOfficialLicensePeriodSpotConfiguration(paramOfficialLicensePeriodSpotId: number): Observable<OfficialLicensePeriodSpotConfigDTO> {
        return this.http.get(`${this.officialLicenseApiEndpoint}/OfficialLicensePeriodSpotConfiguration/${paramOfficialLicensePeriodSpotId}/info`);
    }

    public fetchOfficialLicenseRequestForPersonInPeriod(officialLicensePeriodId: number, personId: number): Observable<OfficialLicenseRequestDTO[]> {
        return this.http.get(`${this.officialLicenseApiEndpoint}/periods/${officialLicensePeriodId}/registrations/person/${personId}`);
    }
}
