import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

import { EventListItemDTO } from '@fiba/models';

export class AgentPreRegisterationFilters extends BaseFilters {

    preRegistrationDate: Date;

    zone: FilterableArray = new FilterableArray('zoneId');

    reset(): void {
        delete this.preRegistrationDate;
        this.zone.reset();
    }
}

export class AgentRegistrationFormFilters extends BaseFilters {
    isTestExempted: boolean;
    event: Filterable = new Filterable('eventId', 'eventId');
    agentRegistrationFormStatus: FilterableArray = new FilterableArray('agentRegistrationFormStatusId');

    reset(): void {
        delete this.isTestExempted;
        this.event.reset();
        this.agentRegistrationFormStatus.reset();
    }
}


export class AgentPreRegistrationFilters extends BaseFilters {
    context: string;
    zone: Filterable = new Filterable('zoneId', 'zoneId');
    reset(): void {
        this.zone.reset();
    }
}
