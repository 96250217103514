import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, CompetitionDTO, CompetitionFilters, CompetitionListItemDTO, CompetitionStatusDTO, ThingDTO, CompetitionInformationDTO, CompetitionInformationSummaryDTO, ActionMessageDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { DateUtils } from '@fiba/utils/date-utils';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionInformationDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    // COMPETITION

    public fetchCompetitionInformation(competitionId: number): Observable<CompetitionInformationDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/information`).pipe(map(
            response => <CompetitionInformationDTO>DateUtils.convertDateStringsToDates(response)));
    }

    public createCompetitionInformation(model: CompetitionInformationDTO): Observable<CompetitionInformationDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/information`, model);
    }

    public updateCompetitionInformation(model: CompetitionInformationDTO): Observable<ActionMessageDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/information`, model);
    }

    public deleteCompetition(id: number): Observable<any> {
        return this.http.delete(`${this.competitionApiEndpoint}/${id}/information`);
    }

    public getCompetitionCode(genderItemId: number,
                              categoryItemId: number,
                              typeItemId: number,
                              disciplineItemId: number,
                              officialFlagItemId: number,
                              ageCategoryItemId: number,
                              zoneId: number): Observable<string> {
        const params = {
            genderItemId: String(genderItemId),
            categoryItemId: String(categoryItemId),
            typeItemId: String(typeItemId),
            disciplineItemId: String(disciplineItemId),
            officialFlagItemId: String(officialFlagItemId),
            ageCategoryItemId: String(ageCategoryItemId),
            zoneId: String(zoneId)
        };

        return this.http.get(this.competitionApiEndpoint.concat('/information/code'), params);
    }
}
