import { Injector, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaSelectSeasonComponent } from './fiba-select-season.component';
import { CompetitionPickerDataService } from '@fiba/data-services';
import { MinMaxSeasonDTO } from '@fiba/models';


@Component({
    selector: 'fibaSelectCompetitionSeason',
    templateUrl: './fiba-select-season.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectCompetitionSeasonComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectCompetitionSeasonComponent extends FibaSelectSeasonComponent implements OnInit {

    protected dataService: CompetitionPickerDataService;
    constructor(protected injector: Injector) {
        super(injector);
        this.dataService = injector.get(CompetitionPickerDataService);
    }

    ngOnInit() {
        this.dataService.fetchCompetitionMinMaxSeason().subscribe(
            (result: MinMaxSeasonDTO) => {
                this.minSeason = result.minSeason;
                this.maxSeason = result.maxSeason;
                super.ngOnInit();
                this._originalItems = this.processListOfSeasons();
                this.handleRestrictedItems();
            }
        );
    }
}
