import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class OrganisationFilters extends BaseFilters {
    context: string;
    name: string;
    category: Filterable = new Filterable('organisationCategoryId', 'itemId');
    organisationCategories: FilterableArray = new FilterableArray('organisationCategoryCodes','organisationCategory');
    country: Filterable = new Filterable('countryId');
    relatedCountries = false;
    zone: FilterableArray = new FilterableArray('zoneId');
    status: Filterable = new Filterable('statusId', 'organisationStatusId');
    addressLine: string;
    addressZipCode: string;
    addressCity: string;
    addressCountry: Filterable = new Filterable('addressCountryId', 'countryId');
    addressRelatedCountries = false;
    isZoneOrganisationCommittee: boolean;
    contact: Filterable = new Filterable('contactId', 'personId');
    //contactName: string;
    member: Filterable =  new Filterable('memberId', 'personId');
    // memberName: string;
    relatedOrganisation: Filterable = new Filterable('relatedOrganisationId', 'organisationId');
    dummyA: Filterable = new Filterable('dummyId', 'personId');
    excludedOrganisationId: number;
    clubGenderCode: string;
    approvalCategoryId: number;
    approvalCategoriesIds: FilterableArray = new FilterableArray('approvalCategoriesIds', 'equipmentApprovalCategoryId');
    reset(): void {
        this.zone.reset();
        this.approvalCategoriesIds.reset();
    }
}

export class OrganisationCompetitionFilters extends BaseFilters {
    competitionCode: string;
    officialName: string;
    season: number;
    roundRankNumber: number;
    roundName: string;
    startDate: Date;
    endDate: Date;

    reset(): void {
        delete this.competitionCode;
        delete this.officialName;
        delete this.season;
        delete this.roundRankNumber;
        delete this.roundName;
        delete this.startDate;
        delete this.endDate;
    }
}
