import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TermsAndConditionsDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class TermsAndConditionsDataService {

    protected termsAndConditionsApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.termsAndConditionsApiEndpoint = config.termsAndConditionsApiEndpoint;
    }

    fetchCurrentTermsAndConditions(): Observable<TermsAndConditionsDTO> {
        return this.http.get(this.termsAndConditionsApiEndpoint);
    }

    acceptTermsAndConditions(termsAndConditionsId: number): Observable<any> {
        return this.http.put(`${this.termsAndConditionsApiEndpoint}/${termsAndConditionsId}`, null);
    }
}
