import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    AddresseeCategoryDTO,
    ApiListResponse,
    FinancialDataPackageListItemDTO,
    FinancialDataPackageListItemSanctionDTO,
    FinancialDataPackageStatusDTO, GenerateFinancialDataPackagesDTO,
    NationalFederationToInvoiceDTO, ThingDTO, FinancialDataPackageDTO,
} from '@fiba/models';

import { IFileData } from '@fiba/data-services';
import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';

import { FinancialReportingFilters, FinancialReportingNationalFederationFilters } from '@fiba/models/financial-reporting/financial-reporting';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class FinancialReportingDataService {
    protected financialReportingApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig, protected dataFinancialPackageStatusService: DataCachedService<FinancialDataPackageStatusDTO>,) {
        this.financialReportingApiEndpoint = config.financialReportingApiEndpoint;
    }

    public fetchList(filters: FinancialReportingFilters): Observable<ApiListResponse<FinancialDataPackageListItemDTO>> {
        return this.http.get(this.financialReportingApiEndpoint, filters.getSearchParams());
    }

    public downloadExport(filters: FinancialReportingFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.financialReportingApiEndpoint}/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }

    public downloadNationalFederationToInvoiceExport(filters: FinancialReportingNationalFederationFilters, financialDataPackageItemCode: string): Observable<IFileData> {
        return this.http.getFileData(`${this.financialReportingApiEndpoint}/national-federations/to-invoice/export-csv`, filters.getSearchParams()).pipe(timeout(300000));
    }

    public fetchNationalFederationToInvoice(filters: FinancialReportingNationalFederationFilters, financialDataPackageItemCode: string): Observable<ApiListResponse<NationalFederationToInvoiceDTO>> {
        return this.http.get(`${this.financialReportingApiEndpoint}/national-federations/to-invoice`, filters.getSearchParams());
    }

    public createFinancialDataPackages(financialDataPackageItemCode: string, generateFinancialDataPackages: GenerateFinancialDataPackagesDTO): Observable<FinancialDataPackageDTO[]> {

        return this.http.post(`${this.financialReportingApiEndpoint}/national-federations/invoice`, generateFinancialDataPackages);

    }

    public createFinancialDataPackagesSanctions(financialDataPackageItemCode: string, generateFinancialDataPackages: GenerateFinancialDataPackagesDTO): Observable<FinancialDataPackageListItemSanctionDTO[]> {

        return this.http.post(`${this.financialReportingApiEndpoint}/national-federations/sanctions/invoice`, generateFinancialDataPackages);
    }

    public deleteFinancialDataPackage(financialDataPackageId: number) {
        return this.http.delete(`${this.financialReportingApiEndpoint}/${financialDataPackageId}`);
    }

    public downloadFinancialDataPackages(financialDataPackageIds: number[]): Observable<IFileData> {
        const params: { [key: string]: string | string[] } = {};
        for (const financialDataPackageId in financialDataPackageIds) {
            params.financialDataPackageIds = String(financialDataPackageIds);
        }
        return this.http.getFileData(`${this.financialReportingApiEndpoint}/download`, params).pipe(timeout(300000)); // TODO: use constant or conf value
    }

    public fetchFinancialDataPackageIssuers(financialDataPackageItemCode: string): Observable<ThingDTO[]> {
        const params: { [key: string]: string | string[] } = {};
        params.financialDataPackageCode = financialDataPackageItemCode;

        return this.http.get(`${this.financialReportingApiEndpoint}/issuers`, params);
    }

    public fetchFinancialDataPackageAddresseeCategories(financialDataPackageItemCode: string): Observable<AddresseeCategoryDTO[]> {
        const params: { [key: string]: string | string[] } = {};
        params.financialDataPackageCode = String(financialDataPackageItemCode);

        return this.http.get(`${this.financialReportingApiEndpoint}/configurations`, params);
    }

    public fetchStatuses(): Observable<FinancialDataPackageStatusDTO[]> {
        return this.dataFinancialPackageStatusService.fetchEntities(`${this.financialReportingApiEndpoint}/statuses`);
    }
}
