import { Injector, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { FibaSelectBaseComponent } from '@fiba/forms';
import { Season } from '@fiba/models/common/season';


@Component({
    selector: 'fibaSelectSeason',
    templateUrl: './fiba-select-season.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectSeasonComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectSeasonComponent extends FibaSelectBaseComponent<number> implements OnInit {

    protected readonly MIN_SEASON: number = 1930;
    protected readonly MAX_SEASON: number = new Date().getFullYear() + 20;
    constructor(protected injector: Injector) {
        super(injector);
        this.valuePrimitive = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this._originalItems = this.processListOfSeasons();
        this.handleRestrictedItems();
    }

    protected _minSeason: number;
    @Input() protected set minSeason(value: number) {
        if (value) {
            if (value >= this.MAX_SEASON) {
                this.value = this.MAX_SEASON;
            }
            this._minSeason = this.checkValueOrSetDefault(+value, this.MIN_SEASON);
            this._filteredItems = this.processListOfSeasons();
            this._originalItems = this.processListOfSeasons();
        }
    }
    protected get minSeason(): number {
        return this._minSeason || this.MIN_SEASON;
    }

    protected _maxSeason: number;
    @Input() protected set maxSeason(value: number) {
        if (value) {
            if (value <= this.MIN_SEASON) {
                value = this.MIN_SEASON;
            }
            this._maxSeason = this.checkValueOrSetDefault(+value, this.MAX_SEASON);
            this._filteredItems = this.processListOfSeasons();
            this._originalItems = this.processListOfSeasons();
        }
    }
    protected get maxSeason(): number {
        return this._maxSeason || this.MAX_SEASON;
    }

    getObservable(): Observable<number[]> {
        return of(this.processListOfSeasons());
    }

    protected processListOfSeasons(): any[] {
        const listOfSeasons = Array<string>();
        if (this.minSeason <= this.maxSeason) {
            for (let season = this.maxSeason; season >= this.minSeason; season--) {
                listOfSeasons.push(String(season));
            }
        }
        return listOfSeasons;
    }

    protected checkValueOrSetDefault(value: number, defaultValue: number) {
        if (typeof value === 'number') {
            return value;
        } else {
            return null;
        }
    }
}
