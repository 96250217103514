import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export type callSuper = never;
export const callSuper: callSuper = undefined as callSuper;

/**
 * Any angular component extending this class will get an $onDestroy observable emitting on the component on destroy.
 */
@Directive()
export class BaseComponent implements OnDestroy {
    protected $onDestroy: Subject<void> = new Subject();

    /**
     * If you override the onDestroyClass, you must call super.ngOnDestroy() or you will get an error.
     * see https://github.com/microsoft/TypeScript/issues/21388#issuecomment-785184392
     */
    public ngOnDestroy(): callSuper {
        this.$onDestroy.next();
        this.$onDestroy.complete();
        return callSuper;
    }

    public takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.$onDestroy);
    }
}

