import { Pipe, PipeTransform } from '@angular/core';
import { CountryDTO } from '@fiba/models';

@Pipe({ name: 'countryFormat' })
export class CountryFormatPipe implements PipeTransform {
    transform(country: CountryDTO): string {
        if (country) {
            let countryFormatted = country.shortName;
            if (country.fibacode) {
                countryFormatted += ' (' + country.fibacode +')';
            }
            return countryFormatted;
        }
        return '';
    }
}
