export class PlayerLicenseActions {
    static readonly PLAYER_LICENSE_ACTION_SAVE = 'save';
    static readonly PLAYER_LICENSE_ACTION_SUBMIT = 'submitted';
    static readonly PLAYER_LICENSE_ACTION_APPROVE = 'approved';
    static readonly PLAYER_LICENSE_ACTION_INCOMPLETE = 'incomplete';
    static readonly PLAYER_LICENSE_ACTION_REJECT = 'rejected';
    static readonly PLAYER_LICENSE_ACTION_PENDING = 'pending';
    static readonly PLAYER_LICENSE_ACTION_DELETE = 'deleted';
    static readonly PLAYER_LICENSE_ACTION_SET_VALIDITY = 'set-validity';
}
