import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../utils/http.service';
import { ApiListResponse, AgentPreRegistrationFilters, AgentPreRegistrationFormListItemDTO } from '@fiba/models';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class AgentPreRegistrationDataService {
    private readonly agentApiEndpoint: string;

    constructor(
        private http: HttpService,
        private config: AppConfig ) {
        this.agentApiEndpoint = config.agentApiEndpoint;
    }

    fetchList(filters: AgentPreRegistrationFilters, context: string = null): Observable<ApiListResponse<AgentPreRegistrationFormListItemDTO>> {
        return this.http.get(`${this.agentApiEndpoint}/pre-registrations-filtered`, filters.getSearchParams());
    }
    /**
     * Get pre registered candidates list
     */
    public fetchPreRegisteredCandidates(): Observable<ApiListResponse<AgentPreRegistrationFormListItemDTO>> {
        return this.http.get(`${this.agentApiEndpoint}/pre-registrations`);
    }

    createAgentPreRegistration(model: AgentPreRegistrationFormListItemDTO): Observable<AgentPreRegistrationFormListItemDTO> {
        return this.http.post(`${this.agentApiEndpoint}/pre-registrations`, model);
    }
    update(model: AgentPreRegistrationFormListItemDTO): Observable<AgentPreRegistrationFormListItemDTO> {
        return this.http.put(`${this.agentApiEndpoint}/pre-registrations/${model.formId}`, model);
    }
    delete(id: number): Observable<any> {
        return this.http.delete(`${this.agentApiEndpoint}/pre-registrations/${id}`);
    }
}
