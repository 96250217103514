import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[fibaUrlValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaUrlValidator), multi: true }
    ]
})
export class FibaUrlValidator implements Validator {

    // Currently matches anything (except new lines), no pattern checked.
    protected pattern = /^.*$/;

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        const v = c.value;

        if (!this.pattern.test(v) && v !== undefined && v !== '') {
            c.setErrors({ fibaUrlValidator: true });
            return {
                fibaUrlFibaValidator: true
            };
        }

        else {
            if (c.errors) {
                delete c.errors['fibaUrlValidator'];
                if (!Object.keys(c.errors).length) {
                    c.setErrors(null);
                }
            }
        }

        return null;
    }
}
