import { Component, Injector, Input } from '@angular/core';
import { FibaPopupBase } from '@fiba/forms';
import { OrganisationDTO, ThingDTO, ZoneDTO } from '@fiba/models';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'fibaPopupClub',
    templateUrl: './fiba-popup-club.component.html',
})
export class FibaPopupClubComponent extends FibaPopupBase<ThingDTO> {
    _value: ThingDTO = null;
    _organisation: OrganisationDTO;
    public _zone: ZoneDTO;

    @Input() public mergeMode: boolean = false;

    @Input()
    protected set organisation(value: OrganisationDTO) {
        this._organisation = value;
    }

    @Input()
    public set zone(value: ZoneDTO) {
        this._zone = value;
    }
    _licenseCategoryCode: string;
    @Input()
    protected set licenseCategoryCode(value: string) {
        this._licenseCategoryCode = value;
    }
    _season: number;
    @Input()
    protected set season(value: number) {
        this._season = value;
    }
    @Input() public allowCreateTemporaryClub = false;
    @Input() public showClubsForAllNf = false;
    @Input() public genderCode: string = null;
    @Input() public isGenderCodeReadOnly = false;
    @Input() public teamDelegationMode = false;

    constructor(protected injector: Injector, protected dialog: DialogRef) {
        super(injector);
    }

    protected onSave(e): void {
        this._model = this._value;
        this.emptyInternalPopupValue();
        super.onSave(e);
        this.dialog.close();
    }

    onCancel(e): void {
        this.emptyInternalPopupValue();
        super.onCancel(e);
        this.dialog.close();
    }

    closeForm(): void {
        this.emptyInternalPopupValue();
        super.closeForm();
        this.dialog.close();
    }

    /**
     *  Empty the popup value, otherwise one can open the popup again and just click ok to select the last value
     */
    protected emptyInternalPopupValue(): void {
        this._value = undefined;
    }
}
