import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

import { FibaTooltipBase } from '@fiba/forms';
import { ItemDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaTooltipItem',
    templateUrl: './fiba-tooltip-item.component.html',
    styleUrls: ['./fiba-tooltip-item.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FibaTooltipItemComponent extends FibaTooltipBase<string> implements OnInit {
    @Input() itemCategoryCode: string = null;
    @Input() itemCode: string = null;
    @Input() displayInnerHtml: boolean = null;
    @Input() position: string = 'bottom';

    public isItemActive: boolean;
    constructor(protected itemDataService: ItemDataService) {
        super();
    }
    public ngOnInit() {
        this.itemDataService.fetchItemByCategoryAndCode(this.itemCategoryCode, this.itemCode).subscribe(
            result => {
                this.model = result.description;
                this.isItemActive = result.isActive;
            },
            (error) => { },
            () => { }
        );
    }
}
