import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'fibaUtcDateTime' })
export class FibaUtcDateTimePipe implements PipeTransform {
    transform(value: any, format: string = 'D-MMM-YYYY HH:mm:ss'): string {
        let momentValue = moment(value);

        if (!momentValue.isValid()) {
            return value; // display "as is"
        }

        if ([1900, 9999].indexOf(momentValue.year()) > -1 || !value) {
            return '';
        }

        if (typeof value !== 'string') { // a string value comes directly from the API and is considered UTC
            momentValue = momentValue.tz('UTC');
        }
        return momentValue.format(format);
    }
}
