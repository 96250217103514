import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DataResult, GroupDescriptor, process } from '@progress/kendo-data-query';

import { DocumentDataService } from '@fiba/data-services';
import { FibaEFGridBaseDirective } from '@fiba/forms';
import { DocumentGenericDTO, DocumentGenericTypes, RoleFamilies } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { RemoveEvent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'fibaGridDocuments',
    templateUrl: './fiba-grid-documents.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaGridDocumentsComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaGridDocumentsComponent extends FibaEFGridBaseDirective<DocumentGenericDTO> {
    readonly DocumentGenericTypes_NATIONALITY = DocumentGenericTypes.NATIONALITY;
    private _deletable = true;
    public readonly RoleFamilies_Player = RoleFamilies.Player;

    @Input() public entityId: number;
    @Input() public categoryCode: string;
    @Input() public parentCode: string;
    @Input() public documentType: string;
    @Input() public entityType: string;
    @Input() public oneDocument = false;
    @Input() public entityTypeBase: string = null;
    @Input() public entityIdBase: number = null;
    @Input() public grouped = true;
    @Input() public editable = true;
    @Input() public showPrintButton = false;
    @Input() public roleFamily: string;
    @Input()
    public get deletable(): boolean {
        return this.editable || this._deletable;
    }
    public set deletable(value: boolean) {
        this._deletable = value;
    }
    @Input() public showComment = false;
    @Input() public optionAllowDownload = true;
    @Input() public optionOpenLink = false;
    @Input() public disableBasketballProcessDocuments = false;

    public saveMethod = 'POST';
    public documentName: string = null;
    public originalDocumentId = 0;

    constructor(
        protected notificationService: NotificationService,
        protected documentDataService: DocumentDataService) {
        super();
    }

    groups: GroupDescriptor[] = [{ field: 'documentType' }];
    documentGrouped: DataResult;
    private originalDocumentDMSId: string = null;

    // Grid document editing
    public addHandler($event): void {
        super.addHandler($event);
        this.saveMethod = 'POST';
        this.documentName = null;
        this.originalDocumentId = 0;
        this.originalDocumentDMSId = null;
    }

    public editHandler($event): void {
        const { dataItem, rowIndex } = $event;
        super.editHandler($event);
        this.saveMethod = 'POST';
        this.editRowId = this.editDataItem['sourceRowIndex'] ?? rowIndex;
        this.originalDocumentId = this.editDataItem.documentId;
        this.originalDocumentDMSId = this.editDataItem.documentDmsid;
        this.documentName = this.editDataItem.fileName;
    }

    public saveHandler(pd: DocumentGenericDTO): void {
        if (this.originalDocumentId !== 0 && this.originalDocumentId !== this.editDataItem.documentId) {
            this.documentDataService.editDocument(this.originalDocumentId, this.editDataItem.documentId)
                .subscribe(
                    null,
                    (error) => {
                        Logger.error(error);
                        this.notificationService.emitNotification(NotificationType.Error, error);
                    });

            pd.documentId = this.originalDocumentId;
            pd.documentDmsid = this.originalDocumentDMSId;
        }

        super.saveHandler(pd);
        this.loadGroup();
    }

    protected equalDocuments(doc1: DocumentGenericDTO, doc2: DocumentGenericDTO): boolean {
        if (doc1 && doc2) {
            return doc1.documentId === doc2.documentId;
        }
        return false;
    }

    downloadHandler(documentId: number, pwc: boolean = false): void {
        this.documentDataService.getMyFileFromBackend(documentId, pwc).subscribe(
            (res) => {
                if (!(window.navigator as any).msSaveOrOpenBlob) {
                    const url = window.URL.createObjectURL(res.blob);
                    const link = document.createElement('a');

                    link.setAttribute('href', url);
                    link.setAttribute('download', res.name);
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    (window.navigator as any).msSaveBlob(res.blob, res.name);
                }
            },
            (error) => {
                Logger.error(error.error.errorMessage);
                this.notificationService.emitNotification(NotificationType.Error, error.error.errorMessage);
            },
            () => Logger.info('Completed file download.'));
    }

    printHandler(documentId: number, pwc: boolean = false): void {
        this.documentDataService.getMyFileFromBackend(documentId, pwc).subscribe(
            (res) => {
                const url = window.URL.createObjectURL(res.blob);
                const win = window.open('');
                win.document.write('<img src="' + url + '" onload="window.print();window.close()" />');
                win.focus();
            },
            (error) => {
                Logger.error(error);
                this.notificationService.emitNotification(NotificationType.Error, error);
            },
            () => Logger.info('Completed file print.'));
    }

    openPopupHandler(event: Event, documentId: number, pwc: boolean = false): void {
        event.stopPropagation();
        this.documentDataService.getMyFileFromBackend(documentId, pwc).subscribe(
            (res) => {
                const url = window.URL.createObjectURL(res.blob);
                const win = window.open('');
                win.document.write('<img src="' + url + '" />');
                win.focus();
            },
            (error) => {
                Logger.error(error);
                this.notificationService.emitNotification(NotificationType.Error, error);
            });
    }

    protected loadGroup(): void {
        if (this._model) {
            this.documentGrouped = process(this._model_filtered, { group: this.groups });
        }
    }

    public refreshFilteredData(): void {
        this._model_filtered = (this._model || []);
        this.loadGroup();
    }

    public removeHandler($event: RemoveEvent): void {
        var index = this._model.indexOf($event.dataItem, 0);
        this._model.splice(index, 1);
        this.onChange(this._model);
        this.refreshFilteredData();
    }
}
