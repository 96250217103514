import { Component, EventEmitter, forwardRef, Input, Injector, OnInit, OnDestroy, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Logger } from '@fiba/utils/logger';
import { FibaInputBase } from '../base/fiba-input-base';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'fibaInputDate',
    templateUrl: './fiba-input-date.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputDateComponent),
            multi: true
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaInputDateComponent extends FibaInputBase<any> implements OnInit, OnDestroy {

    @Input() public type = 'input';
    @Input() public formSaveSuccessEvent: Subject<boolean>;
    @Input() public doNotShiftOffset: boolean = false;

    @Output() public change: EventEmitter<moment.Moment> = new EventEmitter();

    public _dateToDisplay: Date;

    private subs: Subscription = new Subscription();
    private makePristine = true;

    constructor(protected injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.onFormSavedLogic();
    }

    public ngOnDestroy(): void {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    public handleValue() {
        this.change.emit(this.value);
        this.manualChange();
        this.onTouched();
    }

    /**
     * Logic is a little complicated, i'll try my best resuming it so nobody gets confused
     * The function handleValue is actually passing back the date to the parent through ngModel (value)
     * When the component is set, value is pre-filled with parent ngModel
     * The value setter (set value() {}) contains an UTC Tricks so everytime the value get out of the component it gets tricked.
     *
     * What is the UTC Tricks
     * The UTC tricks removeUTCOffsetFromDate is needed to remove offset before the data get out BECAUSE
     * after the data get out of the component it get serialized as a Date object and this serialization
     * break the date and add the local utc offset to it, that's why we REMOVE the offset BEFORE sending the data to the parent
     * in order to keep the date unchanged.
     *
     * SO, everytime the datepicker get touched it triggers dateToDisplay and handleValue and apply the tricks logic to value
     *
     */

    public get value(): any {
        let val = isNullOrUndefined(this._value) ? this._value : moment(this._value);
        if (this.type === 'filter') {
            val = isNullOrUndefined(this._value) ? this._value : this._value.toDate();
        }
        return val;
    }

    public set value(value: any) {
        if (!value) {
            this._value = null;
            this._dateToDisplay = null;
        } else {
            if (!this.doNotShiftOffset) {

                // First call
                value = moment(value);
                this._value = this.removeUTCOffsetFromDate(value);
                this._dateToDisplay = !!value ? value.toDate() : null;

            } else {

                // Then
                value = value.replace('Z', '');
                value = moment(value).utcOffset(0, true);
                this._value = value;
                this._dateToDisplay = !!value ? this.removeUTCOffsetFromDate(value, true).toDate() : null;

            }
        }

        this.handleValue();
        this.makePristineLogic();
    }

    public get dateToDisplay(): Date {
        return this._dateToDisplay;
    }

    public set dateToDisplay(value: Date) {
        if (!value) {
            this.value = null;
        } else {
            this.value = moment(value);
        }
        this._dateToDisplay = value;
    }

    private removeUTCOffsetFromDate(date: moment.Moment, negateOffset: boolean = false): moment.Moment {
        if (date) {
            let newDate = date;
            const userTimezoneOffset = date.toDate().getTimezoneOffset() * 60000;
            if (negateOffset) {
                newDate = moment.utc(new Date(date.toDate().getTime() + userTimezoneOffset)).utcOffset(0, true);
            } else {
                newDate = moment.utc(new Date(date.toDate().getTime() - userTimezoneOffset)).utcOffset(0, true);
            }
            return (newDate);
        }
        Logger.error('Invalid date');
    }

    private makePristineLogic(): void {
        if (!this._dateToDisplay) {
            this.resetPristineAndTouched();
        } else {
            if (this.makePristine) {
                this.resetPristineAndTouched();
                this.makePristine = false;
            }
        }
    }

    private resetPristineAndTouched(): void {
        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.markAsPristine();
            this.ngControl.control.markAsUntouched();
        }
    }

    private onFormSavedLogic(): void {
        if (this.formSaveSuccessEvent) {
            const subscription: Subscription = this.formSaveSuccessEvent.subscribe(
                () => {
                    this.makePristine = true;
                });

            this.subs.add(subscription);
        }
    }

}
