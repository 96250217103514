import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../utils/http.service';
import { AppConfig } from '@app/app.config';
import { ApiListResponse, RoundDTO, ThingDTO } from '../../models';
import { CompetitionStandingHeaderFilters } from '../../models/competition/competition-standing-header-filters';
import { CompetitionStandingHeaderSearchResultLineDTO } from '../../models/dtos/generated/competition-standing-header-search-result-line-dto';
import { CompetitionStandingHeaderDTO } from '../../models/dtos/generated/competition-standing-header-dto';

@Injectable()
export class CompetitionStandingHeadersAdminDataService {
    private readonly competitionStandingHeadersApiEndpoint: string;

    constructor(private http: HttpService, private config: AppConfig) {
        this.competitionStandingHeadersApiEndpoint = config.competitionStandingHeadersApiEndpoint;
    }

    public fetchCompetitionStandingHeadersSearchResults(filters: CompetitionStandingHeaderFilters): Observable<ApiListResponse<CompetitionStandingHeaderDTO>> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/search`, filters.getSearchParams());
    }

    public fetchStandingHeaders(competitionStandingHeaderId: number): Observable<CompetitionStandingHeaderDTO[]> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/${competitionStandingHeaderId}`);
    }

    public fetchRoundCode(roundCode: string): Observable<RoundDTO> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/competition-round-type?roundCode=${roundCode}`);
    }

    public fetchStandingHeadersByCompetition(competitionId: number): Observable<CompetitionStandingHeaderDTO[]> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/competition/${competitionId}`);
    }

    public updateStandingHeaders(standingHeadersDto: CompetitionStandingHeaderDTO[]): Observable<any> {
        return this.http.put(`${this.competitionStandingHeadersApiEndpoint}`, standingHeadersDto);
    }

    public updateCompetitionStandingHeaders(competitionStandingHeaderId: number,competitionId: number, standingHeadersDto: CompetitionStandingHeaderDTO[]): Observable<any> {
        return this.http.put(`${this.competitionStandingHeadersApiEndpoint}/${competitionStandingHeaderId}/competition/${competitionId}`, standingHeadersDto);
    }

    public deleteCompetitionStandingHeaders(competitionStandingHeaderId: number): Observable<any> {
        return this.http.delete(`${this.competitionStandingHeadersApiEndpoint}/${competitionStandingHeaderId}`);
    }

    public createStandingHeaders(standingHeadersDto: CompetitionStandingHeaderDTO[]): Observable<any> {
        return this.http.post(this.competitionStandingHeadersApiEndpoint + '/', standingHeadersDto);
    }

    public fetchCompetitionsCodes(): Observable<ThingDTO[]> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/codes`);
    }

    public fetchCompetitionsRounds(competitionCode: string): Observable<ThingDTO[]> {
        return this.http.get(`${this.competitionStandingHeadersApiEndpoint}/rounds?competitionCode=${competitionCode}`);
    }


}
