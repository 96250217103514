import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, ThingDTO, VenueDTO, VenueListItemDTO, VenueStatusDTO, VenueFilters, VenuePlayingClubsDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { VenueWithBasketballEquipmentsDTO } from '@fiba/models/dtos/generated/venue-with-basketball-equipments-dto';
import { VenueDocumentsAndNotesDTO } from '@fiba/models/dtos/generated/venue-documents-and-notes-dto';
import { VenueWithCompetitionsDTO } from '@fiba/models/dtos/generated/venue-with-competitions-dto';
import { VenueWithVenueRegistrationsDTO } from '@fiba/models/dtos/generated/venue-with-venue-registrations-dto';
import { DataCachedService } from '../utils/data-cached.service';
import { MergeVenuesDTO } from '@fiba/models/dtos/generated/merge-venues-dto';
import {AppConfig} from '@app/app.config';

const VENUES_AUTOCOMPLETE_LIST_SIZE = 30;
const VENUES_SEARCH_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class VenueDataService {
    private readonly venueApiEndpoint: string;
    private readonly venueStatusApiEndpoint: string;
    private readonly venueAutocompleteApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataVenueStatusService: DataCachedService<VenueStatusDTO>, ) {
        this.venueApiEndpoint = config.venueApiEndpoint;
        this.venueStatusApiEndpoint = config.venueStatusApiEndpoint;
        this.venueAutocompleteApiEndpoint = config.venueAutocompleteApiEndpoint;
    }

    fetchVenuePlayingClubs(venueId: number): Observable<VenuePlayingClubsDTO> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}/playing-clubs`);
    }
    fetchVenueWithBasketballEquipments(venueId: number): Observable<VenueWithBasketballEquipmentsDTO> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}/basketball-equipment`);
    }
    updateVenueWithBasketballEquipments(model: VenueWithBasketballEquipmentsDTO): Observable<any> {
        return this.http.put(`${this.venueApiEndpoint}/${model.venueId}/basketball-equipment`, model);
    }

    fetchVenue(venueId: number): Observable<VenueDTO> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}`);
    }

    fetchVenues(): Observable<VenueDTO[]> {
        return this.http.get(this.venueApiEndpoint);
    }

    fetchList(filters: VenueFilters): Observable<ApiListResponse<VenueListItemDTO>> {
        return this.http.get(this.venueApiEndpoint, filters.getSearchParams());
    }

    fetchAutocompleteList(name: string): Observable<ThingDTO[]> {
        const params = { name, take: String(VENUES_AUTOCOMPLETE_LIST_SIZE) };
        return this.http.get(this.venueAutocompleteApiEndpoint, params);
    }

    fetchStatuses(): Observable<VenueStatusDTO[]> {
        return this.dataVenueStatusService.fetchEntities(this.venueStatusApiEndpoint);
    }

    createVenueInformation(model: VenueDTO): Observable<VenueDTO> {
        return this.http.post(`${this.venueApiEndpoint}/venue-information`, model);
    }

    updateVenueInformation(model: VenueDTO): Observable<any> {
        return this.http.put(`${this.venueApiEndpoint}/${model.venueId}/venue-information`, model);
    }

    downloadExport(filters: VenueFilters): Observable<any> {
        return this.http.getFileData(this.venueApiEndpoint + '/export-csv', filters.getSearchParams());
    }

    fetchVenueDocuments(venueId: number): Observable<VenueDocumentsAndNotesDTO> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}/documents`);
    }
    updateVenueDocuments(model: VenueDocumentsAndNotesDTO): Observable<any> {
        return this.http.put(`${this.venueApiEndpoint}/${model.venueId}/documents`, model);
    }
    fetchVenueWithCompetitions(venueId: number): Observable<VenueWithCompetitionsDTO[]> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}/competitions`);
    }
    fetchVenueWithVenueRegistrations(venueId: number): Observable<VenueWithVenueRegistrationsDTO[]> {
        return this.http.get(`${this.venueApiEndpoint}/${venueId}/venue-registrations`);
    }

    mergeVenues(model: MergeVenuesDTO): Observable<number> {
        return this.http.put(`${this.venueApiEndpoint}/merges`, model);
    }
    fetchVenuesToMerge(firstVenueId: number, secondVenueId: number): Observable<MergeVenuesDTO> {
        return this.http.get(`${this.venueApiEndpoint}/merges/?firstVenueId=${firstVenueId}&secondVenueId=${secondVenueId}`);
    }
}
