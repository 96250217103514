import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OfficialNominationsDTO, OfficialNominationCompetitionDTO, ConfigurationRoleCompetitionRoleDTO, ItemDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionNominationDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionNominations(competitionId: number): Observable<OfficialNominationCompetitionDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nominations`);
    }

    fetchOfficialNominations(competitionId: number, levelItemCode: string, levelId: number): Observable<OfficialNominationsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/nominations/${levelItemCode}/${levelId}`);
    }

    updateOfficialNominations(model: OfficialNominationsDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/nominations`, model);
    }

    fetchConfigurationRoleCompetitionRoleItems(roleCode: string = null): Observable<ItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/nominations/competition-role-items`);
    }

    fetchConfigurationRoleCompetitionRoles(roleCode: string = null): Observable<ConfigurationRoleCompetitionRoleDTO[]> {
        let params = null;
        if (roleCode) {
            params = { roleCode: String(roleCode) };
        }
        return this.http.get(`${this.competitionApiEndpoint}/nominations/competition-role-relationships`, params);
    }
}
