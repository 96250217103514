import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { ItemDataService } from '@fiba/data-services';
import { FibaAjaxSelectTreeViewBaseComponent } from '../../forms/base/fiba-select-treeview-base.component';
import { KendoDropDownDataDTO } from '../../models/common/kendo-dropdown-data';
import { map } from 'rxjs/operators';
import { FormRegistrationTemplateEntities, ItemCategoryCodes, ItemDTO } from '../../models';

@Component({
    selector: 'fibaSelectTreeViewItem',
    templateUrl: '../../forms/base/fiba-select-treeview-base.component.html', providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectTreeViewItemComponent),
            multi: true
        }
    ],
    styleUrls: ['./fiba-select-treeview-item.component.css'],
    host: { 'class': 'fiba-input' }
})
export class FibaSelectTreeViewItemComponent extends FibaAjaxSelectTreeViewBaseComponent<KendoDropDownDataDTO> implements OnInit {
    @Input() protected categoryCode;
    @Input() protected displayParent: boolean;
    @Input() protected showOnlyRoots: boolean = false;
    @Input() protected showOnlyChildren: boolean = false;
    @Input() protected isEvcQuestionnaireView: boolean = false;
    @Input() protected isEvcTestReportView: boolean = false;
    
    private ItemCategoryCodes_FormRegistrationTemplateEntity: string = ItemCategoryCodes.FormRegistrationTemplateEntity;
    private FormRegistrationTemplateEntities_EVCForm: string = FormRegistrationTemplateEntities.EVCForm;
    private FormRegistrationTemplateEntities_FRTEVCProductapplicationquestionnaire: string = FormRegistrationTemplateEntities.FRTEVCProductapplicationquestionnaire;
    private FormRegistrationTemplateEntities_FRTEVCApplicantquestionnaire: string = FormRegistrationTemplateEntities.FRTEVCApplicantquestionnaire;
    private FormRegistrationTemplateEntities_FRTEVCProductTestResult: string = FormRegistrationTemplateEntities.FRTEVCProductModelTestResult;

    constructor(protected injector: Injector, protected dataService: ItemDataService) {
        super(injector);
        this._valueField = 'itemId';
        this._childrenField = 'items';
    }

    @Input()
    protected set textField(value: string) {
        this._textField = value;
    }

    @Input()
    protected set valueField(value: string) {
        this._valueField = value || 'itemId';
    }

    protected _parentCode: string;

    @Input()
    protected set parentCode(value: string) {
        this._parentCode = value;
        this.getSubscription();
    };

    protected _parentId: number;

    @Input()
    protected set parentId(value: number) {
        this._parentId = value;
        this.getSubscription();
    };

    ngOnInit(): void {
        super.ngOnInit();
        this._textField = this._textField || (this.displayParent ? 'cli_displayNameWithParent' : 'name');
        this.getObservable();
    }

    getBindData(): (data: KendoDropDownDataDTO[]) => void {
        return (data: KendoDropDownDataDTO[]) => {
            this.getBindDataCallbackBase(data);
        };
    }

    getDataServiceMethod():Observable<ItemDTO[]> {
        if (this.categoryCode == this.ItemCategoryCodes_FormRegistrationTemplateEntity) {
            return this.dataService.fetchTemplateEntityItems();
        } else {
            return this.dataService.fetchItems(this.categoryCode, null, true)
        }
    }

    getObservable(): Observable<KendoDropDownDataDTO[]> {
        return this.getDataServiceMethod().pipe(map(val => {
            var result: KendoDropDownDataDTO[] = [] as KendoDropDownDataDTO[];
            for (let i = 0; i < val.length; i++) {
                if (val[i].parentItemId === null) {
                    let kendoDropDownDataDTO: KendoDropDownDataDTO = {} as KendoDropDownDataDTO;
                    kendoDropDownDataDTO.itemId = val[i].itemId;
                    kendoDropDownDataDTO.name = val[i].name;
                    kendoDropDownDataDTO.code = val[i].code;
                    kendoDropDownDataDTO.displayOrder = val[i].displayOrder;
                    kendoDropDownDataDTO.parentItemId = val[i].parentItemId;
                    let kendoDropDownDataDTOItems: KendoDropDownDataDTO[] = [] as KendoDropDownDataDTO[];
                    let itemList = val.filter(x => x.parentItemId === val[i].itemId);
                    for (let j = 0; j < itemList.length; j++) {
                        let item: KendoDropDownDataDTO = {} as KendoDropDownDataDTO;
                        item.itemId = itemList[j].itemId;
                        item.name = itemList[j].name;
                        item.displayOrder = itemList[j].displayOrder;
                        item.parentItemId = itemList[j].parentItemId;
                        item.code = itemList[j].code;
                        kendoDropDownDataDTOItems.push(item);
                    };
                    kendoDropDownDataDTO.items = kendoDropDownDataDTOItems
                    result.push(kendoDropDownDataDTO);
                }
            }
            if (this.isEvcQuestionnaireView || this.isEvcTestReportView) {
                const evcForm = result.find(res => res.code == this.FormRegistrationTemplateEntities_EVCForm)
                if (this.isEvcQuestionnaireView) {
                    result = evcForm.items.filter(res => res.code == this.FormRegistrationTemplateEntities_FRTEVCProductapplicationquestionnaire || res.code == this.FormRegistrationTemplateEntities_FRTEVCApplicantquestionnaire)
                }
                if (this.isEvcTestReportView) {
                    result = evcForm.items.filter(res => res.code == this.FormRegistrationTemplateEntities_FRTEVCProductTestResult)
                }
            }
            return result;
        }));
    }

    protected getBindDataCallbackBase(data: KendoDropDownDataDTO[]): void {
        data = this.sortItemsList(data);
        this._filteredItems = data.slice(0);
    }

    protected sortItemsList(data: KendoDropDownDataDTO[]): KendoDropDownDataDTO[] {
        if (data) {
            data = data.sort((x, y) => {
                if (x.displayOrder === y.displayOrder) {
                    return !x.parentItemId ? -1 : 1;
                }
                return x.displayOrder < y.displayOrder ? -1 : 1;
            });
        }
        return data;
    }
}
