<div [ngClass]="{'menu-open': !_isFolded, 'menu-folded': _isFolded }" class="navmenu anim02 scrollbar" id="scroll-bar">
    <nav class="main-navigation">
        <div [ngStyle]="{'height': 'calc(100% -25px)'}">
            <ul>
                <li *ngFor="let item of menus">
                    <a *ngIf="!item.children?.length" [ngClass]="{'child-active': item.children?.length}" [routerLinkActive]="['active']"
                       [routerLink]="item.route" class="expandable">
                        <i *ngIf="!isStackIcon(item)" class="fa fa-fw {{item.icon}}"></i>
                        <div *ngIf="isStackIcon(item)" class="fa-stack fa-lg fa-fw">
                            <i *ngFor="let icon of item.icon; let first = first"
                               [ngClass]="{secondary: !first}" class="fa fa-fw {{icon}}"></i>
                        </div>
                        <span>{{item.name}}</span>
                    </a>
                    <div *ngIf="item.children?.length">
                        <a (click)="item.isExpanded = !item.isExpanded" class="fill-all">
                            <i *ngIf="!isStackIcon(item)" class="fa fa-fw {{item.icon}}"></i>
                            <div *ngIf="isStackIcon(item)" class="fa-stack fa-lg fa-fw">
                                <i *ngFor="let icon of item.icon; let first = first"
                                   [ngClass]="{secondary: !first}" class="fa fa-fw {{icon}}"></i>
                            </div>
                            <span>{{item.name}}</span>
                            <i [ngClass]="{'fa-plus': !item.isExpanded, 'fa-minus': item.isExpanded }"
                               class="fa fa-fw expand"></i>
                        </a>
                        <ul *ngIf="item.isExpanded" [@enterAnimation]>
                            <li *ngFor="let childItem of item.children">
                                <!-- Check policy if policy on menu item -->
                                <div *ngIf="!!childItem.policy">
                                    <div *fibaHideByPolicy="childItem.policy">
                                        <a [routerLinkActive]="['active']" [routerLink]="childItem.route">
                                            <i *ngIf="!isStackIcon(childItem)" class="fa fa-fw {{childItem.icon}}"></i>
                                            <div *ngIf="isStackIcon(childItem)" class="fa-stack fa-lg fa-fw">
                                                <i *ngFor="let icon of childItem.icon; let first = first"
                                                   [ngClass]="{secondary: !first}" class="fa fa-fw {{icon}}"></i>
                                            </div>
                                            <span>{{childItem.name}}</span>
                                        </a>
                                    </div>
                                </div>
                                <!-- If no policy display menu  -->
                                <div *ngIf="!childItem.policy">
                                    <a [routerLinkActive]="['active']" [routerLink]="childItem.route">
                                        <i *ngIf="!isStackIcon(childItem)" class="fa fa-fw {{childItem.icon}}"></i>
                                        <div *ngIf="isStackIcon(childItem)" class="fa-stack fa-lg fa-fw">
                                            <i *ngFor="let icon of childItem.icon; let first = first"
                                               [ngClass]="{secondary: !first}" class="fa fa-fw {{icon}}"></i>
                                        </div>
                                        <span>{{childItem.name}}</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div (click)="toggleMenu()" class="clickable fold-menu">
                <i [hidden]="_isFolded" class="fa fa-arrow-circle-left"></i>
                <i [hidden]="!_isFolded" class="fa fa-arrow-circle-right"></i>
                <span>Hide menu</span>
            </div>
        </div>
    </nav>
    <div class="copyright">&copy;&nbsp;FIBA 2016-{{currentYear}}</div>
</div>
