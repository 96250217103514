import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '@fiba/data-services';
import {LocalStorage} from '@fiba/utils/local-storage.service';

@Component({
    template: '',
})
export class AuthHandlerComponent implements OnInit {
    constructor(protected authService: AuthService, protected router: Router) {
    }

    public ngOnInit(): void {
        this.authService.endSignin().then((user) => {
            const redirectUrlFromStorage = LocalStorage.getItem('redirectUrl') || '/';
            LocalStorage.removeItem('redirectUrl');

            this.router.navigate([redirectUrlFromStorage]);
        });
    }
}
