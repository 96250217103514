import { BaseFilters, FilterableArray } from '../common/base.filters';

export class ApprovalCategoryFilters extends BaseFilters {
    keywords: string;
    approvalCategoryStatusId: number;
    reset(): void {
        delete this.keywords;
        delete this.approvalCategoryStatusId;
    }
}
