import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputText',
    templateUrl: './fiba-input-text.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputTextComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaInputTextComponent extends FibaInputBase<string> {
    constructor(protected injector: Injector) {
        super(injector);
    }

    @Input() public pattern: string = null;
    @Input() public maxLength: number;
    @Input() public disableAutoTrim: boolean = false;

    public manualChange(): void {
        if (!this.disableAutoTrim) {
            this.value = (this.value as string).trim();
        }
        super.manualChange();
    }
}
