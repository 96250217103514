import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AuthService, CityDataService } from '@fiba/data-services';
import { FibaInputBase } from '@fiba/forms';

import * as moment from 'moment-timezone';

import { City } from '@fiba/models';
import { Observable, of, Subject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'fibaAutocompleteCity',
    templateUrl: './fiba-autocomplete-city.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaAutocompleteCityComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
    styleUrls: ['./fiba-autocomplete-city.component.css'],
})
/**
 Component's ngModel must be bound to a string
 City value is managed as internal value
 (This is why it doesn't extend FibaInputAutocompleteBase)
 */
export class FibaAutocompleteCityComponent extends FibaInputBase<City> implements OnInit {
    public textField: string;
    public valueField: string;
    public data: Observable<City[]>;
    public valueCity: City;
    public searchTerms: Subject<string> = new Subject<string>();
    public currentDate = Date.now();
    public timezones: any[] = [];
    public isFrontOffice: boolean = null;
    @Input() public useIanatimeZone = false;
    @Input() public placeholder: string;
    @Input() public type = 'filter';
    @Input() public delay = 300;
    @Input() public disabled = false;
    @Input() public isFrontOfficeAuthorize = false;

    @Output() public change: EventEmitter<any> = new EventEmitter();

    @Input() public country: string = null;

    @Output() public cityChange: EventEmitter<string> = new EventEmitter();
    @Output() public ianatimeZone: EventEmitter<string> = new EventEmitter();

    constructor(
        protected injector: Injector,
        private service: CityDataService,
        protected authService: AuthService) {
        super(injector);

        this.data = this.searchTerms.asObservable().pipe(
            debounceTime<string>(this.delay),
            distinctUntilChanged<string>(),
            switchMap((term: string) => this.getObservable(term)
            ),
        );

        this.data.subscribe((item) => {
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.valueField = 'geonameId';
        this.textField = 'name';
        this.timezones = moment.tz.names();
        this.authService.getIsFrontOfficeUserPromise().then((isFrontOffice) => {
            this.isFrontOffice = isFrontOffice;
        });
    }

    public getObservable(city: string): Observable<City[]> {
        if (city) {
            return this.service.fetchCities(city, this.country);
        } else {
            return of<City[]>([]);
        }
    }

    public writeValue(value: City): void {
        this.value = value;
        if (value) {
            this.valueCity = {
                name: value.name,
                displayName: value.displayName,
                geonameId: value.geonameId,
                timeZoneId: value.timeZoneId,
            } as City;
        } else {
            this.valueCity = null;
        }
    }

    public handleFilter(value: string): void {
        if (value) {
            this.searchTerms.next(value);
        } else {
            this.handleValue(null);
        }
        this.onTouched();
    }

    public handleValue(value: City): void {
        if (value) {
            this.cityChange.emit(value.name);
            this.ianatimeZone.emit(value.timeZoneId);
            this.writeValue(value);
        } else {
            this.cityChange.emit(null);
            this.ianatimeZone.emit(null);
            this.writeValue(null);
        }
        this.manualChange();
        this.onTouched();
    }

    public valueNormalizer = (text: Observable<string>) => text.pipe(map((name: string) => ({
        name,
        displayName: name,
    } as City)));

    public timeZoneChange($event) {
        this.ianatimeZone.emit(this.valueCity.timeZoneId);
    }
}
