import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { CompetitionTeamsRegistrationDTO, CompetitionTeamDocumentDTO, CompetitionTeamStatusDTO, CompetitionTeamDocumentStatusDTO, ItemDTO, TeamRegistrationSummaryDTO, TechnicalMeetingStatusDTO, TechnicalMeetingConfigurationsDTO, TechnicalMeetingConfigurationDetailsDTO, TechnicalMeetingCompetitionTeamDTO, CompetitionTeamListItemDTO, TechnicalMeetingConfigurationListItemDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { DataCachedService } from '../../utils/data-cached.service';
import { TechnicalMeetingOperatorUserDetailsDTO } from '@fiba/models/dtos/generated/technical-meeting-operator-user-details-dto';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionTechnicalMeetingConfigurationDataService {
    protected competitionApiEndpoint: string;
    protected technicalMeetingStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataTechnicalMeetingStatusService: DataCachedService<TechnicalMeetingStatusDTO>) {

        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.technicalMeetingStatusApiEndpoint = config.technicalMeetingStatusApiEndpoint;
    }

    fetchTechnicalMeetingConfigurations(competitionId: number): Observable<TechnicalMeetingConfigurationsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration`);
    }
    fetchTechnicalMeetingConfiguration(competitionId: number, technicalMeetingId: number): Observable<TechnicalMeetingConfigurationDetailsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration/${technicalMeetingId}`);
    }

    updateTechnicalMeetingConfiguration(competitionId: number, model: TechnicalMeetingConfigurationDetailsDTO): Observable<TechnicalMeetingConfigurationDetailsDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration/${model.technicalMeetingId}`, model);
    }

    createTechnicalMeetingConfiguration(competitionId: number, model: TechnicalMeetingConfigurationDetailsDTO): Observable<TechnicalMeetingConfigurationDetailsDTO> {
        return this.http.post(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration`, model);
    }

    public deleteTechnicalMeetingConfiguration(model: TechnicalMeetingConfigurationDetailsDTO): Observable<boolean> {
        return this.http.delete(`${this.competitionApiEndpoint}/technical-meeting-configuration/${model.technicalMeetingId}`);
    }

    updateBulkTechnicalMeetingConfigurations(competitionId: number, model: TechnicalMeetingConfigurationListItemDTO[]): Observable<TechnicalMeetingConfigurationListItemDTO[]> {
        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration/bulk`, model);
    }

    fetchTechnicalMeetingStatuses(): Observable<CompetitionTeamStatusDTO[]> {
        return this.dataTechnicalMeetingStatusService.fetchEntities(this.technicalMeetingStatusApiEndpoint);
    }

    fetchTechnicalMeetingOperatorUserDetails(personId: number, competitionId: number): Observable<TechnicalMeetingOperatorUserDetailsDTO[]> {
        let params = null;
        if (competitionId) {
            params = { competitionId: competitionId };
        }
        return this.http.get(`${this.competitionApiEndpoint}/technical-meeting-operator-user-details/${personId}`, params);
    }

    fetchTechnicalMeetingAvailableCompetitionTeams(competitionId: number, technicalMeetingId: number): Observable<CompetitionTeamListItemDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/technical-meeting-configuration/${technicalMeetingId}/available-teams`);
    }


}
