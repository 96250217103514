import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { FormRegistrationTemplateFieldAnswerItemDTO } from '../dtos/dtos.index';

export class FormRegistrationTemplateFilters extends BaseFilters {
    formName: string;
    validFromDate: Date;
    validToDate: Date;
    entityTypeItemIds: FilterableArray = new FilterableArray('entityTypeItemIds', 'itemId');
    statusId: number;

    reset(): void {
        delete this.formName;
        delete this.validFromDate;
        delete this.validToDate;
        delete this.entityTypeItemIds;
        delete this.statusId;
    }
}


declare module '../dtos/generated/form-registration-template-tree-node-dto' {
    export interface FormRegistrationTemplateTreeNodeDTO {
        cli_isCopyOfPreviousVersion: boolean
    }
}
declare module '../dtos/generated/form-registration-template-section-tree-node-dto' {
    export interface FormRegistrationTemplateSectionTreeNodeDTO {
        cli_selected: boolean;
        cli_isNew: boolean;
        cli_isCollapsed: boolean;
        cli_animate: boolean;
    }
}

declare module '../dtos/generated/form-registration-template-group-tree-node-dto' {
    export interface FormRegistrationTemplateGroupTreeNodeDTO {
        cli_selected: boolean;
        cli_isNew: boolean;
        cli_isCollapsed: boolean;
        cli_animate: boolean;
    }
}

declare module '../dtos/generated/form-registration-template-field-tree-node-dto' {
    export interface FormRegistrationTemplateFieldTreeNodeDTO {
        cli_selected: boolean;
        cli_isNew: boolean;
        cli_animate: boolean;
    }
}

declare module '../dtos/generated/form-registration-template-field-answer-item-dto' {
    export interface FormRegistrationTemplateFieldAnswerItemDTO {
        cli_selected: boolean;
        isTriggerFieldRadioButtonYesNoField: boolean;
        isDisplayed: boolean;
    }
}
