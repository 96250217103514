import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../app/shared.module';
import { FibaPopupClubModule } from '../../components/club/fiba-popup-club/fiba-popup-club.module';

import { FibaPopupClubComponent } from '../../components/club/fiba-popup-club/fiba-popup-club.component';
import { FibaPickerClubComponent } from './fiba-picker-club.component';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPickerClubComponent,
];

@NgModule({
    imports: [
        SharedModule,

        FibaPopupClubModule,
    ],
    declarations: [
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPickerClubModule {
}
