import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class PersonFilters extends BaseFilters {
    // Primitives order by name
    public addressLine: string;
    public addressPostCode: string;
    public addressCity: string;
    public ageCategoryId: number;
    public basketballLicenseFromDate: number;
    public basketballLicenseNumber: number;
    public basketballDisplayedLicenseNumber: string;
    public basketballLicenseToDate: number;
    public birthDate: Date;
    public checkAvailability: boolean;
    public coachRoleLicenseFromDate: number;
    public coachRoleLicenseToDate: number;
    public context: string;
    public competitionSeason: number;
    public familyId: number;
    public firstName: string;
    public lastName: string;
    public includeInactiveRoles = true;
    public includeDeceased: boolean;
    public licenseNumber: number;
    public displayedLicenseNumber: string;
    public licenseCoachDate: Date;
    public nominationStartDate: Date;
    public nominationEndDate: Date;
    public personId: number;
    public relatedCountries = false;
    public roleId: number;
    public roleTypeId: number;
    public roleTypeCode: string;
    public roleDateFrom: Date;
    public roleDateTo: Date;
    public season: string;
    public withApplicableLicense: boolean;
    public includeInactive = false;
    public shouldCheckDuplicateFilterByGender = false;
    public onlyIsDisplayedInOrganisationChart = false;
    public competitionId: number;
    

    // Objects order by name
    public addressCountry: Filterable = new Filterable('addressCountryId', 'countryId');
    public club: Filterable = new Filterable('licenseClubId', 'organisationId');
    public competition: Filterable = new Filterable('competitionId');
    public dummyA: Filterable = new Filterable('dummy', 'personId');
    public family: Filterable = new Filterable('familyId', 'roleFamilyId');
    public gender: Filterable = new Filterable('genderId', 'itemId');
    public licenseStateItem: Filterable = new Filterable('licenseStateItemId', 'itemId');
    public licenseNationality: Filterable = new Filterable('licenseNationalityCountryId', 'countryId');
    public licenseCategory: FilterableArray = new FilterableArray('licenseCategoryId', 'itemId');
    public licenseSubCategory: FilterableArray = new FilterableArray('licenseSubCategoryId', 'itemId');
    public nationality: Filterable = new Filterable('nationalityId', 'countryId');
    public nationalFederation: Filterable = new Filterable('licenseNationalFederationId', 'organisationId');
    public nationalFederationCoach: Filterable = new Filterable('licenseCoachNationalFederationId', 'organisationId');
    public officialLicenseCategory: FilterableArray = new FilterableArray('officialLicenseCategoryIds', 'itemId');
    public officialZones: FilterableArray = new FilterableArray('officialZoneIds', 'zoneId');
    public organisation: Filterable = new Filterable('organisationId');
    public personStatus: Filterable = new Filterable('personStatusId');
    public playerZones: FilterableArray = new FilterableArray('playerZoneIds', 'zoneId');
    public roles: FilterableArray = new FilterableArray('roleIds', 'roleId');
    public zones: FilterableArray = new FilterableArray('zoneIds', 'zoneId');

    public relatedPersonId: number;
    public representedPerson: Filterable = new Filterable('relatedPersonId', 'id');

    private __byPassRoleResset = false;

    public setByPassRoleResset(value: boolean = true): void  {
        this.__byPassRoleResset = value;
    }


    public reset(): void {
        // Primitives types
        this.addressLine = undefined;
        this.addressPostCode = undefined;
        this.addressCity = undefined;
        this.ageCategoryId = undefined;
        this.basketballLicenseNumber = undefined;
        this.basketballLicenseFromDate = undefined;
        this.basketballLicenseToDate = undefined;
        this.birthDate = undefined;
        this.checkAvailability = false;
        this.coachRoleLicenseFromDate = undefined;
        this.coachRoleLicenseToDate = undefined;
        this.competitionSeason = undefined;
        this.firstName = undefined;
        this.includeInactiveRoles = true;
        this.includeDeceased = false;
        this.lastName = undefined;
        this.licenseCoachDate = undefined;
        this.licenseNumber = undefined;
        this.displayedLicenseNumber = undefined;
        this.nominationStartDate = undefined;
        this.nominationEndDate = undefined;
        this.personId = undefined;
        this.relatedCountries = false;
        this.relatedPersonId = undefined;
        // roleTypeCode = undefined;
        this.roleTypeId = undefined;
        this.roleDateFrom = undefined;
        this.roleDateTo = undefined;
        this.season = undefined;
        this.withApplicableLicense = false;
        this.onlyIsDisplayedInOrganisationChart = false;


        // Objects types
        this.addressCountry.reset();
        this.club.reset();
        this.competition.reset();
        this.dummyA.reset();
        this.family.reset();
        this.gender.reset();
        this.licenseStateItem.reset();
        this.licenseCategory.reset();
        this.licenseSubCategory.reset();
        this.licenseNationality.reset();
        this.nationality.reset();
        this.nationalFederation.reset();
        this.nationalFederationCoach.reset();
        this.officialLicenseCategory.reset();
        this.officialZones.reset();
        this.organisation.reset();
        this.personStatus.reset();
        this.playerZones.reset();
        this.zones.reset();
        this.representedPerson.reset();

        if (!this.__byPassRoleResset) {
            this.roleId = undefined;
            this.roles.reset();
        }
    }
}

export class GameOfficialFilters extends BaseFilters {
    public context: string;
    public firstName: string;
    public lastName: string;
    public birthDate: Date;
    public officialZones: FilterableArray = new FilterableArray('officialZoneIds', 'zoneId');
    public nationality: Filterable = new Filterable('nationalityId', 'countryId');
    public gender: Filterable = new Filterable('genderId', 'itemId');
    public relatedCountries = false;
    public roleId: number;
    public roleIds: FilterableArray = new FilterableArray('roleIds', 'roleId');
    public includeInactiveRoles = true;
    public organisation: Filterable = new Filterable('organisationId');
    public nominationNationalFederationId: number = null;
    public personId: number;
    public personStatus: Filterable = new Filterable('personStatusId');
    public includeDeceased: boolean;
    public officialLicenseCategory: FilterableArray = new FilterableArray('officialLicenseCategoryIds', 'itemId');
    public officiaLicenseNumber: number;
    public officiaLicenseFromDate: number;
    public officiaLicenseToDate: number;
    public withApplicableLicense: boolean;
    public nominationStartDate: Date;
    public nominationEndDate: Date;
    public checkAvailability: boolean;

    public reset(): void {
        this.officialLicenseCategory.reset();
        this.officialZones.reset();
    }
}

export class BasketballLicenseFilters extends BaseFilters {
    public nationalFederation: Filterable = new Filterable('organisationId');
    public startDate: Date;
    public endDate: Date;
    public roleId: number;

    public reset(): void {
        this.nationalFederation.reset();
        this.startDate = null;
        this.endDate = null;
        this.roleId = null;
    }
}

export class BasketballNominationFilters extends BaseFilters {
    public startDate: Date;
    public endDate: Date;
    public roleId: number;

    public reset(): void {
        this.startDate = null;
        this.endDate = null;
        this.roleId = null;
    }
}

export class PlayerCompetitionFilters extends BaseFilters {
    public dateFrom: Date;
    public dateTo: Date;
    public code: string;
    public officialName: string;
    public season: string;
    public played: boolean;

    public reset(): void {
        this.dateFrom = null;
        this.dateTo = null;
        this.code = null;
        this.officialName = null;
        this.season = null;
        this.played = null;
    }
}

export class PersonCompetitionFilters extends BaseFilters {
    public dateFrom: Date;
    public dateTo: Date;
    public code: string;
    public officialName: string;
    public season: string;

    public reset(): void {
        this.dateFrom = null;
        this.dateTo = null;
        this.code = null;
        this.officialName = null;
        this.season = null;
    }
}

export class AgentPersonRelationFilters extends BaseFilters {
    public context: string;
    public isPlayer: boolean;
    public agentPersonId: number;
    public firstName: string;
    public lastName: string;
    public birthDate: Date;
    public nationality: Filterable = new Filterable('nationalityId', 'countryId');
    public gender: Filterable = new Filterable('genderItemId', 'itemId');
    public contract: Filterable = new Filterable('contractTypeItemId', 'itemId');
    public startDate: Date;
    public endDate: Date;
    public represented: Filterable = new Filterable('representingTypeItemId', 'itemId');

    public reset(): void {
        this.firstName = null;
        this.lastName = null;
        this.birthDate = null;
        this.startDate = null;
        this.endDate = null;
        this.nationality.reset();
        this.gender.reset();
        this.contract.reset();
        this.represented.reset();
    }
}

declare module '../dtos/generated/role-dto' {
    export interface RoleDTO {
        cli_displayName: string;
    }
}

declare module '../dtos/generated/person-role-dto' {
    export interface PersonRoleDTO {
        cli_isNew: boolean;
        cli_isInConflict: boolean;
    }
}
declare module '../dtos/generated/doping-control-dto' {
    export interface DopingControlDTO {
        cli_laboratoryOrganisationsFormatted: string;
        cli_countryFormatted: string;
        cli_competitionFormatted: string;
    }
}
