import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface PromptBeforeLeaving {
    canLeave: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class PromptBeforeLeavingGuard implements CanDeactivate<PromptBeforeLeaving> {
    canDeactivate(component: PromptBeforeLeaving) {
        return component.canLeave ?
            component.canLeave() :
            true;
    }
}
