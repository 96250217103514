import { BaseFilters, FilterableArray } from '../common/base.filters';

export class PartnerApplicationFilters extends BaseFilters {
    companyName: string;
    countryId: number;
    equipmentPartnerCompanyRegistrationStatus: FilterableArray = new FilterableArray('equipmentPartnerCompanyRegistrationStatusId');
    approvalCategoryId: FilterableArray = new FilterableArray('approvalCategoryId', 'equipmentApprovalCategoryId');
    reset(): void {
        this.equipmentPartnerCompanyRegistrationStatus.reset();
        this.approvalCategoryId.reset();
        delete this.companyName;
        delete this.countryId;
    }
}
