import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InsurancePolicyCoverageAssignment, CompetitionTimeManagementDTO, ActionMessageDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionTimeManagementDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionTimeManagement(competitionId: number): Observable<CompetitionTimeManagementDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/time-management`);
    }

    updateCompetitionTimeManagement(model: CompetitionTimeManagementDTO): Observable<ActionMessageDTO> {
        model.competitionTimeManagementListItems.forEach(x => {
            if (typeof x.deadlineDateTimePreliminaryRoster !== 'string' && x.deadlineDateTimePreliminaryRoster) {
                x.deadlineDateTimePreliminaryRoster.setTime(x.deadlineDateTimePreliminaryRoster.getTime() - x.deadlineDateTimePreliminaryRoster.getTimezoneOffset() * 60000);
            }
            if (typeof x.gameDateTime !== 'string' && x.gameDateTime) {
                x.gameDateTime.setTime(x.gameDateTime.getTime() - x.gameDateTime.getTimezoneOffset() * 60000);
            }
        });

        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/time-management`, model);
    }

    recalculateInsurancePolicyForCompetition(competitionId: number): Observable<InsurancePolicyCoverageAssignment[]> {

        return this.http.put(`${this.competitionApiEndpoint}/${competitionId}/time-management/recalculate-insurance-policies`);
    }

}
