import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApiListResponse
} from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { AppConfig } from '@app/app.config';
import { WabcCourseListItemDTO } from '../../models/dtos/generated/wabc-course-list-item-dto';
import { WabcCourseFilters } from '../../models/wabc/wabc-course-filters';
import { WabcCourseDTO } from '../../models/dtos/generated/wabc-course-dto';
import { WabcCourseCandidateDTO } from '../../models/dtos/generated/wabc-course-candidate-dto';
import { WabcCourseCandidateStatusDTO } from '../../models/dtos/generated/wabc-course-candidate-status-dto';

@Injectable({
    providedIn: 'root',
})
export class WabcCourseDataService {

    protected baseUrlEndpoint: string;
    protected wabcCourseApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.baseUrlEndpoint = config.baseUrl;
        this.wabcCourseApiEndpoint = config.wabcCourseApiEndpoint;
    }

    public fetchList(filters: WabcCourseFilters, context: string = null): Observable<ApiListResponse<WabcCourseListItemDTO>> {
        return this.http.get(this.wabcCourseApiEndpoint, filters.getSearchParams());
    }

    public fetchStatuses(): Observable<WabcCourseCandidateStatusDTO[]> {
        return this.http.get(this.wabcCourseApiEndpoint + '/candidate-statuses');
    }

    public fetchWabcCourse(wabcCourseId: number): Observable<WabcCourseDTO> {
        return this.http.get(this.wabcCourseApiEndpoint + '/' + wabcCourseId);
    }

    public createWabcCourse(wabcCourseDto: WabcCourseDTO): Observable<WabcCourseDTO> {
        return this.http.post(this.wabcCourseApiEndpoint, wabcCourseDto);
    }

    public updateWabcCourse(wabcCourseId: number, wabcCourseDto: WabcCourseDTO): Observable<WabcCourseDTO> {
        return this.http.put(this.wabcCourseApiEndpoint + '/' + wabcCourseId, wabcCourseDto);
    }

    public approveWabcCourseCandidate(wabcCourseCandidateId: number): Observable<WabcCourseCandidateDTO> {
        return this.http.put(this.wabcCourseApiEndpoint + '/candidates/' + wabcCourseCandidateId + '/approve');
    }

    public rejectWabcCourseCandidate(wabcCourseCandidateId: number): Observable<WabcCourseCandidateDTO> {
        return this.http.put(this.wabcCourseApiEndpoint + '/candidates/' + wabcCourseCandidateId + '/reject');
    }
}
