<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div class="input-xl">
    <kendo-combobox #pickerCombobox
                    [data]="listItems"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [placeholder]="'Search events...'"
                    [filterable]="true"
                    [(ngModel)]="simpleValue"
                    (valueChange)="valueChangeHandler($event)"
                    (cancel)="cancelHandler()"
                    [disabled]="disabled"
                    class="no-arrow fiba-picker"></kendo-combobox>
    <button kendoButton type="button"
            (click)="editHandler()"
            class="icon-only"
            [disabled]="disabled">
        <i class="fa fa-lg fa-search"></i>
    </button>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>

<fibaPopupEvent [contextCode]="contextCode"
                [model]="tmpValue"
                [eventCategories]="_eventCategories"
                (save)="popupSaveHandler($event)"
                (cancel)="cancelHandler()"
                [isNew]="true"
                [filterTestEvents]="true"
                [fromPicker]="true"
                class="input-normal">
</fibaPopupEvent>

