<div class="dropdown" ngxDropdown>
    <button class="btn fiba-color" type="button" [disabled]="disabled" role="button" ngxDropdownToggle >
        {{getText()}}
    </button>
    <div ngxDropdownMenu aria-labelledby="dropdownMenu" (click)="$event.stopPropagation()">
        <div class="dropdown-container">
            <custom-treeview name="treeview"
                             [config]="config"
                             [headerTemplate]="headerTemplate"
                             [items]="items"
                             [itemTemplate]="itemTemplate"
                             [parentInSelection]="parentInSelection"
                             (selectedChange)="onSelectedChange($event)">
            </custom-treeview>
        </div>
    </div>
</div>