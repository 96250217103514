import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class ClubFilters extends BaseFilters {
    public context: string;
    public name: string;
    public displayName: string;
    public organisationStatus: Filterable = new Filterable('organisationStatusId');
    public parentOrganisation: Filterable = new Filterable('parentOrganisationId', 'organisationId');
    public clubStatus: Filterable = new Filterable('clubStatusId');
    public cityName: string;
    public country: Filterable = new Filterable('countryId');
    public relatedCountries: boolean;
    public zone: FilterableArray = new FilterableArray('zoneId');
    public competition: Filterable = new Filterable('competitionId');
    public discipline: string;
    public gender: string;
    public clubGenderCode: string;
    public season: string;
    public addressZipCode: string;
    public licenseCategoryCode: string;
    public ageCategory: string;
    public showClubsForAllNf: boolean;
    public clubId: number;
    public includeAllStatuses: boolean = false;

    public reset(): void {
        this.context = undefined;
        this.name = undefined;
        this.displayName = undefined;
        this.cityName = undefined;
        this.relatedCountries = false;
        this.discipline = undefined;
        this.gender = undefined;
        this.clubGenderCode = undefined;
        this.season = undefined;
        this.addressZipCode = undefined;
        this.licenseCategoryCode = undefined;
        this.ageCategory = undefined;
        this.showClubsForAllNf = false;
        this.organisationStatus.reset();
        this.parentOrganisation.reset();
        this.clubStatus.reset();
        this.zone.reset();
        this.competition.reset();
        this.country.reset();
        this.clubId = undefined;
    }
}
