import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { DialogAction, DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Logger } from '@fiba/utils/logger';

@Injectable({
    providedIn: 'root',
})
export class DialogActionService {
    public result;
    public static readonly ANSWER_YES = 'Yes';
    public static readonly ANSWER_NO = 'No';
    public static readonly ANSWER_OK = 'OK';
    public static readonly ANSWER_CANCEL = 'Cancel';

    protected dialogsPromise: Promise<DialogAction | DialogCloseResult>; // key: MapPolicy, value: claimIds

    constructor(private dialogService: DialogService) { }

    public showConfirmation(title: string, text: string, isYesPrimary: boolean = true): Observable<DialogAction | DialogCloseResult> {
        const dialog: DialogRef = this.dialogService.open({
            title,
            content: text,
            actions: [
                { text: DialogActionService.ANSWER_NO, primary: !isYesPrimary },
                { text: DialogActionService.ANSWER_YES, primary: !!isYesPrimary },
            ],
        });
        return dialog.result;
    }
    public showInformation(title: string, text: string): Observable<DialogAction | DialogCloseResult> {
        const dialog: DialogRef = this.dialogService.open({
            title,
            content: text,
            actions: [
                { text: DialogActionService.ANSWER_OK, primary: true },
            ],
        });
        return dialog.result;
    }
    public showConfirmationTemplate(title: string, template: TemplateRef<any>, isYesPrimary: boolean = true): Observable<DialogAction | DialogCloseResult> {
        const dialog: DialogRef = this.dialogService.open({
            title,
            content: template,
            actions: [
                { text: DialogActionService.ANSWER_CANCEL, primary: !isYesPrimary },
                { text: DialogActionService.ANSWER_OK, primary: !!isYesPrimary },
            ],
        });
        return dialog.result;
    }

    public askCanLeave(): Promise<boolean> {
        this.dialogsPromise = new Promise<DialogAction | DialogCloseResult>((resolveDialog, rejectDialog) => {
            const dialog: DialogRef = this.dialogService.open({
                title: 'Warning',
                content: `
                            You have unsaved changes.
                            Press Cancel to go back and save these changes, 
                            or OK to lose these changes.
                        `,
                actions: [
                    { text: DialogActionService.ANSWER_OK, primary: false },
                    { text: DialogActionService.ANSWER_CANCEL, primary: true },
                ],
            });

            const sub = dialog.result.subscribe(
                (res) => {
                    resolveDialog(res);
                    sub.unsubscribe();
                },
                (error) => {
                    rejectDialog(error);
                    sub.unsubscribe();
                });
        });
        return this.dialogsPromise.then((result: any) => {
            if (!(result instanceof DialogCloseResult)) {
                if (result.text === DialogActionService.ANSWER_OK) {
                    return true;
                }
            }
            return false;
        });
    }
}

export { DialogCloseResult, DialogAction } from '@progress/kendo-angular-dialog';
