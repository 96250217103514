import { NgModule } from '@angular/core';

import { FibaFormsModule } from '@fiba/forms/forms.module';
import { SharedModule } from '../../app/shared.module';

import { FibaMultiSelectItemComponent } from '@fiba/common/forms/fiba-multiselect-item.component';
import { FibaMultiSelectZoneComponent } from '@fiba/common/forms/fiba-multiselect-zone.component';

import { FibaSelectCountryComponent } from '@fiba/common/forms/fiba-select-country.component';
import { FibaSelectCustomItemComponent } from '@fiba/common/forms/fiba-select-custom-item.component';
import { FibaSelectItemFilteredComponent } from '@fiba/common/forms/fiba-select-item-filtered.component';
import { FibaSelectItemComponent } from '@fiba/common/forms/fiba-select-item.component';
import { FibaSelectSeasonComponent } from '@fiba/common/forms/fiba-select-season.component';
import { FibaSelectThingComponent } from '@fiba/common/forms/fiba-select-thing.component';
import { FibaSelectTimezoneComponent } from '@fiba/common/forms/fiba-select-timezone.component';
import { FibaSelectZoneComponent } from '@fiba/common/forms/fiba-select-zone.component';
import { TreeviewModule as CustomTreeView } from '@fiba/common/components/custom-ngx-treeview';
import { FibaMultiSelectSanctionComponent } from '@fiba/common/forms/fiba-multiselect-sanction.component';
import { FibaSelectCompetitionSeasonComponent } from '@fiba/common/forms/fiba-select-competition-season.component';
import { FibaSelectPlayerLicenseSeasonComponent } from '@fiba/common/forms/fiba-select-player-license-season.component';
import { FibaSelectContactTypeItemComponent } from '@fiba/common/forms/fiba-select-contact-type-item.component';
import { FibaMultiSelectThingComponent } from '@fiba/common/forms/fiba-multiselect-thing.component';
import { FibaSelectFormRegistrationTemplateComponent } from '@fiba/common/forms/fiba-select-form-registration-template.component';
import { FibaSelectOrganisationComponent } from '@fiba/common/forms/fiba-select-organisation.component';
import { FibaSelectComparerComponent } from '@fiba/forms/components/fiba-select-comparer.component';
import { FibaSelectTreeViewItemComponent } from '@fiba/common/forms/fiba-select-treeview-item.component';
import { FibaSelectSanctionAdminOffenceComponent } from './forms/fiba-select-sanction-admin-offence.component';
import { FibaSelectCountryCallingPhoneComponent } from './forms/fiba-select-country-calling-phone.component';
import { FibaSelectTemplateComponent } from './forms/fiba-select-template.component';
import { FibaSelectOfficialLicencePeriodStatusComponent } from './forms/fiba-select-official-licence-period-status.component';
import { FibaMultiSelectGameOfficialRoleComponent } from './forms/fiba-multiselect-game-official-role.component';
import { FibaMultiSelectAprovalCategoryTreeViewComponent } from './forms/fiba-multiselect-approval-category-tree-view.component';

const COMPONENTS: any[] = [
    FibaSelectTimezoneComponent,
    FibaSelectCountryComponent,
    FibaSelectItemComponent,
    FibaSelectTreeViewItemComponent,
    FibaSelectCustomItemComponent,
    FibaSelectZoneComponent,
    FibaMultiSelectItemComponent,
    FibaSelectSeasonComponent,
    FibaMultiSelectGameOfficialRoleComponent,
    FibaMultiSelectZoneComponent,
    FibaSelectItemFilteredComponent,
    FibaSelectThingComponent,
    FibaMultiSelectThingComponent,
    FibaMultiSelectSanctionComponent,
    FibaSelectCompetitionSeasonComponent,
    FibaSelectPlayerLicenseSeasonComponent,
    FibaSelectContactTypeItemComponent,
    FibaSelectFormRegistrationTemplateComponent,
    FibaSelectOrganisationComponent,
    FibaSelectComparerComponent,
    FibaSelectSanctionAdminOffenceComponent,
    FibaSelectCountryCallingPhoneComponent,
    FibaSelectTemplateComponent,
    FibaSelectOfficialLicencePeriodStatusComponent,
    FibaMultiSelectAprovalCategoryTreeViewComponent
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        CustomTreeView.forRoot(),
    ],
    declarations: [
        COMPONENTS,
    ],
    exports: [
        COMPONENTS,
    ],
})
export class FibaCommonSelectModule {
}
