export class MathUtils {

    public static readonly PatternInchesEdit: RegExp = /^([1-2]?[0-9]{1,3})'[ ]*([0-9]|[0-1]{2})((")|(''))/;
    public static readonly PoundToKg: number = 0.45359237;
    /**
     * Will return the max in the array + 1, or 1 if max is less than 1 / inexistent
     *
     * @param numbers : array of integers.
     */
    public static GetNextFrom1(numbers: number[]) {
        return Math.max(0, ...numbers) + 1;
    }

    /**
     * Will return the number given as first argument if it is between a and b (included),
     * otherwise gives a or b (the nearest value from first argument)
     */
    public static clamp(num: number, a: number, b: number) {
        let min: number; let max: number;
        if (a <= b) {
            [min, max] = [a, b];
        } else {
            [min, max] = [b, a];
        }
        return Math.min(Math.max(num, min), max);
    }

    /**
     * Transforms inches into cm
     *
     * @param inches
     */
    public static inchesToCm(inches: number): number {
        return Math.round((inches / 3937.01) * 10000);
    }

    /**
     * Transforms cm into inches
     *
     * @param cm
     */
    public static cmToInches(cm: number): number {
        return Math.round(cm * 3937.01) / 10000;
    }

    /**
     * Transfors a numeric inches into a feet'inches'' string format
     *
     * @param inches
     */
    public static transformIntoFeetInches(inches: number): string {
        inches = Math.round(inches);
        const feet = Math.floor(inches / 12);
        const inch = Math.floor(inches - feet * 12);
        return String(feet).concat('\' ').concat(String(inch)).concat('"');
    }

    /**
     * Transforms an string feet'inches'' into a numeric inches value
     *
     * @param feetInches
     */
    public static transformIntoInch(feetInches: string): number {
        const result = MathUtils.PatternInchesEdit.exec(feetInches);
        if (result && result.length >= 3) {
            const feet = +result[1];
            const inches = +result[2];
            return (feet * 12) + inches;
        }
    }

    /**
     * Transforms pounds into Kilograms
     *
     * @param pounds
     */
    public static poundToKg(pounds: number, decimals: number = 1): number {
        const exp10 = 10 * decimals;
        return Math.round(pounds * MathUtils.PoundToKg * exp10) / exp10;
    }

    /**
     * Transforms kilograms into pounds
     *
     * @param kilograms
     */
    public static kgToPound(kilograms: number, decimals: number = 1): number {
        const exp10 = 10 * decimals;
        return Math.round((kilograms / MathUtils.PoundToKg) * exp10) / exp10;
    }
}
