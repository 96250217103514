import {Route} from '@angular/router';

export class RoutingUtils {
    public static setGuardsOnRouteTree(routeTree: Route, authGuardDiToken: any): void {
        const stack = [routeTree];
        while (stack.length > 0) {
            const route = stack.pop();
            if (!route.hasOwnProperty('redirectTo')) {
                route.canActivate = [authGuardDiToken];

                if (route.children) {
                    for (const childRoute of route.children) {
                        stack.push(childRoute);
                    }
                }
            }
        }
    }
}
