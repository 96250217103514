import { Injector, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { OfficialLicensePeriodStatusDTO, StatusDTO } from '@fiba/models';
import { OfficialLicenseRequestDataService } from '@fiba/data-services';
import { OfficialLicenseRequestTypes } from '../../models/constants/official-license-request-types';

@Component({
    selector: 'fibaSelectOfficialLicencePeriodStatus',
    templateUrl: '../../forms/base/fiba-select-base.component.html', providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectOfficialLicencePeriodStatusComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectOfficialLicencePeriodStatusComponent extends FibaAjaxSelectBaseComponent<StatusDTO> {

    @Input() code: string;
    @Input() isGameOfficialList: boolean = false;
    constructor(protected injector: Injector, protected dataService: OfficialLicenseRequestDataService) {
        super(injector);
        this._valueField = 'code';
        this._textField = 'name';
    }

    public getObservable(): Observable<StatusDTO[]> {
        return this.dataService.fetchOfficialLicenseRequestStatusesByType(OfficialLicenseRequestTypes.Headquarter, true, this.isGameOfficialList, this.code);
    }

    public handleFilter(event: Event): void {
        event.stopPropagation();
    }
}
