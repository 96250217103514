import { Injectable } from '@angular/core';
import { FormRegistrationStatusDTO } from '@fiba/models/dtos/generated/form-registration-status-dto';

import { Observable } from 'rxjs';
import { DataCachedService } from '../utils/data-cached.service';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';




@Injectable({
    providedIn: 'root',
})
export class FormRegistrationStatusDataService {
    protected formRegistrationBaseApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig, protected dataService: DataCachedService<FormRegistrationStatusDTO>, ) {
        this.formRegistrationBaseApiEndpoint = config.formRegistrationBaseApiEndpoint;
    }

    fetchStatuses(): Observable<FormRegistrationStatusDTO[]> {
        return this.dataService.fetchEntities(`${this.formRegistrationBaseApiEndpoint}/statuses`);
    }
}
