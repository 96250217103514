import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse } from '../../models';
import { HttpService, IHttpClientResponseType } from '../utils/http.service';
import { AppConfig } from '@app/app.config';
import { TemplateFilters } from '../../models/administration/template-filters';
import { TemplateSearchResultLineDTO } from '../../models/dtos/generated/template-search-result-line-dto';
import { TemplateAdministrationDTO } from '../../models/dtos/generated/template-administration-dto';

@Injectable()
export class TemplateAdminDataService {
    private readonly templateApiEndpoint: string;

    constructor(private http: HttpService, private config: AppConfig) {
        this.templateApiEndpoint = config.templateApiEndpoint;
    }

    public fetchTemplateSearchResults(filters: TemplateFilters): Observable<ApiListResponse<TemplateSearchResultLineDTO>> {
        return this.http.get(this.templateApiEndpoint + '/search', filters.getSearchParams());
    }

    public fetchTemplateSearchResultsForSql(filters: TemplateFilters): Observable<string> {
        filters.sqlExport = true;
        return this.http.get(this.templateApiEndpoint + '/search', filters.getSearchParams(), IHttpClientResponseType.text);
    }

    fetchTemplate(templateId: number): Observable<TemplateAdministrationDTO> {
        return this.http.get(`${this.templateApiEndpoint}` + '/' + templateId);
    }

    updateTemplate(templateId: number, templateDto: TemplateAdministrationDTO): Observable<any> {
        return this.http.put(`${this.templateApiEndpoint}` + '/' + templateId, templateDto);
    }

    createTemplate(templateDto: TemplateAdministrationDTO): Observable<TemplateAdministrationDTO> {
        return this.http.post(this.templateApiEndpoint + '/', templateDto);
    }

    public fetchTemplatesForSelector(parentsOnly: boolean = true): Observable<TemplateSearchResultLineDTO[]> {
        return this.http.get(this.templateApiEndpoint + '/all?parentsOnly=' + parentsOnly);
    }

}
