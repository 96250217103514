import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GameReportsInfoDTO, CompetitionReportsInfoDTO } from '@fiba/models';
import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';



@Injectable({
    providedIn: 'root',
})
export class ReportsInfoDataService {
    constructor(
        private config: AppConfig,
        private http: HttpService) {
    }

    public fetchGameReportsInfo(gameId: number): Observable<GameReportsInfoDTO> {
        return this.http.get(`${this.config.competitionApiEndpoint}/games/${gameId}/reports-info`);
    }

    public fetchCompetitionReportsInfo(competitionId: number, loadPotentialGroupRankingInfos: boolean = false): Observable<CompetitionReportsInfoDTO> {
        const params = {};
        params['loadPotentialGroupRankingInfos'] = loadPotentialGroupRankingInfos;
        
        return this.http.get(`${this.config.competitionApiEndpoint}/${competitionId}/reports-info`,params);
    }
}
