import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AuthService } from '@fiba/data-services';
import { FibaPopupBase } from '@fiba/forms';
import { GameStructureDTO, ThingDTO } from '@fiba/models';
import { ErrorEvent, FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from '@app/app.config';
import { DialogActionService, DialogCloseResult } from '../../utils/dialog-action.service';

@Component({
    selector: 'fibaPopupGameStructureImport',
    templateUrl: './fiba-popup-game-structure-import.component.html',
})
export class FibaPopupGameStructureImportComponent extends FibaPopupBase<ThingDTO> {

    @Output() public fileImported: EventEmitter<GameStructureDTO[]> = new EventEmitter();
    public uploadSaveUrl: string;
    public uploadSaveheaders: HttpHeaders;
    public uploadInProgress = false;
    public errorMessage: string;

    saveMethod = 'POST';
    @Input() protected groupPairingId: number;
    @Input() protected hasGames: boolean;

    private readonly competitionApiEndpoint: string;
    private accessToken: string;
    private uploadFinished = false;
    private importRoute = 'import-group-structure';

    public _restrictions: FileRestrictions = {
        maxFileSize: 10000000,
        allowedExtensions: ['xls', 'xlsx'],
    };

    constructor(
        protected injector: Injector,
        protected config: AppConfig,
        protected authService: AuthService,
        protected dialogActionService: DialogActionService) {
        super(injector);
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        authService.getUserPromise().then((user) => {
            this.accessToken = user.access_token;
        });
    }

    public open() {
        if (this.hasGames) {
            const title = 'Import Games plan';
            const message = 'A game plan already exists, are you sure you want to perform the import of a new game plan ? This action will erase existing game plan.';
            this.dialogActionService.showConfirmation(title, message).subscribe(
                (result: any) => {
                    if (!(result instanceof DialogCloseResult)) {
                        this.openInternal();
                    }
                },
            );
        } else {
            this.openInternal();
        }
    }

    private openInternal() {
        this.active = true;
        this.errorMessage = null;
        this.uploadFinished = false;
        this.uploadSaveUrl = this.competitionApiEndpoint + '/games/' + this.importRoute + '/' + this.groupPairingId;

        this.uploadSaveheaders = new HttpHeaders({
            ['Authorization']: 'Bearer ' + this.accessToken,
        });
    }

    public successEventHandler(event) {
        this.fileImported.emit(event.response.body);
        this.active = false;
    }

    public errorEventHandler(event: ErrorEvent) {
        this.errorMessage = (event.response as any).error;
    }

    public cancelEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public clearEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public completeEventHandler(event) {
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    // noinspection JSUnusedGlobalSymbols
    public uploadEventHandler(e: UploadEvent) {
        this.uploadInProgress = true;
    }

    closeForm(): void {
        if (this.uploadInProgress === false) {
            this.active = false;
            this.cancel.emit();
        }
    }
}
