import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { ApiListResponse, CompetitionOperationGameResultsDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class GameResultsDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionOperationGameResults(gameId: number): Observable<CompetitionOperationGameResultsDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/games/${gameId}/results`);
    }

    updateCompetitionOperationGameResults(model: CompetitionOperationGameResultsDTO): Observable<CompetitionOperationGameResultsDTO> {
        if (!!model?.gameId) {
            return this.http.put(`${this.competitionApiEndpoint}/games/${model.gameId}/results`, model);
        } else {
            Logger.error('update game-results: invalid model');
        }
    }

    updateGameResultStatus(gameResultId: number, gameResultStatusCode: string): Observable<any> {
        if (gameResultId && gameResultStatusCode) {
            return this.http.put(`${this.competitionApiEndpoint}/games/results/${gameResultId}/status`, gameResultStatusCode);
        } else {
            Logger.error('updateGameResultStatus: invalid id or stastus code');
        }
    }
}
