import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse, EquipmentProductApplicationListItemDTO, EquipmentProductDTO, ProductApplicationFilters } from '@fiba/models';
import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ProductApplicationDataService {
    protected equipmentProductApplicationApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentProductApplicationApiEndpoint = config.equipmentProductApplicationApiEndpoint;
    }

    public fetchList(filters: ProductApplicationFilters): Observable<ApiListResponse<EquipmentProductApplicationListItemDTO>> {
        return this.http.get(`${this.equipmentProductApplicationApiEndpoint}/list-product-application`, filters.getSearchParams());
    }
}
