import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, RoleDTO, RoleFamilies } from '@fiba/models';

import { IFileData } from '@fiba/data-services';
import { HttpService } from '@fiba/data-services/utils/http.service';
import { RoleListItemDTO } from '@fiba/models/dtos/generated/role-list-item-dto';
import { RoleFilters } from '@fiba/models/person/role';
import { DataCachedService } from '../utils/data-cached.service';
import {timeout} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class RoleDataService {
    protected roleApiEndpoint: string;
    protected roleFamilyApiEndpoint: string;

    constructor(
        protected dataService: DataCachedService<RoleDTO>,
        protected config: AppConfig,
        protected http: HttpService) {
        this.roleApiEndpoint = config.roleApiEndpoint;
        this.roleFamilyApiEndpoint = config.roleFamilyApiEndpoint;
    }

    public fetchList(filters: RoleFilters, context: string = null): Observable<ApiListResponse<RoleListItemDTO>> {
        return this.http.get(`${this.roleApiEndpoint}/list-role`, filters.getSearchParams());
    }

    public fetchRole(roleId: number): Observable<RoleDTO> {
        return this.http.get(`${this.roleApiEndpoint}/${roleId}`);
    }

    public fetchLicensableRoles(specificPersonId: number): Observable<RoleDTO[]> {
        let params = {};
        if (specificPersonId) {
            params = { specificPersonId };
        }
        return this.http.get(`${this.roleApiEndpoint}/licensable`, params);
    }

    public fetchRoles(): Observable<RoleDTO[]> {
        return this.dataService.fetchEntities(this.roleApiEndpoint, 'roles');
    }

    public fetchTreeViewRoles(): Observable<RoleDTO[]> {
        return this.dataService.fetchEntities(`${this.roleApiEndpoint}/treeview`, 'treeview');
    }

    public fetchPlayerRole(): Observable<RoleDTO[]> {
        return this.dataService.fetchEntities(`${this.roleApiEndpoint}/with-family-code/${RoleFamilies.Player}`, 'PlayerRole');
    }

    public fetchBasketballRole(): Observable<RoleDTO[]> {
        return this.dataService.fetchEntities(`${this.roleApiEndpoint}/with-family-code/${RoleFamilies.Basketball}`, 'BasketRole');
    }

    public downloadExport(filters: RoleFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.roleApiEndpoint}/export`, filters.getSearchParams()).pipe(timeout(300000)); // TODO: use constant or conf value
    }

    public createRoleInformation(model: RoleDTO): Observable<RoleDTO> {
        return this.http.post(`${this.roleApiEndpoint}/role-information`, model);
    }

    public updateRoleInformation(model: RoleDTO): Observable<any> {
        return this.http.put(`${this.roleApiEndpoint}/${model.roleId}/role-information`, model);
    }

    public deleteRole(roleId: number): Observable<any> {
        return this.http.delete(`${this.roleApiEndpoint}/role-information/${roleId}`);
    }
}
