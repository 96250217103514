import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationRoleGameAssignmentRoleDTO } from '@fiba/models';

import { DataCachedService } from '../../utils/data-cached.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionConfigurationRoleGameAssignmentRoleDataService {
    protected competitionApiEndpoint: string;

    constructor(
        protected dataService: DataCachedService<ConfigurationRoleGameAssignmentRoleDTO>,
        protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    public fetchConfigurationRoleGameAssignmentRoles(): Observable<ConfigurationRoleGameAssignmentRoleDTO[]> {
        return this.dataService.fetchEntities(this.competitionApiEndpoint + '/nominations/game-role-relationships', 'game-role-relationship');
    }

    public fetchConfigurationRoleGameAssignmentRolesNf(): Observable<ConfigurationRoleGameAssignmentRoleDTO[]> {
        return this.dataService.fetchEntities(this.competitionApiEndpoint + '/nominations/game-role-relationships-nf', 'game-role-relationship');
    }

}
