import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, ThingDTO, InsurancePolicyEditDTO, InsurancePolicyListItemDTO, InsurancePolicyStatusDTO, InsurancePolicyFilters, InsurancePolicyInformationDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import { DataCachedService } from '../../utils/data-cached.service';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class InsurancePolicyDataService {
    private readonly insurancePolicyApiEndpoint: string;
    private readonly insurancePolicyStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataInsurancePolicyStatusService: DataCachedService<InsurancePolicyStatusDTO>,) {
        this.insurancePolicyApiEndpoint = config.insurancePolicyApiEndpoint;
        this.insurancePolicyStatusApiEndpoint = config.insurancePolicyStatusApiEndpoint;
    }

    fetchInsurancePolicyInformation(insurancePolicyId: number): Observable<InsurancePolicyInformationDTO> {
        return this.http.get(`${this.insurancePolicyApiEndpoint}/${insurancePolicyId}`);
    }

    fetchList(filters: InsurancePolicyFilters): Observable<ApiListResponse<InsurancePolicyListItemDTO>> {
        return this.http.get(this.insurancePolicyApiEndpoint, filters.getSearchParams());
    }

    fetchStatuses(): Observable<InsurancePolicyStatusDTO[]> {
        return this.dataInsurancePolicyStatusService.fetchEntities(this.insurancePolicyStatusApiEndpoint);
    }

    createInsurancePolicyInformation(model: InsurancePolicyInformationDTO): Observable<InsurancePolicyEditDTO> {
        return this.http.post(`${this.insurancePolicyApiEndpoint}`, model);
    }

    updateInsurancePolicyInformation(model: InsurancePolicyInformationDTO): Observable<any> {
        return this.http.put(`${this.insurancePolicyApiEndpoint}/${model.insurancePolicyId}`, model);
    }


    deleteInsurancePolicy(insurancePolicyId: number): Observable<any> {
        return this.http.delete(`${this.insurancePolicyApiEndpoint}/${insurancePolicyId}`);
    }

}
