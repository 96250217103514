import { Injector, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import { City, ClubFilters, ClubListItemDTO, ItemCategoryCodes } from '@fiba/models';

import { ClubDataService } from '@fiba/data-services';
import { FibaLoadingService } from '@fiba/loading';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';
import { CartListItemDTO } from '../../../models/dtos/generated/cart-list-item-dto';

@Directive()
export class ClubSearchBaseComponent implements OnInit {
    public readonly ItemCategoryCodes_CompetitionDiscipline = ItemCategoryCodes.CompetitionDiscipline;
    public readonly ItemCategoryCodes_ClubGender = ItemCategoryCodes.ClubGender;
    public readonly ItemCategoryCodes_CompetitionAgeCategory = ItemCategoryCodes.CompetitionAgeCategory;

    public filters: ClubFilters;
    public sort: SortDescriptor[] = [];
    public isLoading: boolean;
    public clubs: GridDataResult;
    public cityName: City;
    public showAdvanced = false;

    protected fibaLoadingService: FibaLoadingService;
    protected fetchSub: Subscription;
    protected currentClubListItem: ClubListItemDTO = null;
    protected router: Router;

    public _model: CartListItemDTO[];
    public isNew = false;
    public editDataItem: CartListItemDTO;
    protected editRowIndex: number;

    constructor(
        protected injector: Injector,
        protected dataService: ClubDataService,
        protected notificationService: NotificationService,
        protected simpleStorageService: SimpleStorageService,
        protected pageSize: number,
        protected filterStorageKey: string = null) {

        this.fibaLoadingService = injector.get(FibaLoadingService);

        const savedFilters = (filterStorageKey ? this.simpleStorageService.get(filterStorageKey) : null);
        if (savedFilters) {
            this.filters = savedFilters.clone();
        } else {
            this.filters = new ClubFilters(this.pageSize);
        }

        this.router = injector.get(Router);
    }

    public ngOnInit(): void {
        this.fetchList();
    }

    protected fetchList(displayLoading: boolean = true): void {
        this.isLoading = displayLoading;
        this.fibaLoadingService.show(800, 'Loading');
        if (this.fetchSub) {
            this.fetchSub.unsubscribe();
        }
        this.fetchSub = this.dataService.fetchList(this.filters).subscribe(
            (val) => {
                this.clubs = val;
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
                Logger.error(error);
            },
            () => {
                this.onFetchListCompleted();
            },
        );
    }

    protected onFetchListCompleted(): void {
        this.isLoading = false;
        this.fibaLoadingService.hide();
    }

    public reset(): void {
        this.filters.reset();
        if (this.filterStorageKey) {
            this.simpleStorageService.delete(this.filterStorageKey);
        }
    }

    public filter(): void {
        this.filters.skip = 0;
        this.fetchList(true);
        if (this.filterStorageKey) {
            this.simpleStorageService.set(this.filterStorageKey, this.filters.clone());
        }
    }

    // Grid events
    public handlePageChange(event: PageChangeEvent): void {
        this.filters.skip = event.skip;
        this.fetchList(false);
    }

    public handleSortChange(sort: SortDescriptor[]): void {
        Logger.debug(sort);
        this.sort = sort;
        this.filters.skip = 0;
        this.filters.sortBy = sort[0].field;
        this.filters.sortOrder = sort[0].dir;
        this.fetchList();
    }

    public handleSelChange(event: SelectionEvent): void {
        const firstSelectedRowDataItem = event?.selectedRows?.[0]?.dataItem;

        this.currentClubListItem = firstSelectedRowDataItem || null;
    }

    public handleSelAndCellChange(dataItem: any): void {
        this.currentClubListItem = dataItem;
    }

    public addCartHandler(): void {
        if (!this._model) {
            this._model = [];
        }
        this.editDataItem = {} as CartListItemDTO;
        this.isNew = true;
        this.editRowIndex = this._model.length;
    }
}
