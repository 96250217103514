import {UserManagerSettings} from 'oidc-client';

export enum Env {
    DEV,
    PROD,
}

export abstract class AppConfig {
    public readonly env: Env;
    constructor(public readonly name = '') {
        this.name = name;
    }

    // Authentication
    public readonly oidcSettings: UserManagerSettings;

    // URLs and API endpoints
    readonly agentApiEndpoint: string;
    readonly agentRelationsApiEndpoint: string;
    readonly agentRelationSharesApiEndpoint: string;
    readonly approvalCategoryApiEndpoint: string;
    readonly azureFileApiEndpoint: string;
    readonly azureFilesCompetitionsDirectory: string;
    readonly azureFilesDefaultFileShare: string;
    readonly azureFilesDefaultParentDirectory: string;
    readonly azureFilesSponsorStripsDirectory: string;
    readonly alfrescoBaseContentUrl: string;
    readonly azureFilesFormRegistrationTemplateDirectory: string;
    readonly basePublicUrl: string;
    readonly baseUrl: string;
    readonly basketballLicenseApiEndpoint: string;
    readonly basketballLicenseStatusApiEndpoint: string;
    readonly basketballPersonsApiEndpoint: string;
    readonly cartApiEndpoint: string;
    readonly clubApiEndpoint: string;
    readonly clubAutocompleteApiEndpoint: string;
    readonly clubStatusApiEndpoint: string;
    readonly coachApiEndpoint: string;
    readonly coachAutocompleteApiEndpoint: string;
    readonly coachAutocompleteApiV2Endpoint: string;
    readonly coachLicenseApiEndpoint: string;
    readonly coachLicenseRequestApiEndpoint: string;
    readonly competitionApiEndpoint: string;
    readonly competitionAutocompleteApiEndpoint: string;
    readonly competitionStatusApiEndpoint: string;
    readonly competitionTeamDocumentStatusApiEndpoint: string;
    readonly competitionTeamFormRegistrationApiEndpoint: string;
    readonly competitionTeamStatusApiEndpoint: string;
    readonly competitionStandingHeadersApiEndpoint: string;
    readonly configurationContactEmailCategoryApiEndpoint: string;
    readonly countryApiEndpoint: string;
    readonly currentUserAccessApiEndpoint: string;
    readonly currentUserAuthorisationApiEndpoint: string;
    readonly currentUserOrganisationApiEndpoint: string;
    readonly documentApiEndpoint: string;
    readonly azureDocumentApiEndpoint: string;
    readonly equipmentApiEndpoint: string;
    readonly equipmentAgreementApiEndpoint: string;
    readonly equipmentDashboardApiEndpoint: string;
    readonly equipmentFibaPartnerApiEndpoint: string;
    readonly equipmentFormRegistrationApiEndpoint: string;
    readonly equipmentPartnerApplicationApiEndpoint: string;
    readonly equipmentPaymentApiEndpoint: string;
    readonly equipmentProductApiEndpoint: string;
    readonly equipmentProductApplicationApiEndpoint: string;
    readonly equipmentTestResultApplicationApiEndpoint: string;
    readonly equipmentTestInstituteApiEndpoint: string;
    readonly eventApiEndpoint: string;
    readonly feeApiEndpoint: string;
    readonly finalRosterMemberStatusApiEndpoint: string;
    readonly finalRosterStatusApiEndpoint: string;
    readonly financialReportingApiEndpoint: string;
    readonly financialDataPackageApiEndpoint: string;
    readonly formRegistrationBaseApiEndpoint: string;
    readonly formRegistrationTemplateApiEndpoint: string;
    readonly formRegistrationSiteVisitApiEndpoint: string;
    readonly gameInformationFormRegistrationApiEndpoint: string;
    readonly gameOfficialApiEndpoint: string;
    readonly gameOfficialAutocompleteApiEndpoint: string;
    readonly insurancePolicyStatusApiEndpoint: string;
    readonly insurancePolicyApiEndpoint: string;
    readonly integratedReportingApiEndpoint: string;
    readonly itemApiEndpoint: string;
    readonly itemCategoriesApiEndpoint: string;
    readonly licenseRequestApiEndpoint: string;
    readonly milestoneConfigurationApiEndpoint: string;
    readonly nationalFederationApiEndpoint: string;
    readonly nationalFederationAutocompleteApiEndpoint: string;
    readonly nationalFederationMembershipStatusApiEndpoint: string;
    readonly notificationApiEndpoint: string;
    readonly nfDashboardCompetitionListApiEndpoint: string;
    readonly officialLicenseApiEndpoint: string;
    readonly officialLicensePeriodApiEndpoint: string;
    readonly officialLicenseRegistrationApiEndpoint: string;
    readonly officialRegistrationApiEndpoint: string;
    readonly organisationAgenciesApiEndpoint: string;
    readonly organisationApiEndpoint: string;
    readonly organisationAutocompleteApiEndpoint: string;
    readonly organisationStatusApiEndpoint: string;
    readonly paymentApiEndpoint: string;
    readonly personApiEndpoint: string;
    readonly personAutocompleteApiEndpoint: string;
    // Removed for PBI #12259
    //readonly personAutocompleteApiV2Endpoint: string;
    readonly personRoleApiEndpoint: string;
    readonly personStatusApiEndpoint: string;
    readonly playerApiEndpoint: string;
    readonly playerLicenseApiEndpoint: string;
    readonly playerLicenseStatusApiEndpoint: string;
    readonly playerLicenseSummaryApiEndpoint: string;
    readonly playerReleaseApiEndpoint: string;
    readonly policiesApiEndpoint: string;
    readonly publicDocumentApiEndpoint: string;
    readonly rankingApiEndpoint: string;
    readonly reportingApiEndpoint: string;
    readonly reportingImageApiEndpoint: string;
    readonly roleApiEndpoint: string;
    readonly roleFamilyApiEndpoint: string;
    readonly roleTypeApiEndpoint: string;
    readonly sanctionApiEndpoint: string;
    readonly sanctionApplicationApiEndpoint: string;
    readonly tdDashboardCompetitionListApiEndpoint: string;
    readonly technicalDelegateReportingFormRegistrationApiEndpoint: string;
    readonly technicalMeetingStatusApiEndpoint: string;
    readonly templateApiEndpoint: string;
    readonly termsAndConditionsApiEndpoint: string;
    readonly transferRequestApiEndpoint: string;
    readonly userApiEndpoint: string;
    readonly userProfileApiEndpoint: string;
    readonly userRolesApiEndpoint: string;
    readonly userStatusApiEndpoint: string;
    readonly venueApiEndpoint: string;
    readonly venueAutocompleteApiEndpoint: string;
    readonly venueFormRegistrationApiEndpoint: string;
    readonly venueStatusApiEndpoint: string;
    readonly wabcMemberApiEndpoint: string;
    readonly wabcCourseApiEndpoint: string;
    readonly webCISUrl: string;
    readonly lsiUrl: string;
    readonly zoneApiEndpoint: string; 
    
}
