import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService, TermsAndConditionsDataService} from '@fiba/data-services';
import {TermsAndConditionsDTO} from '@fiba/models';
import {NotificationService, NotificationType} from '@fiba/utils/notification.service';

@Component({
    selector: 'termsAndConditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css'],
})
export class TermsAndConditionsComponent implements OnInit {
    public termsAndConditions: TermsAndConditionsDTO = undefined;
    public acceptRequestRunning = false;

    constructor(
        protected authService: AuthService,
        protected router: Router,
        protected dataService: TermsAndConditionsDataService,
        protected notificationService: NotificationService) {
    }

    public ngOnInit(): void {
        const tcSub = this.dataService.fetchCurrentTermsAndConditions().subscribe(
            (termsAndConditions) => {
                this.termsAndConditions = termsAndConditions;
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
            },
            () => {
                tcSub.unsubscribe();
            },
        );
    }

    acceptTermsAndConditions(): void {
        if (!this.termsAndConditions || this.acceptRequestRunning) {
            return;
        }

        this.acceptRequestRunning = true;

        const tcSub = this.dataService.acceptTermsAndConditions(this.termsAndConditions.termsAndConditionsId).subscribe(
            (response) => {
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);
                this.acceptRequestRunning = false;
            },
            () => {
                tcSub.unsubscribe();
                window.location.href = '/';
            },
        );
    }
}
