import { Injector, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { FibaSelectBaseComponent } from '@fiba/forms';
import { Season } from '@fiba/models/common/season';


@Component({
    selector: 'fibaCheckEqual',
    templateUrl: './fiba-check-equal.component.html',
})
export class FibaCheckEqualComponent {
    public similar = true;
    @Input() protected originalValue: any;
    @Input() protected isDate = false;

    protected _newValue: any;
    @Input() protected set newValue(value: any) {
        this._newValue = value;

        if (this.isDate) {
            if ((this._newValue && !this.originalValue) || (!this._newValue && this.originalValue)) {
                this.similar = false;
            } else if (!this.originalValue && !this._newValue) {
                this.similar = true;
            } else {
                const newDate = new Date(this._newValue);
                const oldDate = new Date(this.originalValue);

                this.similar = oldDate.getDate() === newDate.getDate()
                    && oldDate.getMonth() === newDate.getMonth()
                    && oldDate.getFullYear() === newDate.getFullYear();
            }
        } else {
            this.similar = this._newValue === this.originalValue;
        }
    }
}
