import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApiListResponse,
    PaymentListItemDTO,
    PaymentDTO,
    PaymentFilters,
    PaymentStatusDTO,

    PaymentForwardDTO
} from '@fiba/models';
import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';
import { PaymentTermsAndConditionsDTO, PaymentAssertDTO, PaymentInitializeDTO } from '@fiba/models';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class PaymentDataService {
    protected paymentApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataPaymentStatusService: DataCachedService<PaymentStatusDTO>) {
        this.paymentApiEndpoint = config.paymentApiEndpoint;
    }

    public fetchList(filters: PaymentFilters): Observable<ApiListResponse<PaymentListItemDTO>> {
        return this.http.get(this.paymentApiEndpoint, filters.getSearchParams());
    }

    public fetchPayment(paymentId: number): Observable<PaymentDTO> {
        return this.http.get(`${this.paymentApiEndpoint}/${paymentId}`);
    }
    public fetchPaymentManual(paymentId: number): Observable<PaymentDTO> {
        return this.http.get(`${this.paymentApiEndpoint}/${paymentId}/manual`);
    }

    public createPayment(model: PaymentDTO): Observable<PaymentDTO> {
        return this.http.post(`${this.paymentApiEndpoint}`, model);
    }

    public updatePayment(model: PaymentDTO): Observable<PaymentDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${model.paymentId}`, model);
    }

    public createAndSendPayment(model: PaymentDTO): Observable<PaymentDTO> {
        return this.http.post(`${this.paymentApiEndpoint}/send`, model);
    }

    public updateAndSendPayment(model: PaymentDTO): Observable<PaymentDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${model.paymentId}/send`, model);
    }

    public fetchStatuses(): Observable<PaymentStatusDTO[]> {
        return this.dataPaymentStatusService.fetchEntities(`${this.paymentApiEndpoint}/statuses`);
    }

    public fetchPaymentReceipt(paymentId: number, transactionId: number): Observable<any> {
        return this.http.getFileData(`${this.paymentApiEndpoint}/${paymentId}/transactions/${transactionId}/receipt`);
    }
    public fetchPaymentTermsAndConditions(paymentId: number): Observable<PaymentTermsAndConditionsDTO> {
        return this.http.get(`${this.paymentApiEndpoint}/${paymentId}/terms-and-conditions`);
    }

    public assertPayment(requestId: string, state: string): Observable<PaymentAssertDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${requestId}/${state}`);
    }

    public initializePayment(paymentId: number): Observable<PaymentInitializeDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${paymentId}/initialize`);
    }

    public forwardPayment(paymentId: number, model: PaymentForwardDTO): Observable<any> {
        return this.http.put(`${this.paymentApiEndpoint}/${paymentId}/forward`, model);
    }

    public fetchVatrate(processTypeItemId: number, businessEntityId: number): Observable<number> {
        return this.http.get(`${this.paymentApiEndpoint}/processTypes/${processTypeItemId}/vatrate/${businessEntityId}`);
    }
}
