import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@fiba/data-services';
import {AuthHandlerComponent} from '../auth-handler.component';

const routes: Routes = [
    {
        path: '',
        component: AuthHandlerComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthHandlerRoutingModule {
}
