import { Injectable } from '@angular/core';
import { ItemDTO } from '@fiba/models';
import { Observable } from 'rxjs';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';


@Injectable({
    providedIn: 'root',
})
export class ConfigurationContactCategoryDataService {
    protected apiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.apiEndpoint = config.configurationContactEmailCategoryApiEndpoint;
    }

    fetchCategoryItems(contactEntityItemCode: string, contactTypeItemCode: string,): Observable<ItemDTO[]> {
        return this.http.get(`${this.apiEndpoint}/contact-entity/${contactEntityItemCode}/category-items?contactTypeItemCode=${contactTypeItemCode}`);
    }
}
