<div [class.site-banner-border-title-red]="optionStyles && optionStyles.style.color=='red'"
     [class.site-banner-border-title-blue]="optionStyles && optionStyles.style.color=='blue'"
     [class.site-banner-border-title-green]="optionStyles && optionStyles.style.color=='green'"
     [class.site-banner-border-title-pink]="optionStyles && optionStyles.style.color=='pink'"
     [class.site-banner-border-title-yellow]="optionStyles && optionStyles.style.color=='yellow'"
     class="top-menu anim02 ">

    <a [routerLink]="''" class="site-title" title="FIBA Management and Administration Platform">
        FIBA Management and
        Administration Platform
    </a>

    <div *ngIf="optionStyles && optionStyles.name" class="site-banner-title">
        <span>{{ optionStyles.caption }}</span>
    </div>

    <div *ngIf="userInfo && userInfo.shouldShowOrganisationInfo" class="organisation-header">
        <img *ngIf="userInfo.organisationCountryLogoUrl" [src]="userInfo.organisationCountryLogoUrl"
             class="flag-custom">

        <span class="country-name">{{ userInfo.organisationCountryName }}</span>, <span class="organisation-name">{{ userInfo.organisationName }}</span>
    </div>
    <div *ngIf="user && userInfo" class="log-user">
        <userDropdownMenu [userInfo]="userInfo" [user]="user"></userDropdownMenu>
        <span (click)="logout()" class="logout clickable"><i class="fa fa-lg fa-sign-out"></i></span>
    </div>
</div>
