import { Injectable } from '@angular/core';

import { HttpService } from '../utils/http.service';
import { Observable } from 'rxjs';
import { ApiListResponse, FormRegistrationVenueDetailDTO, ItemDTO } from '@fiba/models';
import { FormRegistrationVenueFilters } from '@fiba/models/form-registration-venue/form-registration-venue';
import { FormRegistrationVenueListItemDTO } from '@fiba/models/dtos/generated/form-registration-venue-list-item-dto';
import { FormRegistrationAction } from '@fiba/models/constants/generated/form-registration-action';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/app.config';
import { FormRegistrationEvaluationDTO } from '../../models/dtos/generated/form-registration-evaluation-dto';


@Injectable({
    providedIn: 'root',
})
export class VenueFormRegistrationDataService {
    protected venueFormRegistrationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.venueFormRegistrationApiEndpoint = config.venueFormRegistrationApiEndpoint;
    }

    fetchList(filters: FormRegistrationVenueFilters): Observable<ApiListResponse<FormRegistrationVenueListItemDTO>> {
        return this.http.get(this.venueFormRegistrationApiEndpoint, filters.getSearchParams()).pipe(map(
            response => response as ApiListResponse<FormRegistrationVenueListItemDTO>));
    }

    fetchVenueFormRegistration(venueFormRegistrationId: number): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.get(this.venueFormRegistrationApiEndpoint + '/' + venueFormRegistrationId);
    }

    fetchInitialVersion(entityItemId?: number, venueId?: number): Observable<FormRegistrationVenueDetailDTO> {
        const params = {};
        if (entityItemId) {
            params['entityItemId'] = entityItemId;
        }
        if (venueId) {
            params['venueId'] = venueId;
        }
        return this.http.get(this.venueFormRegistrationApiEndpoint + '/initial-version', params);
    }

    createVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.post(this.venueFormRegistrationApiEndpoint, model);
    }

    createSubmitVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.post(this.venueFormRegistrationApiEndpoint + '?action=' + FormRegistrationAction.Submit, model);
    }

    updateVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId, model);
    }

    submitVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Submit, model);
    }

    approveVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Approve, model);
    }

    rejectVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Rejected, model);
    }

    requestInformationVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.PendingUpdates, model);
    }

    cancelVenueFormRegistration(model: FormRegistrationVenueDetailDTO): Observable<FormRegistrationVenueDetailDTO> {
        return this.http.put(this.venueFormRegistrationApiEndpoint + '/' + model.formRegistrationId + '?action=' + FormRegistrationAction.Cancel, model);
    }

    evaluateVenueFormRegistration(formRegistrationId: number): Observable<FormRegistrationEvaluationDTO> {
        return this.http.get(this.venueFormRegistrationApiEndpoint + '/' + formRegistrationId + '/evaluation');
    }

}
