
export abstract class UserPasswordRule {

    protected _message: string;

    abstract isValid(password: string): boolean;

    getMessage(): string{
        return this._message;
    }

    setMessage(value: string): void {
        this._message = value;
    }
}

/**** Password Rules ****/

/**
 *
 * @returns
 */
export class MustHaveLength extends UserPasswordRule {
    private readonly _minPasswordLength: number ;

    constructor(minLenght: number) {
        super();
        this._minPasswordLength = minLenght;
        this._message = 'be ' + this._minPasswordLength + ' characters long.';
    }

    isValid(password: string): boolean {
        return password && password.length >= this._minPasswordLength;

    }
}

export class MustHaveOneUppercaseCharacter extends UserPasswordRule {

    constructor() {
        super();
        this._message = 'one uppercase character';
    }

    isValid(password: string): boolean {
        return /[A-Z]/.test(password);
    }
}

export class MustHaveOneLowercaseCharacter extends UserPasswordRule {

    constructor() {
        super();
        this._message = 'one lowercase character';
    }

    isValid(password: string): boolean {
        return /[a-z]/.test(password);
    }
}

export class MustHaveOneNonAlphabeticalCharacter extends UserPasswordRule {

    constructor() {
        super();
        this._message = 'one non-alphabetical character';
    }

    isValid(password: string): boolean {
        return /[^a-zA-Z]/.test(password);
    }
}

export class MustHaveTwoNonAlphabeticalCharacters extends UserPasswordRule {
    constructor() {
        super();
        this._message = 'contains two non-alphabetical character';
    }

    isValid(password: string): boolean {
        if (!password) {
            return false;
        }
        const matches = password.match(/[^a-zA-Z]/g);
        return matches && matches.length >= 2;
    }
}

export class MustNotHaveFirstCharactersUppercase extends UserPasswordRule {
    constructor() {
        super();
        this._message = 'not have the first character uppercase';
    }

    isValid(password: string): boolean {
        return !/^[A-Z]+/.test(password);
    }
}

export class MustHaveNonAlphanumericCharacters extends UserPasswordRule {
    constructor() {
        super();
        this._message = 'contains a special character (neither alphabetical nor numeric).';
    }

    isValid(password: string): boolean {
        return !/^[A-Za-z0-9]+$/.test(password);
    }
}

export class MustNotHaveEqualConsecutiveCharacters extends UserPasswordRule {
    constructor() {
        super();
        this._message = 'not contain consecutive equal characters.';
    }

    isValid(password: string): boolean {
        return !/(.)\1/.test(password);
    }
}

export class RuleResult {
    protected _rule: UserPasswordRule;
    protected _isValid: boolean;
    protected _executed: boolean;

    constructor(rule: UserPasswordRule, isValid: boolean, _executed: boolean) {
        this._rule = rule;
        this._isValid = isValid;
        this._rule = rule;
    }

    getMessage(): string {
        return this._rule.getMessage();
    }

    get isValid(): boolean {
        return this._isValid;
    }
}

export class RulesSet {
    protected _rules: UserPasswordRule[] = [];
    protected _rulesResult: RuleResult[] = [];
    protected _name: string;
    protected _description: string;

    constructor(name: string, description: string, ...args: UserPasswordRule[]) {
        let i = 0; let arg;
        for (; arg = args[i]; i++) {
            this._rules.push(args[i]);
        }
        this._name = name;
        this._description = description;
    }

    get Name(): string {
        return this._name;
    }

    get Description(): string {
        return this._description;
    }

    get Rules(): UserPasswordRule[] {
        return this._rules;
    }

    get RulesResult(): RuleResult[] {
        return this._rulesResult;
    }

    CleanRulesResults(): void {
        this._rulesResult = [];
    }

    CheckRules(password: string): RuleResult[] {
        this._rulesResult = [];
        for (const rule of this._rules) {
            this._rulesResult.push(new RuleResult(rule, rule.isValid(password), true));
        }
        return this._rulesResult;
    }
}


export class PasswordValidationRules {
    protected _mustHaveRules: RulesSet = new RulesSet(
        'Password Rules',
        'Password must contain at least:',
        new MustHaveLength(8),
        new MustHaveOneUppercaseCharacter(),
        new MustHaveOneLowercaseCharacter(),
        new MustHaveOneNonAlphabeticalCharacter()
    );
    protected _niceToHaveRules: RulesSet = new RulesSet(
        'Password Strength Tips',
        'Strong passwords should:',
        new MustHaveLength(10),
        new MustHaveTwoNonAlphabeticalCharacters(),
        new MustNotHaveFirstCharactersUppercase(),
        new MustHaveNonAlphanumericCharacters(),
        new MustNotHaveEqualConsecutiveCharacters(),
    );

    CheckRules(password: string): void {
        this._mustHaveRules.CheckRules(password);
        this._niceToHaveRules.CheckRules(password);
    }

    public CleanRuleRules(): void {
        this._mustHaveRules.CleanRulesResults();
        this._niceToHaveRules.CleanRulesResults();
    }

    public get MustHaveRules(): RulesSet {
        return this._mustHaveRules;
    }

    public get NiceToHaveRules(): RulesSet {
        return this._niceToHaveRules;
    }

    public get TotalMustHaveRules(): number {
        return this._mustHaveRules.Rules.length;
    }

    public get TotalNiceToRules(): number {
        return this._niceToHaveRules.Rules.length;
    }

    public get TotalRules(): number {
        return this.TotalMustHaveRules + this.TotalNiceToRules;
    }

    public get TotalValidRules(): number {
        const validMustRules = this.TotalValidMustHaveRules;
        if (validMustRules < this.TotalMustHaveRules) {
            return validMustRules;
        }
        return validMustRules + this.TotalValidNiceToHaveRules;
    }

    public get TotalValidMustHaveRules(): number {
        return this._mustHaveRules.RulesResult.filter((x) => x.isValid).length;
    }

    public get TotalValidNiceToHaveRules(): number {
        return this._niceToHaveRules.RulesResult.filter((x) => x.isValid).length;
    }
}
