/* eslint-disable no-console */

// For ease of use, this class is not intended to be injected as a standard ng service
export class Logger {
    public static readonly ALL = 999;
    public static readonly DEBUG = 40;
    public static readonly INFO = 30;
    public static readonly WARNING = 20;
    public static readonly ERROR = 10;
    public static readonly NONE = 0;

    private static logLevel = Logger.ALL;

    public static setLogLevel(logLevel: number): void {
        Logger.logLevel = logLevel;
    }

    public static getLogLevel(): number {
        return Logger.logLevel;
    }

    public static debug(...args: any[]): void {
        if (Logger.logLevel >= Logger.DEBUG) {
            console.debug(...args);
        }
    }

    public static info(...args: any[]): void {
        if (Logger.logLevel >= Logger.INFO) {
            console.info(...args);
        }
    }

    public static warn(...args: any[]): void {
        if (Logger.logLevel >= Logger.WARNING) {
            console.warn(...args);
        }
    }

    public static error(...args: any[]): void {
        if (Logger.logLevel >= Logger.ERROR) {
            return console.error(...args);
        }
    }
}
