import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nullReplace' })
export class NullReplacePipe implements PipeTransform {
    transform(value: any, replaceBy: string): string {
        if (!!value) {
            return value.toString();
        }
        return !!replaceBy ? replaceBy : '';
    }
}
