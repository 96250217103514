import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared.module';
import { FibaCommonModule } from '@fiba/common';
import { FibaCommonSelectModule } from '@fiba/common/fiba-common-select.module';
import { FibaFormsModule } from '@fiba/forms';

import { EventSearchComponent } from '@fiba/event/event-search/event-search.component';
import { FibaMultiSelectEventStatusComponent } from '@fiba/event/fiba-multiselect-event-status/fiba-multiselect-event-status.component';

const EXTERNAL_COMPONENTS = [
    EventSearchComponent,
];

const INTERNAL_COMPONENTS = [
    FibaMultiSelectEventStatusComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaCommonSelectModule,
        FibaFormsModule,
        FibaCommonModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class EventSearchModule {
}
