<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<div>
    <kendo-dropdowntree kendoDropDownTreeExpandable
                        id="{{name}}"
                        [placeholder]="placeholder"
                        [(ngModel)]="value"
                        [kendoDropDownTreeHierarchyBinding]="_filteredItems"
                        [disabled]="disabled"
                        [textField]="_textField"
                        [valueField]="_valueField"
                        [childrenField]="_childrenField"
                        [valuePrimitive]="valuePrimitive"
                        (filterChange)="handleFilter($event)"
                        (valueChange)="handleValue($event)"
                        (close)="handleClose($event)"
                        class="fiba-select">
        {{_textField}}
    </kendo-dropdowntree>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
