import { Component, forwardRef, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ThingDTO } from "../../models";
import { FibaSelectBaseComponent } from "../base/fiba-select-base.component";


@Component({
    selector: 'fibaSelectComparer',
    templateUrl: '../base/fiba-select-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectComparerComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaSelectComparerComponent extends FibaSelectBaseComponent<ThingDTO> {
    constructor(protected injector: Injector) {
        super(injector);
        this._valueField = 'value';
        this._textField = 'name';
        this.valuePrimitive = true;
        this._originalItems = [
            <ThingDTO>{ value: '<', name: '<' },
            <ThingDTO>{ value: '>', name: '>' },
            <ThingDTO>{ value: '=', name: '=' }];
        this._filteredItems = this._originalItems;
    }
}
