import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';

export enum Direction { UNKNOWN, NEXT, PREV }

@Component({
    selector: 'fibaCarousel',
    templateUrl: './fiba-carousel.component.html',
    styleUrls: ['./fiba-carousel.component.css'],
    animations: [
        trigger('slide', [
            state('false', style({ marginLeft: '0px', marginRight: '0px' })),
            state('next1-100', style({ marginLeft: '-120px', marginRight: '120px' })),
            state('next2-100', style({ marginLeft: '-240px', marginRight: '240px' })),
            state('next3-100', style({ marginLeft: '-360px', marginRight: '360px' })),
            state('next4-100', style({ marginLeft: '-480px', marginRight: '480px' })),
            state('next5-100', style({ marginLeft: '-600px', marginRight: '600px' })),
            state('next6-100', style({ marginLeft: '-720px', marginRight: '720px' })),
            state('next7-100', style({ marginLeft: '-840px', marginRight: '840px' })),
            state('next8-100', style({ marginLeft: '-960px', marginRight: '960px' })),
            state('next9-100', style({ marginLeft: '-1080px', marginRight: '1080px' })),
            state('prev1-100', style({ marginLeft: '+120px', marginRight: '-120px' })),
            state('prev2-100', style({ marginLeft: '+240px', marginRight: '-240px' })),
            state('prev3-100', style({ marginLeft: '+360px', marginRight: '-360px' })),
            state('prev4-100', style({ marginLeft: '+480px', marginRight: '-480px' })),
            state('prev5-100', style({ marginLeft: '+600px', marginRight: '-600px' })),
            state('prev6-100', style({ marginLeft: '+720px', marginRight: '-720px' })),
            state('prev7-100', style({ marginLeft: '+840px', marginRight: '-840px' })),
            state('prev8-100', style({ marginLeft: '+960px', marginRight: '-960px' })),
            state('prev9-100', style({ marginLeft: '+1080px', marginRight: '-1080px' })),
            state('next1-200', style({ marginLeft: '-220px', marginRight: '220px' })),
            state('next2-200', style({ marginLeft: '-440px', marginRight: '440px' })),
            state('next3-200', style({ marginLeft: '-660px', marginRight: '660px' })),
            state('next4-200', style({ marginLeft: '-880px', marginRight: '880px' })),
            state('next5-200', style({ marginLeft: '-1000px', marginRight: '1000px' })),
            state('prev1-200', style({ marginLeft: '+220px', marginRight: '-220px' })),
            state('prev2-200', style({ marginLeft: '+440px', marginRight: '-440px' })),
            state('prev3-200', style({ marginLeft: '+660px', marginRight: '-660px' })),
            state('prev4-200', style({ marginLeft: '+880px', marginRight: '-880px' })),
            state('prev5-200', style({ marginLeft: '+1000px', marginRight: '-1000px' })),
            state('next1-300', style({ marginLeft: '-320px', marginRight: '320px' })),
            state('next2-300', style({ marginLeft: '-640px', marginRight: '640px' })),
            state('next3-300', style({ marginLeft: '-960px', marginRight: '960px' })),
            state('next4-300', style({ marginLeft: '-1280px', marginRight: '1280px' })),
            state('prev1-300', style({ marginLeft: '+320px', marginRight: '-320px' })),
            state('prev2-300', style({ marginLeft: '+640px', marginRight: '-640px' })),
            state('prev3-300', style({ marginLeft: '+960px', marginRight: '-960px' })),
            state('prev4-300', style({ marginLeft: '+1280px', marginRight: '-1280px' })),
            state('next1-400', style({ marginLeft: '-420px', marginRight: '420px' })),
            state('next2-400', style({ marginLeft: '-840px', marginRight: '840px' })),
            state('next3-400', style({ marginLeft: '-1260px', marginRight: '1260px' })),
            state('prev1-400', style({ marginLeft: '+420px', marginRight: '-420px' })),
            state('prev2-400', style({ marginLeft: '+840px', marginRight: '-840px' })),
            state('prev3-400', style({ marginLeft: '+1260px', marginRight: '-1260px' })),

            transition('false => *', animate('300ms ease-in-out')),
            transition('* => false', animate('0ms ease-in-out')),
        ])
    ],
})

export class FibaCarouselComponent implements OnInit {

    @ContentChild(TemplateRef) template: TemplateRef<any>;
    private firstInit = true;
    private animationInAction = false;
    private _isSettingSlide = false;
    private _initSlide: any = null;
    private _preselectedSlide: any = null;
    @Input() emitOnSlidesSet = true;

    @Input()
    protected set initSlide(value: any) {
        this._initSlide = value;
        this.selectSlide(false);
    }
    protected get initSlide(): any {
        return this._initSlide;
    }

    @Input()
    protected set preSelectedSlide(value: any) {
        this._preselectedSlide = value;
        this.preSelectSlide();
    }

    @Input()
    protected set slides(value: Array<any>) {
        this.model = [];
        value.forEach(x => {
            this.model.push({ selected: false, preselected: false, slide: x });
        });
        this.selectSlide();
        this.preSelectSlide();
    };

    @Input() protected preSelectComponent;

    private selectSlide(emit: boolean = true): void {
        if (!this._isSettingSlide) {
            this._isSettingSlide = true;
            const selectedSlide = this.model.find(s => s.slide === this.initSlide);
            const index = this.model.findIndex(s => s.slide === this.initSlide);

            if (selectedSlide) {
                this.model.forEach(s => s.selected = false);
                selectedSlide.selected = true;
                if (this.firstInit) {
                    const center = Math.floor(this.model.length / 2);
                    this.shift = index - center;
                    this.nextState = this.shift > 0 ? true : false;
                    this.moveSlides();
                    //this.firstInit = false;
                }

                if (emit && this.emitOnSlidesSet) {
                    this.selectedSlide.emit(this.model.find(s => s.selected).slide);
                }
            }
            this._isSettingSlide = false;
        }
    }

    @Input()
    protected set size(value) {
        switch (value) {
            case 'small':
                this.width = 100;
                break;
            default:
            case 'normal':
                this.width = 200;
                break;
            case 'large':
                this.width = 300;
                break;
            case 'x-large':
                this.width = 400;
                break;
        }
    }

    @Input()
    protected set minHeight(value: number) {
        if (value) {
            this._minHeight = value;
        }
    }

    @Output() protected selectedSlide = new EventEmitter<any>();

    public model: { selected: boolean; preselected: boolean; slide: any }[] = [];
    width = 200;
    animate = 'false';
    private nextState: boolean = null;
    private shift = 0;
    _minHeight = 20;

    ngOnInit() {
    }

    private preSelectSlide(): void {
        if (!this._isSettingSlide) {
            this._isSettingSlide = true;

            if (this.model) {
                const preselectedSlide = this.model.find(s => s.slide === this._preselectedSlide);
                const index = this.model.findIndex(s => s.slide === this.initSlide);
                if (!!preselectedSlide) {
                    this.model.forEach(s => s.preselected = false);
                    preselectedSlide.preselected = true;
                    const selectedSlide = this.model.find(s => s.slide === this.initSlide);
                    if (!selectedSlide) {
                        const center = Math.floor(this.model.length / 2);
                        this.shift = index - center;
                        this.nextState = this.shift > 0 ? true : false;
                        this.moveSlides();
                    }
                }
            }
            this._isSettingSlide = false;
        }
    }


    next(e) {
        if (this.animate === 'false') {
            e.preventDefault();
            this.animate = 'next1-' + String(this.width);
            this.nextState = true;
            this.shift = 1;
        }
    }

    prev(e) {
        if (this.animate === 'false') {
            e.preventDefault();
            this.animate = 'prev1-' + String(this.width);
            this.nextState = false;
            this.shift = -1;
        }
    }

    private preSelectHandler(): boolean {
        return !this.preSelectComponent || this.preSelectComponent.canLeave();
    }

    async selectHandler(e, slide: any) {
        if (this.animate === 'false') {
            e.preventDefault();
            if (await this.preSelectHandler()) {
                this.firstInit = false;
                this.animationInAction = true;
                this.model.forEach(s => {
                    s.selected = s.slide === slide.slide;
                    if (s.selected) {
                        this.selectedSlide.emit(s.slide);
                    }
                });

                const index = this.model.findIndex(s => s.selected);
                const center = Math.floor(this.model.length / 2);
                this.shift = index - center;
                this.nextState = this.shift > 0;
                this.animate = (this.nextState ? 'next' : 'prev') + String(Math.abs(this.shift)) + '-' + String(this.width);
            }
        }
    }

    endEventHandler(e) {
        if (this.nextState === false || this.nextState === true) {
            this.moveSlides();
            this.animate = 'false';
            this.animationInAction = false;
            this.firstInit = true;
        }
    }

    private moveSlides() {
        if (this.nextState === false || this.nextState === true) {

            const index = this.nextState ? 0 : this.model.length - Math.abs(this.shift);

            const movedSlides = this.model.splice(0, this.nextState ? Math.abs(this.shift) : index);

            movedSlides.forEach(s => this.model.push(s));

            this.shift = 0;
            this.nextState = null;
        }
    }
}

