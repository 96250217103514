import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompetitionTechnicalDelegateReportingFormDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';
import { CompetitionWithWindowsDTO } from '../../models/dtos/generated/competition-with-windows-dto';

const COMPETITION_AUTOCOMPLETE_LIST_SIZE = 30;

@Injectable({
    providedIn: 'root',
})
export class CompetitionTechnicalDelegateReportingFormDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionTechnicalDelegateReportingForms(competitionId: number, windowId: number): Observable<CompetitionTechnicalDelegateReportingFormDTO> {
        const params = {};
        if (windowId) {
            params['windowId'] = windowId;
        }
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/technical-delegate-reporting-forms`, params);
    }

    fetchCurrentUserTechnicalDelegateReportingFormsCompetitionList(): Observable<CompetitionWithWindowsDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/current-user/technical-delegate-reporting-forms-competition-list`);
    }

    fetchCurrentUserTechnicalDelegateReportingFormsInformations(): Observable<CompetitionTechnicalDelegateReportingFormDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/current-user/technical-delegate-dashboard-forms`);
    }

    deleteBubbleTechnicalDelegateReportingForms(competitionId: number, bubbleId: number): Observable<CompetitionTechnicalDelegateReportingFormDTO> {
        return this.http.delete(`${this.competitionApiEndpoint}/${competitionId}/technical-delegate-reporting-forms/bubbles/${bubbleId}`);
    }

    deleteWindowTechnicalDelegateReportingForms(competitionId: number, windowId: number): Observable<CompetitionTechnicalDelegateReportingFormDTO> {
        return this.http.delete(`${this.competitionApiEndpoint}/${competitionId}/technical-delegate-reporting-forms/windows/${windowId}`);
    }
}
