import * as _ from 'lodash';
import {TreeviewItem} from './treeview-item';

export class TreeviewHelper {
    public static findItem(root: TreeviewItem, value: any): TreeviewItem {
        if (_.isNil(root)) {
            return undefined;
        }

        if (root.value === value) {
            return root;
        }

        if (root.children) {
            for (const child of root.children) {
                const foundItem = this.findItem(child, value);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    public static findItemInList(list: TreeviewItem[], value: any): TreeviewItem {
        if (_.isNil(list)) {
            return undefined;
        }

        for (const item of list) {
            const foundItem = this.findItem(item, value);
            if (foundItem) {
                return foundItem;
            }
        }

        return undefined;
    }

    public static findParent(root: TreeviewItem, item: TreeviewItem): TreeviewItem {
        if (_.isNil(root) || _.isNil(root.children)) {
            return undefined;
        }

        for (const child of root.children) {
            if (child === item) {
                return root;
            } else {
                const parent = this.findParent(child, item);
                if (parent) {
                    return parent;
                }
            }
        }

        return undefined;
    }

    public static removeItem(root: TreeviewItem, item: TreeviewItem): boolean {
        const parent = this.findParent(root, item);
        if (parent) {
            _.pull(parent.children, item);
            if (parent.children.length === 0) {
                parent.children = undefined;
            } else {
                parent.correctChecked();
            }
            return true;
        }

        return false;
    }
};

