import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { EventDataService } from '@fiba/data-services';
import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { Logger } from '@fiba/utils/logger';

/**
 * Component fibaMultiSelectEventStatus
 */
@Component({
    selector: 'fibaMultiSelectEventStatus',
    templateUrl: '../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectEventStatusComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})

/**
 * Class FibaMultiSelectEventStatusComponent
 *
 * @param {EventDataService} protected dataService
 * @returns
 */
export class FibaMultiSelectEventStatusComponent extends FibaMultiSelectBaseComponent {
    constructor(protected dataService: EventDataService) {
        super();
        this._valueField = 'eventStatusId';
        this._textField = 'name';
        this.value = [];
    }

    /**
     * Gets the person status from the service
     *
     * @returns A subscription
     */
    public getSubscription(): Subscription {
        return this.dataService.fetchStatuses().subscribe(
            (data) => {
                this.items = data;
                this._data = data.slice(0);
            },
            (err) => {
                Logger.error(err);
            },
            () => { },
        );
    }
}
