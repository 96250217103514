<div *ngIf="model && model.lastModificationUserName" class="filled block-top">

    <ng-container *ngIf="entityName">
        Last modification on {{entityName}} done
    </ng-container>
    <ng-container *ngIf="!entityName">
        Last modified
    </ng-container>
    <span *fibaHideIfFront>by </span>
    <span *fibaHideIfFront class="variable">{{ model?.lastModificationUserName }}</span>
    on
    <span class="variable">{{ model?.lastModificationDateTime | fibaDateTime }} (UTC)</span>
</div>
