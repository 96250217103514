import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FibaMultiSelectBaseComponent } from '@fiba/forms';
import { ThingDTO } from '@fiba/models';
import { Subscription } from 'rxjs';
import { GameDetailsDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaMultiSelectThing',
    templateUrl: '../../forms/base/fiba-multiselect-base.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaMultiSelectThingComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaMultiSelectThingComponent extends FibaMultiSelectBaseComponent {

    protected getSubscription(): Subscription {
        return this.dataService.fetchSimpleGameList(this.competitionId).subscribe(
            (data) => {
                this.items = data;
                this._data = data.slice(0);
            },
            (err) => {
                // Logger.error(err);
            },
            () => { },
        );
    }

    @Input() protected competitionId: number;

    constructor(private dataService: GameDetailsDataService) {
        super();
        this.valuePrimitive = true;
        this._textField = 'name';
        this._valueField = 'id';
    }

}
