import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class FibaPartnerFilters extends BaseFilters {
    approvalCategoryId: FilterableArray = new FilterableArray('approvalCategoryId', 'equipmentApprovalCategoryId');
    countryId: number;
    fibaRegionId: number;
    typeItemId: number;
    reset(): void {
        this.approvalCategoryId.reset();
        delete this.typeItemId;
        delete this.countryId;
        delete this.fibaRegionId;
    }
}
