import { ClubFormatPipe } from './pipes/club.pipe';
import { CommaSeparatedPipe } from './pipes/commaSeparated.pipe';
import { CountryFormatPipe } from './pipes/country.pipe';
import { FibaAuditFooterComponent } from './components/fiba-audit-footer.component';
import { FibaAutocompleteCityComponent } from '@fiba/common/forms/fiba-autocomplete-city.component';
import { FibaBackToTopButtonComponent } from './forms/fiba-back-to-top-button.component';
import { FibaCheckEqualComponent } from '@fiba/common/forms/fiba-check-equal.component';
import { FibaCommonSelectModule } from './fiba-common-select.module';
import { FibaCurrencyAmountPipe } from './pipes/fibaCurrencyAmount.pipe';
import { FibaDateNoDaysPipe } from './pipes/fibaDateNoDays.pipe';
import { FibaDatePipe } from './pipes/fibaDate.pipe';
import { FibaDateTimePipe } from './pipes/fibaDateTime.pipe';
import { FibaDisplayTextComponent } from './components/fiba-display-text.component';
import { FibaFormsModule } from '@fiba/forms/forms.module';
import { FibaGridDocumentsComponent } from '@fiba/common/components/documents/fiba-grid-documents.component';
import { FibaHideByPolicyDirective } from './directives/fiba-hide-by-policy.directive';
import { FibaHideIfBackDirective } from './directives/fiba-hide-if-back.directive';
import { FibaHideIfFrontDirective } from './directives/fiba-hide-if-front.directive';
import { FibaImageCropperComponent } from './components/fiba-image-cropper.component';
import { FibaInputUploadAzureFilesComponent } from './forms/fiba-input-upload-azure-files.component';
import { FibaInputUploadDocumentComponent } from '@fiba/common/forms/fiba-input-upload-document.component';
import { FibaInputUploadDocumentV2Component } from '@fiba/common/forms/fiba-input-upload-document-v2.component';
import { FibaPersonNamePipe } from './pipes/fibaPersonName.pipe';
import { FibaPopupDocumentComponent } from './components/fiba-popup-document.component';
import { FibaPopupDocumentRejectComponent } from './components/documents/fiba-popup-document-reject.component';
import { FibaPopupGameStructureImportComponent } from './components/fiba-popup-game-structure-import.component';
import { FibaPopupImportComponent } from './components/fiba-popup-import.component';
import { FibaPopupItemComponent } from './components/fiba-popup-item/fiba-popup-item.component';
import { FibaTooltipComponent } from './forms/fiba-tooltip/fiba-tooltip.component';
import { FibaTooltipItemComponent } from './forms/fiba-tooltip-item/fiba-tooltip-item.component';
import { FibaUtcDateTimePipe } from './pipes/fibaUtcDateTime.pipe';
import { FibaUtcOffsetPipe } from './pipes/fibaUtcOffset.pipe';
import { HeightPipe } from './pipes/height.pipe';
import { HostFormatArrayPipe, HostFormatPipe } from './pipes/host.pipe';
import { ItemToTextPipe } from './pipes/itemToText.pipe';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../app/shared.module';
import { TabListComponent } from './components/tablist.component';
import { FibaSafeHtmlPipe } from './pipes/fibaSafeHtml.pipe';
import { FibaNumericToTimePipe } from './pipes/fibaNumericToTime.pipe';
import { FibaInputUploadAzureDocumentComponent } from './forms/fiba-input-upload-azure-document.component';
import { FibaAutocompleteAirportComponent } from './forms/fiba-autocomplete-airport.component';
import { FibaUtcOffsetOnlyPipe } from './pipes/fibaUtcOffsetOnly.pipe';
import { fibaDateWithMinValuePipe } from './pipes/fibaDateWithMinValue.pipe';

const COMMON_COMPONENTS: any[] = [
    ClubFormatPipe,
    CommaSeparatedPipe,
    CountryFormatPipe,
    FibaAuditFooterComponent,
    FibaAutocompleteCityComponent,
    FibaAutocompleteAirportComponent,
    FibaBackToTopButtonComponent,
    FibaBackToTopButtonComponent,
    FibaCheckEqualComponent,
    FibaCheckEqualComponent,
    FibaCheckEqualComponent,
    FibaCurrencyAmountPipe,
    FibaDateNoDaysPipe,
    FibaDatePipe,
    FibaDateTimePipe,
    fibaDateWithMinValuePipe,
    FibaNumericToTimePipe,
    FibaDisplayTextComponent,
    FibaGridDocumentsComponent,
    FibaHideByPolicyDirective,
    FibaHideIfBackDirective,
    FibaHideIfFrontDirective,
    FibaImageCropperComponent,
    FibaImageCropperComponent,
    FibaImageCropperComponent,
    FibaImageCropperComponent,
    FibaInputUploadAzureFilesComponent,
    FibaInputUploadDocumentComponent,
    FibaInputUploadDocumentV2Component,
    FibaInputUploadAzureDocumentComponent,
    FibaPersonNamePipe,
    FibaPersonNamePipe,
    FibaPopupDocumentComponent,
    FibaPopupDocumentRejectComponent,
    FibaPopupGameStructureImportComponent,
    FibaPopupImportComponent,
    FibaPopupItemComponent,
    FibaTooltipComponent,
    FibaTooltipItemComponent,
    FibaUtcDateTimePipe,
    FibaUtcOffsetPipe,
    FibaUtcOffsetOnlyPipe,
    HeightPipe,
    HostFormatArrayPipe,
    HostFormatPipe,
    ItemToTextPipe,
    TabListComponent,
    FibaSafeHtmlPipe,
];

// Components
export { FibaPopupDocumentRejectComponent } from './components/documents/fiba-popup-document-reject.component';
export { FibaImageCropperComponent } from './components/fiba-image-cropper.component';
export { FibaPopupDocumentComponent } from './components/fiba-popup-document.component';
export { TabListItem } from './components/tablist.component';
export { FibaDatePipe } from './pipes/fibaDate.pipe';
export { FibaPersonNamePipe } from './pipes/fibaPersonName.pipe';
export { FibaSafeHtmlPipe } from './pipes/fibaSafeHtml.pipe';

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonSelectModule,
    ],
    declarations: [
        COMMON_COMPONENTS,
    ],
    exports: [
        COMMON_COMPONENTS,
    ],
})
export class FibaCommonModule {
}
