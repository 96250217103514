import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';

export class SanctionApplicationFilters extends BaseFilters {
    context: string;
    applicabilityItem: FilterableArray = new FilterableArray('applicabilityItemIds', 'itemId');
    sanctions: FilterableArray = new FilterableArray('sanctionIds', 'sanctionId');
    severity: FilterableArray = new FilterableArray('severityItemIds', 'itemId');
    caseNumber: string;
    invoiced: boolean;
    banApplicabilityItem: FilterableArray = new FilterableArray('banApplicabilityItemIds', 'itemId');
    isStatusActive: boolean;
    name: string;
    // Club filters
    public clubName: string;
    public clubCountry: Filterable = new Filterable('clubCountryId','countryId');
    public clubRelatedCountries: boolean;
    public clubZone: FilterableArray = new FilterableArray('clubZoneId', 'zoneId');

    public clubGenderItemId: Filterable = new Filterable('clubGenderItemId', 'itemId');

    reset(): void {
        this.applicabilityItem.reset();
        this.sanctions.reset();
        this.severity.reset();
        delete this.caseNumber;
        delete this.invoiced;
        this.banApplicabilityItem.reset();
        delete this.isStatusActive;
        delete this.name;
        delete this.clubGenderItemId;
    }
}
