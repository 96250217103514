import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../../app/shared.module';
import { FibaCommonModule } from '@fiba/common';
import { FibaCommonSelectModule } from '../../../../common/fiba-common-select.module';
import { FibaFormsModule } from '@fiba/forms';

import { FibaPopupClubCreatorComponent } from './fiba-popup-club-creator.component';
import { FibaPickerNationalFederationModule } from '@fiba/organisation/forms/fiba-picker-national-federation/fiba-picker-national-federation.module';

const EXTERNAL_COMPONENTS: any[] = [
    FibaPopupClubCreatorComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonModule,
        FibaCommonSelectModule,
        FibaPickerNationalFederationModule,
    ],
    declarations: [
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
})
export class FibaPopupClubCreatorModule {
}
