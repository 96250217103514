import { Observable } from 'rxjs';

export class ReportInfo {
    name: string;
    isVisible = true;
    isLoading = false;
    isError = false;
    isSuccess: boolean;
    downloadServiceFunction: (...args: any[]) => Observable<any>;
    args: any[];

    constructor(init?: Partial<ReportInfo>) {
        Object.assign(this, init);
    }
}
