import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { CompetitionTimelineDTO, CompetitionTimelinesDTO, ConfigurationMilestoneForPopupDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionTimelineConfigurationService {
    protected competitionApiEndpoint: string;
    protected milestoneConfigApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
        this.milestoneConfigApiEndpoint = config.milestoneConfigurationApiEndpoint;
    }

    fetchMilestonesConfiguration(): Observable<ConfigurationMilestoneForPopupDTO> {
        return this.http.get(this.milestoneConfigApiEndpoint);
    }

    fetchCompetitionTimelines(competitionId: number): Observable<CompetitionTimelinesDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/timelines-information`);
    }

    updateCompetitionTimelines(model: CompetitionTimelinesDTO): Observable<any> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/timelines`, model);
    }
}
