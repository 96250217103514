import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared.module';
import { FibaFormsModule } from '@fiba/forms';
import { FibaCommonModule } from './common.module';
import { FibaCommonSelectModule } from './fiba-common-select.module';

import { FibaCompetitionCommonSelectModule } from '../competition/common/fiba-competition-common-select.module';
import { FibaCompetitionCommonSharedModule } from '../competition/common/fiba-competition-common-shared.module';

import { FibaPickerEventModule } from '@fiba/event/fiba-picker-event/fiba-picker-event.module';
import { FibaPickerClubModule } from '../organisation/forms/fiba-picker-club/fiba-picker-club.module';
import { FibaPickerNationalFederationModule } from '../organisation/forms/fiba-picker-national-federation/fiba-picker-national-federation.module';

import { FibaPickerSanctionComponent } from '@fiba/common/forms/fiba-picker-sanction.component';
import { FibaPopupSanctionSearchComponent } from './components/fiba-popup-sanction-search.component';
import { SanctionSearchComponent } from './components/sanction-search.component';
import { FibaMultiSelectSanctionBanApplicabilityComponent } from './components/fiba-multiselect-sanction-ban-applicability/fiba-multiselect-sanction-ban-applicability';
import { FibaPopupSanctionModule } from './components/fiba-popup-sanction/fiba-popup-sanction.module';

const COMPONENTS: any[] = [
    FibaPopupSanctionSearchComponent,
    FibaPickerSanctionComponent,
    SanctionSearchComponent,
    FibaMultiSelectSanctionBanApplicabilityComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaCommonModule,
        FibaFormsModule,
        FibaCommonSelectModule,

        FibaCompetitionCommonSelectModule,
        FibaPickerNationalFederationModule,
        FibaPickerClubModule,
        FibaCompetitionCommonSharedModule,
        FibaPickerEventModule,
    ],
    declarations: [
        COMPONENTS,
    ],
    exports: [
        COMPONENTS,
    ],
})
export class FibaCommonSanctionModule {
}
