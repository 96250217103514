import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'height' })
export class HeightPipe implements PipeTransform {
    transform(height: number, format: string = 'cm'): string {
        switch (format) {
            default:
            case 'cm':
                return String(height || 0) + ' cm';
            case 'inch':
                height = Math.round(height);
                const feet = Math.floor(height / 12);
                const inch = Math.round(height - feet * 12);
                return String(feet).concat('\' ').concat(String(inch)).concat('"');
        }
    }
}
