import {
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { MapPolicy } from '@fiba/models';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import {AppConfig} from '@app/app.config';
import { FileType } from '@fiba/common/enums/fileType';

@Component({
    selector: 'fibaCompetitionLogo',
    templateUrl: './fiba-competition-logo.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaCompetitionLogoComponent),
            multi: true,
        },
    ],
})
export class FibaCompetitionLogoComponent implements OnChanges {
    @Input() public competitionLogoUrl: string;
    @Input() public competitionId: number = null;
    @Input() public competitionCode: string = null;
    @Input() public isEditable = false;

    @Output() public logoUrlChange: EventEmitter<string> = new EventEmitter();

    @ViewChild(TooltipDirective)
    public tooltipDir: TooltipDirective;

    public edit = false;
    public pictureUrl: string = null;

    public editCompetitionLogo: string = null;
    public isNew = true;
    public recommendedSize = '250 pixels x 150 pixels';
    public entityReference: string = null;
    public fileType: number = null;

    public readonly MapPolicy_AzureFilesEdit = MapPolicy.AzureFilesEdit;
    private readonly placeholderFilepath: string = 'static/fiba-logo-landscape-w1.png';

    protected ngAfterViewChecked(): void {
        if (this.competitionId)
        {
            this.entityReference = this.competitionId.toString();
        }
    }

    constructor(protected config: AppConfig) {
        this.fileType = FileType.competitions;
    }

    public ngOnChanges(changes): void {
        this.getPicture();
    }

    protected getPicture() {
        if (!!this.competitionLogoUrl) {
            this.pictureUrl = this.competitionLogoUrl;
        } else {
            this.pictureUrl = null;
        }
    }
    public editClick() {
        this.edit = !this.edit;
        this.editCompetitionLogo = this.pictureUrl ? this.pictureUrl : '';
        if (this.pictureUrl) {
            this.isNew = false;
        }
    }
    public saveCompetitionLogoHandler(value: string) {
        this.logoUrlChange.emit(value);
    }
    public cancelCompetitionLogoHandler() {
        this.edit = false;
        this.editCompetitionLogo = null;
    }

    getDefaultPicture(): string {
        return this.placeholderFilepath;
    }

    copyLogoToClipboard(eventTarget: any) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.pictureUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.tooltipDir.toggle(eventTarget);
        setTimeout(() => {
            this.tooltipDir.hide();
        }, 800);
    }

    deletePicture() {
        this.editCompetitionLogo = undefined;
        this.logoUrlChange.emit(undefined);
    }
}
