import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, CompetitionRankingInformationDTO, GroupPairingDTO, RoundDTO, CompetitionTeamRankDTO } from '@fiba/models';

import { HttpService } from '../../utils/http.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class CompetitionRankingDataService {
    protected competitionApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.competitionApiEndpoint = config.competitionApiEndpoint;
    }

    fetchCompetitionRankingInformation(competitionId: number): Observable<CompetitionRankingInformationDTO> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/ranking`);
    }

    updateCompetitionRankingInformation(model: CompetitionRankingInformationDTO): Observable<CompetitionRankingInformationDTO> {
        return this.http.put(`${this.competitionApiEndpoint}/${model.competitionId}/ranking`, model.teamRanks);
    }

    fetchGroupPairingsFromRound(roundId: number): Observable<GroupPairingDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/round/${roundId}/grouppairings`);
    }

    fetchRoundsFromCompetition(competitionId: number): Observable<RoundDTO[]> {
        return this.http.get(`${this.competitionApiEndpoint}/${competitionId}/rounds`);
    }
}
