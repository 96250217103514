import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputTextarea',
    templateUrl: './fiba-input-textarea.component.html',
    styleUrls: ['./fiba-input-textarea.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputTextareaComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaInputTextareaComponent extends FibaInputBase<string> {
    @Input() public placeholder: string;

    constructor(protected injector: Injector) {
        super(injector);
    }
    @Input() public maxLength: number;
}
