<div *ngIf="model.length > 0" class="fibacarousel mw400">
    <a (click)="prev($event)" class="navigation previous clickable"><i class="fa fa-chevron-left"></i></a>
    <a (click)="next($event)" class="navigation next clickable"><i class="fa fa-chevron-right"></i></a>

    <ul [@slide]="animate" (@slide.done)="endEventHandler($event)">
        <li (click)="selectHandler($event,slide)" [ngClass]="{ 'selected': slide.selected, 'pre-selected' : slide.preselected }"
            [ngStyle]="{ 'max-width' : width + 'px', 'min-width': width + 'px', 'min-height': _minHeight + 'px' }"
            *ngFor="let slide of model;">
            <ng-template [ngTemplateOutlet]="template"
                         [ngTemplateOutletContext]="{slide: slide.slide}">
            </ng-template>
        </li>
    </ul>
</div>
