<input #input="ngModel"
       type="radio"
       name="{{name}}"
       [id]="option + name"
       class="k-radio"
       [(ngModel)]="value"
       [disabled]="disabled"
       [value]="option"
       (change)="manualChange()" />
<label *ngIf="label && label.length > 0" [for]="option + name"
       class="k-radio-label">{{label}}</label>

<div *ngIf="ngControl.errors" class="validation-errors">
    <ul>
        <li [hidden]="!ngControl.errors.required">
            One option is required
        </li>
        <li [hidden]="!ngControl.errors.repeatedUniformNumber">
            {{value}} is already selected.
        </li>
    </ul>
</div>