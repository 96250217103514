import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActionMapping, ITreeOptions, TreeModel, TreeNode } from 'angular-tree-component';

export interface ITreeNodeData {
    id: string;
    name: string;
    data: any;
    children?: ITreeNodeData[];
    isExpanded?: boolean;
    isInSelectedPath?: boolean;
    isNotSelectable?: boolean;
}

interface ITreeEvent {
    eventName: string;
    node: TreeNode;
    treeModel: TreeModel;
}

const actionMapping: IActionMapping = {
    mouse: {
        click: (tree, node, $event) => {
            $event.preventDefault();
            if (!node.data.isNotSelectable) {
                node.toggleActivated();
            } else {
                node.toggleExpanded();
            }
        },
    },
};

@Component({
    selector: 'fibaTreelist',
    templateUrl: './fiba-treelist.component.html',
})
export class FibaTreelistComponent implements OnInit {
    @Input() public items: TreeNode[];

    @Output() public onSelectionChange = new EventEmitter<ITreeNodeData>();

    public options: ITreeOptions;

    protected activatedNode: TreeNode;

    public ngOnInit(): void {
        this.options = {
            allowDrag: false,
            allowDrop: false,
            nodeClass: (node: TreeNode) => {
                if ((node.data as ITreeNodeData).isInSelectedPath) {
                    return 'in-selected-path';
                }
                return null;
            },
            actionMapping,
        };

        this.activatedNode = null;
    }

    public handleActivatedNode($event: ITreeEvent): void {
        if ($event.node !== this.activatedNode) {
            $event.treeModel.doForAll((node: TreeNode) => {
                this.flagAsSelectedPath(node, false);
            });
            setTimeout(() => { // ensures that the function will be called AFTER the doForAll()
                this.flagParentsAsSelectedPath($event.node);
            }, 0);

            this.activatedNode = $event.node;

            this.onSelectionChange.emit($event.node.data as ITreeNodeData);
        }
    }

    public handleDeactivatedNode($event: ITreeEvent): void {
        if ($event.node === this.activatedNode) {
            this.activatedNode.toggleActivated(); // reactivate the node
        }
    }

    protected flagAsSelectedPath(node: TreeNode, inSelectedPath: boolean): void {
        (node.data as ITreeNodeData).isInSelectedPath = inSelectedPath;
    }

    protected flagParentsAsSelectedPath(node: TreeNode): void {
        const parent = node.realParent;
        if (parent) {
            this.flagAsSelectedPath(parent, true);
            this.flagParentsAsSelectedPath(parent);
        }
    }
}
