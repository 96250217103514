import { PlayerLicenseInputStateService } from '@fiba/player-license/services/player-license-input-state.service';

import { BaseFilters, Filterable, FilterableArray } from '../common/base.filters';
import { InputState } from '@fiba/models';
import { PlayerLicenseDTO } from '../dtos/generated/player-license-dto';
import { PlayerLicenseStatusDTO } from '../dtos/generated/player-license-status-dto';
import { ZoneDTO } from '../dtos/generated/zone-dto';
import { OrganisationDTO, PersonDTO } from '@fiba/models';

export class TransferFilters extends BaseFilters {
    personId: number;
    personFirstName: string;
    personLastName: string;
    personBirthDate: Date;
    transferStatus: FilterableArray = new FilterableArray('transferStatusId');

    personGenderItemId: number;
    nationalityId: number;
    transferDate: Date;
    typeItemId: number;
    fromClubOrganisationId: number;
    toClubOrganisationId: number;
    fromOrganisationId: number;
    toOrganisationId: number;
    fromClubOrganisation: OrganisationDTO;
    toClubOrganisation: OrganisationDTO;
    fromOrganisation: OrganisationDTO;
    toOrganisation: OrganisationDTO;
    transferId: number;
    uSLeagues: boolean;
    isInvolvedAgent: string;
    agentPerson: PersonDTO;
    agentPersonId: number;
    fromDate: Date;
    toDate: Date;


    reset(): void {
        delete this.personId;
        delete this.personFirstName;
        delete this.personLastName;
        delete this.personBirthDate;
        this.transferStatus.reset();

        delete this.personGenderItemId;
        delete this.nationalityId;
        delete this.transferDate;
        delete this.typeItemId;
        delete this.fromClubOrganisation;
        delete this.toClubOrganisation;
        delete this.fromOrganisation;
        delete this.toOrganisation;
        delete this.fromClubOrganisationId;
        delete this.toClubOrganisationId;
        delete this.fromOrganisationId;
        delete this.toOrganisationId;
        delete this.transferId;
        delete this.uSLeagues;
        delete this.isInvolvedAgent;
        delete this.agentPerson;
        delete this.agentPersonId;
        delete this.fromDate;
        delete this.toDate;

    }
}
