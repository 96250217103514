import {Input, Output, EventEmitter, Directive, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { FibaMultiInputBaseComponent } from './fiba-multi-input-base.component';

/**
 * Abstract class for MultiSelect game-information
 */
@Directive()
export abstract class FibaMultiSelectBaseComponent extends FibaMultiInputBaseComponent implements OnInit, OnDestroy {

    public _textField: string;
    public _valueField: string;
    public _data: any | any[];

    @Input() protected items: any[];
    @Input() public type = 'filter'; // filter or input
    @Input() protected valuePrimitive = true;
    @Input() disabled = false;

    @Output() protected change: EventEmitter<any> = new EventEmitter();

    private _subscription: Subscription;

    /**
     * Initialises the component
     */
    public ngOnInit(): void {
        this._subscription = this.getSubscription();
    }

    /**
     * Destroyes the component
     */
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    /**
     * Handles the filter event
     *
     * @param value
     */
    public handleFilter(value) {
        this.onTouched();
        this._data = this.items.filter((s) =>
            s[this._textField].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    /**
     * Handles the value event
     *
     * @param value
     */
    public handleValue(value) {
        this.onTouched();
        this.change.emit(value);
    }

    protected abstract getSubscription(): Subscription;

}
