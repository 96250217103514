import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentTypes } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { DocumentStatusDataService } from './document-status-data.service';
import {AppConfig} from '@app/app.config';

@Injectable({
    providedIn: 'root',
})
export class TransferRequestDocumentStatusDataService extends DocumentStatusDataService {

    private readonly transferRequestDocumentApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        super(http, config);
        this.transferRequestDocumentApiEndpoint = config.transferRequestApiEndpoint;
    }

    public get ItemDocumentTypeCode(): string {
        return DocumentTypes.TransferRequestDocument;
    }

    /**
     * Download all documents linked to the current entityId.
     *
     * @param entityId
     */
    public downloadAllDocuments(entityId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.transferRequestDocumentApiEndpoint}/${entityId}/download-all-documents`, null, 'allDocumentsTransferRequest.zip');
    }
}
